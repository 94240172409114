/* eslint-disable @typescript-eslint/no-empty-function */
import { Role } from '~/components/apollo-components';
import { intersection } from './maths';

export function noop(): void {}
export async function noopAsync(): Promise<void> {}
export type Id = string | number;

export interface IUserName {
  firstName: string;
  lastName: string;
}

export const getCombinedUsername = (user: IUserName): string => {
  return `${user.firstName} ${user.lastName}`;
};

export function isGranted<T extends { roles: Role[] }>(
  { roles: grantedRoles }: T,
  ...neededRoles: Role[]
): boolean {
  return intersection(grantedRoles, neededRoles).length > 0;
}

export interface IModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk?: () => void;
}
