import { ButtonType } from 'antd/lib/button';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { IStyledButtonProps, SecondaryButton } from '~/utils/styles';

interface IProps extends IStyledButtonProps {
  children: React.ReactNode;
  to: string;
  type?: ButtonType;
  target?: string;
}

export const SecondaryLinkButton: FunctionComponent<IProps> = ({
  children,
  to,
  type = 'primary',
  target,
  ...rest
}: IProps) => (
  <Link to={to} target={target}>
    <SecondaryButton type={type} {...rest}>
      {children}
    </SecondaryButton>
  </Link>
);
