import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useList } from 'react-use';
import { BreadcrumbContext, IBreadcrumb } from '~/contexts/BreadcrumbContext';

type SetFunction<T> = Dispatch<SetStateAction<T>>;

interface IProps {
  children: React.ReactNode;
}

export const BreadcrumbProvider: FunctionComponent<IProps> = ({
  children,
}: IProps) => {
  /**
   * This is needed to determine wether `addBreadcrumb` should prepend or append to the `items`array.
   */
  const isTopLevelRenderFinished = useRef(false);
  const [items, { set, filter }] = useList<IBreadcrumb>();

  const addBreadcrumb = useCallback(
    /**
     * `topLevelRenderFinished` is only `true` when called by theup
     * most route (`Motorex` in this case).
     */
    (item: IBreadcrumb, topLevelRenderFinished?: boolean) => {
      const prepend = !isTopLevelRenderFinished.current;
      ((set as unknown) as SetFunction<IBreadcrumb[]>)((currentItems) =>
        prepend ? [item, ...currentItems] : [...currentItems, item],
      );
      if (topLevelRenderFinished) {
        isTopLevelRenderFinished.current = topLevelRenderFinished;
      }
      return () => {
        filter((breadcrumb) => breadcrumb !== item);
      };
    },
    [filter, set],
  );

  const value = useMemo(() => ({ items, addBreadcrumb }), [
    items,
    addBreadcrumb,
  ]);

  return (
    <BreadcrumbContext.Provider value={value}>
      {children}
    </BreadcrumbContext.Provider>
  );
};
