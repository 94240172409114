import React, { FunctionComponent } from 'react';
import { Box, Flex } from 'reflexbox';
import { ReactComponent as Logo } from '~/assets/logo.svg';
import Background from '~/assets/error-background.svg';
import { Role } from '~/components/apollo-components';
import { useAuthentication } from '~/hooks/useAuthentication';
import { styled } from '~/theme';
import { Footer } from './Footer';
import { AdminHeader } from './Header/AdminHeader';
import { UserHeader } from './Header/UserHeader';
import { isGranted } from '~/utils/common';

interface IProps {
  children: React.ReactNode;
}

const Container = styled.div`
  background: url(${Background}) no-repeat top center fixed;
  background-size: cover;
  background-position: center;
`;

const Wrapper = styled(Flex)`
  min-height: calc(100vh - 48px);
`;

export const ErrorLayout: FunctionComponent<IProps> = ({
  children,
}: IProps) => {
  const { currentUser } = useAuthentication();

  return (
    <Container>
      {currentUser != null &&
        (isGranted(currentUser, Role.ADMIN) ? <AdminHeader /> : <UserHeader />)}
      <Wrapper
        flexDirection="column"
        p={{ _: '100px 150px', xl: '250px 50px 100px 200px' }}
      >
        <Box width={0.4}>
          <Logo width={318} height={175} />
          <Box pl="22px" pt={4}>
            {children}
          </Box>
        </Box>
      </Wrapper>
      <Footer authenticationLayout={true} />
    </Container>
  );
};
