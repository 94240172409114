import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '~/theme';
import { SecondaryButton } from '~/utils/styles';
import { MinusOutlined } from '@ant-design/icons';
import { Flex } from 'reflexbox';
const ButtonText = styled.span`
  margin-left: 0.5rem;
`;

interface IRemoveChartButtonProps {
  onClick: () => void;
}

export const RemoveChartButton: FunctionComponent<IRemoveChartButtonProps> = ({
  onClick,
}: IRemoveChartButtonProps) => {
  const { t } = useTranslation('EasyMonitoring');

  return (
    <Flex width={1 / 3} justifyContent="flex-end">
      <SecondaryButton
        onClick={onClick}
        icon={<MinusOutlined translate="no" />}
        type="primary"
      >
        <ButtonText>{t('Hide graph')}</ButtonText>
      </SecondaryButton>
    </Flex>
  );
};
