export const termsAndConditions = {
  'General Terms of Sale and Delivery':
    'Allgemeine Verkaufs- und Lieferbedingungen (AVLB)',

  'These General Terms of Sale and Delivery are binding if the Seller delivers the ordered goods or if it is stated that the General Terms of Sale and Delivery are applicable. Any other conditions of the Buyer differing from these terms are only valid if expressly accepted in writing by the Seller.':
    'Diese AVLB sind verbindlich, wenn die Verkäuferin die bestellte Ware ausliefert oder wenn die AVLB mit der Auftragsbestätigung als anwendbar erklärt werden. Anderslautende Bedingungen des Käufers haben nur Gültigkeit, soweit sie von der Verkäuferin ausdrücklich und schriftlich angenommen worden sind.',
  'The contract of sale shall be considered to have been agreed if, when the order has been received, the Seller acknowledges it in writing or the goods have been delivered without any reservations.':
    'Der Kaufvertrag gilt als abgeschlossen, wenn die Verkäuferin nach Eingang einer Bestellung deren Annahme schriftlich bestätigt hat oder die Ware ohne Vorbehalte ausgeliefert hat.',
  'Where quantities and weights are stated in orders, the Seller shall be entitled to make deliveries of up to 10% above or below the stated figure. The weights at departure shall be decisive.':
    'Mengen und Gewichtsangaben bei Bestellungen berechtigen zu Mehr- und Minderlieferungen bis zu 10 %. Massgebend sind die Abgangsgewichte.',
  'Unless agreed otherwise in writing, the Seller’s prices shall be net, ex works and in Swiss Francs. Drums and packaging are – with the exception of containers and reusable receptacles – included in the price.':
    'Die Preise der Verkäuferin verstehen sich, soweit nichts anderes schriftlich vereinbart wird, netto, ab Werk, in Schweizerfranken. Gebinde und Verpackungen sind – mit Ausnahme von Containern und Mehrweggebinden – im Preis inbegriffen.',
  'The terms of payment shall be 30 days net from the date of the invoice. Variations to these terms or conditions of payment shall only be valid and binding if made in writing. The purchaser shall effect payments at the Seller’s domicile, with no deductions in respect of cash payments, bank charges, taxes and fees of any kind. If payment is delayed, the Seller reserves the right to stop outstanding deliveries and is entitled to charge interest on overdue payments at a rate of 6% p.a.':
    'Die Zahlungsfrist beträgt 30 Tage netto ab Rechnungsdatum. Es können abweichende Zahlungsfristen oder Zahlungskonditionen vereinbart werden. Damit sie gültig und bindend sind, muss dies in schriftlicher Form erfolgen. Für Lieferungen in Länder ohne schriftlich definierte Zahlungsfristen erfolgt die Zahlung durch ein unwiderrufliches und durch eine Schweizer Bank bestätigtes Akkreditiv. Die Zahlungen sind vom Käufer am Domizil der Verkäuferin ohne Abzug von Skonto, Spesen, Steuern und Gebühren irgendwelcher Art zu leisten. Bei Zahlungsverzug behält sich die Verkäuferin die sofortige Einstellung von offenen Lieferungen vor und ist berechtigt, einen Verzugszins von 6 % p.a. zu berechnen.',
  'The Seller shall retain the ownership of the delivery until the goods have been paid for in full. The purchaser has a duty to take necessary measures to protect the Seller’s property. The Seller is entitled to have his property secured through suitable measures.':
    'Die Verkäuferin behält sich das Eigentum an der Lieferung bis zu deren vollständigen Bezahlung vor. Der Käufer ist verpflichtet, die zum Schutz des Eigentums der Verkäuferin erforderlichen Massnahmen zu treffen. Die Verkäuferin ist berechtigt, ihr Eigentum durch geeignete Massnahmen sichern zu lassen.',
  'The delivery period shall commence with the acceptance of the order by the Seller. The delivery periods shall be extended accordingly if':
    'Die Lieferfrist beginnt mit der Annahme der Bestellung durch die Verkäuferin. Die Lieferfrist wird angemessen verlängert',
  'Information required to execute the order is not supplied to the Seller in good time, or if this information is subsequently changed by the purchaser.':
    'wenn die Angaben, die für die Ausführung der Bestellung benötigt werden, der Verkäuferin nicht rechtzeitig zugehen, oder wenn diese durch den Käufer nachträglich abgeändert werden;',
  'Payment is not made by the stipulated date, the letter of credit has been opened too late or if the necessary import licences are not received by the Seller in due time.':
    'wenn Zahlungsfristen nicht eingehalten werden, Akkreditive zu spät eröffnet werden oder erforderliche Importlizenzen nicht rechtzeitig bei der Verkäuferin eintreffen;',
  'If obstacles arise which the Seller cannot avoid in spite of taking the necessary care, irrespective of whether these occur at the Seller, the purchaser or a third party. Such obstacles also include force majeure such as epidemics, mobilization of troops, war, riot, serious disruptions to the business, accidents, industrial conflicts, delayed or incorrect delivery of the necessary raw materials, semi-finished or finished products, irresolvable problems with important tools that causes them to be scrapped, measures imposed by the authorities or omissions, and natural occurrences.':
    'wenn Hindernisse auftreten, die die Verkäuferin trotz Anwendung der gebotenen Sorgfalt nicht abwenden kann, ungeachtet ob diese bei der Verkäuferin, beim Käufer oder einem Dritten entstehen. Solche Hindernisse sind auch Vorkommnisse höherer Gewalt, beispielsweise Epidemien, Mobilmachung, Krieg, Aufruhr, erhebliche Betriebsstörungen, Unfälle, Arbeitskonflikte, verspätete oder fehlerhafte Zulieferung der benötigten Rohmaterialien, Halb- oder Fertigfabrikate, Ausschusswerden von wichtigen Werkstücken, behördliche Massnahmen oder Unterlassungen, Naturereignisse.',
  'The Seller shall be advised in good time of special requests relating to the shipment and insurance of the goods. Transport shall be at the expense and risk of the purchaser. Complaints in relation to transport shall be made without delay by the purchaser to the last freight forwarder upon receipt of the delivery or freight documents. The purchaser shall be responsible for insurance against losses of all kinds. Even if the Seller has to take out the insurance, it shall be charged to the purchaser.':
    'Besondere Wünsche betreffend Versand und Versicherung sind der Verkäuferin rechtzeitig bekanntzugeben. Der Transport erfolgt auf Rechnung und Gefahr des Käufers. Beschwerden im Zusammenhang mit dem Transport sind vom Käufer bei Erhalt der Lieferung oder der Frachtdokumente unverzüglich an den letzten Frachtführer zu richten. Die Versicherung gegen Schäden irgendwelcher Art obliegt dem Käufer. Auch wenn sie von der Verkäuferin abzuschliessen ist, geht sie auf Rechnung des Käufers.',
  'Any complaints regarding volume of delivery, packing or other defects are to be addressed from the Buyer to the Seller in writing within 8 days after receipt of goods at the latest.':
    'Beanstandungen über Lieferumfang, Verpackung und andere Mängel sind vom Käufer der Verkäuferin spätestens innert 8 Tagen seit Empfang schriftlich anzuzeigen.',
  'The Seller’s guarantees include':
    'Die Gewährleistungen der Verkäuferin umfassen',
  'Seller warrants that the Products delivered to Buyer pursuant to the contract of sale as defined herein are free of manufacturing faults and material flaws and that they conform to the specifications expressly described in the order acknowledgment pursuant to Clause 2 hereinabove, in the instructions for use and the specification sheet, with the tolerances applicable in the relevant industry.':
    'Die Verkäuferin garantiert, dass die dem Käufer entsprechend dem hier beschriebenen Kaufvertrag gelieferten Produkte frei von Fabrikations- und Materialfehler sind und dass sie den in der Auftragsbestätigung laut Ziff. 2 oben, in der Bedienungsanleitung und im Datenblatt ausdrücklich beschriebenen Spezifikation entsprechen, allenfalls mit den branchenüblichen Fertigungstoleranzen.',
  'Buyer understands and acknowledges that the acceptability and suitability of the Products for a particular use is solely within its own discretionary power to decide. Seller makes no other warranty, written or oral, statutory, express or implied, including but not limited to merchantability or fitness for a particular purpose.':
    'Der Käufer nimmt zur Kenntnis und ist damit einverstanden, dass der Entscheid über Einsatz und Eignung der Produkte für einen speziellen Gebrauch in seiner alleinigen Verantwortung steht. Die Verkäuferin gewährt keine weitergehende Garantie, weder schriftlich noch mündlich, weder ausdrücklich noch sinngemäss, weder hinsichtlich allgemeiner Eignung noch hinsichtlich speziellen Einsatzzwecks.',
  'The warranty is void unless the Buyer inspects the products and notifies Seller in writing within 8 days upon delivery of the Products of any rejections based on non conformity to specifications or of defects in the Products.':
    'Jeder Garantieanspruch erlischt, falls der Käufer die Produkte nicht innerhalb von 8 Tagen nach Lieferung prüft und die Verkäuferin über allfällige Abweichungen von den Spezifikationen oder über Mängel informiert.',
  'In the event of defective or non conformity to specifications of Products, Seller’ warranty is limited to a replacement delivery of the defective products subject to Buyer returning the defective goods to Seller. More particularly, Buyer understands and acknowledges that depending the items on which the Product is used, a proper cleaning and entire removal of any residues and remains thereof is required for which Buyer is exclusively responsible. As a consequence, Seller shall not be liable for any other direct, indirect and/or consequential damages, losses and causes of action, whether in contract, tort, including negligence or otherwise.':
    'Im Fall von defekten Produkten oder Abweichungen von den Spezifikationen beschränkt sich die Garantie der Verkäuferin auf den Ersatz der vom Käufer zurückgesandten Produkte. Der Käufer nimmt zur Kenntnis und ist damit einverstanden, dass er je nach Material auf dem das Produkt angewendet wird, für die einwandfreie Reinigung des Materials und für dessen Befreiung von Resten des Produkts verantwortlich ist. Die Verkäuferin lehnt jede Haftung für weiteren direkten oder indirekten Schaden oder Folgeschäden ab, weder auf Grund vertraglicher oder Verschuldenshaftung einschliesslich Fahrlässigkeit oder anderes.',
  'These general terms of sale and delivery shall be subject to Swiss law and shall exclude the United Nations Convention on Contracts for the International Sales of Goods (Vienna Sales Convention) dated 11 April 1980. The only place of jurisdiction shall be Langenthal, Switzerland.':
    'Die vorliegenden Allgemeinen Verkaufs- und Lieferbedingungen unterliegen schweizerischem Recht, unter Ausschluss des Übereinkommens der Vereinigten Nationen über Verträge über den internationalen Warenkauf (Wiener Kaufrecht) vom 11. April 1980. Der alleinige Gerichtsstand ist Langenthal, Schweiz.',
};
