import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Role } from '~/components/apollo-components';
import { styled } from '~/theme';
import { RoleTile } from './RoleTile';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.grey.light};
  padding: 10px 20px;
  justify-content: space-around;
  border-radius: ${({ theme }) => theme.defaults.borderRadius};
  border: ${({ theme }) => `1px solid ${theme.colors.grey.dark}`};

  > div {
    width: 30%;
  }
`;

interface IProps {
  selectedRoles: Role[];
  onUpdate: (roles: Role[]) => void;
}

const ALL_ROLES: { role: Role; icon: string }[] = [
  { role: Role.USER, icon: 'user' },
  { role: Role.ADMIN, icon: 'admin' },
];

export const RoleSelection: FunctionComponent<IProps> = ({
  selectedRoles,
  onUpdate,
}: IProps) => {
  const toggleRole = useCallback(
    (role: Role) => {
      const updateRoles = selectedRoles.includes(role)
        ? selectedRoles.filter((r) => r !== role)
        : [...selectedRoles, role];
      onUpdate(updateRoles);
    },
    [selectedRoles, onUpdate],
  );

  const rolesToDisplay = useMemo(
    () =>
      ALL_ROLES.map((role) => ({
        ...role,
        selected: selectedRoles.includes(role.role),
      })),
    [selectedRoles],
  );

  return (
    <Wrapper>
      {rolesToDisplay.map(({ role, selected, icon }) => (
        <RoleTile
          key={role}
          role={role}
          selected={selected}
          icon={icon}
          toggleRole={toggleRole}
        />
      ))}
    </Wrapper>
  );
};
