import { Menu, Dropdown, Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import { styled } from '~/theme';
import { ChemicalType } from '~/components/apollo-components';
import { getTranslationForChemicals } from '~/pages/EasyMonitoring/Dashboard/LastMeasurementsCard/getTranslationForChemicals.tsx';
import { PrimaryText } from '~/utils/styles';

const StyledText = styled(PrimaryText)`
  color: ${({ theme }) => theme.colors.primary};
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledButton = styled(Button)`
  white-space: normal;
  text-align: left;
  padding: 0;
`;

const StyledFilterOutlined = styled(FilterOutlined)`
  font-size: 1.2rem;
`;

interface IFilterMeasurementsDropdownProps {
  filter: ChemicalType | null;
  setFilter: (filter: ChemicalType | null) => void;
}

export const FilterMeasurementsDropdown: FunctionComponent<IFilterMeasurementsDropdownProps> = ({
  filter,
  setFilter,
}: IFilterMeasurementsDropdownProps) => {
  const { t } = useTranslation('EasyMonitoring');

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setFilter(null)}>{t('common:All')}</Menu.Item>

      {Object.keys(ChemicalType).map((chemical) => {
        if (![ChemicalType.CONCENTRATION].includes(chemical as ChemicalType)) {
          const label = getTranslationForChemicals(t)[chemical];
          return (
            <Menu.Item
              onClick={() => setFilter(ChemicalType[chemical as ChemicalType])}
              key={chemical}
            >
              {label}
            </Menu.Item>
          );
        }

        return null;
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <StyledButton type="link" block={true}>
        <StyledText>
          <StyledFilterOutlined translate="no" />
          {filter
            ? getTranslationForChemicals(t)[filter]
            : t('Filter by measurement values')}
        </StyledText>
      </StyledButton>
    </Dropdown>
  );
};
