import { Modal } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { formatFromISO } from '~/utils/dates';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  name: string;
}

export const DeleteMeasurementModal: FunctionComponent<IProps> = ({
  visible,
  onCancel,
  onOk,
  name,
}: IProps) => {
  const { t } = useTranslation('EasyMonitoring');
  const parsedName = formatFromISO(name);
  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
        title={t('Delete measurement')}
      >
        <p>
          {t(`Are you sure you want to delete the measurement from {{name}}?`, {
            name: parsedName,
          })}
        </p>
      </Modal>
    </>
  );
};
