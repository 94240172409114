import { COMPANY_ALREADY_CREATED_MESSAGE } from '@motorex/common';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import {
  CreateCompanyInput,
  useCreateCompanyMutation,
} from '~/components/apollo-components';
import { AdminLayout } from '~/components/AdminLayout';
import { Content } from '~/components/Content';
import { CompanyForm } from '~/components/CompanyForm';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { createNotification } from '~/utils/createNotification';
import { Routes } from '~/utils/Routes';

export const CreateCompany: FunctionComponent<RouteComponentProps> = ({
  history,
}: RouteComponentProps) => {
  const { t } = useTranslation(['Admin', 'common']);
  const [update, { loading }] = useCreateCompanyMutation();
  const handleError = useErrorHandler();

  const onSubmit = useCallback(
    async (values: CreateCompanyInput) => {
      try {
        await update({
          variables: values,
        });

        createNotification('success', t('Company successfully created.'));
        history.push(Routes.admin.companies.base);
      } catch (e) {
        handleError(e, t('common:Creation failed'), {
          [COMPANY_ALREADY_CREATED_MESSAGE]: t(
            `common:${COMPANY_ALREADY_CREATED_MESSAGE}`,
          ),
        });
      }
    },
    [t, update, history, handleError],
  );

  const onCancel = useCallback(
    () => history.push(Routes.admin.companies.base),
    [history],
  );

  return (
    <AdminLayout>
      <Content small={true}>
        <CompanyForm
          loading={loading}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Content>
    </AdminLayout>
  );
};
