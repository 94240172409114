import React, { FunctionComponent, ReactNode } from 'react';
import { styled } from '~/theme';

const Icon = styled.svg`
  width: 38px;
  height: 38px;
  margin: 0.3rem;
`;
interface ISocialMediaIconProps {
  link: string;
  //icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  icon: ReactNode;
}

export const SocialMediaIcon: FunctionComponent<ISocialMediaIconProps> = ({
  link,
  icon,
}: ISocialMediaIconProps) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Icon>{icon}</Icon>
    </a>
  );
};
