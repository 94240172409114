import { Button, Descriptions } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MachineFragment,
  MeasurementType,
} from '~/components/apollo-components';
import { DetailStatus } from '~/pages/EasyMonitoring/MachineDetail/DetailOverviewCard/DetailStatus';
import { styled } from '~/theme';
import { getCombinedUsername, IUserName } from '~/utils/common';
import { formatFromISO } from '~/utils/dates';
import { ButtonRow } from '~/utils/styles';

const { Item: DescriptionItem } = Descriptions;

const EditButtonRow = styled(ButtonRow)`
  justify-content: flex-start;
`;

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-row:not(:last-of-type) {
    > th {
      padding-bottom: 0;
    }

    > td {
      padding-bottom: 1.25rem;
    }
  }
`;

export interface IDetailsFormValues {
  productName: string;
}

interface IDetailDisplayProps {
  machine: MachineFragment;
  edit: boolean;
  handleSubmit: (
    values: IDetailsFormValues,
    formikHelpers: FormikHelpers<IDetailsFormValues>,
  ) => void;
  handleCancel: () => void;
}

export const Details: FunctionComponent<IDetailDisplayProps> = ({
  machine,
  edit,
  handleSubmit,
  handleCancel,
}: IDetailDisplayProps) => {
  const { t } = useTranslation(['EasyMonitoring']);

  return (
    <Formik<IDetailsFormValues>
      initialValues={{ productName: machine.productName }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <StyledDescriptions layout="vertical" size="small" column={1}>
            {machine.company != null && (
              <DescriptionItem label={t('common:Customer')}>
                {machine.company?.name ?? machine.user != null
                  ? getCombinedUsername(machine.user as IUserName)
                  : '-'}
              </DescriptionItem>
            )}
            <DescriptionItem label={t('Product')}>
              {edit ? <Input name="productName" /> : machine.productName ?? '-'}
            </DescriptionItem>
            <DescriptionItem label={t('Measurement Type')}>
              {t(machine.measurementType)}
            </DescriptionItem>
            {machine.measurementType === MeasurementType.EMULSION && (
              <DescriptionItem label={t('Factor')}>
                {machine.factor.toFixed(2)}
              </DescriptionItem>
            )}
            <DescriptionItem label={t('Last Measurement')}>
              {formatFromISO(machine.updatedAt)}
            </DescriptionItem>
            <DescriptionItem label={t('Measured by')}>
              {machine.lastMeasurer
                ? getCombinedUsername(machine.lastMeasurer)
                : '-'}
            </DescriptionItem>
            <DescriptionItem label={t('Status')}>
              <DetailStatus
                lastMeasurement={
                  machine.measurements != null
                    ? machine.measurements.items[0]
                    : null
                }
              />
            </DescriptionItem>
          </StyledDescriptions>
          {edit && (
            <EditButtonRow>
              <Button
                type="link"
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                {t('common:Cancel')}
              </Button>
              <SubmitButton type="primary" disabled={isSubmitting}>
                {t('common:Save')}
              </SubmitButton>
            </EditButtonRow>
          )}
        </Form>
      )}
    </Formik>
  );
};
