import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client';
import { from, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import i18n from '../i18n';
import { createNotification } from './createNotification';

export type MotorexClient = ApolloClient<any>;
export const createApolloClient = (): MotorexClient => {
  // Create HttpLink to include cookies
  const httpLink = createHttpLink({
    credentials: 'include',
    uri: '/graphql',
  });

  // Create Websocket connection for GraphQL subscriptions
  const wsLink = new WebSocketLink({
    options: {
      inactivityTimeout: 100,
      lazy: true,
      reconnect: true,
    },
    uri: `${window.location.origin.replace(/^http(s)?:\/\//, 'ws://')}/graphql`,
  });

  const errorLink = onError(({ networkError, response }) => {
    // Check for networking errors
    if (networkError && networkError.message === 'Failed to fetch') {
      if (response != null) response.errors = undefined;
      createNotification(
        'error',
        i18n.t('Network Error'),
        i18n.t('Could not fetch data. Please check your connection.'),
      );
      (networkError as any).handled = true;
    }
  });

  const link = from([
    errorLink,
    split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    ),
  ]);

  // Create the client
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    resolvers: {},
  });

  return client;
};
