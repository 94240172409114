import React, { FunctionComponent } from 'react';
import { StaticPage } from '~/components/StaticPage';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { styled } from '~/theme';
import {
  StyledSubParagraph,
  StyledCard,
  StyledTitle,
  StyledListItem,
} from '~/utils/staticPageStyling';

const { Title, Paragraph } = Typography;

const BoldBlock = styled.span`
  font-weight: bold;
`;

const StyledList = styled.ul`
  padding-top: 0.8rem;
`;

const StyledParagraph = styled(Paragraph)`
  letter-spacing: 1px;
  line-height: 22px;
`;

const StyledSubTitle = styled(Title)`
  && {
    font-size: 1.2rem;
    font-weight: normal;
  }
`;

const StyledBodyTitle = styled(Title)`
  && {
    font-size: 1.5rem;
    font-weight: normal;
  }
`;

const CompactBlock = styled.div`
  padding-bottom: 1rem;
`;

const CompactParagraph = styled(Paragraph)`
  && {
    margin-bottom: 0;
  }
  letter-spacing: 1px;
  line-height: 22px;
`;

export const PrivacyProtection: FunctionComponent = () => {
  const { t } = useTranslation('privacyProtection');

  return (
    <StaticPage>
      <StyledCard>
        <StyledTitle level={2}>{t('Privacy Policy')}</StyledTitle>
        <StyledParagraph>
          {t(
            'Personal data (usually referred to just as "data" below) will only be processed by us to the extent necessary and for the purpose of providing a functional and user-friendly website, including its contents, and the services offered there.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection Regulation (hereinafter referred to as the "GDPR"), "processing" refers to any operation or set of operations such as collection, recording, organization, structuring, storage, adaptation, alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making available, alignment, or combination, restriction, erasure, or destruction performed on personal data, whether by automated means or not.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The following privacy policy is intended to inform you in particular about the type, scope, purpose, duration, and legal basis for the processing of such data either under our own control or in conjunction with others. We also inform you below about the third-party components we use to optimize our website and improve the user experience which may result in said third parties also processing data they collect and control.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t('Our privacy policy is structured as follows')}
        </StyledParagraph>
        <CompactParagraph>
          {t('I. Information about us as controllers of your data')}
        </CompactParagraph>
        <CompactParagraph>
          {t('II. The rights of users and data subjects')}
        </CompactParagraph>
        <CompactParagraph>
          {t('III. Information about the data processing')}
        </CompactParagraph>
        <StyledBodyTitle>
          {t('I. Information about us as controllers of your data')}
        </StyledBodyTitle>
        <StyledParagraph>
          {t(
            'The party responsible for this website (the "controller") for purposes of data protection law is',
          )}
        </StyledParagraph>
        <CompactBlock>
          <CompactParagraph>Bucher AG Langenthal</CompactParagraph>
          <CompactParagraph> Bern-Zürich-Strasse 31</CompactParagraph>
          <CompactParagraph> 4901 Langenthal</CompactParagraph>
          <CompactParagraph> Schweiz</CompactParagraph>
        </CompactBlock>
        <CompactBlock>
          <CompactParagraph>
            {t('Telephone')}: +41 62 919 75 75
          </CompactParagraph>
          <CompactParagraph>{t('Fax')}: +41 62 919 75 95</CompactParagraph>
          <CompactParagraph>
            {t('Email')}:{' '}
            <a
              href="mailto:info@motorex.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@motorex.com
            </a>
          </CompactParagraph>
        </CompactBlock>
        <StyledBodyTitle>
          {t('II. The rights of users and data subjects')}
        </StyledBodyTitle>
        <StyledParagraph>
          {t(
            'With regard to the data processing to be described in more detail below, users and data subjects have the right',
          )}
          <StyledList>
            <StyledListItem>
              {t(
                'to confirmation of whether data concerning them is being processed, information about the data being processed, further information about the nature of the data processing, and copies of the data (cf. also Art. 15 GDPR);',
              )}
            </StyledListItem>
            <StyledListItem>
              {t(
                'to correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);',
              )}
            </StyledListItem>
            <StyledListItem>
              {t(
                'to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO), or, alternatively, if further processing is necessary as stipulated in Art. 17 Para. 3 GDPR, to restrict said processing per Art. 18 GDPR;',
              )}
            </StyledListItem>
            <StyledListItem>
              {t(
                'to receive copies of the data concerning them and/or provided by them and to have the same transmitted to other providers/controllers (cf. also Art. 20 GDPR);',
              )}
            </StyledListItem>
            <StyledListItem>
              {t(
                'to file complaints with the supervisory authority if they believe that data concerning them is being processed by the controller in breach of data protection provisions (see also Art. 77 GDPR).',
              )}
            </StyledListItem>
          </StyledList>
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'In addition, the controller is obliged to inform all recipients to whom it discloses data of any such corrections, deletions, or restrictions placed on processing the same per Art. 16, 17 Para. 1, 18 GDPR. However, this obligation does not apply if such notification is impossible or involves a disproportionate effort. Nevertheless, users have a right to information about these recipients.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          <BoldBlock>
            {t(
              "Likewise, under Art. 21 GDPR, users and data subjects have the right to object to the controller's future processing of their data pursuant to Art. 6 Para. 1 lit. f) GDPR. In particular, an objection to data processing for the purpose of direct advertising is permissible.",
            )}
          </BoldBlock>
        </StyledParagraph>
        <StyledBodyTitle>
          {t('III. Information about the data processing')}
        </StyledBodyTitle>
        <StyledParagraph>
          {t(
            'Your data processed when using our website will be deleted or blocked as soon as the purpose for its storage ceases to apply, provided the deletion of the same is not in breach of any statutory storage obligations or unless otherwise stipulated below.',
          )}
        </StyledParagraph>
        <StyledSubTitle>{t('Server data')}</StyledSubTitle>
        <StyledParagraph>
          {t(
            'For technical reasons, the following data sent by your internet browser to us or to our server provider will be collected, especially to ensure a secure and stable website. These server log files record the type and version of your browser, operating system, the website from which you came (referrer URL), the webpages on our site visited, the date and time of your visit, as well as the IP address from which you visited our site.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The data thus collected will be temporarily stored, but not in association with any other of your data.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The basis for this storage is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the improvement, stability, functionality, and security of our website.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The data will be deleted within no more than seven days, unless continued storage is required for evidentiary purposes. In which case, all or part of the data will be excluded from deletion until the investigation of the relevant incident is finally resolved.',
          )}
        </StyledParagraph>
        <StyledSubTitle>{t('Cookies')}</StyledSubTitle>
        <StyledSubParagraph>{t('a) Session cookies')}</StyledSubParagraph>
        <StyledParagraph>
          {t(
            'We use cookies on our website. Cookies are small text files or other storage technologies stored on your computer by your browser. These cookies process certain specific information about you, such as your browser, location data, or IP address.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'This processing makes our website more user-friendly, efficient, and secure, allowing us, for example, to display our website in different languages or to offer a shopping cart function.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The legal basis for such processing is Art. 6 Para. 1 lit. b) GDPR, insofar as these cookies are used to collect data to initiate or process contractual relationships.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'If the processing does not serve to initiate or process a contract, our legitimate interest lies in improving the functionality of our website. The legal basis is then Art. 6 Para. 1 lit. f) GDPR.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t('When you close your browser, these session cookies are deleted.')}
        </StyledParagraph>
        <StyledSubParagraph>{t('b) Third-party cookies')}</StyledSubParagraph>
        <StyledParagraph>
          {t(
            'If necessary, our website may also use cookies from companies with whom we cooperate for the purpose of advertising, analyzing, or improving the features of our website.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'Please refer to the following information for details, in particular for the legal basis and purpose of such third-party collection and processing of data collected through cookies.',
          )}
        </StyledParagraph>
        <StyledSubParagraph>{t('c) Disabling cookies')}</StyledSubParagraph>
        <StyledParagraph>
          {t(
            'You can refuse the use of cookies by changing the settings on your browser. Likewise, you can use the browser to delete cookies that have already been stored. However, the steps and measures required vary, depending on the browser you use. If you have any questions, please use the help function or consult the documentation for your browser or contact its maker for support. Browser settings cannot prevent so-called flash cookies from being set. Instead, you will need to change the setting of your Flash player. The steps and measures required for this also depend on the Flash player you are using. If you have any questions, please use the help function or consult the documentation for your Flash player or contact its maker for support.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'If you prevent or restrict the installation of cookies, not all of the functions on our site may be fully usable.',
          )}
        </StyledParagraph>
        <StyledBodyTitle>{t('Contact')}</StyledBodyTitle>
        <StyledParagraph>
          {t(
            'If you contact us via email or the contact form, the data you provide will be used for the purpose of processing your request. We must have this data in order to process and answer your inquiry; otherwise we will not be able to answer it in full or at all.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The legal basis for this data processing is Art. 6 Para. 1 lit. b) GDPR.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'Your data will be deleted once we have fully answered your inquiry and there is no further legal obligation to store your data, such as if an order or contract resulted therefrom.',
          )}
        </StyledParagraph>
        <StyledBodyTitle>{t('Google Analytics')}</StyledBodyTitle>
        <StyledParagraph>
          {t(
            'We use Google Analytics on our website. This is a web analytics service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 (hereinafter Google).',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t('Through certification according to the EU-US Privacy Shield')}
        </StyledParagraph>
        <StyledParagraph>
          <a
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
          </a>
        </StyledParagraph>
        <StyledParagraph>
          {t(
            "Google guarantees that it will follow the EU's data protection regulations when processing data in the United States.",
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The Google Analytics service is used to analyze how our website is used. The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the analysis, optimization, and economic operation of our site.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'Usage and user-related information, such as IP address, place, time, or frequency of your visits to our website will be transmitted to a Google server in the United States and stored there. However, we use Google Analytics with the so-called anonymization function, whereby Google truncates the IP address within the EU or the EEA before it is transmitted to the US.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The data collected in this way is in turn used by Google to provide us with an evaluation of visits to our website and what visitors do once there. This data can also be used to provide other services related to the use of our website and of the internet in general.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'Google states that it will not connect your IP address to other data. In addition, Google provides further information with regard to its data protection practices at',
          )}{' '}
          <a
            href="https://www.google.com/intl/de/policies/privacy/partners"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/policies/privacy/partners
          </a>
          ,{' '}
          {t(
            'including options you can exercise to prevent such use of your data.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t('In addition, Google offers an opt-out add-on at')}{' '}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout?hl=en
          </a>{' '}
          {t(
            "in addition with further information. This add-on can be installed on the most popular browsers and offers you further control over the data that Google collects when you visit our website. The add-on informs Google Analytics' JavaScript (ga.js) that no information about the website visit should be transmitted to Google Analytics. However, this does not prevent information from being transmitted to us or to other web analytics services we may use as detailed herein.",
          )}
        </StyledParagraph>
        <StyledBodyTitle>{t('Google-Maps')}</StyledBodyTitle>
        <StyledParagraph>
          {t(
            'Our website uses Google Maps to display our location and to provide directions. This is a service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 (hereinafter Google).',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t('Through certification according to the EU-US Privacy Shield')}{' '}
          <a
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
          </a>{' '}
          {t(
            "Google guarantees that it will follow the EU's data protection regulations when processing data in the United States.",
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'To enable the display of certain fonts on our website, a connection to the Google server in the USA is established whenever our website is accessed.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'If you access the Google Maps components integrated into our website, Google will store a cookie on your device via your browser. Your user settings and data are processed to display our location and create a route description. We cannot prevent Google from using servers in the USA.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in optimizing the functionality of our website.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'By connecting to Google in this way, Google can determine from which website your request has been sent and to which IP address the directions are transmitted.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'If you do not agree to this processing, you have the option of preventing the installation of cookies by making the appropriate settings in your browser. Further details can be found in the section about cookies above.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'In addition, the use of Google Maps and the information obtained via Google Maps is governed by the',
          )}{' '}
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Google Terms of Use')}
          </a>{' '}
          {t('and the')}{' '}
          <a
            href="https://www.google.com/intl/de_de/help/terms_maps/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Terms and Conditions for Google Maps')}
          </a>{' '}
        </StyledParagraph>
        <StyledParagraph>
          {t('Google also offers further information at')}
        </StyledParagraph>
        <StyledParagraph>
          <a
            href="https://adssettings.google.com/authenticated            "
            target="_blank"
            rel="noopener noreferrer"
          >
            https://adssettings.google.com/authenticated
          </a>
        </StyledParagraph>
        <StyledParagraph>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy
          </a>
        </StyledParagraph>
        <StyledBodyTitle>{t('Facebook plug-in')}</StyledBodyTitle>
        <StyledParagraph>
          {t(
            'Our website uses the plug-in of the Facebook social network. Facebook.com is a service provided by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA. In the EU, this service is also operated by Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, hereinafter both referred to as "Facebook."',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t('Through certification according to the EU-US Privacy Shield')}
        </StyledParagraph>
        <StyledParagraph>
          <a
            href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active
          </a>
        </StyledParagraph>
        <StyledParagraph>
          {t(
            "Facebook guarantees that it will follow the EU's data protection regulations when processing data in the United States.",
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in improving the quality of our website.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'Further information about the possible plug-ins and their respective functions is available from Facebook at',
          )}{' '}
          <a
            href="https://developers.facebook.com/docs/plugins/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.facebook.com/docs/plugins/
          </a>
        </StyledParagraph>
        <StyledParagraph>
          {t(
            "If the plug-in is stored on one of the pages you visit on our website, your browser will download an icon for the plug-in from Facebook's servers in the USA. For technical reasons, it is necessary for Facebook to process your IP address. In addition, the date and time of your visit to our website will also be recorded.",
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'If you are logged in to Facebook while visiting one of our plugged-in websites, the information collected by the plug-in from your specific visit will be recognized by Facebook. The information collected may then be assigned to your personal account at Facebook. If, for example, you use the Facebook Like button, this information will be stored in your Facebook account and published on the Facebook platform. If you want to prevent this, you must either log out of Facebook before visiting our website or use an add-on for your browser to prevent the Facebook plug-in from loading.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            "Further information about the collection and use of data as well as your rights and protection options in Facebook's privacy policy found at",
          )}{' '}
          <a
            href="https://www.facebook.com/policy.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policy.php
          </a>
        </StyledParagraph>
        <StyledBodyTitle>YouTube</StyledBodyTitle>
        <StyledParagraph>
          {t(
            'We use YouTube on our website. This is a video portal operated by YouTube LLC, 901 Cherry Ave, 94066 San Bruno, CA, USA, hereinafter referred to as "YouTube".',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'YouTube is a subsidiary of Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, hereinafter referred to as "Google".',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t('Through certification according to the EU-US Privacy Shield')}{' '}
          <a
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
          </a>
        </StyledParagraph>
        <StyledParagraph>
          {t(
            "Google and its subsidiary YouTube guarantee that they will follow the EU's data protection regulations when processing data in the United States.",
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'We use YouTube in its advanced privacy mode to show you videos. The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in improving the quality of our website. According to YouTube, the advanced privacy mode means that the data specified below will only be transmitted to the YouTube server if you actually start a video.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'Without this mode, a connection to the YouTube server in the USA will be established as soon as you access any of our webpages on which a YouTube video is embedded.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'This connection is required in order to be able to display the respective video on our website within your browser. YouTube will record and process at a minimum your IP address, the date and time the video was displayed, as well as the website you visited. In addition, a connection to the DoubleClick advertising network of Google is established.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'If you are logged in to YouTube when you access our site, YouTube will assign the connection information to your YouTube account. To prevent this, you must either log out of YouTube before visiting our site or make the appropriate settings in your YouTube account.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'For the purpose of functionality and analysis of usage behavior, YouTube permanently stores cookies on your device via your browser. If you do not agree to this processing, you have the option of preventing the installation of cookies by making the appropriate settings in your browser. Further details can be found in the section about cookies above.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            "Further information about the collection and use of data as well as your rights and protection options in Google's privacy policy found at",
          )}{' '}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy
          </a>
        </StyledParagraph>
        <StyledBodyTitle>
          {t('Google AdWords with Conversion Tracking')}
        </StyledBodyTitle>
        <StyledParagraph>
          {t(
            'Our website uses Google AdWords and conversion tracking. This is a service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 (hereinafter Google).',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t('Through certification according to the EU-US Privacy Shield')}{' '}
          <a
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
          </a>
        </StyledParagraph>
        <StyledParagraph>
          {t(
            "Google guarantees that it will follow the EU's data protection regulations when processing data in the United States.",
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'We use conversion tracking to provide targeted promotion of our site. The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the analysis, optimization, and economic operation of our site.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'If you click on an ad placed by Google, the conversion tracking we use stores a cookie on your device. These so-called conversion cookies expire after 30 days and do not otherwise identify you personally.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'If the cookie is still valid and you visit a specific page of our website, both we and Google can evaluate that you clicked on one of our ads placed on Google and that you were then forwarded to our website.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The data collected in this way is in turn used by Google to provide us with an evaluation of visits to our website and what visitors do once there. In addition, we receive information about the number of users who clicked on our advertisement(s) as well as about the pages on our site that are subsequently visited. Neither we nor third parties who also use Google AdWords will be able to identify you from this conversion tracking.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'You can also prevent or restrict the installation of cookies by making the appropriate settings in your browser. Likewise, you can use the browser to delete cookies that have already been stored. However, the steps and measures required vary, depending on the browser you use. If you have any questions, please use the help function or consult the documentation for your browser or contact its maker for support.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'In addition, Google provides further information with regard to its data protection practices at',
          )}
        </StyledParagraph>
        <StyledParagraph>
          <CompactBlock>
            <a
              href="https://services.google.com/sitestats/de.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://services.google.com/sitestats/de.html
            </a>
          </CompactBlock>
          <CompactBlock>
            <a
              href="http://www.google.com/policies/technologies/ads/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.google.com/policies/technologies/ads/
            </a>
          </CompactBlock>
          <CompactBlock>
            <a
              href="http://www.google.de/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.google.de/policies/privacy/
            </a>
          </CompactBlock>
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'in particular information on how you can prevent the use of your data.',
          )}
        </StyledParagraph>
        <StyledBodyTitle>{t('Google Remarketing')}</StyledBodyTitle>
        <StyledParagraph>
          {t(
            'We use the remarketing function on our website. This is a service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 (hereinafter Google).',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t('Through certification according to the EU-US Privacy Shield')}{' '}
          <a
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
          </a>{' '}
          {t(
            "Google guarantees that it will follow the EU's data protection regulations when processing data in the United States.",
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            "We use this feature to deliver interest-based, personalized advertising on third-party websites that also participate in Google's advertising network.",
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the analysis, optimization, and economic operation of our site.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'To allow this advertising service to function, Google stores a cookie with a sequence of numbers on your device via your browser when you visit our website. This cookie records both your visit and the use of our website in anonymous form. However, personal data will not be passed on. If you subsequently visit a third-party website that also uses the Google advertising network, advertising may appear that refers to our website or our offers there.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'To permanently disable this feature, Google provides a browser plugin for most common browsers at',
          )}{' '}
          <a
            href="https://www.google.com/settings/ads/plugin?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/settings/ads/plugin?hl=de
          </a>{' '}
        </StyledParagraph>
        <StyledParagraph>
          {t('Likewise, the use of cookies from certain providers, e.g. via')}{' '}
          <a
            href="http://www.youronlinechoices.com/uk/your-ad-choices/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.youronlinechoices.com/uk/your-ad-choices/
          </a>{' '}
          {t('or')}{' '}
          <a
            href="http://www.networkadvertising.org/choices/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.networkadvertising.org/choices/
          </a>{' '}
          {t('can be deactivated by opt-out.')}
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'Cross-device marketing allows Google to track your usage patterns across multiple devices, so you may see interest-based, personalized advertising even when you switch devices. However, this requires that you have agreed to link your browsing history to your existing Google account.',
          )}
        </StyledParagraph>
        <StyledParagraph>
          {t('Google offers more information about Google Remarketing at ')}
          <a
            href="http://www.google.com/privacy/ads/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.google.com/privacy/ads/
          </a>
        </StyledParagraph>
      </StyledCard>
    </StaticPage>
  );
};
