export interface IFaqItem {
  question: string;
  answer: string;
  replaceEmail?: string;
}

export const faqContent: IFaqItem[] = [
  {
    question: 'What can I see in the course of the data?',
    answer:
      'The history of the entered data per machine/tank/container, with limitation to the corresponding period. All values displayed on the color sticks with corresponding limit ranges.',
  },
  {
    question: 'What is the web platform for?',
    answer:
      'The complete history of the entered data can be displayed and checked. In addition, it enables the display of liquid-relevant data for several users at the same time.',
  },
  {
    question: 'How can I generate a QR code?',
    answer:
      'Naming of the machine/tank/container and the product/medium used. Then click on "Generate QR Code" and print it out on an adhesive label. From this point on, the respective QR code can be generated directly via sources in the app\'s selection menu.',
  },
  {
    question: 'What can be simplified with QR Code?',
    answer:
      'The scan process with QR Code enables the clear identification of the "machine/tank" as well as the allocation of the corresponding product for recurring measurements.',
  },
  {
    question: 'Can I change limit values individually?',
    answer:
      'Currently, these changes are not yet customizable, but will be possible in a next release.',
  },
  {
    question: 'How accurate are the measurements using sticks?',
    answer:
      'If no interferences are present, the measurements are almost identical to the laboratory values. (The water measurements as well as the emulsion measurements represent the free ions in the respective liquid. These can change the display value due to metal ions, which are released in copper or magnesium, for example).',
  },
  {
    question: 'Can Easy Monitoring replace laboratory measurements?',
    answer:
      'For normal operation and TRGS 611 measurements (Technical Guideline for Hazardous Substances in Germany) the Easy Monitoring data is sufficient.\n' +
      '\n' +
      'Laboratory tests are usually more extensive, since the same biological activities are also recorded. However, laboratory measurements are only taken if there are really compelling reasons. In normal KSS operation, the Easy Monitoring data are sufficient.',
  },
  {
    question: 'Where do I get the test strips?',
    answer:
      'These can be ordered directly from us via the MOTOREX homepage. Access can be obtained by means of a QR scan on the respective "MWF" or "WATER" tube.',
  },
  {
    question: 'How long is the data stored on the web platform?',
    answer:
      'The data is stored for at least 10 years, but is only available on request for this period.',
  },
  {
    question: 'How can I delete my account for Easy Monitoring?',
    answer:
      'Please contact easymonitoring@motorex.com',
    replaceEmail: 'easymonitoring@motorex.com',
  },
];
