import React, { FunctionComponent } from 'react';
import { StaticPage } from '~/components/StaticPage';

export const Contact: FunctionComponent = () => {
  return (
    <StaticPage>
      <></>
    </StaticPage>
  );
};
