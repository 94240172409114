import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthentication } from '~/hooks/useAuthentication';
import { Routes } from '~/utils/Routes';
import { isGranted } from '~/utils/common';
import { Role } from '~/components/apollo-components';

export const Base: FunctionComponent = () => {
  const { currentUser } = useAuthentication();

  // User is not authenticated => redirect to login
  if (currentUser == null) {
    return <Redirect to={Routes.auth.login.base} />;
  }

  if (isGranted(currentUser, Role.ADMIN)) {
    return <Redirect to={Routes.admin.companies.base} />;
  }

  return <Redirect to={Routes.easyMonitoring.dashboard.base} />;
};
