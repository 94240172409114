import { ColumnProps } from 'antd/es/table';
import { TFunction } from 'i18next';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'reflexbox';
import {
  CollectionQueryOptions,
  SortArgs,
  SortOrder,
  CompanyInformationFragment,
  UserInformationFragment,
  useCompanyUsersQuery,
  useUpdateUserCompanyMutation,
  useFindUsersToAddQuery,
  useLoginAdminMutation,
  Maybe,
  useUpdateUserByAdminMutation,
} from '~/components/apollo-components';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { Table } from '~/components/DataTable';
import { initialOptions } from '~/components/DataTable/TableSettingsProvider';
import { createNotification } from '~/utils/createNotification';
import { DashboardCard } from '~/utils/styles';
import { Button, Select } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useState } from 'react';
import { useDebounce } from 'use-debounce/lib';
import { UserDeleteModal } from './UserDeleteModal';
import { ReactComponent as Eye } from '~/assets/icons/eye.svg';
import { ReactComponent as Edit } from '~/assets/icons/edit.svg';
import { UpdateUserModal } from '~/pages/Admin/Users/UserList/UpdateUserModal';

const columns = (
  t: TFunction,
  openDeleteModal: (user: UserInformationFragment) => void,
  jumpIntoUser: (userId: string) => Promise<void>,
  selectUser: (user: UserInformationFragment) => void,
  companyName?: string,
): ColumnProps<UserInformationFragment>[] => [
  {
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: true,
    title: t('common:First Name'),
  },
  {
    dataIndex: 'lastName',
    defaultSortOrder: 'ascend',
    key: 'lastName',
    sorter: true,
    title: t('common:Last Name'),
  },
  {
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    title: t('common:Email Address'),
  },
  {
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    sorter: true,
    title: t('common:Phone Number'),
  },
  {
    dataIndex: 'company',
    key: 'company',
    render: () => companyName,
    title: t('Company'),
  },
  {
    key: 'action-editUser',
    render: (_, user: UserInformationFragment) => (
      <Flex justifyContent="flex-end">
        <Button
          shape="circle"
          type="text"
          icon={<Edit style={{ width: 20, height: 20 }} />}
          onClick={() => selectUser(user)}
        />
      </Flex>
    ),
  },
  {
    key: 'action-jumpIntoUser',
    render: (_, user: UserInformationFragment) => (
      <Flex justifyContent="flex-end">
        <Button
          shape="circle"
          type="text"
          icon={<Eye style={{ width: 20, height: 20 }} />}
          onClick={() => jumpIntoUser(user.id)}
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </Flex>
    ),
  },
  {
    dataIndex: 'action',
    key: 'id',
    render: (_, record) => {
      return (
        <Flex justifyContent="center">
          <Button danger onClick={() => openDeleteModal(record)}>
            {t('Remove')}
          </Button>
        </Flex>
      );
    },
    title: '',
  },
];

const initialSort: SortArgs = {
  field: 'lastName',
  order: SortOrder.ASC,
};

interface IProps {
  data: CompanyInformationFragment | undefined;
  loading: boolean;
}

export const UsersCard: FunctionComponent<IProps> = ({
  data,
  loading,
}: IProps) => {
  const { t } = useTranslation(['Admin', 'common']);
  const [query, setQuery] = useState('');
  const [queryTerm] = useDebounce(query, 500);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [
    editSelectedUser,
    setEditSelectedUser,
  ] = useState<UserInformationFragment | null>(null);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [userDeleteModalVisible, setUserDeleteModalVisible] = useState(false);
  const [
    userToDelete,
    setUserToDelete,
  ] = useState<UserInformationFragment | null>(null);

  const {
    data: companyData,
    loading: isFetching,
    error,
    refetch,
  } = useCompanyUsersQuery({
    variables: {
      companyId: data?.id ?? '',
      ...initialOptions,
      sort: initialSort,
    },
    skip: data == null,
  });

  const { data: usersToAdd, refetch: refetchUser } = useFindUsersToAddQuery({
    variables: {
      query: query,
    },
  });

  useEffect(() => {
    refetchUser({ query: queryTerm });
  }, [queryTerm, refetchUser]);

  const [update] = useUpdateUserCompanyMutation();

  const removeUser = useCallback(
    async (userId: string) => {
      await update({ variables: { userId: userId } });
      setUserDeleteModalVisible(false);
      await Promise.all([refetch(), refetchUser({ query: queryTerm })]);
    },
    [update, refetch, refetchUser, queryTerm],
  );

  const openDeleteModal = useCallback((user: UserInformationFragment) => {
    setUserToDelete(user);
    setUserDeleteModalVisible(true);
  }, []);

  const addUser = useCallback(
    async (userId: string, companyId: string) => {
      await update({ variables: { userId: userId, companyId: companyId } });
      await Promise.all([refetchUser(), refetch()]);
    },
    [update, refetch, refetchUser],
  );

  const [loginAsUser] = useLoginAdminMutation();

  const jumpIntoUser = useCallback(
    async (userId: string) => {
      await loginAsUser({ variables: { userId: userId } });
      window.location.href = '/easy-monitoring/dashboard';
    },
    [loginAsUser],
  );

  const selectUser = useCallback((user: UserInformationFragment) => {
    setEditSelectedUser(user);
    setEditUserModalOpen(true);
  }, []);

  const [updateUser] = useUpdateUserByAdminMutation();

  const onUpdateUser = async (
    id: string,
    data: {
      email: string;
      firstName: string;
      lastName: string;
      phoneNumber: Maybe<string> | undefined;
    },
  ) => {
    const { email, firstName, lastName, phoneNumber } = data;
    try {
      await updateUser({
        variables: {
          id,
          email,
          firstName,
          lastName,
          phoneNumber,
        },
      });
      setEditUserModalOpen(false);
      await refetch();
      createNotification('success', t('User updated'));
    } catch (e) {
      if (e instanceof Error) {
        createNotification('error', t(`${e?.message}`));
      }
      createNotification('error', t('Error updating user'));
    }
  };

  const allColumns = useMemo(
    () => columns(t, openDeleteModal, jumpIntoUser, selectUser, data?.name),
    [t, data, jumpIntoUser, selectUser, openDeleteModal],
  );

  const onFetch = useCallback(
    async (options: CollectionQueryOptions) => {
      await refetch(options);
    },
    [refetch],
  );

  useEffect(() => {
    if (error) {
      createNotification('error', t('common:Error fetching user data'));
    }
  }, [error, t]);

  if (loading || data == null) {
    return (
      <DashboardCard>
        <LoadingSpinner />
      </DashboardCard>
    );
  }

  return (
    <DashboardCard>
      <Flex flexDirection="column" height="100%">
        <Table<UserInformationFragment>
          title={t('Users Overview')}
          data={companyData?.companyUsers ?? { items: [], totalCount: 0 }}
          loading={isFetching}
          columns={allColumns}
          refetch={onFetch}
          initialSorting={initialSort}
        />
        {userToDelete && (
          <UserDeleteModal
            visible={userDeleteModalVisible}
            onCancel={() => setUserDeleteModalVisible(false)}
            onOk={removeUser}
            user={userToDelete}
          />
        )}
        <Title level={3}>{t('Add new user')}</Title>
        <Flex>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={t('Search to Select')}
            optionFilterProp="children"
            onSearch={(value) => setQuery(value)}
            onChange={(value) => setSelectedUser(value.toString())}
            value={selectedUser || undefined}
          >
            {usersToAdd?.findUsersToAdd.map((user) => (
              <Select.Option key={user.id} value={user.id}>
                {user.email}
              </Select.Option>
            ))}
          </Select>
          <Button
            onClick={() => {
              if (selectedUser != null) {
                addUser(selectedUser, data.id);
                setSelectedUser(null);
              }
            }}
            disabled={!selectedUser || !data || !data.id}
          >
            {t('Add User')}
          </Button>
        </Flex>
      </Flex>
      {editUserModalOpen && editSelectedUser && (
        <UpdateUserModal
          isOpen={editUserModalOpen}
          onOk={onUpdateUser}
          onClose={() => setEditUserModalOpen(false)}
          data={editSelectedUser}
        />
      )}
    </DashboardCard>
  );
};
