export const details = {
  'Add another graph': 'Aggiungi un altro grafico',
  'Alkalinity (A)': 'Durezza carbonatica (DC)',
  'All latest measured values are in the normal range.':
    'Tutti i valori misurati di recente rientrano nel range normale.',
  'Carbon Dioxide': 'Diossido di carbonio',
  Chlorine: 'Cloro',
  'CO<1>2</1>': 'CO<1>2</1>',
  Concentration: 'Concentrazione',
  'Critical Alkalinity': 'Durezza carbonatica critica',
  'Critical Chlorine': 'Quantità di cloro critica',
  'Critical CO<1>2</1>': 'Quantità di CO<1>2</1> critica',
  'Critical Hardness': 'Durezza totale critica',
  'Critical Nitrate': 'Quantità di nitrato critica',
  'Critical Nitrite': 'Quantità di nitrito critica',
  'Critical pH-value': 'Valore pH critico',
  'Critical Temperature': 'Temperatura critica',
  Date: 'Data',
  Details: 'Dettagli',
  'Export Table': 'Esporta tabella',
  'Hardness (H)': 'Durezza totale (DT)',
  'Hide graph': 'Nascondi grafico',
  History: 'Cronologia',
  'History in Numbers': 'Cronologia in cifre',
  'Measurement Results': 'Risultati di misurazione',
  Nitrate: 'Nitrato',
  Nitrite: 'Nitrito',
  'Noticeable Alkalinity': 'Durezza carbonatica anomala',
  'Noticeable Chlorine': 'Quantità di cloro anomala',
  'Noticeable CO<1>2</1>': 'Quantità di CO<1>2</1> anomala',
  'Noticeable Hardness': 'Durezza totale anomala',
  'Noticeable Nitrate': 'Quantità di nitrato anomala',
  'Noticeable Nitrite': 'Quantità di nitrito anomala',
  'Noticeable pH-value': 'Valore pH anomalo',
  'Noticeable Temperature': 'Temperatura anomala',
  'pH-value': 'Valore pH',
  Temperature: 'Temperatura',
};
