import { TFunction } from 'i18next';
import { array, object, string } from 'yup';

export const validationSchema = (t: TFunction): any =>
  object().shape({
    email: string()
      .label(t('Email'))
      .email()
      .required()
      .max(128),
    firstName: string()
      .label(t('First Name'))
      .required()
      .min(2)
      .max(32),
    jobTitle: string()
      .label(t('Job Title'))
      .nullable()
      .min(4)
      .max(64),
    lastName: string()
      .label(t('Last Name'))
      .required()
      .min(2)
      .max(32),
    phoneNumber: string()
      .label(t('Phone Number'))
      .nullable()
      .max(64),
    roles: array()
      .label(t('Role(s)'))
      .min(1, t('At least one role must be specified')),
  });
