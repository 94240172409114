export const termsAndConditions = {
  // 'General Terms of Sale and Delivery': '',
  // 'These General Terms of Sale and Delivery are binding if the Seller delivers the ordered goods or if it is stated that the General Terms of Sale and Delivery are applicable. Any other conditions of the Buyer differing from these terms are only valid if expressly accepted in writing by the Seller.':
  //   '',
  // 'The contract of sale shall be considered to have been agreed if, when the order has been received, the Seller acknowledges it in writing or the goods have been delivered without any reservations.':
  //   '',
  // 'Where quantities and weights are stated in orders, the Seller shall be entitled to make deliveries of up to 10% above or below the stated figure. The weights at departure shall be decisive.':
  //   '',
  // 'Unless agreed otherwise in writing, the Seller’s prices shall be net, ex works and in Swiss Francs. Drums and packaging are – with the exception of containers and reusable receptacles – included in the price.':
  //   '',
  // 'The terms of payment shall be 30 days net from the date of the invoice. Variations to these terms or conditions of payment shall only be valid and binding if made in writing. The purchaser shall effect payments at the Seller’s domicile, with no deductions in respect of cash payments, bank charges, taxes and fees of any kind. If payment is delayed, the Seller reserves the right to stop outstanding deliveries and is entitled to charge interest on overdue payments at a rate of 6% p.a.':
  //   '',
  // 'The Seller shall retain the ownership of the delivery until the goods have been paid for in full. The purchaser has a duty to take necessary measures to protect the Seller’s property. The Seller is entitled to have his property secured through suitable measures.':
  //   '',
  // 'The delivery period shall commence with the acceptance of the order by the Seller. The delivery periods shall be extended accordingly if':
  //   '',
  // 'Information required to execute the order is not supplied to the Seller in good time, or if this information is subsequently changed by the purchaser.':
  //   '',
  // 'Payment is not made by the stipulated date, the letter of credit has been opened too late or if the necessary import licences are not received by the Seller in due time.':
  //   '',
  // 'If obstacles arise which the Seller cannot avoid in spite of taking the necessary care, irrespective of whether these occur at the Seller, the purchaser or a third party. Such obstacles also include force majeure such as epidemics, mobilization of troops, war, riot, serious disruptions to the business, accidents, industrial conflicts, delayed or incorrect delivery of the necessary raw materials, semi-finished or finished products, irresolvable problems with important tools that causes them to be scrapped, measures imposed by the authorities or omissions, and natural occurrences.':
  //   '',
  // 'The Seller shall be advised in good time of special requests relating to the shipment and insurance of the goods. Transport shall be at the expense and risk of the purchaser. Complaints in relation to transport shall be made without delay by the purchaser to the last freight forwarder upon receipt of the delivery or freight documents. The purchaser shall be responsible for insurance against losses of all kinds. Even if the Seller has to take out the insurance, it shall be charged to the purchaser.':
  //   '',
  // 'Any complaints regarding volume of delivery, packing or other defects are to be addressed from the Buyer to the Seller in writing within 8 days after receipt of goods at the latest.':
  //   '',
  // 'The Seller’s guarantees include': '',
  // 'Seller warrants that the Products delivered to Buyer pursuant to the contract of sale as defined herein are free of manufacturing faults and material flaws and that they conform to the specifications expressly described in the order acknowledgment pursuant to Clause 2 hereinabove, in the instructions for use and the specification sheet, with the tolerances applicable in the relevant industry.':
  //   '',
  // 'Buyer understands and acknowledges that the acceptability and suitability of the Products for a particular use is solely within its own discretionary power to decide. Seller makes no other warranty, written or oral, statutory, express or implied, including but not limited to merchantability or fitness for a particular purpose.':
  //   '',
  // 'The warranty is void unless the Buyer inspects the products and notifies Seller in writing within 8 days upon delivery of the Products of any rejections based on non conformity to specifications or of defects in the Products.':
  //   '',
  // 'In the event of defective or non conformity to specifications of Products, Seller’ warranty is limited to a replacement delivery of the defective products subject to Buyer returning the defective goods to Seller. More particularly, Buyer understands and acknowledges that depending the items on which the Product is used, a proper cleaning and entire removal of any residues and remains thereof is required for which Buyer is exclusively responsible. As a consequence, Seller shall not be liable for any other direct, indirect and/or consequential damages, losses and causes of action, whether in contract, tort, including negligence or otherwise.':
  //   '',
  // 'These general terms of sale and delivery shall be subject to Swiss law and shall exclude the United Nations Convention on Contracts for the International Sales of Goods (Vienna Sales Convention) dated 11 April 1980. The only place of jurisdiction shall be Langenthal, Switzerland.':
  //   '',
};
