import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusType } from '~/components/apollo-components';
import { ChartLegendItem } from '~/pages/EasyMonitoring/Dashboard/LastMeasurementsCard/ChartLegend/ChartLegendItem';

export const ChartLegend: FunctionComponent = () => {
  const { t } = useTranslation('EasyMonitoring');
  return (
    <>
      <ChartLegendItem
        status={StatusType.NORMAL}
        infoText={t('Sources with all measured values in the standard range')}
      />
      <ChartLegendItem
        status={StatusType.NOTICEABLE}
        infoText={t('Sources with at least one abnormal reading')}
      />
      <ChartLegendItem
        status={StatusType.CRITICAL}
        infoText={t('Sources with at least one critical reading')}
      />
    </>
  );
};
