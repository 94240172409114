import { useApolloClient } from '@apollo/client';
import { Button, Menu, Typography } from 'antd';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box } from 'reflexbox/styled-components';
import { useLogoutMutation } from '~/components/apollo-components';
import { useAuthentication } from '~/hooks/useAuthentication';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { styled } from '~/theme';
import { colors } from '~/theme/variables';
import { getCombinedUsername } from '~/utils/common';
import { Routes } from '~/utils/Routes';

const { Title, Paragraph } = Typography;

const StyledMenu = styled(Menu)`
  width: 345px;
  padding: 1.25rem 2.25rem;

  .ant-menu-item {
    padding: 0;
  }

  > .ant-menu-item-divier {
    color: ${colors.grey.light};
    background-color: ${colors.grey.light};
  }

  &.ant-menu-root {
    box-shadow: 0 1px 5px ${({ theme }) => theme.colors.grey.dark};
  }
`;

const MenuButton = styled(Button)`
  padding: 0;
`;

export const UserMenu: FunctionComponent = () => {
  const { t } = useTranslation('common');
  const { currentUser } = useAuthentication();
  const [logout] = useLogoutMutation();
  const client = useApolloClient();
  const handleError = useErrorHandler();

  const onLogout = useCallback(async () => {
    try {
      await logout();
      await client.clearStore();
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } catch (error) {
      handleError(error, t('Error'), {});
    }
  }, [t, client, logout, handleError]);

  if (currentUser == null) {
    return null;
  }

  return (
    <StyledMenu>
      <Box paddingBottom="1rem">
        <Title level={4}>{getCombinedUsername(currentUser)}</Title>
        {currentUser.company != null && (
          <Paragraph>{currentUser.company.name}</Paragraph>
        )}
      </Box>
      <Menu.Divider />
      <Menu.Item key="profile">
        <Link to={Routes.profile.base}>
          <MenuButton type="link">{t('Show profile')}</MenuButton>
        </Link>
      </Menu.Item>
      <Menu.Item key="logout">
        <MenuButton type="link" onClick={onLogout}>
          {t('Logout')}
        </MenuButton>
      </Menu.Item>
    </StyledMenu>
  );
};
