import { Button, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { TFunction } from 'i18next';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  CollectionQueryOptions,
  Maybe,
  SortArgs,
  SortOrder,
  useLoginAdminMutation,
  UserInformationFragment,
  useUpdateUserByAdminMutation,
  useUsersQuery,
} from '~/components/apollo-components';
import { AdminLayout } from '~/components/AdminLayout';
import { Content } from '~/components/Content';
import { Table } from '~/components/DataTable';
import { initialOptions } from '~/components/DataTable/TableSettingsProvider';
import { createNotification } from '~/utils/createNotification';
import { Flex } from 'reflexbox';
import { ReactComponent as Edit } from '~/assets/icons/edit.svg';
import { ReactComponent as Eye } from '~/assets/icons/eye.svg';
import { UpdateUserModal } from './UpdateUserModal';

const { Text } = Typography;

const ColoredText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;

const columns = (
  t: TFunction,
  selectUser: (user: UserInformationFragment) => void,
  jumpIntoUser: (userId: string) => Promise<void>,
): ColumnProps<UserInformationFragment>[] => [
  {
    dataIndex: 'lastName',
    defaultSortOrder: 'ascend',
    key: 'lastName',
    render: (text: string) => <ColoredText>{text}</ColoredText>,
    sorter: true,
    title: t('common:Last Name'),
  },
  {
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: true,
    title: t('common:First Name'),
  },
  {
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    title: t('common:Email Address'),
  },
  {
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    sorter: true,
    title: t('common:Phone Number'),
  },
  {
    dataIndex: 'company',
    key: 'company',
    render: (text: any, record: UserInformationFragment) =>
      record.company?.name,
    sorter: true,
    title: t('Company'),
  },
  {
    key: 'actions',
    render: (text: any, record: UserInformationFragment) => (
      <Flex justifyContent="flex-end">
        <Button
          shape="circle"
          type="text"
          icon={<Edit style={{ width: 20, height: 20 }} />}
          onClick={() => selectUser(record)}
        />
      </Flex>
    ),
    title: '',
  },
  {
    key: 'actions2',
    render: (text: any, record: UserInformationFragment) => (
      <Flex justifyContent="flex-end">
        <Button
          shape="circle"
          type="text"
          icon={<Eye style={{ width: 20, height: 20 }} />}
          onClick={() => jumpIntoUser(record.id)}
        />
      </Flex>
    ),
    title: '',
  },
];

const initialSort: SortArgs = {
  field: 'lastName',
  order: SortOrder.ASC,
};

export const UserList: FunctionComponent = () => {
  const { t } = useTranslation(['Admin', 'common']);
  const [openModal, setOpenModal] = useState(false);
  const [
    selectedUser,
    setSelectedUser,
  ] = useState<UserInformationFragment | null>(null);

  const { data, loading, error, refetch } = useUsersQuery({
    variables: {
      ...initialOptions,
      sort: initialSort,
    },
  });

  const [loginAsUser] = useLoginAdminMutation();

  const jumpIntoUser = useCallback(
    async (userId: string) => {
      await loginAsUser({
        variables: { userId },
      });
      window.location.href = '/easy-monitoring/dashboard';
    },

    [loginAsUser],
  );

  const selectUser = useCallback((user: UserInformationFragment) => {
    setSelectedUser(user);
    setOpenModal(true);
  }, []);

  const allColumns = useMemo(() => columns(t, selectUser, jumpIntoUser), [
    t,
    selectUser,
    jumpIntoUser,
  ]);

  const onFetch = useCallback(
    async (options: CollectionQueryOptions) => {
      await refetch(options);
    },
    [refetch],
  );

  useEffect(() => {
    if (error) {
      createNotification('error', t('common:Error fetching user data'));
    }
  }, [error, t]);

  const [updateUser] = useUpdateUserByAdminMutation();

  const onUpdateUser = async (
    id: string,
    data: {
      email: string;
      firstName: string;
      lastName: string;
      phoneNumber: Maybe<string> | undefined;
    },
  ) => {
    const { email, firstName, lastName, phoneNumber } = data;
    try {
      await updateUser({
        variables: {
          id,
          email,
          firstName,
          lastName,
          phoneNumber,
        },
      });
      setOpenModal(false);
      await refetch();
      createNotification('success', t('User updated'));
    } catch (e) {
      if (e instanceof Error) {
        createNotification('error', t(`${e?.message}`));
      } else {
        createNotification('error', t('Error updating user'));
      }
    }
  };

  return (
    <AdminLayout>
      <Content>
        <Table<UserInformationFragment>
          title={t('Users Overview')}
          data={data?.users ?? { items: [], totalCount: 0 }}
          loading={loading}
          columns={allColumns}
          refetch={onFetch}
          initialSorting={initialSort}
        />
        {selectedUser && openModal && (
          <UpdateUserModal
            isOpen={openModal}
            onOk={onUpdateUser}
            onClose={() => setOpenModal(false)}
            data={selectedUser}
          />
        )}
      </Content>
    </AdminLayout>
  );
};
