import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface IMainProps {
  secondaryHeaderEnabled: boolean;
}

const StyledMain = styled.main<IMainProps>`
  padding: 25px 96px;
  margin-top: ${({ secondaryHeaderEnabled }) =>
    secondaryHeaderEnabled ? '120px' : '60px'};
  min-height: calc(
    100vh -
      ${({ secondaryHeaderEnabled }) =>
        secondaryHeaderEnabled ? '120px' : '60px'} - (1.5 * 1rem + 24px)
  );
  display: flex;
  min-width: 100%;

  > * {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.screenXl}) {
    padding: 25px 15px;
  }
`;

interface IMainContentProps {
  children: ReactNode;
  secondaryHeaderEnabled: boolean;
}

export const MainContent: FunctionComponent<IMainContentProps> = ({
  children,
  secondaryHeaderEnabled,
}: IMainContentProps) => {
  return (
    <StyledMain
      className="ant-layout-content"
      secondaryHeaderEnabled={secondaryHeaderEnabled}
    >
      {children}
    </StyledMain>
  );
};
