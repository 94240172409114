import React, { FunctionComponent, useContext } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { AuthenticationContext } from '~/contexts/AuthenticationContext';
import { Routes } from '~/utils/Routes';
import { Route } from './Route';

export const UnauthenticatedRoute: FunctionComponent<RouteProps> = props => {
  const { currentUser } = useContext(AuthenticationContext);

  if (currentUser != null) {
    return <Redirect to={Routes.base} />;
  }

  return <Route {...props} />;
};
