export const chemicals = {
  Alkalinity: 'Alcalinité',
  Chlorine: 'Chlore',
  Concentration: 'Concentration',
  'CO<1>2</1>': 'CO<1>2</1>',
  Hardness: 'Dureté totale',
  Nitrate: 'Nitrate',
  Nitrite: 'Nitrite',
  pH: 'pH',
  Temperature: 'Température',
};
