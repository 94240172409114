export const companies = {
  'Add another domain': 'Aggiungi un altro dominio',
  'Add company': 'Aggiungi azienda',
  'Add company address here': 'Aggiungi qui l’indirizzo dell’azienda',
  'Add company name here': 'Aggiungi qui il nome dell’azienda',
  'Add contact email address here':
    'Aggiungi qui l’indirizzo e-mail del referente',
  'Add contact phone number here':
    'Aggiungi qui il numero di telefono del referente',
  'Add contact first name here': 'Aggiungi qui il nome del referente',
  'Add contact last name here': 'Aggiungi qui il cognome del referente',
  'Add domain here': 'Aggiungi qui il dominio',
  Address: 'Indirizzo',
  'Back to Overview': 'Torna al riepilogo',
  'Companies Overview': 'Riepilogo dati azienda',
  Company: 'Azienda',
  'Company Address': 'Indirizzo dell’azienda',
  'Company Name': 'Nome dell’azienda',
  'Company successfully created.': 'Azienda creata con successo.',
  Contact: 'Referente',
  'Contact Email Address': 'Indirizzo e-mail del referente',
  'Contact First Name': 'Nome del referente',
  'Contact Last Name': 'Cognome del referente',
  'Contact Phone Number': 'Numero di telefono del referente',
  'Customer No.': 'Codice cliente',
  'Domain must be a valid domain and start with an @':
    'Il dominio deve contenere un dominio valido e iniziare con una chiocciola (@).',
  Domains: 'Dominio',
  Employees: 'Dipendenti',
  'Error fetching company data':
    'Errore durante il richiamo dei dati aziendali',
  'Remove this domain': 'Rimuovi questo dominio',
  'Users Overview': 'Riepilogo dati utente',
  'Company Infos': 'Informazioni sull’azienda',
  'Customer Number': 'Codice cliente',
  'Add new user': 'Aggiungi un nuovo utente',
  'Add User': 'Aggiungi un utente',
  'Search to Select': 'Cerca e seleziona',
  'Delete Company': 'Elimina azienda',
  'Are you sure you want to delete this company?':
    'Sei sicuro di voler eliminare questa azienda?',
  Remove: 'Rimuovi',
  'Delete User': 'Elimina utente',
  'Are you sure you want to remove {{email}}?':
    'Sei sicuro di voler rimuovere {{email}}?',
  'A user with this Email is already registered.':
    'Un utente con questo indirizzo e-mail è già registrato.',
};
