import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { compareAsc, parseISO } from 'date-fns';
import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box } from 'reflexbox/styled-components';
import {
  ChemicalFragment,
  ChemicalsWaterResponse,
  CollectionQueryOptions,
  DateOperator,
  MeasurementFragment,
  MeasurementHistoryQuery,
  MeasurementHistoryQueryVariables,
  MeasurementType,
  SortOrder,
  StatusType,
  useMeasurementHistoryLazyQuery,
} from '~/components/apollo-components';
import { Table } from '~/components/DataTable';
import { HistoryNumbersTableExportButton } from '~/pages/EasyMonitoring/MachineDetail/MeasurementHistoryCard/HistoryNumbersTable/HistoryNumbersTableExportButton';
import { styled } from '~/theme';
import { getCombinedUsername } from '~/utils/common';
import { formatFromISO } from '~/utils/dates';
import { StatusColorMap } from '~/utils/EasyMonitoring/colorMaps';
import { ColorText, WarningIcon } from '~/utils/EasyMonitoring/styles';
import { emulsionRows } from './utils/emulsionRows';
import { waterRows } from './utils/waterRows';

const { Text } = Typography;

const TableWrapperBox = styled(Box)`
  table {
    thead {
      th:first-of-type {
        background-color: ${({ theme }) => theme.colors.white};
      }
    }

    tbody {
      tr {
        td:first-of-type,
        :hover td:first-of-type {
          background-color: ${({ theme }) => theme.colors.grey.light};
        }
      }
    }
  }
`;

const TableWarningIcon = styled(WarningIcon)`
  font-size: 1.25rem;
  margin-left: 0.25rem;
`;

export type RowInfoType = {
  label: string | ReactNode;
  plainLabel: string;
  key: string;
};

type ExtendedChemicalFragment = ChemicalFragment & { unit?: string };

export type RowDataType = (
  | ExtendedChemicalFragment
  | { measuredValue?: string | number; key: string }
  | RowInfoType
) & { key: string };

function isExtendedMeasurementFragment(
  record: RowDataType,
): record is ExtendedChemicalFragment {
  if (!record) {
    return false;
  }
  return (record as ExtendedChemicalFragment).key !== undefined;
}

function isChemicalsWaterResponse(
  chemicals: MeasurementFragment['chemicals'],
): chemicals is ChemicalsWaterResponse {
  return chemicals.__typename === 'ChemicalsWaterResponse';
}

export interface IHistoryRowData {
  [key: string]: RowDataType;
  rowInfo: RowInfoType;
}

interface IHistoryNumbersTable {
  initialData: MeasurementHistoryQuery | null;
  isInitial: boolean;
  limit: number | null;
  selectedRange: RangeValue<Moment> | null;
}

export const HistoryNumbersTable: FunctionComponent<IHistoryNumbersTable> = ({
  initialData,
  isInitial,
  limit,
  selectedRange,
}: IHistoryNumbersTable) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation('EasyMonitoring');
  const [getHistoryData, { data, loading }] = useMeasurementHistoryLazyQuery();

  const onFetch = useCallback(
    async ({ pagination }: CollectionQueryOptions) => {
      const filter =
        selectedRange != null &&
        selectedRange[0] != null &&
        selectedRange[1] != null
          ? {
              date: {
                field: 'createdAt',
                operator: DateOperator.BETWEEN,
                value: selectedRange[0].toISOString(),
                endValue: selectedRange[1].toISOString(),
              },
            }
          : null;
      const variables: MeasurementHistoryQueryVariables = isInitial
        ? {
            machineId: id,
            limit,
            filter,
            sort: { field: 'createdAt', order: SortOrder.DESC },
          }
        : {
            machineId: id,
            pagination,
            filter,
            sort: { field: 'createdAt', order: SortOrder.ASC },
          };
      getHistoryData({
        variables,
      });
    },
    [id, getHistoryData, limit, isInitial, selectedRange],
  );

  const { columns, dataSource } = useMemo(() => {
    const historyData = isInitial ? initialData : data;

    if (
      historyData?.machine.measurements == null ||
      historyData?.machine.measurements.totalCount === 0
    ) {
      return { columns: [], dataSource: { items: [], totalCount: 0 } };
    }

    const isWaterMachine =
      historyData.machine.measurementType === MeasurementType.WATER;

    const items = [...historyData.machine.measurements.items];

    if (isInitial) {
      items.sort((a, b) => {
        const dateA = parseISO(a.createdAt);
        const dateB = parseISO(b.createdAt);
        return compareAsc(dateA, dateB);
      });
    }

    const rows = isWaterMachine ? waterRows(t) : emulsionRows(t);

    const adjustedData = items.reduce<IHistoryRowData[]>((acc, measurement) => {
      acc[0] = {
        ...acc[0],
        [measurement.createdAt]: {
          key: `${measurement.createdAt}-0`,
          measuredValue: getCombinedUsername(measurement.measurer),
        },
      };

      if (isChemicalsWaterResponse(measurement.chemicals)) {
        acc[1] = {
          ...acc[1],
          [measurement.createdAt]: {
            ...measurement.chemicals.nitrate,
            key: `${measurement.createdAt}-2`,
          },
        };
        acc[2] = {
          ...acc[2],
          [measurement.createdAt]: {
            ...measurement.chemicals.nitrite,
            key: `${measurement.createdAt}-3`,
          },
        };
        acc[3] = {
          ...acc[3],
          [measurement.createdAt]: {
            ...measurement.chemicals.hardness,
            key: `${measurement.createdAt}-4`,
            unit: '°',
          },
        };
        acc[4] = {
          ...acc[4],
          [measurement.createdAt]: {
            ...measurement.chemicals.alkalinity,
            key: `${measurement.createdAt}-5`,
            unit: '°',
          },
        };
        acc[5] = {
          ...acc[5],
          [measurement.createdAt]: {
            ...measurement.chemicals.ph,
            key: `${measurement.createdAt}-6`,
          },
        };
        acc[6] = {
          ...acc[6],
          [measurement.createdAt]: {
            ...measurement.chemicals.chlorine,
            key: `${measurement.createdAt}-7`,
          },
        };
        acc[7] = {
          ...acc[7],
          [measurement.createdAt]: {
            ...measurement.chemicals.co2,
            key: `${measurement.createdAt}-8`,
          },
        };
      } else if (
        measurement.chemicals.__typename === 'ChemicalsEmulsionResponse'
      ) {
        acc[1] = {
          ...acc[1],
          [measurement.createdAt]: {
            ...measurement.chemicals.concentration,
            key: `${measurement.createdAt}-1`,
            unit: '%',
          },
        };
        acc[2] = {
          ...acc[2],
          [measurement.createdAt]: {
            ...measurement.chemicals.nitrite,
            key: `${measurement.createdAt}-2`,
          },
        };
        acc[3] = {
          ...acc[3],
          [measurement.createdAt]: {
            ...measurement.chemicals.hardness,
            key: `${measurement.createdAt}-3`,
          },
        };
        acc[4] = {
          ...acc[4],
          [measurement.createdAt]: {
            ...measurement.chemicals.ph,
            key: `${measurement.createdAt}-4`,
          },
        };
        acc[5] = {
          ...acc[5],
          [measurement.createdAt]: {
            ...measurement.chemicals.temperature,
            key: `${measurement.createdAt}-5`,
            unit: '°C',
          },
        };
      }

      return acc;
    }, rows);

    const dynamicColumns: ColumnProps<IHistoryRowData>[] = [
      {
        dataIndex: ['rowInfo', 'label'],
        key: 'rowInfo',
      },
    ];

    items.forEach(({ createdAt }, index) => {
      dynamicColumns.push({
        dataIndex: [createdAt, 'measuredValue'],
        key: `${createdAt}-${index}`,
        title: formatFromISO(createdAt, 'dd.MM.'),
        render: (value, record) => {
          const concreteRecord: RowDataType = record[createdAt];

          if (isExtendedMeasurementFragment(concreteRecord)) {
            const content = (
              <>
                {value != null && value !== -1 ? value : t('n.a.')}
                {value != null && value !== -1 ? concreteRecord.unit : null}
              </>
            );

            if (concreteRecord.status === StatusType.NORMAL) {
              return <Text>{content}</Text>;
            }

            return (
              <ColorText
                color={StatusColorMap[concreteRecord.status as StatusType]}
              >
                {content}
                {concreteRecord.status === StatusType.CRITICAL && (
                  <TableWarningIcon
                    translate="no"
                    status={concreteRecord.status}
                  />
                )}
              </ColorText>
            );
          }

          return <Text>{value ? value : t('n.a.')}</Text>;
        },
      });
    });

    return {
      dataSource: {
        items: adjustedData,
        totalCount: historyData.machine.measurements.totalCount,
      },
      columns: dynamicColumns,
    };
  }, [data, t, initialData, isInitial]);

  return (
    <TableWrapperBox>
      <Table<IHistoryRowData>
        title={t('History in Numbers')}
        titleLevel={4}
        data={dataSource}
        columns={columns}
        loading={loading}
        refetch={onFetch}
        searchDisabled={true}
        additionalActions={
          <HistoryNumbersTableExportButton
            dataSource={dataSource}
            columns={columns}
          />
        }
      />
    </TableWrapperBox>
  );
};
