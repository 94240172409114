import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '~/components/Header';
import { IMenuItem } from '~/components/Header/AppNavigation';
import { Routes } from '~/utils/Routes';
import { ISecondaryHeaderProps } from './SecondaryHeader';

interface IAdminHeaderProps {
  secondaryHeaderOptions?: ISecondaryHeaderProps;
}

export const AdminHeader: FunctionComponent<IAdminHeaderProps> = ({
  secondaryHeaderOptions,
}: IAdminHeaderProps) => {
  const { t } = useTranslation(['Admin']);

  const menuItems: IMenuItem[] = useMemo(() => {
    const menus: IMenuItem[] = [];

    menus.push({
      key: 'companies',
      route: Routes.admin.companies.base,
      label: t('Companies'),
    });
    menus.push({
      key: 'users',
      route: Routes.admin.users.base,
      label: t('Users'),
    });

    return menus;
  }, [t]);

  return (
    <Header
      menuItems={menuItems}
      secondaryHeaderOptions={secondaryHeaderOptions}
    />
  );
};
