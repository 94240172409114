import { WarningFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import { StatusType } from '~/components/apollo-components';
import { styled } from '~/theme';
import { StatusColorMap } from '~/utils/EasyMonitoring/colorMaps';

const { Text } = Typography;

interface IColorTextProps {
  color: string;
}

export const ColorText = styled(Text)<IColorTextProps>`
  color: ${({ color }) => color};
`;

interface IWarningIconProps {
  status: StatusType;
}

export const WarningIcon = styled(WarningFilled)<IWarningIconProps>`
  color: ${({ status }) => StatusColorMap[status]};
`;
