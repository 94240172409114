import { TFunction } from 'i18next';
import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { ChemicalType, StatusType } from '~/components/apollo-components';

interface KeyTranslationMap {
  [StatusType.NOTICEABLE]: string | ReactNode;
  [StatusType.CRITICAL]: string | ReactNode;
}

export const getTranslationForChemical = (
  t: TFunction,
): Record<ChemicalType, string | ReactNode> => ({
  [ChemicalType.ALKALINITY]: t('Alkalinity (A)'),
  [ChemicalType.CHLORINE]: t('Chlorine'),
  [ChemicalType.CO2]: (
    <Trans t={t}>
      CO<sub>2</sub>
    </Trans>
  ),

  [ChemicalType.CONCENTRATION]: '',
  [ChemicalType.HARDNESS]: t('Hardness (H)'),
  [ChemicalType.NITRATE]: t('Nitrate'),
  [ChemicalType.NITRITE]: t('Nitrite'),
  [ChemicalType.PH]: t('pH-value'),
  [ChemicalType.TEMPERATURE]: t('Temperature'),
});

export const getTranslationForChemicalStatus = (
  t: TFunction,
): Record<ChemicalType, KeyTranslationMap> => ({
  [ChemicalType.ALKALINITY]: {
    [StatusType.NOTICEABLE]: t('Noticeable Alkalinity'),
    [StatusType.CRITICAL]: t('Critical Alkalinity'),
  },
  [ChemicalType.CHLORINE]: {
    [StatusType.NOTICEABLE]: t('Noticeable Chlorine'),
    [StatusType.CRITICAL]: t('Critical Chlorine'),
  },
  [ChemicalType.CO2]: {
    [StatusType.NOTICEABLE]: (
      <Trans t={t}>
        Noticeable CO<sub>2</sub>
      </Trans>
    ),
    [StatusType.CRITICAL]: (
      <Trans t={t}>
        Critical CO<sub>2</sub>
      </Trans>
    ),
  },
  [ChemicalType.CONCENTRATION]: {
    [StatusType.NOTICEABLE]: '',
    [StatusType.CRITICAL]: '',
  },
  [ChemicalType.HARDNESS]: {
    [StatusType.NOTICEABLE]: t('Noticeable Hardness'),
    [StatusType.CRITICAL]: t('Critical Hardness'),
  },
  [ChemicalType.NITRATE]: {
    [StatusType.NOTICEABLE]: t('Noticeable Nitrate'),
    [StatusType.CRITICAL]: t('Critical Nitrate'),
  },
  [ChemicalType.NITRITE]: {
    [StatusType.NOTICEABLE]: t('Noticeable Nitrite'),
    [StatusType.CRITICAL]: t('Critical Nitrite'),
  },
  [ChemicalType.PH]: {
    [StatusType.NOTICEABLE]: t('Noticeable pH-value'),
    [StatusType.CRITICAL]: t('Critical pH-value'),
  },
  [ChemicalType.TEMPERATURE]: {
    [StatusType.NOTICEABLE]: t('Noticeable temperature'),
    [StatusType.CRITICAL]: t('Critical temperature'),
  },
});
