import { CaretDownFilled } from '@ant-design/icons';
import { Dropdown, Menu, Typography } from 'antd';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useBoolean } from 'react-use';
import { Box, Flex } from 'reflexbox/styled-components';
import {
  MeasurementFragment,
  MeasurementTimesQuery,
} from '~/components/apollo-components';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { styled } from '~/theme';
import { colors } from '~/theme/variables';
import { formatFromISO } from '~/utils/dates';

const { Text } = Typography;

const DropdownTrigger = styled(Flex)`
  height: 2.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.medium};
  width: 18rem;
  max-width: 100%;
  padding: 0 0.75rem;
  cursor: pointer;
`;

const MachineItemGroup = styled(Menu.ItemGroup)`
  max-height: 175px;
  overflow-y: auto;
`;

interface IMeasurementDropdownProps {
  currentMeasurement: MeasurementFragment | null;
  handleDropdownSelect: (measurementId: string) => void;
  data: MeasurementTimesQuery | undefined;
  loading: boolean;
}

export const MeasurementDropdown: FunctionComponent<IMeasurementDropdownProps> = ({
  currentMeasurement,
  handleDropdownSelect,
  data,
  loading,
}: IMeasurementDropdownProps) => {
  const [isVisible, setIsVisible] = useBoolean(false);

  const handleVisibilityChange = useCallback(
    (visible: boolean) => {
      setIsVisible(visible);
    },
    [setIsVisible],
  );

  const handleMenuClick = useCallback(
    (event) => {
      const { key } = event;
      setIsVisible(false);
      handleDropdownSelect(key);
    },
    [handleDropdownSelect, setIsVisible],
  );

  const dropdownContent = useMemo(() => {
    const otherMeasurements = loading ? (
      <LoadingSpinner />
    ) : (
      data?.measurements?.items.map(({ id, createdAt }) => {
        return <Menu.Item key={id}>{formatFromISO(createdAt)}</Menu.Item>;
      })
    );

    return (
      <Menu onClick={handleMenuClick}>
        <MachineItemGroup>{otherMeasurements}</MachineItemGroup>
      </Menu>
    );
  }, [loading, data, handleMenuClick]);

  if (currentMeasurement == null) {
    return (
      <Flex alignItems="center">
        <DropdownTrigger alignItems="center">
          <Box flex="1 1 auto">
            <LoadingSpinner />
          </Box>
          <Box flex="0 0 auto">
            <CaretDownFilled translate="no" />
          </Box>
        </DropdownTrigger>
      </Flex>
    );
  }

  return (
    <Flex alignItems="center">
      <Dropdown
        overlay={dropdownContent}
        trigger={['click']}
        placement="bottomCenter"
        visible={isVisible}
        onVisibleChange={handleVisibilityChange}
        overlayStyle={{ border: `1px solid ${colors.grey.medium}` }}
        getPopupContainer={(triggerNode) => triggerNode}
      >
        <DropdownTrigger alignItems="center">
          <Box flex="1 1 auto">
            <Text>{formatFromISO(currentMeasurement.createdAt)}</Text>
          </Box>
          <Box flex="0 0 auto">
            <CaretDownFilled translate="no" />
          </Box>
        </DropdownTrigger>
      </Dropdown>
    </Flex>
  );
};
