import { Maybe } from '@motorex/common';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDebounce } from 'use-debounce';
import {
  CollectionQueryOptions,
  FilterArgs,
  SortArgs,
} from '../apollo-components';
import {
  ITableSettingsContext,
  TableSettingsContext,
} from './TableSettingsContext';

function areFilterArgsEqual(a: Maybe<FilterArgs>, b: Maybe<FilterArgs>) {
  if (a?.text == null && b?.text == null) {
    return true;
  }

  if (a?.text == null || b?.text == null) {
    return false;
  }

  if (
    a.text.value.length !== b.text.value.length ||
    a.text.field !== b.text.field
  ) {
    return false;
  }

  return a.text.value.every((ai) => b.text?.value.includes(ai));
}

interface IProps {
  children: React.ReactNode;
  refetch: (fetchOptions: CollectionQueryOptions) => void;
  initialFilter?: FilterArgs;
  initialSorting?: SortArgs;
}

export const initialOptions: CollectionQueryOptions = {
  pagination: { currentPage: 1, itemsPerPage: 10 },
  search: null,
};

export const TableSettingsProvider: FunctionComponent<IProps> = ({
  children,
  refetch,
  initialFilter,
  initialSorting,
}: IProps) => {
  const [fetchOptions, setFetchOptions] = useState<CollectionQueryOptions>({
    ...initialOptions,
    filter: initialFilter,
    sort: initialSorting,
  });
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

  useEffect(() => {
    setFetchOptions({
      ...fetchOptions,
      pagination: {
        ...fetchOptions.pagination,
        currentPage: 1,
      },
      search: debouncedSearchTerm,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const onPagination = useCallback(
    (page?: number, pageSize?: number) => {
      // If the items per page are changed reset the currentPage and return
      if (
        pageSize != null &&
        pageSize !== fetchOptions.pagination?.itemsPerPage
      ) {
        return {
          pagination: {
            currentPage: 1,
            itemsPerPage: pageSize,
          },
        };
      }

      if (page != null && page !== fetchOptions.pagination?.currentPage) {
        return {
          pagination: {
            currentPage: page,
            itemsPerPage: pageSize,
          },
        };
      }

      return null;
    },
    [fetchOptions],
  );

  const onSort = useCallback(
    (sort: SortArgs | null) => {
      if (sort == null) {
        // Nothing changed
        if (fetchOptions.sort == null) {
          return null;
        }
        // Remove sorting
        return {
          sort: null,
        };
      }

      // Something changed
      if (
        fetchOptions.sort == null ||
        fetchOptions.sort.field !== sort.field ||
        fetchOptions.sort.order !== sort.order
      ) {
        return {
          sort: {
            field: sort.field,
            order: sort.order,
          },
        };
      }

      return null;
    },
    [fetchOptions],
  );

  const onFilter = useCallback(
    (filter: Record<keyof any, string[]> | null) => {
      const currentFilter = fetchOptions.filter;

      if (filter == null || Object.keys(filter).length === 0) {
        // Remove filter
        const updatedFilter = {
          filter: initialFilter,
        };
        return areFilterArgsEqual(currentFilter, updatedFilter.filter)
          ? null
          : updatedFilter;
      }

      // Something changed
      const key = Object.keys(filter)[0];
      const newFilter = {
        filter: {
          text: {
            field: key,
            value: filter[key],
          },
        },
      };
      return areFilterArgsEqual(currentFilter, newFilter.filter)
        ? null
        : newFilter;
    },
    [fetchOptions, initialFilter],
  );

  const mergeFetchOptions = useCallback(
    (partialOptions: Partial<CollectionQueryOptions>) => {
      setFetchOptions((currentFetchOptions) => ({
        ...currentFetchOptions,
        ...partialOptions,
      }));
    },
    [],
  );

  useEffect(() => {
    refetch(fetchOptions);
  }, [fetchOptions, refetch]);

  const value: ITableSettingsContext = useMemo(
    () => ({
      mergeFetchOptions,
      onFilter,
      onSort,
      pagination: {
        currentPage: fetchOptions.pagination?.currentPage || 1,
        itemsPerPage: fetchOptions.pagination?.itemsPerPage || 10,
        onPagination,
      },
      search: {
        onSearch: setSearchTerm,
        searchTerm,
      },
    }),
    [
      fetchOptions,
      searchTerm,
      onPagination,
      onSort,
      onFilter,
      mergeFetchOptions,
    ],
  );

  return (
    <TableSettingsContext.Provider value={value}>
      {children}
    </TableSettingsContext.Provider>
  );
};
