import { Card } from 'antd';
import axios from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'reflexbox';
import styled from 'styled-components';
import { StaticPage } from '~/components/StaticPage';
import { LoadingSpinner } from '~/components/LoadingSpinner';

const Wrapper = styled(Box)`
  code {
    white-space: pre-line;
  }

  h3 {
    font-weight: bold;
    padding-top: 1rem;
  }
`;

const LicenseCard = styled(Card)`
  width: 100%;
`;

export const Licenses: FunctionComponent = () => {
  const { t } = useTranslation('common');
  const [text, setText] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get('/meta/licenses.html', {
          maxContentLength: 5000000,
          responseType: 'text',
        });
        setText(result.data);
      } catch (e) {
        setText(t('Cannot load licenses.'));
      }
    };
    fetchData();
  }, [t]);

  return (
    <StaticPage>
      <LicenseCard>
        {text === null ? (
          <LoadingSpinner />
        ) : (
          <Wrapper dangerouslySetInnerHTML={{ __html: text }} />
        )}
      </LicenseCard>
    </StaticPage>
  );
};
