import { Menu } from 'antd';
import React, { FunctionComponent, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '~/theme';
import { colors } from '~/theme/variables';

export interface IMenuItem {
  key: string;
  route: string;
  label: string;
}

export interface IAppNavigationProps {
  menuItems: IMenuItem[];
}

const AppMenu = styled(Menu)`
  background-color: transparent;
  border-bottom: 0;

  &.ant-menu-horizontal:not(.ant-menu-dark) {
    > .ant-menu-item {
      padding: 0;
      margin-right: 1rem;
      margin-left: 2.375rem;

      &:hover {
        border-bottom: 5px solid ${colors.white};
      }

      ~ .ant-menu-item {
        margin-left: 1rem;
      }

      &-selected,
      &-active {
        border-bottom: 5px solid ${colors.white};
      }

      a {
        color: ${colors.white};

        &:hover {
          color: ${colors.grey.light};
        }
      }
    }
  }

  > .ant-menu-item {
    top: initial;
  }
`;

const AppMenuItem = styled(Menu.Item)`
  font-weight: 700;
  height: 4rem;
`;

export const AppNavigation: FunctionComponent<IAppNavigationProps> = ({
  menuItems,
}: IAppNavigationProps) => {
  const location = useLocation();

  const selectedKey = useMemo(() => {
    const matchedItem = menuItems.find((menu) =>
      location.pathname.startsWith(menu.route),
    );
    return matchedItem?.key ?? '';
  }, [menuItems, location]);

  return (
    <AppMenu mode="horizontal" selectedKeys={[selectedKey]}>
      {menuItems.map(({ key, route, label }) => (
        <AppMenuItem key={key}>
          <Link to={route}>{label}</Link>
        </AppMenuItem>
      ))}
    </AppMenu>
  );
};
