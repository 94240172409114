import { ApolloQueryResult } from '@apollo/client';
import { Divider, Switch, Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';
import { ReactComponent as LeftArrow } from '~/assets/icons/arrow_left.svg';
import {
  MeasurementType,
  MeQuery,
  UpdateUserSettingsInput,
  UserInformationFragment,
  useUpdateUserSettingsMutation,
} from '~/components/apollo-components';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { ProfileImage } from '~/components/ProfileImage';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { API } from '~/utils/API';
import { createNotification } from '~/utils/createNotification';
import { HTTP } from '~/utils/HTTP';
import { Routes } from '~/utils/Routes';
import { DarkLink, PrimaryBoldText, SmallButton } from '~/utils/styles';
import { UpdatePasswordModal } from './UpdatePassword';

const { Paragraph, Text } = Typography;

const StyledParagraph = styled(Paragraph)`
  &.ant-typography {
    margin-bottom: 0.5rem;
  }
`;

const LinkText = styled(Text)`
  margin-left: 1rem;
`;

const AlignedArrow = styled(LeftArrow)`
  vertical-align: text-bottom;
`;

const WaterSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

const EmulsionSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${({ theme }) => theme.colors.yellow};
  }
`;

interface IProps {
  data: UserInformationFragment;
  refetch: () => Promise<ApolloQueryResult<MeQuery>>;
  toggleIsEditing: (nextValue?: any) => void;
}

export const ViewProfile: FunctionComponent<IProps> = ({
  data,
  refetch,
  toggleIsEditing,
}: IProps) => {
  const { t } = useTranslation(['EasyMonitoring', 'Admin', 'common']);
  const handleError = useErrorHandler();
  const [update, { loading: isUpdating }] = useUpdateUserSettingsMutation();
  const [modalVisible, toggleModalVisible] = useToggle(false);
  const [isUploading, toggleIsUploading] = useToggle(false);

  const canMeasurementTypeButtonsChange = useMemo(() => {
    return {
      [MeasurementType.EMULSION]: data.waterMeasurement,
      [MeasurementType.WATER]: data.emulsionMeasurement,
    };
  }, [data.emulsionMeasurement, data.waterMeasurement]);

  const onChangeMeasurement = useCallback(
    async (variables: UpdateUserSettingsInput) => {
      try {
        await update({
          variables,
        });
        await refetch();
      } catch (e) {
        handleError(e, t('common:Update failed'));
      }
    },
    [t, update, refetch, handleError],
  );

  const onChangeWater = useCallback(
    (checked: boolean) => {
      onChangeMeasurement({
        waterMeasurement: checked,
      });
    },
    [onChangeMeasurement],
  );

  const onChangeEmulsion = useCallback(
    (checked: boolean) => {
      onChangeMeasurement({
        emulsionMeasurement: checked,
      });
    },
    [onChangeMeasurement],
  );

  const uploadRequest = useCallback(
    async (info) => {
      toggleIsUploading(true);

      const data = new FormData();
      data.append('file', info.file);

      await HTTP.post(`${API.profile.photo.base}`, data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      await refetch();
      toggleIsUploading(false);
    },
    [refetch, toggleIsUploading],
  );

  const beforeUpload = useCallback(
    (file: RcFile) => {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        createNotification(
          'error',
          t('common:You can only upload JPG or PNG files.'),
        );
        return false;
      }

      if (file.size / 1024 / 1024 > 2) {
        createNotification(
          'error',
          t('common:Image must be smaller than 2MB.'),
        );
        return false;
      }

      return true;
    },
    [t],
  );

  return (
    <>
      <Flex>
        <Box width={1 / 3}>
          <DarkLink to={Routes.base}>
            <AlignedArrow />
            <LinkText>{t('common:Back')}</LinkText>
          </DarkLink>
        </Box>
        <Box width={1 / 3} textAlign="center">
          <Box width="104px" height="104px">
            {isUploading ? <LoadingSpinner /> : <ProfileImage />}
          </Box>
        </Box>
        <Box width={1 / 3} textAlign="right">
          <Upload
            name="file"
            accept={'image/jpeg,image/png'}
            customRequest={uploadRequest}
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            <SmallButton size="small" type="link">
              {t('common:Change Profile Image')}
            </SmallButton>
          </Upload>
        </Box>
      </Flex>
      <Divider />
      <StyledParagraph>
        <Flex>
          <Box width={1 / 2}>
            <PrimaryBoldText>{t('common:First Name')}</PrimaryBoldText>
          </Box>
          <Box width={1 / 2} textAlign="right">
            <SmallButton size="small" type="link" onClick={toggleIsEditing}>
              {t('common:Edit Profile')}
            </SmallButton>
          </Box>
        </Flex>
      </StyledParagraph>
      <Paragraph>{data.firstName}</Paragraph>
      <StyledParagraph>
        <PrimaryBoldText>{t('common:Last Name')}</PrimaryBoldText>
      </StyledParagraph>
      <Paragraph>{data.lastName}</Paragraph>
      <StyledParagraph>
        <PrimaryBoldText>{t('common:Email Address')}</PrimaryBoldText>
      </StyledParagraph>
      <Paragraph>{data.email}</Paragraph>
      <StyledParagraph>
        <PrimaryBoldText>{t('common:Phone Number')}</PrimaryBoldText>
      </StyledParagraph>
      <Paragraph>
        {data.phoneNumber != null && data.phoneNumber !== ''
          ? data.phoneNumber
          : '-'}
      </Paragraph>
      <StyledParagraph>
        <PrimaryBoldText>{t('Admin:Company')}</PrimaryBoldText>
      </StyledParagraph>
      <Paragraph>{data.company?.name ?? '-'}</Paragraph>
      <Divider />
      <PrimaryBoldText>{t('Measurement Types Selection')}</PrimaryBoldText>
      <Flex my={3}>
        <Box width={1 / 3}>{t(MeasurementType.WATER)}</Box>
        <Box width={2 / 3}>
          <WaterSwitch
            disabled={
              isUpdating ||
              !canMeasurementTypeButtonsChange[MeasurementType.WATER]
            }
            onChange={onChangeWater}
            defaultChecked={data.waterMeasurement}
          />
        </Box>
      </Flex>
      <Flex mt={3}>
        <Box width={1 / 3}>{t(MeasurementType.EMULSION)}</Box>
        <Box width={2 / 3}>
          <EmulsionSwitch
            disabled={
              isUpdating ||
              !canMeasurementTypeButtonsChange[MeasurementType.EMULSION]
            }
            onChange={onChangeEmulsion}
            defaultChecked={data.emulsionMeasurement}
          />
        </Box>
      </Flex>
      <Divider />
      <SmallButton onClick={toggleModalVisible} size="small" type="link">
        {t('common:Change Password')}
      </SmallButton>
      <UpdatePasswordModal
        visible={modalVisible}
        onCancel={toggleModalVisible}
        onOk={toggleModalVisible}
      />
    </>
  );
};
