import React, { FunctionComponent } from 'react';
import { Route, Switch, UnauthenticatedRoute } from '~/components/Route';
import { Routes } from '~/utils/Routes';
import { ChangePassword } from './ChangePassword';
import { ForgotPassword } from './ForgotPassword';
import { LoginPage } from './Login';
import { RegisterPage } from './Register';
import { VerifyEmail } from './VerifyEmail';

export const authPaths = [
  Routes.auth.changePassword.base,
  Routes.auth.login.base,
  Routes.auth.register.base,
  Routes.auth.forgotPassword.base,
  Routes.auth.verifyEmail.base,
];

export const AuthRouter: FunctionComponent = () => {
  return (
    <Switch>
      <Route
        path={Routes.auth.changePassword.base}
        component={ChangePassword}
        exact={true}
      />
      <UnauthenticatedRoute
        path={Routes.auth.login.base}
        component={LoginPage}
        exact={true}
      />
      <UnauthenticatedRoute
        path={Routes.auth.register.base}
        component={RegisterPage}
        exact={true}
      />
      <UnauthenticatedRoute
        path={Routes.auth.forgotPassword.base}
        component={ForgotPassword}
        exact={true}
      />
      <UnauthenticatedRoute
        path={Routes.auth.verifyEmail.base}
        component={VerifyEmail}
        exact={true}
      />
    </Switch>
  );
};
