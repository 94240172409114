export const staticPages = {
  Contact: 'Referente',
  FAQ: 'FAQ',
  Imprint: 'Colophon',
  Licenses: 'Licenze',
  'Privacy Protection': 'Protezione dei dati',
  'Terms and Conditions': 'CGC',
  'If you have any other questions, please contact us directly via one of the following channels':
    'Per tutte le altre domande ti preghiamo di contattarci direttamente attraverso uno dei seguenti canali',
  Phone: 'Telefono',
  Fax: 'Telefax',
  'E-Mail': 'E-mail',
  'Company name': 'Ragione sociale',
  'Contact information': 'Dati di contatto',
  'Authorized person': 'Persona autorizzata alla rappresentanza',
  'Entry in the commercial register': 'Iscrizione nel registro di commercio',
  'VAT-Number': 'Numero di partita IVA',
  'Responsible for content': 'Responsabile dei contenuti',
  Copyright: 'Copyright',
  'Please read the relevant paragraph in our privacy policy.':
    'Si prega di leggere il paragrafo corrispondente nelle nostre disposizioni sulla protezione dei dati.',
};
