import React, { FunctionComponent } from 'react';
import { EasyMonitoring } from '~/pages/EasyMonitoring';
import { Profile } from '~/pages/Profile';
import { staticPagePaths, StaticPageRouter } from '~/pages/StaticPages';
import { AuthenticatedRoute, Route, Switch } from './components/Route';
import { adminPaths, AdminRouter } from './pages/Admin';
import { authPaths, AuthRouter } from './pages/Auth';
import { Base } from './pages/Base';
import { Routes } from './utils/Routes';
import { Role } from './components/apollo-components';

export const Motorex: FunctionComponent = () => {
  return (
    <Switch>
      <Route path={authPaths} component={AuthRouter} exact={true} />
      <Route path={adminPaths} component={AdminRouter} exact={true} />
      <Route path={staticPagePaths} component={StaticPageRouter} />
      <AuthenticatedRoute path={Routes.profile.base} component={Profile} />
      <AuthenticatedRoute
        path={Routes.easyMonitoring.base}
        component={EasyMonitoring}
        neededRoles={[Role.USER]}
      />
      <Route path={Routes.base} component={Base} exact={true} />
    </Switch>
  );
};
