import { Button, Dropdown } from 'antd';
import React, { FunctionComponent } from 'react';
import { UserMenu } from '~/components/Header/UserDropdown/UserMenu';
import { ProfileImage } from '~/components/ProfileImage';
import { styled } from '~/theme';

const MenuButton = styled(Button)`
  height: 80%;
  width: 96px;
`;

export const UserDropdown: FunctionComponent = () => {
  return (
    <Dropdown
      overlay={<UserMenu />}
      trigger={['click']}
      placement="bottomRight"
    >
      <MenuButton type="link">
        <ProfileImage width="44px" height="44px" />
      </MenuButton>
    </Dropdown>
  );
};
