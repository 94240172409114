import { auth } from '~/translations/it/auth';
import { common } from '~/translations/it/common';
import { EasyMonitoring } from '~/translations/it/EasyMonitoring';
import { Admin } from '~/translations/it/Admin';
import { faq } from '~/translations/it/faq';
import { staticPages } from '~/translations/it/staticPages';
import { privacyProtection } from '~/translations/it/privacyProtection';
import { termsAndConditions } from '~/translations/it/termsAndConditions';

export const it = {
  auth,
  common,
  faq,
  staticPages,
  EasyMonitoring,
  Admin,
  privacyProtection,
  termsAndConditions,
};
