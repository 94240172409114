import React, { FunctionComponent } from 'react';
import { Route, Switch } from '~/components/Route';
import { Contact } from '~/pages/StaticPages/Contact';
import { FAQ } from '~/pages/StaticPages/FAQ';
import { Imprint } from '~/pages/StaticPages/Imprint';
import { Licenses } from '~/pages/StaticPages/Licenses';
import { PrivacyProtection } from '~/pages/StaticPages/PrivacyProtection';
import { TermsAndConditions } from '~/pages/StaticPages/TermsAndConditions';
import { Routes } from '~/utils/Routes';

export const staticPagePaths = [
  Routes.static.contact.base,
  Routes.static.faq.base,
  Routes.static.imprint.base,
  Routes.static.licenses.base,
  Routes.static.privacy.base,
  Routes.static.terms.base,
];

export const StaticPageRouter: FunctionComponent = () => {
  return (
    <Switch>
      <Route
        path={Routes.static.contact.base}
        component={Contact}
        exact={true}
      />
      <Route path={Routes.static.faq.base} component={FAQ} exact={true} />
      <Route
        path={Routes.static.imprint.base}
        component={Imprint}
        exact={true}
      />
      <Route
        path={Routes.static.licenses.base}
        component={Licenses}
        exact={true}
      />
      <Route
        path={Routes.static.privacy.base}
        component={PrivacyProtection}
        exact={true}
      />
      <Route
        path={Routes.static.terms.base}
        component={TermsAndConditions}
        exact={true}
      />
    </Switch>
  );
};
