import { Button, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';
import { AlignedBox, ButtonRow, DisabledText } from '~/utils/styles';
import { UpdateUserInput } from '../apollo-components';
import { validationSchema } from './validationSchema';
import { SmallButton } from '~/utils/styles';

const { Paragraph } = Typography;
const { Item } = Form;

const StyledForm = styled(Form)`
  label:first-of-type {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-typography {
    margin-bottom: 0.5rem;
  }
`;

const StyledBox = styled(Box)`
  font-weight: 600;
`;

const StyledButtonRow = styled(ButtonRow)`
  margin-top: 0;
`;

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
}

interface IProfileFormProps {
  data: IFormData;
  companyName: string;
  onSubmit: (values: UpdateUserInput) => void;
  onCancel: () => void;
  loading: boolean;
}

export const ProfileForm: FunctionComponent<IProfileFormProps> = ({
  data,
  companyName,
  onSubmit,
  onCancel,
  loading,
}: IProfileFormProps) => {
  const { t } = useTranslation(['common', 'Admin']);
  const valSchema = useMemo(() => validationSchema(t), [t]);

  return (
    <Formik<UpdateUserInput>
      onSubmit={onSubmit}
      validationSchema={valSchema}
      initialValues={data}
    >
      {({ isValid }) => (
        <StyledForm layout="vertical">
          <Item
            name="firstName"
            label={
              <Flex width="100%">
                <StyledBox width={1 / 2}>{t('First Name')}</StyledBox>
                <AlignedBox width={1 / 2} align="right">
                  <SmallButton size="small" type="link" disabled={true}>
                    {t('Edit Profile')}
                  </SmallButton>
                </AlignedBox>
              </Flex>
            }
            required={true}
          >
            <Input name="firstName" />
          </Item>
          <Item
            name="lastName"
            label={<StyledBox>{t('Last Name')}</StyledBox>}
            required={true}
          >
            <Input name="lastName" />
          </Item>
          <Item
            name="email"
            label={<StyledBox>{t('Email Address')}</StyledBox>}
            required={true}
          >
            <Input name="email" />
          </Item>
          <Item
            name="phoneNumber"
            label={<StyledBox>{t('Phone Number')}</StyledBox>}
          >
            <Input name="phoneNumber" />
          </Item>
          <Paragraph>
            <DisabledText>
              {<StyledBox>{t('Admin:Company')}</StyledBox>}
            </DisabledText>
          </Paragraph>
          <DisabledText>{companyName}</DisabledText>
          <StyledButtonRow>
            <Button
              disabled={loading}
              onClick={onCancel}
              type="link"
              size="large"
            >
              {t('Cancel')}
            </Button>
            <SubmitButton loading={loading} disabled={!isValid} size="large">
              {t('Save')}
            </SubmitButton>
          </StyledButtonRow>
        </StyledForm>
      )}
    </Formik>
  );
};
