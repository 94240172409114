import { ChemicalEnum } from '@motorex/common';
import { TFunction } from 'i18next';
import React, { ReactNode } from 'react';

export const getLabelTextForChemical = (
  t: TFunction,
): Record<ChemicalEnum, string | ReactNode> => ({
  [ChemicalEnum.ALKALINITY]: t('Alkalinity (A)'),
  [ChemicalEnum.CHLORINE]: <>{t('Chlorine')} mg/l</>,
  [ChemicalEnum.CO2]: (
    <>
      {t('Carbon Dioxide')} (CO<sub>2</sub>) mg/l
    </>
  ),
  [ChemicalEnum.CONCENTRATION]: t('Concentration'),
  [ChemicalEnum.HARDNESS]: t('Hardness (H)'),
  [ChemicalEnum.NITRATE]: (
    <>
      {t('Nitrate')} (NO<sub>3</sub>) mg/l
    </>
  ),
  [ChemicalEnum.NITRITE]: (
    <>
      {t('Nitrite')} (NO<sub>2</sub>) mg/l
    </>
  ),
  [ChemicalEnum.PH]: t('pH-value'),
  [ChemicalEnum.TEMPERATURE]: t('Temperature'),
});
