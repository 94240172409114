import React, { FunctionComponent } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { Role } from '~/components/apollo-components';
import { NotAuthorized } from '~/components/Error';
import { useAuthentication } from '~/hooks/useAuthentication';
import { isGranted } from '~/utils/common';
import { Routes } from '~/utils/Routes';
import { Route } from './Route';

interface IProps extends RouteProps {
  neededRoles?: Role[];
}

export const AuthenticatedRoute: FunctionComponent<IProps> = ({
  neededRoles,
  ...props
}: IProps) => {
  const { currentUser } = useAuthentication();

  if (currentUser == null) {
    return <Redirect to={Routes.base} />;
  }

  if (
    neededRoles != null &&
    neededRoles.length > 0 &&
    !isGranted(currentUser, ...neededRoles)
  ) {
    return <NotAuthorized />;
  }

  return <Route {...props} />;
};
