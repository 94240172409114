export const details = {
  'Add another graph': 'Weiteren Graph anzeigen',
  'Alkalinity (A)': 'Karbonathärte (KH)',
  'All latest measured values are in the normal range.':
    'Alle aktuellen Messwerte befinden sich im Normbereich.',
  'Carbon Dioxide': 'Kohlenstoffdioxid',
  Chlorine: 'Chlor',
  'CO<1>2</1>': 'CO<1>2</1>-Menge',
  Concentration: 'Konzentration',
  'Critical Alkalinity': 'Kritische Karbonhärte',
  'Critical Chlorine': 'Kritische Chlor-Menge',
  'Critical CO<1>2</1>': 'Kritischer CO<1>2</1>-Menge',
  'Critical Hardness': 'Kritische Gesamthärte',
  'Critical Nitrate': 'Kritische Nitrat-Menge',
  'Critical Nitrite': 'Kritische Nitrit-Menge',
  'Critical pH-value': 'Kritischer pH-Wert',
  'Critical Temperature': 'Kritische Temperatur',
  Date: 'Datum',
  Details: 'Details',
  'Export Table': 'Tabelle exportieren',
  'Hardness (H)': 'Gesamthärte (GH)',
  'Hide graph': 'Graph ausblenden',
  History: 'Verlauf',
  'History in Numbers': 'Verlauf in Zahlen',
  'Measurement Results': 'Messergebnisse',
  'n.a.': 'n.v.',
  Nitrate: 'Nitrat',
  Nitrite: 'Nitrit',
  'Noticeable Alkalinity': 'Auffällige Karbonhärte',
  'Noticeable Chlorine': 'Auffällige Chlor-Menge',
  'Noticeable CO<1>2</1>': 'Auffälliger CO<1>2</1>-Menge',
  'Noticeable Hardness': 'Auffällige Gesamthärte',
  'Noticeable Nitrate': 'Auffällige Nitrat-Menge',
  'Noticeable Nitrite': 'Auffällige Nitrit-Menge',
  'Noticeable pH-value': 'Auffälliger pH-Wert',
  'Noticeable Temperatur': 'Auffällige Temperatur',
  'pH-value': 'pH-Wert',
  Temperature: 'Temperatur',
};
