import { QrcodeOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { FormikHelpers } from 'formik';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { Box, Flex } from 'reflexbox/styled-components';
import {
  MachineFragment,
  MeasurementType,
  useDeleteMachineMutation,
  useUpdateMachineMutation,
} from '~/components/apollo-components';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { SecondaryLinkButton } from '~/components/SecondaryLinkButton';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import {
  Details,
  IDetailsFormValues,
} from '~/pages/EasyMonitoring/MachineDetail/DetailOverviewCard/Details';
import { styled } from '~/theme';
import { createNotification } from '~/utils/createNotification';
import { Routes } from '~/utils/Routes';
import { DashboardCard, LinkStyleButton } from '~/utils/styles';
import { ReactComponent as WaterTypeIcon } from '~/assets/icons/machine_water.svg';
import { ReactComponent as EmulsionTypeIcon } from '~/assets/icons/machine_emulsion.svg';
import { useHistory, useParams } from 'react-router-dom';
import { MachineDeleteModal } from '../../Dashboard/MachineDeleteModal';

const { Title } = Typography;

const MachineTitle = styled(Title)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightFlex = styled(Flex)`
  svg {
    width: 5rem;
    height: 5rem;
  }
`;

const QrIcon = styled(QrcodeOutlined)`
  font-size: 1.5rem;
`;

interface IDetailOverviewCardProps {
  loading: boolean;
  machine?: MachineFragment;
}

export const DetailOverviewCard: FunctionComponent<IDetailOverviewCardProps> = ({
  loading,
  machine,
}: IDetailOverviewCardProps) => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation(['EasyMonitoring']);
  const handleError = useErrorHandler();
  const [edit, setEdit] = useBoolean(false);
  const [deleteModalVisible, setDeleteModalVisible] = useBoolean(false);

  const [update] = useUpdateMachineMutation();
  const [deleteMachine] = useDeleteMachineMutation();

  const enableEditMode = useCallback(() => {
    setEdit(true);
  }, [setEdit]);

  const onCancel = useCallback(() => {
    setEdit(false);
  }, [setEdit]);

  const updateMachine = useCallback(
    async (
      values: IDetailsFormValues,
      formikHelpers: FormikHelpers<IDetailsFormValues>,
    ) => {
      if (machine == null) {
        return;
      }

      try {
        await update({
          variables: {
            id: machine.id,
            machineName: machine.machineName,
            ...values,
          },
        });
        setEdit(false);
        createNotification('success', t('common:Changes successfully saved.'));
      } catch (err) {
        handleError(err);
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [update, setEdit, machine, t, handleError],
  );

  const onDeleteMachine = (id: string) => {
    deleteMachine({ variables: { machineId: id } });
    setDeleteModalVisible(false);
    history.push('/easy-monitoring/dashboard');
    createNotification('success', t('common:Machine successfully deleted.'));
  };

  useEffect(() => {
    if (loading) {
      setEdit(false);
    }
  }, [loading, setEdit]);

  if (loading) {
    return (
      <DashboardCard>
        <LoadingSpinner />
      </DashboardCard>
    );
  }

  if (machine == null) {
    return <DashboardCard />;
  }

  return (
    <>
      <DashboardCard>
        <Flex>
          <Box>
            <MachineTitle level={3}>{machine.machineName}</MachineTitle>
          </Box>
        </Flex>
        <Flex>
          <Box flex="1 1 auto">
            <Details
              machine={machine}
              edit={edit}
              handleSubmit={updateMachine}
              handleCancel={onCancel}
            />
          </Box>
          <Flex
            flexDirection="column"
            flex="1 0 auto"
            justifyContent="space-between"
          >
            <RightFlex flexDirection="column" alignItems="flex-end">
              <LinkStyleButton
                type="link"
                onClick={enableEditMode}
                disabled={edit}
              >
                {t('common:Edit')}
              </LinkStyleButton>
              {machine.measurementType === MeasurementType.WATER ? (
                <WaterTypeIcon />
              ) : (
                <EmulsionTypeIcon />
              )}
            </RightFlex>
          </Flex>
        </Flex>
        {!edit && (
          <Flex justifyContent="flex-end" alignItems="center">
            <Button type="link" onClick={() => setDeleteModalVisible(true)}>
              {t('delete source')}
            </Button>
            <Box>
              <SecondaryLinkButton
                to={Routes.easyMonitoring.machine.single.qr(id)}
                target="_blank"
                icon={<QrIcon translate="no" />}
              >
                <span>{t('Generate QR-Code')}</span>
              </SecondaryLinkButton>
            </Box>
          </Flex>
        )}
      </DashboardCard>
      <MachineDeleteModal
        visible={deleteModalVisible}
        name={machine.machineName}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={() => onDeleteMachine(machine.id)}
      />
    </>
  );
};
