import { PASSWORD_TOKEN_INVALID_MESSAGE } from '@motorex/common';

export const auth = {
  'Forgot password': 'Password dimenticata',
  Login: 'Login',
  'Password change failed': 'La modifica della password non è riuscita.',
  'Password Confirmation': 'Conferma della password',
  'Password successfully set.': 'Password impostata con successo.',
  [PASSWORD_TOKEN_INVALID_MESSAGE]:
    'Il token di reset della password non è valido.',
  'Passwords do not match': 'Le password non corrispondono.',
  'Phone Number': 'Numero di telefono',
  'Please confirm your new password': 'Conferma la nuova password.',
  'Please enter a new password': 'Inserisci una nuova password.',
  'Please enter your Email': 'Inserisci il tuo indirizzo e-mail.',
  'Please enter your email address to request a new password.':
    'Inserisci il tuo indirizzo e-mail per reimpostare la password.',
  'Please enter your First Name': 'Inserisci il tuo nome.',
  'Please enter your Last Name': 'Inserisci il tuo cognome.',
  'Please enter your password': 'Inserisci la tua password.',
  Register: 'Registrazione',
  'Reset password': 'Reimposta password',
  'Welcome!': 'Benvenuto!',
};
