import { Spin } from 'antd';
import React, { FunctionComponent } from 'react';
import { styled } from '~/theme';

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 600px;
  align-items: center;
`;

export const LoadingContentWrapper: FunctionComponent = () => {
  return (
    <LoadingWrapper>
      <Spin />
    </LoadingWrapper>
  );
};
