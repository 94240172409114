import React, { FunctionComponent } from 'react';
import { StaticPage } from '~/components/StaticPage';
import { useTranslation } from 'react-i18next';
import { Card, Descriptions, Typography } from 'antd';
import { styled } from '~/theme';

const { Text, Title, Paragraph } = Typography;
const { Item: DescriptionItem } = Descriptions;

const StyledParagraph = styled(Paragraph)`
  && {
    margin-bottom: 0;
  }
  letter-spacing: 1px;
  line-height: 22px;
`;

const StyledText = styled(Text)`
  margin-left: 5px;
`;

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-row:not(:last-of-type) {
    > th {
      padding-bottom: 0;
    }

    > td {
      padding-bottom: 1.25rem;
    }
  }
`;

export const Imprint: FunctionComponent = () => {
  const { t } = useTranslation('staticPages');

  return (
    <StaticPage>
      <Card>
        <StyledDescriptions
          title={<Title level={2}>{t('Imprint')}</Title>}
          layout="vertical"
          size="small"
          column={1}
        >
          <DescriptionItem label={t('Company name')}>
            BUCHER AG LANGENTHAL
          </DescriptionItem>
          <DescriptionItem label={t('common:Address')}>
            <StyledParagraph>Bern-Zürich-Strasse 31</StyledParagraph>
            <StyledParagraph>Postfach</StyledParagraph>
            <StyledParagraph>CH-4901 Langenthal</StyledParagraph>
          </DescriptionItem>
          <DescriptionItem label={t('Contact information')}>
            <StyledParagraph>
              {t('Phone')}:<StyledText>+41 62 919 75 75</StyledText>
            </StyledParagraph>
            <StyledParagraph>
              {t('Fax')}:<StyledText>+41 62 919 75 95</StyledText>
            </StyledParagraph>
            <StyledParagraph>
              {t('E-Mail')}:
              <StyledText>
                <a
                  href="mailto:info@motorex.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@motorex.com
                </a>
              </StyledText>
            </StyledParagraph>
          </DescriptionItem>
          <DescriptionItem label={t('Authorized person')}>
            Edi Fischer
          </DescriptionItem>
          <DescriptionItem label={t('Entry in the commercial register')}>
            <StyledParagraph>{t('Number')} CH-053.3.000.356-0</StyledParagraph>
            <StyledParagraph>Handelsregisteramt Kanton Bern</StyledParagraph>
          </DescriptionItem>
          <DescriptionItem label={t('VAT-Number')}>
            CHE-107.149.804
          </DescriptionItem>
          <DescriptionItem label={t('Responsible for content')}>
            BUCHER AG LANGENTHAL
          </DescriptionItem>
          <DescriptionItem label={t('Copyright')}>
            {t('Please read the relevant paragraph in our privacy policy.')}
          </DescriptionItem>
        </StyledDescriptions>
      </Card>
    </StaticPage>
  );
};
