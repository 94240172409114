import { CaretDownFilled } from '@ant-design/icons';
import { Dropdown, Input, Menu } from 'antd';
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { useBoolean } from 'react-use';
import { Box, Flex } from 'reflexbox/styled-components';
import {
  MachineFragment,
  useDashboardMachinesQuery,
} from '~/components/apollo-components';
import { ColoredMachineName } from '~/components/EasyMonitoring/ColoredMachineName';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { styled } from '~/theme';
import { colors } from '~/theme/variables';
import { Routes } from '~/utils/Routes';
import { ReactComponent as SearchIcon } from '~/assets/icons/search.svg';

const DropdownTrigger = styled(Flex)`
  height: 3rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.medium};
  width: 26rem;
  max-width: 100%;
  padding: 0 0.75rem;
`;

const NameBox = styled(Box)`
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MachineItemGroup = styled(Menu.ItemGroup)`
  max-height: 175px;
  overflow-y: auto;
`;

const StyledSearchIcon = styled(SearchIcon)`
  fill: ${({ theme }) => theme.colors.primary};
  width: 1.85rem;
  height: 1.85rem;
`;

const SearchInput = styled(Input)`
  padding: 0;
`;

const MachineSearchDivider = styled(Menu.Divider)`
  background-color: ${({ theme }) => theme.colors.grey.medium};
`;

interface IMachineDropdownProps {
  currentMachine?: MachineFragment;
  currentMachineLoading: boolean;
}

export const MachineDropdown: FunctionComponent<IMachineDropdownProps> = ({
  currentMachine,
  currentMachineLoading,
}: IMachineDropdownProps) => {
  const { t } = useTranslation('EasyMonitoring');
  const [isVisible, setIsVisible] = useBoolean(false);
  const { data, loading, refetch } = useDashboardMachinesQuery();

  const searchSource = useCallback(
    async (changeEvent: ChangeEvent<HTMLInputElement>) => {
      await refetch({ search: changeEvent.target.value });
    },
    [refetch],
  );

  const handleVisibilityChange = useCallback(
    (visible: boolean) => {
      setIsVisible(visible);
    },
    [setIsVisible],
  );

  const handleMenuClick = useCallback(
    (event) => {
      const { key, domEvent } = event;
      if (key === 'search') {
        domEvent.preventDefault();
      } else {
        setIsVisible(false);
      }
    },
    [setIsVisible],
  );

  const dropdownContent = useMemo(() => {
    const machines = (data?.machines?.items ?? [])
      .filter((machine) => machine.id !== currentMachine?.id)
      .map(({ id, measurementType, machineName }) => (
        <Menu.Item key={id}>
          <Link key={id} to={Routes.easyMonitoring.machine.single.base(id)}>
            <ColoredMachineName
              measurementType={measurementType}
              content={machineName}
            />
          </Link>
        </Menu.Item>
      ));

    return (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="search" onClick={(e) => e.domEvent.preventDefault()}>
          <SearchInput
            prefix={<StyledSearchIcon />}
            bordered={false}
            placeholder={t('Search Source')}
            onChange={searchSource}
          />
        </Menu.Item>
        <MachineSearchDivider />
        <MachineItemGroup>{machines}</MachineItemGroup>
      </Menu>
    );
  }, [data, currentMachine, t, searchSource, handleMenuClick]);

  if (currentMachineLoading || loading) {
    return (
      <Flex alignItems="center">
        <DropdownTrigger alignItems="center">
          <NameBox flex="1 1 auto">
            <LoadingSpinner />
          </NameBox>
          <Box flex="0 0 auto">
            <CaretDownFilled translate="no" />
          </Box>
        </DropdownTrigger>
      </Flex>
    );
  }

  if (currentMachine == null && !currentMachineLoading) {
    return <Redirect to={Routes.easyMonitoring.dashboard.base} />;
  }

  return (
    <Flex alignItems="center">
      <Dropdown
        overlay={dropdownContent}
        trigger={['click']}
        placement="bottomCenter"
        visible={isVisible}
        onVisibleChange={handleVisibilityChange}
        overlayStyle={{ border: `1px solid ${colors.grey.medium}` }}
        getPopupContainer={(triggerNode) => triggerNode}
      >
        <DropdownTrigger alignItems="center">
          <NameBox flex="1 1 auto">
            <ColoredMachineName
              measurementType={currentMachine!.measurementType}
              content={currentMachine!.machineName}
            />
          </NameBox>
          <Box flex="0 0 auto">
            <CaretDownFilled translate="no" />
          </Box>
        </DropdownTrigger>
      </Dropdown>
    </Flex>
  );
};
