import { ColumnProps } from 'antd/lib/table';
import { format } from 'date-fns';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ITableData } from '~/components/DataTable/DataTable';
import { IHistoryRowData, RowDataType, RowInfoType } from './index';
import { styled } from '~/theme';
import { SecondaryButton } from '~/utils/styles';
import { ReactComponent as ExportIcon } from '~/assets/icons/export.svg';

const ButtonText = styled.span`
  margin-left: 0.5rem;
`;

function isRowInfoType(
  rowData: RowInfoType | RowDataType,
): rowData is RowInfoType {
  return (rowData as RowInfoType).label !== undefined;
}

interface IHistoryNumbersTableExportButtonProps {
  dataSource: ITableData<IHistoryRowData>;
  columns: ColumnProps<IHistoryRowData>[];
}

export const HistoryNumbersTableExportButton: FunctionComponent<IHistoryNumbersTableExportButtonProps> = ({
  dataSource,
  columns,
}: IHistoryNumbersTableExportButtonProps) => {
  const { t } = useTranslation('EasyMonitoring');

  const handleExportClick = useCallback(
    (event) => {
      event.preventDefault();
      let csvContent = 'data:text/csv;charset=utf-8;\n,';
      for (const column of columns) {
        csvContent += `"${column.title ?? ''}",`;
      }

      csvContent += '\n';

      for (const rowData of dataSource.items) {
        for (const cellData of Object.values(rowData)) {
          if (isRowInfoType(cellData)) {
            if (typeof cellData.label !== 'string') {
              csvContent += `"${cellData.plainLabel}",`;
            } else {
              csvContent += `"${cellData.label}",`;
            }
          } else {
            csvContent += `"${cellData.measuredValue}",`;
          }
        }
        csvContent += '\n';
      }

      const encodedCsv = encodeURI(csvContent);
      const a = document.createElement('a');
      a.href = encodedCsv;
      a.download = `history-export-${format(new Date(), 'yyyy-MM-dd')}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    [columns, dataSource],
  );

  return (
    <SecondaryButton
      onClick={handleExportClick}
      icon={<ExportIcon />}
      type="primary"
    >
      <ButtonText>{t('Export Table')}</ButtonText>
    </SecondaryButton>
  );
};
