import { PASSWORD_TOKEN_INVALID_MESSAGE } from '@motorex/common';
import { Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Box } from 'reflexbox';
import {
  ChangePasswordMutationVariables,
  useChangePasswordMutation,
  useValidatePasswordTokenQuery,
} from '~/components/apollo-components';
import { AuthenticationLayout } from '~/components/AuthenticationLayout';
import { LoadingPage } from '~/components/LoadingPage';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { createNotification } from '~/utils/createNotification';
import { Routes } from '~/utils/Routes';
import { AuthenticationCard, ButtonRow } from '~/utils/styles';
import { validationSchema } from './validationSchema';

const { Title, Text } = Typography;
const { Item } = Form;
const { Password } = Input;

interface IRouteParams {
  token: string;
}

interface IFormValues {
  newPassword: string;
  confirmPassword: string;
}

export const ChangePassword: FunctionComponent<RouteComponentProps<
  IRouteParams
>> = ({ match }: RouteComponentProps<IRouteParams>) => {
  const { t } = useTranslation('common');
  const { data, error, loading } = useValidatePasswordTokenQuery({
    variables: { token: match.params.token },
  });
  const [
    changePassword,
    { loading: passwordChangeLoading },
  ] = useChangePasswordMutation();
  const history = useHistory();
  const handleError = useErrorHandler();

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      try {
        const payload: ChangePasswordMutationVariables = {
          newPassword: values.newPassword,
          newPasswordConfirmation: values.confirmPassword,
          token: match.params.token,
        };
        const response = await changePassword({ variables: payload });
        if (response && response.data && response.data.changePassword) {
          createNotification(
            'success',
            t('Set new password'),
            t('Password successfully set.'),
          );
          history.push(Routes.base);
        }
      } catch (error) {
        handleError(error, t('Change Password'), {
          [PASSWORD_TOKEN_INVALID_MESSAGE]: t(PASSWORD_TOKEN_INVALID_MESSAGE),
        });
      }
    },
    [t, history, match, handleError, changePassword],
  );

  const cachedValidationSchema = useMemo(() => validationSchema(t), [t]);
  const initialValues = useMemo(
    () => ({ newPassword: '', confirmPassword: '' }),
    [],
  );

  useEffect(() => {
    if (error) {
      handleError(error, t('Set new password'), {
        [PASSWORD_TOKEN_INVALID_MESSAGE]: t(PASSWORD_TOKEN_INVALID_MESSAGE),
      });
      history.replace(Routes.base);
    }
  }, [data, error, handleError, history, t]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <AuthenticationLayout>
      <AuthenticationCard>
        <Box mb={5}>
          <Title level={3}>{t('Set new password')}</Title>
        </Box>
        <Formik<IFormValues>
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={cachedValidationSchema}
        >
          {({ isValid }) => (
            <Form layout="vertical">
              <Item
                name="newPassword"
                label={<Text>{t('New password')}</Text>}
                required={true}
              >
                <Password name="newPassword" size="large" />
              </Item>
              <Item
                name="confirmPassword"
                label={<Text>{t('Confirm new password')}</Text>}
                required={true}
              >
                <Password name="confirmPassword" size="large" />
              </Item>
              <ButtonRow>
                <SubmitButton
                  loading={loading || passwordChangeLoading}
                  disabled={!isValid || loading || passwordChangeLoading}
                  size="large"
                >
                  {t('Save password')}
                </SubmitButton>
              </ButtonRow>
            </Form>
          )}
        </Formik>
      </AuthenticationCard>
    </AuthenticationLayout>
  );
};
