export const staticPages = {
  Contact: 'Personne de contact',
  FAQ: 'FAQ',
  Imprint: 'Mentions légales',
  Licenses: 'Licences',
  'Privacy Protection': 'Protection des données',
  'Terms and Conditions': 'Conditions générales',
  'If you have any other questions, please contact us directly via one of the following channels':
    "Si vous avez d'autres questions, veuillez nous contacter directement via l'un des canaux suivants",
  Phone: 'Téléphone',
  Fax: 'Fax',
  'E-Mail': 'Courriel',
  'Company name': "Nom de l'entreprise",
  'Contact information': 'Information de contact',
  'Authorized person': 'Personne autorisée',
  'Entry in the commercial register': 'Inscription au registre du commerce',
  'VAT-Number': 'Numéro TVA',
  'Responsible for content': 'Responsable du contenu',
  Copyright: "Droit d'auteur",
  'Please read the relevant paragraph in our privacy policy.':
    'Veuillez lire le paragraphe correspondant dans notre police de confidentialité.',
};
