import { Typography, Card } from 'antd';
import { styled } from '~/theme';

const { Title, Paragraph } = Typography;


export const StyledSubParagraph = styled(Paragraph)`
  font-size: 0.85rem;
`;

export const StyledCard = styled(Card)`
  max-width: 100%;
`;

export const StyledTitle = styled(Title)`
  white-space: initial;
`;

export const StyledListItem = styled.li`
  padding: 0.3rem 0;
  letter-spacing: 1px;
  line-height: 22px;
`;

