import { Card, Divider } from 'antd';
import { parseISO } from 'date-fns';
import { RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';
import { Box } from 'reflexbox';
import {
  DateOperator,
  SortOrder,
  useMeasurementHistoryLazyQuery,
} from '~/components/apollo-components';
import { HistoryNumbersTable } from '~/pages/EasyMonitoring/MachineDetail/MeasurementHistoryCard/HistoryNumbersTable';
import { HistoryHeader } from './HistoryHeader';
import { HistoryGraphs } from './HistoryGraphs';

export const MeasurementHistoryCard: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [isInitial, setIsInitial] = useBoolean(true);
  const [selectedRange, setSelectedRange] = useState<RangeValue<Moment> | null>(
    null,
  );

  const limit = useMemo(() => (isInitial ? 10 : null), [isInitial]);

  const [
    getMeasurementHistory,
    { data, loading },
  ] = useMeasurementHistoryLazyQuery();

  const handleDateRangeSelection = useCallback(
    async (range: RangeValue<Moment>) => {
      setSelectedRange(range);
      setIsInitial(false);
    },
    [setIsInitial, setSelectedRange],
  );

  useEffect(() => {
    if (isInitial) {
      getMeasurementHistory({
        variables: {
          machineId: id,
          limit,
          pagination: null,
          sort: { field: 'createdAt', order: SortOrder.DESC },
        },
      });
    } else if (
      selectedRange != null &&
      selectedRange[0] != null &&
      selectedRange[1] != null
    ) {
      getMeasurementHistory({
        variables: {
          machineId: id,
          sort: { field: 'createdAt', order: SortOrder.ASC },
          filter: {
            date: {
              field: 'createdAt',
              operator: DateOperator.BETWEEN,
              value: selectedRange[0].toISOString(),
              endValue: selectedRange[1].toISOString(),
            },
          },
        },
      });
    }
  }, [isInitial, id, limit, getMeasurementHistory, selectedRange]);

  useEffect(() => {
    if (
      !loading &&
      data?.machine.measurements != null &&
      data.machine.measurements.items.length > 0 &&
      isInitial
    ) {
      const measurementCount = data.machine.measurements.items.length;
      const firstMeasurement =
        data.machine.measurements.items[measurementCount - 1];
      setSelectedRange([
        moment(parseISO(firstMeasurement.createdAt)),
        moment(),
      ]);
    }
  }, [loading, data, isInitial, getMeasurementHistory, id, limit]);

  return (
    <Card>
      <Box>
        <HistoryHeader
          range={selectedRange}
          onDateSelect={handleDateRangeSelection}
        />
        <Divider />

        <HistoryGraphs isInitial={isInitial} data={data} loading={loading} />

        <Divider />
        <HistoryNumbersTable
          initialData={data ?? null}
          isInitial={isInitial}
          limit={limit}
          selectedRange={selectedRange}
        />
      </Box>
    </Card>
  );
};
