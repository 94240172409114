import { Button } from 'antd';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '~/assets/icons/close.svg';
import { ReactComponent as WarningIcon } from '~/assets/icons/warning.svg';
import {
  Chemical,
  ChemicalFragment,
  SortOrder,
  StatusType,
  UpdateUserSettingsInput,
  useMachineMeasurementsLazyQuery,
  useMachinesLazyQuery,
  useMeQuery,
  useUpdateUserSettingsMutation,
} from '~/components/apollo-components';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { getTranslationForChemical } from '~/utils/EasyMonitoring/chemicalTranslation';
import { Routes } from '~/utils/Routes';
import {
  AlignedBox,
  BlackLabel,
  DarkLink,
  DashboardCard,
} from '~/utils/styles';

const InlineLi = styled.li`
  display: inline;

  & + &:before {
    content: ', ';
  }
`;

const InlineUl = styled.ul`
  display: inline;
  list-style: none;
  padding: 0;
`;

const StyledCard = styled(DashboardCard)`
  border: 3px solid ${({ theme }) => theme.colors.red};

  .ant-card-body {
    padding: 10px 16px;
  }
`;

const WarningLink = styled(DarkLink)`
  font-size: 20px;
`;

const CloseButton = styled(Button)`
  padding: 0;
`;

const NavigationButton = styled(Button)`
  font-size: 12px;
  font-weight: normal;
  padding: 0;
  margin-left: 1rem;
`;

export const WarningCard: FunctionComponent = () => {
  const { t } = useTranslation(['EasyMonitoring', 'common']);
  const handleError = useErrorHandler();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: user, refetch: refetchUser } = useMeQuery();
  const [
    getMachines,
    { data: machinesData, loading: isMachinesLoading, refetch: refetchMachine },
  ] = useMachinesLazyQuery({
    variables: {
      filter: { status: [StatusType.CRITICAL] },
      pagination: { itemsPerPage: 1 },
    },
  });
  const [
    getMachineMeasurements,
    { data: machineMeasurements, loading: isMachineMeasurementsLoading },
  ] = useMachineMeasurementsLazyQuery();
  const [update, { loading: isUpdating }] = useUpdateUserSettingsMutation();

  const machine = useMemo(() => {
    return machinesData?.machines?.items[0];
  }, [machinesData]);

  const chemicals = useMemo(() => {
    const measurement = machineMeasurements?.machine.measurements?.items[0];
    if (measurement == null) return '';

    // eslint-disable-next-line no-unused-vars
    const { __typename, ...realChemicals } = measurement.chemicals;
    const criticalChemicals: Chemical[] = Object.values(realChemicals).filter(
      (chemical: ChemicalFragment | null) =>
        chemical != null && chemical.status === StatusType.CRITICAL,
    );

    return criticalChemicals.map((chemical) => {
      return getTranslationForChemical(t)[chemical.key];
    });
  }, [machineMeasurements, t]);

  const onPaginate = useCallback(
    async (newPage: number) => {
      if (newPage !== currentPage && refetchMachine != null) {
        refetchMachine({
          pagination: { itemsPerPage: 1, currentPage: newPage },
        });
      }
      setCurrentPage(newPage);
    },
    [currentPage, refetchMachine],
  );

  const onClose = useCallback(async () => {
    try {
      const variables: UpdateUserSettingsInput = { dashboardWarnings: false };
      await update({
        variables,
      });
      await refetchUser();
    } catch (e) {
      handleError(e, t('common:An unexpected error occurred.'));
    }
  }, [handleError, refetchUser, t, update]);

  useEffect(() => {
    if (machinesData == null && !isMachinesLoading) {
      getMachines();
    }
  }, [getMachines, isMachinesLoading, machinesData]);

  useEffect(() => {
    if (machine?.id != null) {
      getMachineMeasurements({
        variables: {
          id: machine.id,
          sort: { field: 'updatedAt', order: SortOrder.DESC },
        },
      });
    }
  }, [getMachineMeasurements, machine]);

  if (
    !user?.me?.dashboardWarnings ||
    machinesData?.machines?.totalCount === 0
  ) {
    return null;
  }

  return (
    <Box paddingBottom="1.75rem">
      <StyledCard>
        <Flex>
          <Box>
            <WarningIcon height="100%" />
          </Box>
          <Box width={2 / 3} marginLeft={4}>
            <Flex flexDirection="column" height="100%">
              {isMachinesLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Box>
                    <BlackLabel>
                      {t('Warning {{current}}/{{total}}', {
                        current: currentPage,
                        total: machinesData?.machines?.totalCount,
                      })}
                    </BlackLabel>
                  </Box>
                  <Box marginTop="0.5rem">
                    {isMachineMeasurementsLoading && <LoadingSpinner />}
                    {machine && machineMeasurements && (
                      <WarningLink
                        to={Routes.easyMonitoring.machine.single.base(
                          machine.id,
                        )}
                      >
                        <Trans t={t} i18nKey="criticalChemicalsAtSource">
                          Critical{' '}
                          <InlineUl>
                            {Array.isArray(chemicals) &&
                              chemicals.map((elem, index) => (
                                <InlineLi key={index}>{elem}</InlineLi>
                              ))}
                          </InlineUl>{' '}
                          at {{ source: machine.machineName }}
                        </Trans>
                      </WarningLink>
                    )}
                  </Box>
                </>
              )}
            </Flex>
          </Box>
          <AlignedBox width={1 / 3} align="right">
            <Flex flexDirection="column" height="100%">
              <Box>
                <CloseButton
                  icon={<CloseIcon />}
                  type="link"
                  onClick={onClose}
                  loading={isUpdating}
                />
              </Box>
              <Box flex="1 1 auto" />
              <Box marginTop={0.5}>
                <NavigationButton
                  type="link"
                  disabled={currentPage === 1}
                  onClick={() => onPaginate(currentPage - 1)}
                >
                  {t('common:previous')}
                </NavigationButton>
                <NavigationButton
                  type="link"
                  disabled={currentPage === machinesData?.machines?.totalCount}
                  onClick={() => onPaginate(currentPage + 1)}
                >
                  {t('common:next')}
                </NavigationButton>
              </Box>
            </Flex>
          </AlignedBox>
        </Flex>
      </StyledCard>
    </Box>
  );
};
