import { TFunction } from 'i18next';
import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { ChemicalType } from '~/components/apollo-components';

export const getTranslationForChemicals = (
  t: TFunction,
): Record<string, string | ReactNode> => ({
  [ChemicalType.ALKALINITY]: t('Alkalinity'),
  [ChemicalType.CHLORINE]: t('Chlorine'),
  [ChemicalType.CONCENTRATION]: t('Concentration'),
  [ChemicalType.CO2]: (
    <Trans t={t}>
      CO<sub>2</sub>
    </Trans>
  ),
  [ChemicalType.HARDNESS]: t('Hardness'),
  [ChemicalType.NITRATE]: t('Nitrate'),
  [ChemicalType.NITRITE]: t('Nitrite'),
  [ChemicalType.PH]: t('pH'),
  [ChemicalType.TEMPERATURE]: t('Temperature'),
});
