import { auth } from '~/translations/fr/auth';
import { common } from '~/translations/fr/common';
import { EasyMonitoring } from '~/translations/fr/EasyMonitoring';
import { Admin } from '~/translations/fr/Admin';
import { faq } from '~/translations/fr/faq';
import { staticPages } from '~/translations/fr/staticPages';
import { privacyProtection } from '~/translations/fr/privacyProtection';
import { termsAndConditions } from '~/translations/fr/termsAndConditions';

export const fr = {
  auth,
  common,
  faq,
  staticPages,
  EasyMonitoring,
  Admin,
  privacyProtection,
  termsAndConditions,
};
