import { ConfigProvider } from 'antd';
import { Locale } from 'antd/es/locale-provider';
import deDE from 'antd/es/locale-provider/de_DE';
import enUS from 'antd/es/locale-provider/en_US';
import frFR from 'antd/es/locale-provider/fr_FR';
import itIT from 'antd/es/locale-provider/it_IT';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Language, useMeQuery } from '~/components/apollo-components';
import { setLanguage } from '~/i18n';
import { theme, ThemeProvider } from '~/theme';

interface IProps {
  children: React.ReactNode;
}

function findLanguage(language: string): Language {
  return Object.values(Language).find(element => element.toLowerCase().startsWith(language.split("-")[0].toLowerCase())) ?? Language.DEDE;
}

export const ThemeAndConfigProvider: FunctionComponent<IProps> = ({
  children,
}: IProps) => {
  const { data, loading } = useMeQuery();
  const selectedLocale = useMemo<Locale>(() => {
    if (data) {
      switch (data.me.language) {
        case Language.ENUS:
          return enUS;
        case Language.DEDE:
          return deDE;
        case Language.FRFR:
          return frFR;
        case Language.ITIT:
          return itIT;
      }
    }
    return deDE;
  }, [data]);

  useEffect(() => {
    // Extra method to keep above useMemo() free from side-effects.
    const currentLanguage = localStorage.getItem('i18nextLng');
    if (data) {
      setLanguage(data.me.language);
    } else if (!data && currentLanguage) {
      setLanguage(findLanguage(currentLanguage));
    }
  }, [data, loading]);

  return (
    <ConfigProvider locale={selectedLocale}>
      <ThemeProvider theme={theme}>
        <>{children}</>
      </ThemeProvider>
    </ConfigProvider>
  );
};
