// THIS IS A GENERATED FILE, DO NOT EDIT IT!
/* eslint-disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };


/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: string;
};

export type AddUserToCompanyResponse = {
  __typename?: 'AddUserToCompanyResponse';
  id: Scalars['String'];
  email: Scalars['String'];
};

export type ChangePasswordInput = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirmation: Scalars['String'];
};

export type Chemical = {
  __typename?: 'Chemical';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  key: ChemicalType;
  measuredValue: Scalars['Float'];
  position: Scalars['Float'];
  numSegments: Scalars['Float'];
  label: Scalars['String'];
  status: StatusType;
  measurement: Measurement;
};

export type ChemicalCreateInput = {
  key: ChemicalType;
  measuredValue: Scalars['Float'];
  position: Scalars['Float'];
  numSegments: Scalars['Float'];
  label: Scalars['String'];
};

export type ChemicalsBaseResponse = {
  __typename?: 'ChemicalsBaseResponse';
  hardness?: Maybe<Chemical>;
  nitrite?: Maybe<Chemical>;
  ph?: Maybe<Chemical>;
};

export type ChemicalsEmulsionResponse = {
  __typename?: 'ChemicalsEmulsionResponse';
  hardness?: Maybe<Chemical>;
  nitrite?: Maybe<Chemical>;
  ph?: Maybe<Chemical>;
  concentration?: Maybe<Chemical>;
  temperature?: Maybe<Chemical>;
};

export type ChemicalsResponseUnionType = ChemicalsEmulsionResponse | ChemicalsWaterResponse;

export type ChemicalsWaterResponse = {
  __typename?: 'ChemicalsWaterResponse';
  hardness?: Maybe<Chemical>;
  nitrite?: Maybe<Chemical>;
  ph?: Maybe<Chemical>;
  alkalinity?: Maybe<Chemical>;
  chlorine?: Maybe<Chemical>;
  co2?: Maybe<Chemical>;
  nitrate?: Maybe<Chemical>;
};

export enum ChemicalType {
  ALKALINITY = 'ALKALINITY',
  CONCENTRATION = 'CONCENTRATION',
  CHLORINE = 'CHLORINE',
  HARDNESS = 'HARDNESS',
  NITRATE = 'NITRATE',
  NITRITE = 'NITRITE',
  PH = 'PH',
  CO2 = 'CO2',
  TEMPERATURE = 'TEMPERATURE'
}

export type CollectionQueryBaseOptions = {
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
  limit?: Maybe<Scalars['Int']>;
};

export type CollectionQueryOptions = {
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterArgs>;
};

export type CompaniesResponse = {
  __typename?: 'CompaniesResponse';
  items: Array<Company>;
  totalCount: Scalars['Float'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
  customerNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  contactFirstName: Scalars['String'];
  contactLastName: Scalars['String'];
  contactEmail: Scalars['String'];
  contactPhoneNumber?: Maybe<Scalars['String']>;
  domains: Array<Scalars['String']>;
  machines?: Maybe<Array<Machine>>;
  users?: Maybe<Array<User>>;
};

export type CreateCompanyInput = {
  name: Scalars['String'];
  customerNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  contactFirstName: Scalars['String'];
  contactLastName: Scalars['String'];
  contactEmail: Scalars['String'];
  contactPhoneNumber?: Maybe<Scalars['String']>;
  domains: Array<Scalars['String']>;
};

export type CreateUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  roles: Array<Role>;
};

export type DateFilterArgs = {
  field: Scalars['String'];
  operator?: Maybe<DateOperator>;
  value: Scalars['DateTime'];
  endValue?: Maybe<Scalars['DateTime']>;
};

export enum DateOperator {
  BEFORE = 'BEFORE',
  BEFORE_EQUAL = 'BEFORE_EQUAL',
  EQUAL = 'EQUAL',
  AFTER_EQUAL = 'AFTER_EQUAL',
  AFTER = 'AFTER',
  BETWEEN = 'BETWEEN'
}


export type FilterArgs = {
  text?: Maybe<TextFilterArgs>;
  date?: Maybe<DateFilterArgs>;
  status?: Maybe<Array<StatusType>>;
};

export type InitiateChangePasswordInput = {
  /** Email is not correct */
  email: Scalars['String'];
};

export enum Language {
  ENUS = 'EnUs',
  DEDE = 'DeDe',
  ITIT = 'ItIt',
  FRFR = 'FrFr'
}

export type LoginUserInput = {
  /** Email is not correct */
  email: Scalars['String'];
  password: Scalars['String'];
  language?: Maybe<Language>;
  useJwt?: Maybe<Scalars['Boolean']>;
};

export type Machine = {
  __typename?: 'Machine';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  machineName: Scalars['String'];
  productName: Scalars['String'];
  factor: Scalars['Float'];
  measurementType: MeasurementType;
  status: StatusType;
  declined: Scalars['Boolean'];
  measurements?: Maybe<MeasurementsResponse>;
  lastMeasurer?: Maybe<User>;
  company?: Maybe<Company>;
  user?: Maybe<User>;
  lastMeasurerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};


export type MachineMeasurementsArgs = {
  input?: Maybe<MachineMeasurementQueryOptions>;
};

export type MachineBaseInput = {
  productName: Scalars['String'];
};

export type MachineCreateInput = {
  productName: Scalars['String'];
  machineName: Scalars['String'];
  measurementType: MeasurementType;
  factor?: Maybe<Scalars['Float']>;
};

export type MachineMeasurementFilterArgs = {
  date?: Maybe<DateFilterArgs>;
};

export type MachineMeasurementQueryOptions = {
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<MachineMeasurementFilterArgs>;
};

export type MachinesResponse = {
  __typename?: 'MachinesResponse';
  items: Array<Machine>;
  totalCount: Scalars['Float'];
  declinedMachines: Scalars['Float'];
  recentlyUpdatedMachines: Scalars['Float'];
};

export type MachineUpdateInput = {
  productName: Scalars['String'];
  machineId: Scalars['ID'];
  machineName: Scalars['String'];
  factor?: Maybe<Scalars['Float']>;
};

export type Measurement = {
  __typename?: 'Measurement';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: StatusType;
  measurer: User;
  machine: Machine;
  chemicals: ChemicalsResponseUnionType;
  manual: Scalars['Boolean'];
  next?: Maybe<Measurement>;
  previous?: Maybe<Measurement>;
};

export type MeasurementCreateInput = {
  machineId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  chemicals: Array<ChemicalCreateInput>;
  manual: Scalars['Boolean'];
};

export type MeasurementsResponse = {
  __typename?: 'MeasurementsResponse';
  items: Array<Measurement>;
  totalCount: Scalars['Float'];
};

export enum MeasurementType {
  WATER = 'WATER',
  EMULSION = 'EMULSION'
}

export type MeasurementUpdateInput = {
  measurementId: Scalars['ID'];
  key: ChemicalType;
  measuredValue: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  changePassword: Scalars['Boolean'];
  updatePassword: Scalars['Boolean'];
  initiatePasswordChange: Scalars['Boolean'];
  login: User;
  loginAdmin: User;
  logout: Scalars['Boolean'];
  register: User;
  verifyEmail: Scalars['Boolean'];
  createCompany: Company;
  deleteCompany: Scalars['Boolean'];
  updateCompanyContact: Company;
  createMachine: Machine;
  updateMachine: Machine;
  deleteMachine: Scalars['String'];
  deleteMachines: Array<Scalars['String']>;
  createMeasurement: Measurement;
  deleteMeasurements: Array<Scalars['String']>;
  deleteMeasurement: Scalars['String'];
  updateMeasurement: Measurement;
  createUser: User;
  updateUser: User;
  updateUserSettings: User;
  updateUserCompany: User;
  updateUserByAdmin: User;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationInitiatePasswordChangeArgs = {
  input: InitiateChangePasswordInput;
};


export type MutationLoginArgs = {
  input: LoginUserInput;
};


export type MutationLoginAdminArgs = {
  userId: Scalars['String'];
};


export type MutationRegisterArgs = {
  input: RegisterUserInput;
};


export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCompanyContactArgs = {
  input: UpdateCompanyContactInput;
};


export type MutationCreateMachineArgs = {
  input: MachineCreateInput;
};


export type MutationUpdateMachineArgs = {
  input: MachineUpdateInput;
};


export type MutationDeleteMachineArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMachinesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationCreateMeasurementArgs = {
  input: MeasurementCreateInput;
};


export type MutationDeleteMeasurementsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteMeasurementArgs = {
  id: Scalars['String'];
};


export type MutationUpdateMeasurementArgs = {
  input: MeasurementUpdateInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserSettingsArgs = {
  input: UpdateUserSettingsInput;
};


export type MutationUpdateUserCompanyArgs = {
  input: UpdateUserCompanyInput;
};


export type MutationUpdateUserByAdminArgs = {
  input: UpdateUserInput;
  id: Scalars['String'];
};

export type PaginationArgs = {
  itemsPerPage?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  findUsersToAdd: Array<AddUserToCompanyResponse>;
  company: Company;
  companies: CompaniesResponse;
  companyUsers: UsersResponse;
  machine: Machine;
  machines?: Maybe<MachinesResponse>;
  measurement: Measurement;
  measurements?: Maybe<MeasurementsResponse>;
  me: User;
  user: User;
  users: UsersResponse;
  validatePasswordToken: Scalars['Boolean'];
};


export type QueryFindUsersToAddArgs = {
  query: Scalars['String'];
};


export type QueryCompanyArgs = {
  id: Scalars['String'];
};


export type QueryCompaniesArgs = {
  input: CollectionQueryOptions;
};


export type QueryCompanyUsersArgs = {
  input: CollectionQueryOptions;
  companyId: Scalars['String'];
};


export type QueryMachineArgs = {
  id: Scalars['String'];
};


export type QueryMachinesArgs = {
  input?: Maybe<CollectionQueryOptions>;
};


export type QueryMeasurementArgs = {
  id: Scalars['String'];
};


export type QueryMeasurementsArgs = {
  input?: Maybe<CollectionQueryOptions>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  input: CollectionQueryOptions;
};


export type QueryValidatePasswordTokenArgs = {
  token: Scalars['String'];
};

export type RegisterUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  language: Language;
};

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER'
}

export type SortArgs = {
  field: Scalars['String'];
  order: SortOrder;
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum StatusType {
  CRITICAL = 'CRITICAL',
  NOTICEABLE = 'NOTICEABLE',
  NORMAL = 'NORMAL'
}

export type TextFilterArgs = {
  field: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type UpdateCompanyContactInput = {
  id: Scalars['String'];
  contactFirstName: Scalars['String'];
  contactLastName: Scalars['String'];
  contactEmail: Scalars['String'];
  contactPhoneNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  customerNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  domains: Array<Scalars['String']>;
};

export type UpdatePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirmation: Scalars['String'];
};

export type UpdateUserCompanyInput = {
  userId: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UpdateUserSettingsInput = {
  waterMeasurement?: Maybe<Scalars['Boolean']>;
  emulsionMeasurement?: Maybe<Scalars['Boolean']>;
  dashboardWarnings?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  roles: Array<Role>;
  phoneNumber?: Maybe<Scalars['String']>;
  language: Language;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Company>;
  machines?: Maybe<Array<Machine>>;
  lastMeasuredMachines?: Maybe<Array<Machine>>;
  waterMeasurement: Scalars['Boolean'];
  emulsionMeasurement: Scalars['Boolean'];
  dashboardWarnings: Scalars['Boolean'];
  photo?: Maybe<Scalars['String']>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  items: Array<User>;
  totalCount: Scalars['Float'];
};

export type VerifyEmailInput = {
  token: Scalars['String'];
};

export type CompanyInformationFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'customerNumber' | 'address' | 'contactFirstName' | 'contactLastName' | 'contactEmail' | 'contactPhoneNumber' | 'domains'>
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & UserInformationFragment
  )>> }
);

export type BaseMachineFragment = (
  { __typename?: 'Machine' }
  & Pick<Machine, 'id' | 'machineName' | 'productName' | 'factor' | 'measurementType' | 'updatedAt'>
  & { lastMeasurer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
  )>, company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'customerNumber'>
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
  )> }
);

export type ChemicalFragment = (
  { __typename?: 'Chemical' }
  & Pick<Chemical, 'id' | 'createdAt' | 'updatedAt' | 'key' | 'status' | 'measuredValue' | 'position' | 'numSegments' | 'label'>
);

export type DashboardMachineFragment = (
  { __typename?: 'Machine' }
  & Pick<Machine, 'id' | 'createdAt' | 'updatedAt' | 'machineName' | 'productName' | 'factor' | 'measurementType' | 'status'>
  & { lastMeasurer?: Maybe<(
    { __typename?: 'User' }
    & UserInformationFragment
  )> }
);

export type MachineFragment = (
  { __typename?: 'Machine' }
  & Pick<Machine, 'id' | 'machineName' | 'productName' | 'factor' | 'measurementType' | 'updatedAt'>
  & { lastMeasurer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
  )>, company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'customerNumber'>
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
  )>, measurements?: Maybe<(
    { __typename?: 'MeasurementsResponse' }
    & Pick<MeasurementsResponse, 'totalCount'>
    & { items: Array<(
      { __typename?: 'Measurement' }
      & MeasurementFragment
    )> }
  )> }
);

export type MeasurementFragment = (
  { __typename?: 'Measurement' }
  & Pick<Measurement, 'id' | 'createdAt' | 'updatedAt' | 'status' | 'manual'>
  & { measurer: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
  ), chemicals: (
    { __typename?: 'ChemicalsEmulsionResponse' }
    & { concentration?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )>, hardness?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )>, nitrite?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )>, ph?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )>, temperature?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )> }
  ) | (
    { __typename?: 'ChemicalsWaterResponse' }
    & { alkalinity?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )>, chlorine?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )>, co2?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )>, hardness?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )>, nitrate?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )>, nitrite?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )>, ph?: Maybe<(
      { __typename?: 'Chemical' }
      & ChemicalFragment
    )> }
  ), next?: Maybe<(
    { __typename?: 'Measurement' }
    & Pick<Measurement, 'id'>
  )>, previous?: Maybe<(
    { __typename?: 'Measurement' }
    & Pick<Measurement, 'id'>
  )> }
);

export type UserInformationFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'roles' | 'phoneNumber' | 'language' | 'createdAt' | 'deletedAt' | 'waterMeasurement' | 'emulsionMeasurement' | 'dashboardWarnings' | 'photo'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirmation: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type CreateCompanyMutationVariables = Exact<{
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  contactFirstName: Scalars['String'];
  contactLastName: Scalars['String'];
  contactEmail: Scalars['String'];
  contactPhoneNumber?: Maybe<Scalars['String']>;
  domains: Array<Scalars['String']>;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: (
    { __typename?: 'Company' }
    & CompanyInformationFragment
  ) }
);

export type CreateUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  roles: Array<Role>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & UserInformationFragment
  ) }
);

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompany'>
);

export type DeleteMachineMutationVariables = Exact<{
  machineId: Scalars['String'];
}>;


export type DeleteMachineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMachine'>
);

export type DeleteMeasurementMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteMeasurementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMeasurement'>
);

export type UpdateMachineMutationVariables = Exact<{
  id: Scalars['ID'];
  machineName: Scalars['String'];
  productName: Scalars['String'];
}>;


export type UpdateMachineMutation = (
  { __typename?: 'Mutation' }
  & { updateMachine: (
    { __typename?: 'Machine' }
    & MachineFragment
  ) }
);

export type InitiatePasswordChangeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type InitiatePasswordChangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'initiatePasswordChange'>
);

export type LoginAdminMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type LoginAdminMutation = (
  { __typename?: 'Mutation' }
  & { loginAdmin: (
    { __typename?: 'User' }
    & UserInformationFragment
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  language: Language;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'User' }
    & UserInformationFragment
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type RegisterMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  language: Language;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'User' }
    & UserInformationFragment
  ) }
);

export type UpdateCompanyContactMutationVariables = Exact<{
  id: Scalars['String'];
  contactFirstName: Scalars['String'];
  contactLastName: Scalars['String'];
  contactEmail: Scalars['String'];
  contactPhoneNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  domains: Array<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
}>;


export type UpdateCompanyContactMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyContact: (
    { __typename?: 'Company' }
    & CompanyInformationFragment
  ) }
);

export type UpdatePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirmation: Scalars['String'];
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePassword'>
);

export type UpdateUserByAdminMutationVariables = Exact<{
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
}>;


export type UpdateUserByAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateUserByAdmin: (
    { __typename?: 'User' }
    & UserInformationFragment
  ) }
);

export type UpdateUserCompanyMutationVariables = Exact<{
  userId: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
}>;


export type UpdateUserCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateUserCompany: (
    { __typename?: 'User' }
    & UserInformationFragment
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & UserInformationFragment
  ) }
);

export type UpdateUserSettingsMutationVariables = Exact<{
  waterMeasurement?: Maybe<Scalars['Boolean']>;
  emulsionMeasurement?: Maybe<Scalars['Boolean']>;
  dashboardWarnings?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateUserSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings: (
    { __typename?: 'User' }
    & UserInformationFragment
  ) }
);

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verifyEmail'>
);

export type CompaniesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
}>;


export type CompaniesQuery = (
  { __typename?: 'Query' }
  & { companies: (
    { __typename?: 'CompaniesResponse' }
    & Pick<CompaniesResponse, 'totalCount'>
    & { items: Array<(
      { __typename?: 'Company' }
      & CompanyInformationFragment
    )> }
  ) }
);

export type CompanyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompanyQuery = (
  { __typename?: 'Query' }
  & { company: (
    { __typename?: 'Company' }
    & CompanyInformationFragment
  ) }
);

export type CompanyUsersQueryVariables = Exact<{
  companyId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
}>;


export type CompanyUsersQuery = (
  { __typename?: 'Query' }
  & { companyUsers: (
    { __typename?: 'UsersResponse' }
    & Pick<UsersResponse, 'totalCount'>
    & { items: Array<(
      { __typename?: 'User' }
      & UserInformationFragment
    )> }
  ) }
);

export type DashboardMachinesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
  filter?: Maybe<FilterArgs>;
}>;


export type DashboardMachinesQuery = (
  { __typename?: 'Query' }
  & { machines?: Maybe<(
    { __typename?: 'MachinesResponse' }
    & Pick<MachinesResponse, 'totalCount' | 'recentlyUpdatedMachines'>
    & { items: Array<(
      { __typename?: 'Machine' }
      & DashboardMachineFragment
    )> }
  )> }
);

export type LastMeasurementsQueryVariables = Exact<{ [key: string]: never; }>;


export type LastMeasurementsQuery = (
  { __typename?: 'Query' }
  & { machines?: Maybe<(
    { __typename?: 'MachinesResponse' }
    & Pick<MachinesResponse, 'totalCount' | 'recentlyUpdatedMachines'>
    & { items: Array<(
      { __typename?: 'Machine' }
      & Pick<Machine, 'id' | 'machineName' | 'status' | 'measurementType'>
      & { measurements?: Maybe<(
        { __typename?: 'MeasurementsResponse' }
        & Pick<MeasurementsResponse, 'totalCount'>
        & { items: Array<(
          { __typename?: 'Measurement' }
          & MeasurementFragment
        )> }
      )> }
    )> }
  )> }
);

export type MachineMeasurementsQueryVariables = Exact<{
  id: Scalars['String'];
  sort: SortArgs;
}>;


export type MachineMeasurementsQuery = (
  { __typename?: 'Query' }
  & { machine: (
    { __typename?: 'Machine' }
    & { measurements?: Maybe<(
      { __typename?: 'MeasurementsResponse' }
      & { items: Array<(
        { __typename?: 'Measurement' }
        & MeasurementFragment
      )> }
    )> }
  ) }
);

export type MachineQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MachineQuery = (
  { __typename?: 'Query' }
  & { machine: (
    { __typename?: 'Machine' }
    & MachineFragment
  ) }
);

export type MachinesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
  filter?: Maybe<FilterArgs>;
}>;


export type MachinesQuery = (
  { __typename?: 'Query' }
  & { machines?: Maybe<(
    { __typename?: 'MachinesResponse' }
    & Pick<MachinesResponse, 'totalCount' | 'recentlyUpdatedMachines'>
    & { items: Array<(
      { __typename?: 'Machine' }
      & BaseMachineFragment
    )> }
  )> }
);

export type MeasurementHistoryQueryVariables = Exact<{
  machineId: Scalars['String'];
  sort: SortArgs;
  pagination?: Maybe<PaginationArgs>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<MachineMeasurementFilterArgs>;
}>;


export type MeasurementHistoryQuery = (
  { __typename?: 'Query' }
  & { machine: (
    { __typename?: 'Machine' }
    & Pick<Machine, 'id' | 'factor' | 'measurementType'>
    & { measurements?: Maybe<(
      { __typename?: 'MeasurementsResponse' }
      & Pick<MeasurementsResponse, 'totalCount'>
      & { items: Array<(
        { __typename?: 'Measurement' }
        & MeasurementFragment
      )> }
    )> }
  ) }
);

export type MeasurementTimesQueryVariables = Exact<{
  machineId: Scalars['String'];
}>;


export type MeasurementTimesQuery = (
  { __typename?: 'Query' }
  & { measurements?: Maybe<(
    { __typename?: 'MeasurementsResponse' }
    & Pick<MeasurementsResponse, 'totalCount'>
    & { items: Array<(
      { __typename?: 'Measurement' }
      & Pick<Measurement, 'id' | 'createdAt'>
    )> }
  )> }
);

export type MeasurementResultsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MeasurementResultsQuery = (
  { __typename?: 'Query' }
  & { measurement: (
    { __typename?: 'Measurement' }
    & MeasurementFragment
  ) }
);

export type DashboardOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardOverviewQuery = (
  { __typename?: 'Query' }
  & { machines?: Maybe<(
    { __typename?: 'MachinesResponse' }
    & Pick<MachinesResponse, 'recentlyUpdatedMachines' | 'totalCount'>
  )>, declinedMachines?: Maybe<(
    { __typename?: 'MachinesResponse' }
    & Pick<MachinesResponse, 'totalCount'>
    & { items: Array<(
      { __typename?: 'Machine' }
      & Pick<Machine, 'machineName'>
    )> }
  )>, measurements?: Maybe<(
    { __typename?: 'MeasurementsResponse' }
    & Pick<MeasurementsResponse, 'totalCount'>
  )>, normalMeasurements?: Maybe<(
    { __typename?: 'MeasurementsResponse' }
    & Pick<MeasurementsResponse, 'totalCount'>
  )> }
);

export type FindUsersToAddQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type FindUsersToAddQuery = (
  { __typename?: 'Query' }
  & { findUsersToAdd: Array<(
    { __typename?: 'AddUserToCompanyResponse' }
    & Pick<AddUserToCompanyResponse, 'id' | 'email'>
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & UserInformationFragment
  ) }
);

export type UserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & UserInformationFragment
  ) }
);

export type UsersQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<SortArgs>;
  pagination?: Maybe<PaginationArgs>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UsersResponse' }
    & Pick<UsersResponse, 'totalCount'>
    & { items: Array<(
      { __typename?: 'User' }
      & UserInformationFragment
    )> }
  ) }
);

export type ValidatePasswordTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type ValidatePasswordTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'validatePasswordToken'>
);

export const UserInformationFragmentDoc = gql`
    fragment UserInformation on User {
  id
  firstName
  lastName
  email
  roles
  phoneNumber
  language
  createdAt
  deletedAt
  createdAt
  waterMeasurement
  emulsionMeasurement
  dashboardWarnings
  photo
  company {
    name
  }
}
    `;
export const CompanyInformationFragmentDoc = gql`
    fragment CompanyInformation on Company {
  id
  name
  customerNumber
  address
  contactFirstName
  contactLastName
  contactEmail
  contactPhoneNumber
  domains
  users {
    ...UserInformation
  }
}
    ${UserInformationFragmentDoc}`;
export const BaseMachineFragmentDoc = gql`
    fragment BaseMachine on Machine {
  id
  machineName
  productName
  factor
  measurementType
  updatedAt
  lastMeasurer {
    firstName
    lastName
  }
  company {
    name
    customerNumber
  }
  user {
    firstName
    lastName
  }
}
    `;
export const DashboardMachineFragmentDoc = gql`
    fragment DashboardMachine on Machine {
  id
  createdAt
  updatedAt
  machineName
  productName
  factor
  measurementType
  lastMeasurer {
    ...UserInformation
  }
  status
}
    ${UserInformationFragmentDoc}`;
export const ChemicalFragmentDoc = gql`
    fragment Chemical on Chemical {
  id
  createdAt
  updatedAt
  key
  status
  measuredValue
  position
  numSegments
  label
}
    `;
export const MeasurementFragmentDoc = gql`
    fragment Measurement on Measurement {
  id
  createdAt
  updatedAt
  status
  manual
  measurer {
    firstName
    lastName
  }
  chemicals {
    ... on ChemicalsEmulsionResponse {
      concentration {
        ...Chemical
      }
      hardness {
        ...Chemical
      }
      nitrite {
        ...Chemical
      }
      ph {
        ...Chemical
      }
      temperature {
        ...Chemical
      }
    }
    ... on ChemicalsWaterResponse {
      alkalinity {
        ...Chemical
      }
      chlorine {
        ...Chemical
      }
      co2 {
        ...Chemical
      }
      hardness {
        ...Chemical
      }
      nitrate {
        ...Chemical
      }
      nitrite {
        ...Chemical
      }
      ph {
        ...Chemical
      }
    }
  }
  next {
    id
  }
  previous {
    id
  }
}
    ${ChemicalFragmentDoc}`;
export const MachineFragmentDoc = gql`
    fragment Machine on Machine {
  id
  machineName
  productName
  factor
  measurementType
  updatedAt
  lastMeasurer {
    firstName
    lastName
  }
  company {
    name
    customerNumber
  }
  user {
    firstName
    lastName
  }
  measurements(input: {sort: {field: "createdAt", order: DESC},limit: 1}) {
    totalCount
    items {
      ...Measurement
    }
  }
}
    ${MeasurementFragmentDoc}`;
export const ChangePasswordDocument = gql`
    mutation changePassword($token: String!, $newPassword: String!, $newPasswordConfirmation: String!) {
  changePassword(input: {token: $token, newPassword: $newPassword, newPasswordConfirmation: $newPasswordConfirmation})
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *      newPasswordConfirmation: // value for 'newPasswordConfirmation'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($name: String!, $address: String, $customerNumber: String, $contactFirstName: String!, $contactLastName: String!, $contactEmail: String!, $contactPhoneNumber: String, $domains: [String!]!) {
  createCompany(input: {name: $name, address: $address, customerNumber: $customerNumber, contactFirstName: $contactFirstName, contactLastName: $contactLastName, contactEmail: $contactEmail, contactPhoneNumber: $contactPhoneNumber, domains: $domains}) {
    ...CompanyInformation
  }
}
    ${CompanyInformationFragmentDoc}`;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      customerNumber: // value for 'customerNumber'
 *      contactFirstName: // value for 'contactFirstName'
 *      contactLastName: // value for 'contactLastName'
 *      contactEmail: // value for 'contactEmail'
 *      contactPhoneNumber: // value for 'contactPhoneNumber'
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($firstName: String!, $lastName: String!, $email: String!, $phoneNumber: String, $roles: [Role!]!) {
  createUser(input: {firstName: $firstName, lastName: $lastName, email: $email, phoneNumber: $phoneNumber, roles: $roles}) {
    ...UserInformation
  }
}
    ${UserInformationFragmentDoc}`;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: String!) {
  deleteCompany(id: $id)
}
    `;
export type DeleteCompanyMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, baseOptions);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const DeleteMachineDocument = gql`
    mutation deleteMachine($machineId: String!) {
  deleteMachine(id: $machineId)
}
    `;
export type DeleteMachineMutationFn = ApolloReactCommon.MutationFunction<DeleteMachineMutation, DeleteMachineMutationVariables>;

/**
 * __useDeleteMachineMutation__
 *
 * To run a mutation, you first call `useDeleteMachineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMachineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMachineMutation, { data, loading, error }] = useDeleteMachineMutation({
 *   variables: {
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useDeleteMachineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMachineMutation, DeleteMachineMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMachineMutation, DeleteMachineMutationVariables>(DeleteMachineDocument, baseOptions);
      }
export type DeleteMachineMutationHookResult = ReturnType<typeof useDeleteMachineMutation>;
export type DeleteMachineMutationResult = ApolloReactCommon.MutationResult<DeleteMachineMutation>;
export type DeleteMachineMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMachineMutation, DeleteMachineMutationVariables>;
export const DeleteMeasurementDocument = gql`
    mutation deleteMeasurement($id: String!) {
  deleteMeasurement(id: $id)
}
    `;
export type DeleteMeasurementMutationFn = ApolloReactCommon.MutationFunction<DeleteMeasurementMutation, DeleteMeasurementMutationVariables>;

/**
 * __useDeleteMeasurementMutation__
 *
 * To run a mutation, you first call `useDeleteMeasurementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeasurementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeasurementMutation, { data, loading, error }] = useDeleteMeasurementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMeasurementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMeasurementMutation, DeleteMeasurementMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMeasurementMutation, DeleteMeasurementMutationVariables>(DeleteMeasurementDocument, baseOptions);
      }
export type DeleteMeasurementMutationHookResult = ReturnType<typeof useDeleteMeasurementMutation>;
export type DeleteMeasurementMutationResult = ApolloReactCommon.MutationResult<DeleteMeasurementMutation>;
export type DeleteMeasurementMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMeasurementMutation, DeleteMeasurementMutationVariables>;
export const UpdateMachineDocument = gql`
    mutation updateMachine($id: ID!, $machineName: String!, $productName: String!) {
  updateMachine(input: {machineId: $id, machineName: $machineName, productName: $productName}) {
    ...Machine
  }
}
    ${MachineFragmentDoc}`;
export type UpdateMachineMutationFn = ApolloReactCommon.MutationFunction<UpdateMachineMutation, UpdateMachineMutationVariables>;

/**
 * __useUpdateMachineMutation__
 *
 * To run a mutation, you first call `useUpdateMachineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMachineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMachineMutation, { data, loading, error }] = useUpdateMachineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      machineName: // value for 'machineName'
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useUpdateMachineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMachineMutation, UpdateMachineMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMachineMutation, UpdateMachineMutationVariables>(UpdateMachineDocument, baseOptions);
      }
export type UpdateMachineMutationHookResult = ReturnType<typeof useUpdateMachineMutation>;
export type UpdateMachineMutationResult = ApolloReactCommon.MutationResult<UpdateMachineMutation>;
export type UpdateMachineMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMachineMutation, UpdateMachineMutationVariables>;
export const InitiatePasswordChangeDocument = gql`
    mutation initiatePasswordChange($email: String!) {
  initiatePasswordChange(input: {email: $email})
}
    `;
export type InitiatePasswordChangeMutationFn = ApolloReactCommon.MutationFunction<InitiatePasswordChangeMutation, InitiatePasswordChangeMutationVariables>;

/**
 * __useInitiatePasswordChangeMutation__
 *
 * To run a mutation, you first call `useInitiatePasswordChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiatePasswordChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiatePasswordChangeMutation, { data, loading, error }] = useInitiatePasswordChangeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInitiatePasswordChangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InitiatePasswordChangeMutation, InitiatePasswordChangeMutationVariables>) {
        return ApolloReactHooks.useMutation<InitiatePasswordChangeMutation, InitiatePasswordChangeMutationVariables>(InitiatePasswordChangeDocument, baseOptions);
      }
export type InitiatePasswordChangeMutationHookResult = ReturnType<typeof useInitiatePasswordChangeMutation>;
export type InitiatePasswordChangeMutationResult = ApolloReactCommon.MutationResult<InitiatePasswordChangeMutation>;
export type InitiatePasswordChangeMutationOptions = ApolloReactCommon.BaseMutationOptions<InitiatePasswordChangeMutation, InitiatePasswordChangeMutationVariables>;
export const LoginAdminDocument = gql`
    mutation loginAdmin($userId: String!) {
  loginAdmin(userId: $userId) {
    ...UserInformation
  }
}
    ${UserInformationFragmentDoc}`;
export type LoginAdminMutationFn = ApolloReactCommon.MutationFunction<LoginAdminMutation, LoginAdminMutationVariables>;

/**
 * __useLoginAdminMutation__
 *
 * To run a mutation, you first call `useLoginAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAdminMutation, { data, loading, error }] = useLoginAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLoginAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginAdminMutation, LoginAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginAdminMutation, LoginAdminMutationVariables>(LoginAdminDocument, baseOptions);
      }
export type LoginAdminMutationHookResult = ReturnType<typeof useLoginAdminMutation>;
export type LoginAdminMutationResult = ApolloReactCommon.MutationResult<LoginAdminMutation>;
export type LoginAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginAdminMutation, LoginAdminMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!, $language: Language!) {
  login(input: {email: $email, password: $password, language: $language}) {
    ...UserInformation
  }
}
    ${UserInformationFragmentDoc}`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RegisterDocument = gql`
    mutation register($firstName: String!, $lastName: String!, $email: String!, $password: String!, $passwordConfirmation: String!, $language: Language!) {
  register(input: {firstName: $firstName, lastName: $lastName, email: $email, password: $password, passwordConfirmation: $passwordConfirmation, language: $language}) {
    ...UserInformation
  }
}
    ${UserInformationFragmentDoc}`;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const UpdateCompanyContactDocument = gql`
    mutation updateCompanyContact($id: String!, $contactFirstName: String!, $contactLastName: String!, $contactEmail: String!, $contactPhoneNumber: String, $name: String!, $address: String, $domains: [String!]!, $customerNumber: String) {
  updateCompanyContact(input: {id: $id, contactFirstName: $contactFirstName, contactLastName: $contactLastName, contactEmail: $contactEmail, contactPhoneNumber: $contactPhoneNumber, name: $name, address: $address, domains: $domains, customerNumber: $customerNumber}) {
    ...CompanyInformation
  }
}
    ${CompanyInformationFragmentDoc}`;
export type UpdateCompanyContactMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyContactMutation, UpdateCompanyContactMutationVariables>;

/**
 * __useUpdateCompanyContactMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyContactMutation, { data, loading, error }] = useUpdateCompanyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contactFirstName: // value for 'contactFirstName'
 *      contactLastName: // value for 'contactLastName'
 *      contactEmail: // value for 'contactEmail'
 *      contactPhoneNumber: // value for 'contactPhoneNumber'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      domains: // value for 'domains'
 *      customerNumber: // value for 'customerNumber'
 *   },
 * });
 */
export function useUpdateCompanyContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyContactMutation, UpdateCompanyContactMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyContactMutation, UpdateCompanyContactMutationVariables>(UpdateCompanyContactDocument, baseOptions);
      }
export type UpdateCompanyContactMutationHookResult = ReturnType<typeof useUpdateCompanyContactMutation>;
export type UpdateCompanyContactMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyContactMutation>;
export type UpdateCompanyContactMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyContactMutation, UpdateCompanyContactMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($oldPassword: String!, $newPassword: String!, $newPasswordConfirmation: String!) {
  updatePassword(input: {oldPassword: $oldPassword, newPassword: $newPassword, newPasswordConfirmation: $newPasswordConfirmation})
}
    `;
export type UpdatePasswordMutationFn = ApolloReactCommon.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *      newPasswordConfirmation: // value for 'newPasswordConfirmation'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, baseOptions);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = ApolloReactCommon.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdateUserByAdminDocument = gql`
    mutation updateUserByAdmin($id: String!, $firstName: String!, $lastName: String!, $email: String!, $phoneNumber: String) {
  updateUserByAdmin(id: $id, input: {firstName: $firstName, lastName: $lastName, email: $email, phoneNumber: $phoneNumber}) {
    ...UserInformation
  }
}
    ${UserInformationFragmentDoc}`;
export type UpdateUserByAdminMutationFn = ApolloReactCommon.MutationFunction<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>;

/**
 * __useUpdateUserByAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByAdminMutation, { data, loading, error }] = useUpdateUserByAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateUserByAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>(UpdateUserByAdminDocument, baseOptions);
      }
export type UpdateUserByAdminMutationHookResult = ReturnType<typeof useUpdateUserByAdminMutation>;
export type UpdateUserByAdminMutationResult = ApolloReactCommon.MutationResult<UpdateUserByAdminMutation>;
export type UpdateUserByAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>;
export const UpdateUserCompanyDocument = gql`
    mutation updateUserCompany($userId: String!, $companyId: String) {
  updateUserCompany(input: {userId: $userId, companyId: $companyId}) {
    ...UserInformation
  }
}
    ${UserInformationFragmentDoc}`;
export type UpdateUserCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateUserCompanyMutation, UpdateUserCompanyMutationVariables>;

/**
 * __useUpdateUserCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateUserCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCompanyMutation, { data, loading, error }] = useUpdateUserCompanyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdateUserCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserCompanyMutation, UpdateUserCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserCompanyMutation, UpdateUserCompanyMutationVariables>(UpdateUserCompanyDocument, baseOptions);
      }
export type UpdateUserCompanyMutationHookResult = ReturnType<typeof useUpdateUserCompanyMutation>;
export type UpdateUserCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateUserCompanyMutation>;
export type UpdateUserCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserCompanyMutation, UpdateUserCompanyMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($firstName: String!, $lastName: String!, $email: String!, $phoneNumber: String) {
  updateUser(input: {firstName: $firstName, lastName: $lastName, email: $email, phoneNumber: $phoneNumber}) {
    ...UserInformation
  }
}
    ${UserInformationFragmentDoc}`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserSettingsDocument = gql`
    mutation updateUserSettings($waterMeasurement: Boolean, $emulsionMeasurement: Boolean, $dashboardWarnings: Boolean) {
  updateUserSettings(input: {waterMeasurement: $waterMeasurement, emulsionMeasurement: $emulsionMeasurement, dashboardWarnings: $dashboardWarnings}) {
    ...UserInformation
  }
}
    ${UserInformationFragmentDoc}`;
export type UpdateUserSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      waterMeasurement: // value for 'waterMeasurement'
 *      emulsionMeasurement: // value for 'emulsionMeasurement'
 *      dashboardWarnings: // value for 'dashboardWarnings'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, baseOptions);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($token: String!) {
  verifyEmail(input: {token: $token})
}
    `;
export type VerifyEmailMutationFn = ApolloReactCommon.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, baseOptions);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = ApolloReactCommon.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const CompaniesDocument = gql`
    query companies($search: String, $sort: SortArgs, $pagination: PaginationArgs) {
  companies(input: {search: $search, sort: $sort, pagination: $pagination}) {
    items {
      ...CompanyInformation
    }
    totalCount
  }
}
    ${CompanyInformationFragmentDoc}`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
      }
export function useCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = ApolloReactCommon.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyDocument = gql`
    query company($id: String!) {
  company(id: $id) {
    ...CompanyInformation
  }
}
    ${CompanyInformationFragmentDoc}`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
      }
export function useCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = ApolloReactCommon.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const CompanyUsersDocument = gql`
    query companyUsers($companyId: String!, $search: String, $sort: SortArgs, $pagination: PaginationArgs) {
  companyUsers(companyId: $companyId, input: {search: $search, sort: $sort, pagination: $pagination}) {
    items {
      ...UserInformation
    }
    totalCount
  }
}
    ${UserInformationFragmentDoc}`;

/**
 * __useCompanyUsersQuery__
 *
 * To run a query within a React component, call `useCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCompanyUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyUsersQuery, CompanyUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(CompanyUsersDocument, baseOptions);
      }
export function useCompanyUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyUsersQuery, CompanyUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(CompanyUsersDocument, baseOptions);
        }
export type CompanyUsersQueryHookResult = ReturnType<typeof useCompanyUsersQuery>;
export type CompanyUsersLazyQueryHookResult = ReturnType<typeof useCompanyUsersLazyQuery>;
export type CompanyUsersQueryResult = ApolloReactCommon.QueryResult<CompanyUsersQuery, CompanyUsersQueryVariables>;
export const DashboardMachinesDocument = gql`
    query DashboardMachines($search: String, $sort: SortArgs, $pagination: PaginationArgs, $filter: FilterArgs) {
  machines(input: {search: $search, sort: $sort, pagination: $pagination, filter: $filter}) {
    totalCount
    recentlyUpdatedMachines
    items {
      ...DashboardMachine
    }
  }
}
    ${DashboardMachineFragmentDoc}`;

/**
 * __useDashboardMachinesQuery__
 *
 * To run a query within a React component, call `useDashboardMachinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardMachinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardMachinesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDashboardMachinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardMachinesQuery, DashboardMachinesQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardMachinesQuery, DashboardMachinesQueryVariables>(DashboardMachinesDocument, baseOptions);
      }
export function useDashboardMachinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardMachinesQuery, DashboardMachinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardMachinesQuery, DashboardMachinesQueryVariables>(DashboardMachinesDocument, baseOptions);
        }
export type DashboardMachinesQueryHookResult = ReturnType<typeof useDashboardMachinesQuery>;
export type DashboardMachinesLazyQueryHookResult = ReturnType<typeof useDashboardMachinesLazyQuery>;
export type DashboardMachinesQueryResult = ApolloReactCommon.QueryResult<DashboardMachinesQuery, DashboardMachinesQueryVariables>;
export const LastMeasurementsDocument = gql`
    query lastMeasurements {
  machines {
    totalCount
    recentlyUpdatedMachines
    items {
      id
      machineName
      status
      measurementType
      measurements(input: {pagination: {currentPage: 1, itemsPerPage: 1}, sort: {field: "createdAt", order: DESC}}) {
        items {
          ...Measurement
        }
        totalCount
      }
    }
  }
}
    ${MeasurementFragmentDoc}`;

/**
 * __useLastMeasurementsQuery__
 *
 * To run a query within a React component, call `useLastMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastMeasurementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastMeasurementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LastMeasurementsQuery, LastMeasurementsQueryVariables>) {
        return ApolloReactHooks.useQuery<LastMeasurementsQuery, LastMeasurementsQueryVariables>(LastMeasurementsDocument, baseOptions);
      }
export function useLastMeasurementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LastMeasurementsQuery, LastMeasurementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LastMeasurementsQuery, LastMeasurementsQueryVariables>(LastMeasurementsDocument, baseOptions);
        }
export type LastMeasurementsQueryHookResult = ReturnType<typeof useLastMeasurementsQuery>;
export type LastMeasurementsLazyQueryHookResult = ReturnType<typeof useLastMeasurementsLazyQuery>;
export type LastMeasurementsQueryResult = ApolloReactCommon.QueryResult<LastMeasurementsQuery, LastMeasurementsQueryVariables>;
export const MachineMeasurementsDocument = gql`
    query MachineMeasurements($id: String!, $sort: SortArgs!) {
  machine(id: $id) {
    measurements(input: {sort: $sort}) {
      items {
        ...Measurement
      }
    }
  }
}
    ${MeasurementFragmentDoc}`;

/**
 * __useMachineMeasurementsQuery__
 *
 * To run a query within a React component, call `useMachineMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMachineMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMachineMeasurementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMachineMeasurementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MachineMeasurementsQuery, MachineMeasurementsQueryVariables>) {
        return ApolloReactHooks.useQuery<MachineMeasurementsQuery, MachineMeasurementsQueryVariables>(MachineMeasurementsDocument, baseOptions);
      }
export function useMachineMeasurementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MachineMeasurementsQuery, MachineMeasurementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MachineMeasurementsQuery, MachineMeasurementsQueryVariables>(MachineMeasurementsDocument, baseOptions);
        }
export type MachineMeasurementsQueryHookResult = ReturnType<typeof useMachineMeasurementsQuery>;
export type MachineMeasurementsLazyQueryHookResult = ReturnType<typeof useMachineMeasurementsLazyQuery>;
export type MachineMeasurementsQueryResult = ApolloReactCommon.QueryResult<MachineMeasurementsQuery, MachineMeasurementsQueryVariables>;
export const MachineDocument = gql`
    query Machine($id: String!) {
  machine(id: $id) {
    ...Machine
  }
}
    ${MachineFragmentDoc}`;

/**
 * __useMachineQuery__
 *
 * To run a query within a React component, call `useMachineQuery` and pass it any options that fit your needs.
 * When your component renders, `useMachineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMachineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMachineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MachineQuery, MachineQueryVariables>) {
        return ApolloReactHooks.useQuery<MachineQuery, MachineQueryVariables>(MachineDocument, baseOptions);
      }
export function useMachineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MachineQuery, MachineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MachineQuery, MachineQueryVariables>(MachineDocument, baseOptions);
        }
export type MachineQueryHookResult = ReturnType<typeof useMachineQuery>;
export type MachineLazyQueryHookResult = ReturnType<typeof useMachineLazyQuery>;
export type MachineQueryResult = ApolloReactCommon.QueryResult<MachineQuery, MachineQueryVariables>;
export const MachinesDocument = gql`
    query Machines($search: String, $sort: SortArgs, $pagination: PaginationArgs, $filter: FilterArgs) {
  machines(input: {search: $search, sort: $sort, pagination: $pagination, filter: $filter}) {
    totalCount
    recentlyUpdatedMachines
    items {
      ...BaseMachine
    }
  }
}
    ${BaseMachineFragmentDoc}`;

/**
 * __useMachinesQuery__
 *
 * To run a query within a React component, call `useMachinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMachinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMachinesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMachinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MachinesQuery, MachinesQueryVariables>) {
        return ApolloReactHooks.useQuery<MachinesQuery, MachinesQueryVariables>(MachinesDocument, baseOptions);
      }
export function useMachinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MachinesQuery, MachinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MachinesQuery, MachinesQueryVariables>(MachinesDocument, baseOptions);
        }
export type MachinesQueryHookResult = ReturnType<typeof useMachinesQuery>;
export type MachinesLazyQueryHookResult = ReturnType<typeof useMachinesLazyQuery>;
export type MachinesQueryResult = ApolloReactCommon.QueryResult<MachinesQuery, MachinesQueryVariables>;
export const MeasurementHistoryDocument = gql`
    query MeasurementHistory($machineId: String!, $sort: SortArgs!, $pagination: PaginationArgs, $limit: Int, $filter: MachineMeasurementFilterArgs) {
  machine(id: $machineId) {
    id
    factor
    measurementType
    measurements(input: {pagination: $pagination, limit: $limit, sort: $sort, filter: $filter}) {
      totalCount
      items {
        ...Measurement
      }
    }
  }
}
    ${MeasurementFragmentDoc}`;

/**
 * __useMeasurementHistoryQuery__
 *
 * To run a query within a React component, call `useMeasurementHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeasurementHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeasurementHistoryQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMeasurementHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeasurementHistoryQuery, MeasurementHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<MeasurementHistoryQuery, MeasurementHistoryQueryVariables>(MeasurementHistoryDocument, baseOptions);
      }
export function useMeasurementHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeasurementHistoryQuery, MeasurementHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeasurementHistoryQuery, MeasurementHistoryQueryVariables>(MeasurementHistoryDocument, baseOptions);
        }
export type MeasurementHistoryQueryHookResult = ReturnType<typeof useMeasurementHistoryQuery>;
export type MeasurementHistoryLazyQueryHookResult = ReturnType<typeof useMeasurementHistoryLazyQuery>;
export type MeasurementHistoryQueryResult = ApolloReactCommon.QueryResult<MeasurementHistoryQuery, MeasurementHistoryQueryVariables>;
export const MeasurementTimesDocument = gql`
    query MeasurementTimes($machineId: String!) {
  measurements(input: {filter: {text: {field: "machineId", value: [$machineId]}}, sort: {field: "createdAt", order: DESC}}) {
    totalCount
    items {
      id
      createdAt
    }
  }
}
    `;

/**
 * __useMeasurementTimesQuery__
 *
 * To run a query within a React component, call `useMeasurementTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeasurementTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeasurementTimesQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useMeasurementTimesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeasurementTimesQuery, MeasurementTimesQueryVariables>) {
        return ApolloReactHooks.useQuery<MeasurementTimesQuery, MeasurementTimesQueryVariables>(MeasurementTimesDocument, baseOptions);
      }
export function useMeasurementTimesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeasurementTimesQuery, MeasurementTimesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeasurementTimesQuery, MeasurementTimesQueryVariables>(MeasurementTimesDocument, baseOptions);
        }
export type MeasurementTimesQueryHookResult = ReturnType<typeof useMeasurementTimesQuery>;
export type MeasurementTimesLazyQueryHookResult = ReturnType<typeof useMeasurementTimesLazyQuery>;
export type MeasurementTimesQueryResult = ApolloReactCommon.QueryResult<MeasurementTimesQuery, MeasurementTimesQueryVariables>;
export const MeasurementResultsDocument = gql`
    query MeasurementResults($id: String!) {
  measurement(id: $id) {
    ...Measurement
  }
}
    ${MeasurementFragmentDoc}`;

/**
 * __useMeasurementResultsQuery__
 *
 * To run a query within a React component, call `useMeasurementResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeasurementResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeasurementResultsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMeasurementResultsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeasurementResultsQuery, MeasurementResultsQueryVariables>) {
        return ApolloReactHooks.useQuery<MeasurementResultsQuery, MeasurementResultsQueryVariables>(MeasurementResultsDocument, baseOptions);
      }
export function useMeasurementResultsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeasurementResultsQuery, MeasurementResultsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeasurementResultsQuery, MeasurementResultsQueryVariables>(MeasurementResultsDocument, baseOptions);
        }
export type MeasurementResultsQueryHookResult = ReturnType<typeof useMeasurementResultsQuery>;
export type MeasurementResultsLazyQueryHookResult = ReturnType<typeof useMeasurementResultsLazyQuery>;
export type MeasurementResultsQueryResult = ApolloReactCommon.QueryResult<MeasurementResultsQuery, MeasurementResultsQueryVariables>;
export const DashboardOverviewDocument = gql`
    query DashboardOverview {
  machines {
    recentlyUpdatedMachines
    totalCount
  }
  declinedMachines: machines(input: {filter: {text: {field: "declined", value: "true"}}}) {
    totalCount
    items {
      machineName
    }
  }
  measurements {
    totalCount
  }
  normalMeasurements: measurements(input: {filter: {status: [NORMAL]}}) {
    totalCount
  }
}
    `;

/**
 * __useDashboardOverviewQuery__
 *
 * To run a query within a React component, call `useDashboardOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardOverviewQuery, DashboardOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardOverviewQuery, DashboardOverviewQueryVariables>(DashboardOverviewDocument, baseOptions);
      }
export function useDashboardOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardOverviewQuery, DashboardOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardOverviewQuery, DashboardOverviewQueryVariables>(DashboardOverviewDocument, baseOptions);
        }
export type DashboardOverviewQueryHookResult = ReturnType<typeof useDashboardOverviewQuery>;
export type DashboardOverviewLazyQueryHookResult = ReturnType<typeof useDashboardOverviewLazyQuery>;
export type DashboardOverviewQueryResult = ApolloReactCommon.QueryResult<DashboardOverviewQuery, DashboardOverviewQueryVariables>;
export const FindUsersToAddDocument = gql`
    query findUsersToAdd($query: String!) {
  findUsersToAdd(query: $query) {
    id
    email
  }
}
    `;

/**
 * __useFindUsersToAddQuery__
 *
 * To run a query within a React component, call `useFindUsersToAddQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersToAddQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersToAddQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useFindUsersToAddQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindUsersToAddQuery, FindUsersToAddQueryVariables>) {
        return ApolloReactHooks.useQuery<FindUsersToAddQuery, FindUsersToAddQueryVariables>(FindUsersToAddDocument, baseOptions);
      }
export function useFindUsersToAddLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindUsersToAddQuery, FindUsersToAddQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindUsersToAddQuery, FindUsersToAddQueryVariables>(FindUsersToAddDocument, baseOptions);
        }
export type FindUsersToAddQueryHookResult = ReturnType<typeof useFindUsersToAddQuery>;
export type FindUsersToAddLazyQueryHookResult = ReturnType<typeof useFindUsersToAddLazyQuery>;
export type FindUsersToAddQueryResult = ApolloReactCommon.QueryResult<FindUsersToAddQuery, FindUsersToAddQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...UserInformation
  }
}
    ${UserInformationFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const UserDocument = gql`
    query user($id: String!) {
  user(id: $id) {
    ...UserInformation
  }
}
    ${UserInformationFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users($search: String, $sort: SortArgs, $pagination: PaginationArgs) {
  users(input: {search: $search, sort: $sort, pagination: $pagination}) {
    items {
      ...UserInformation
    }
    totalCount
  }
}
    ${UserInformationFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const ValidatePasswordTokenDocument = gql`
    query validatePasswordToken($token: String!) {
  validatePasswordToken(token: $token)
}
    `;

/**
 * __useValidatePasswordTokenQuery__
 *
 * To run a query within a React component, call `useValidatePasswordTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePasswordTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePasswordTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidatePasswordTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidatePasswordTokenQuery, ValidatePasswordTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidatePasswordTokenQuery, ValidatePasswordTokenQueryVariables>(ValidatePasswordTokenDocument, baseOptions);
      }
export function useValidatePasswordTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidatePasswordTokenQuery, ValidatePasswordTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidatePasswordTokenQuery, ValidatePasswordTokenQueryVariables>(ValidatePasswordTokenDocument, baseOptions);
        }
export type ValidatePasswordTokenQueryHookResult = ReturnType<typeof useValidatePasswordTokenQuery>;
export type ValidatePasswordTokenLazyQueryHookResult = ReturnType<typeof useValidatePasswordTokenLazyQuery>;
export type ValidatePasswordTokenQueryResult = ApolloReactCommon.QueryResult<ValidatePasswordTokenQuery, ValidatePasswordTokenQueryVariables>;