import React, { FunctionComponent } from 'react';

interface IHexagonImageProps {
  background: string;
  width?: string;
  height?: string;
}

export const HexagonImage: FunctionComponent<IHexagonImageProps> = ({
  background,
  width,
  height,
}: IHexagonImageProps) => {
  return (
    <svg
      width={width ?? '104px'}
      height={height ?? '104px'}
      version="1.1"
      viewBox="0 0 104 104"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="hexagon">
          <path
            fill="#fff"
            d="M5.000000000000001 53.69357503463519Q0 45.033320996790806 5.000000000000001 36.373066958946424L21 8.660254037844386Q26 0 36 0L68 0Q78 0 83 8.660254037844386L99 36.373066958946424Q104 45.033320996790806 99 53.69357503463519L83 81.40638795573723Q78 90.06664199358161 68 90.06664199358161L36 90.06664199358161Q26 90.06664199358161 21 81.40638795573723Z"
          ></path>
        </clipPath>
      </defs>
      <image
        width="104px"
        height="104px"
        xlinkHref={background}
        clipPath="url(#hexagon)"
      />
    </svg>
  );
};
