import { Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box } from 'reflexbox';
import {
  InitiatePasswordChangeMutationVariables,
  useInitiatePasswordChangeMutation,
} from '~/components/apollo-components';
import { AuthenticationLayout } from '~/components/AuthenticationLayout';
import { DarkButton } from '~/components/DarkButton';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { createNotification } from '~/utils/createNotification';
import { Routes } from '~/utils/Routes';
import { AuthenticationCard, ButtonRow } from '~/utils/styles';
import { validationSchema } from './validationSchema';

const { Title, Text } = Typography;
const { Item } = Form;

export const ForgotPassword: FunctionComponent = () => {
  const { t } = useTranslation(['common']);
  const handleError = useErrorHandler();
  const [
    initiatePasswordChange,
    { loading },
  ] = useInitiatePasswordChangeMutation();
  const history = useHistory();

  const onCancel = useCallback(() => {
    history.push(Routes.auth.login.base);
  }, [history]);

  const onSubmit = useCallback(
    async (values: InitiatePasswordChangeMutationVariables) => {
      try {
        const response = await initiatePasswordChange({ variables: values });
        if (response && response.data && response.data.initiatePasswordChange) {
          createNotification(
            'success',
            t('auth:Forgot password'),
            t('You will receive an email with a link to reset your password.'),
          );
          history.push(Routes.auth.login.base);
        }
      } catch (error) {
        handleError(error, t('auth:Forgot password'), {});
      }
    },
    [history, initiatePasswordChange, t, handleError],
  );

  const cachedValidationSchema = useMemo(() => validationSchema(t), [t]);
  const initialValues = useMemo(() => ({ email: '' }), []);

  return (
    <AuthenticationLayout>
      <AuthenticationCard>
        <Box mb={4}>
          <Title level={3}>{t('auth:Forgot password')}</Title>
          <Text type="secondary">
            {t(
              'auth:Please enter your email address to request a new password.',
            )}
          </Text>
        </Box>
        <Formik<InitiatePasswordChangeMutationVariables>
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={cachedValidationSchema}
        >
          {({ isValid }) => (
            <Form layout="vertical">
              <Item name="email" label={<Text>{t('Email')}</Text>}>
                <Input name="email" size="large" />
              </Item>
              <ButtonRow>
                <DarkButton
                  type="link"
                  disabled={loading}
                  htmlType="button"
                  onClick={onCancel}
                >
                  {t('Cancel')}
                </DarkButton>
                <SubmitButton
                  loading={loading}
                  disabled={!isValid}
                  size="large"
                >
                  {t('auth:Reset password')}
                </SubmitButton>
              </ButtonRow>
            </Form>
          )}
        </Formik>
      </AuthenticationCard>
    </AuthenticationLayout>
  );
};
