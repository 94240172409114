import { ERROR_TYPE, Maybe } from '@motorex/common';
import * as Sentry from '@sentry/browser';
import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createNotification } from '../utils/createNotification';

export function useErrorHandler(): any {
  const { t } = useTranslation();

  const handleError = useCallback(
    (
      error: any,
      namespace: string,
      errorMessageMapping: { [code: string]: string },
    ) => {
      const unknownError = () => {
        // Check if this error was a networking error and was already handled
        if (
          error != null &&
          error.networkError != null &&
          error.networkError.handled
        ) {
          return;
        }

        createNotification(
          'error',
          namespace,
          t('An unexpected error occurred.'),
        );
        // Capture exception we did not handle
        Sentry.captureException(error);
      };

      if (
        !(error instanceof ApolloError) ||
        error.graphQLErrors.length === 0 ||
        error.graphQLErrors[0].extensions == null ||
        error.graphQLErrors[0].extensions.code == null
      ) {
        unknownError();
        return;
      }

      const errorCode: string = error.graphQLErrors[0].extensions.code;
      const errorMessage: Maybe<string> = error.graphQLErrors[0].message;

      // Handle errors explicitly mapped by the caller
      if (
        errorMessageMapping[errorCode] != null ||
        (errorMessage != null && errorMessageMapping[errorMessage] != null)
      ) {
        const message =
          errorMessageMapping[errorCode] != null
            ? errorMessageMapping[errorCode]
            : errorMessageMapping[errorMessage];
        createNotification('error', namespace, message);
        return;
      }

      // Handle general errors
      switch (errorCode) {
        case ERROR_TYPE.USER_INPUT_ERROR:
          createNotification(
            'error',
            namespace,
            t('Validation error during data processing.'),
          );
          return;
        case ERROR_TYPE.UNAUTHORIZED_ERROR:
        case ERROR_TYPE.FORBIDDEN_ERROR:
          createNotification(
            'error',
            namespace,
            t('You are not authorized to perform this action.'),
          );
          return;
      }

      unknownError();
    },
    [t],
  );

  return handleError;
}
