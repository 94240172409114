/* eslint-disable */
import './polyfill';

import * as Sentry from '@sentry/browser';

if (process.env.REACT_APP_SENTRY_DSN != null) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
  Sentry.configureScope((scope) => {
    scope.setTag('source', 'client');
    scope.setTag('commit', process.env.REACT_APP_VERSION_COMMIT_HASH || '-');
  });
}

import './i18n';
import './fonts.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';

ReactDOM.render(<App />, document.getElementById('root'));
