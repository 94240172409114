import { SearchOutlined } from '@ant-design/icons';
import { Input, Table, Typography } from 'antd';
import { ColumnProps, TablePaginationConfig, TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox';
import { styled } from '~/theme';
import { CollectionQueryOptions, SortOrder } from '../apollo-components';
import { TableSettingsContext } from './TableSettingsContext';

const { Title } = Typography;

const TableTitle = styled(Title)`
  &.ant-typography {
    margin-bottom: 0;
  }
`;

const Search = styled(Input)`
  max-width: 450px;
`;

const SearchIcon = styled(SearchOutlined)`
  color: ${({ theme }) => theme.colors.primary};
`;

export interface ITableData<T> {
  items: T[];
  totalCount: number;
}

interface IProps<T> {
  id?: string;
  columns: ColumnProps<T>[];
  data: ITableData<T>;
  loading: boolean;
  title?: ReactNode | string;
  titleLevel?: 1 | 2 | 3 | 4 | 5;
  maxWidth?: number;
  additionalActions?: ReactNode;
  searchDisabled?: boolean;
  searchPlaceholder?: string;
}

type ICombinedProps<T> = Omit<TableProps<T>, keyof IProps<T>> & IProps<T>;

// eslint-disable-next-line @typescript-eslint/ban-types
export function DataTable<T extends object>({
  columns,
  data,
  loading,
  title,
  titleLevel,
  maxWidth,
  additionalActions,
  searchDisabled,
  searchPlaceholder,
  onRow,
}: ICombinedProps<T>): any {
  const { t } = useTranslation(['common']);
  const {
    search: { searchTerm, onSearch },
    pagination: { itemsPerPage, currentPage, onPagination },
    onSort,
    onFilter,
    mergeFetchOptions,
  } = useContext(TableSettingsContext);

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onSearch(event.target.value),
    [onSearch],
  );

  const onTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filter: any,
      sorter: SorterResult<T> | SorterResult<T>[],
    ) => {
      // TODO: Make it compatible with arrays
      if (sorter instanceof Array) return;

      const paginationUpdate = onPagination(
        pagination.current,
        pagination.pageSize,
      );
      const sortUpdate = onSort(
        sorter.field != null && sorter.order != null
          ? {
              field: sorter.field.toString(),
              order: sorter.order === 'ascend' ? SortOrder.ASC : SortOrder.DESC,
            }
          : null,
      );
      const filterUpdate = onFilter(filter);

      const update: Partial<CollectionQueryOptions> = {
        ...sortUpdate,
        ...filterUpdate,
        ...(sortUpdate != null || filterUpdate != null
          ? {
              pagination: { currentPage: 1, itemsPerPage: pagination.pageSize },
            }
          : paginationUpdate),
      };

      mergeFetchOptions(update);
    },
    [onPagination, onSort, onFilter, mergeFetchOptions],
  );

  const paginationOptions: TablePaginationConfig = useMemo(
    () => ({
      current: currentPage,
      pageSize: itemsPerPage,
      position: ['bottomCenter'],
      showSizeChanger: false,
      total: data.totalCount,
    }),
    [itemsPerPage, currentPage, data.totalCount],
  );

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex mb="4" flex="1 0 auto">
          {title != null && (
            <Box>
              <TableTitle level={titleLevel ?? 3}>{title}</TableTitle>
            </Box>
          )}
        </Flex>
        <Box flex="1 1 100%" />
        <Flex justifyContent="space-between" mb="4" flex="1 1 450px">
          {!searchDisabled && (
            <Search
              prefix={<SearchIcon translate="no" />}
              placeholder={searchPlaceholder ?? t('Search...')}
              value={searchTerm || ''}
              onChange={onSearchChange}
            />
          )}
        </Flex>
      </Flex>
      <Table<T>
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={data.items}
        pagination={paginationOptions}
        onChange={onTableChange}
        scroll={{ x: maxWidth != null ? maxWidth : 1000 }}
        onRow={onRow}
      />
      <Flex justifyContent="flex-end">{additionalActions}</Flex>
    </>
  );
}
