import React, { FunctionComponent } from 'react';
import { Box } from 'reflexbox/styled-components';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { styled } from '~/theme';

const LoadingBox = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.75;
  z-index: 1;
`;

export const LoadingOverlay: FunctionComponent = () => {
  return (
    <LoadingBox>
      <LoadingSpinner />
    </LoadingBox>
  );
};
