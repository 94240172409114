import { LocaleObject } from 'yup';

/* eslint-disable no-template-curly-in-string */

export const fr: LocaleObject = {
  mixed: {
    default: "${path} n'est pas valide",
    required: '${path} est un champ obligatoire',
    oneOf: "${path} doit être l'une des valeurs suivantes : ${values}",
    notOneOf: '${path} ne doit pas être une des valeurs suivantes : ${values}',
  },

  string: {
    length: '${path} doit être exactement ${length} caractères',
    min: '${path} doit comporter au moins ${min} caractères',
    max: '${path} doit comporter au maximum ${max} caractères',
    matches: '${path} doit correspondre à ce qui suit : "${regex}"',
    email: '${path} doit être un courriel valide',
    url: '${path} doit être une URL valide',
    trim: '${path} ne doit pas contenir de blancs au début ou à la fin',
    lowercase: '${path} ne peut contenir que des minuscules',
    uppercase: '${path} ne peut contenir que des majuscules',
  },
  number: {
    min: '${path} doit être supérieur ou égal à ${min}',
    max: '${path} doit être inférieur ou égal à ${max}',
    lessThan: '${path} doit être inférieur à ${less}',
    moreThan: '${path} doit être supérieur ou égal à ${min}',
    positive: '${path} doit être un chiffre positif',
    negative: '${path} doit être un chiffre négatif',
    integer: '${path} doit être un nombre entier',
  },
  date: {
    min: '${path} doit être ultérieur à ${min}',
    max: '${path} doit être antérieur à ${max}',
  },
  object: {
    noUnknown:
      "${path} ne peut pas avoir de clés non spécifiées dans la forme de l'objet",
  },
  array: {
    min: '${path} doit comporter au moins ${min} éléments ',
    max:
      "${path} doit comporter un nombre d'éléments inférieur ou égal à ${max}.",
  },
  boolean: {},
};
