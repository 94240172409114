import {
  REGISTER_MISMATCHED_PASSWORD_MESSAGE,
  USER_ALREADY_REGISTERED_MESSAGE,
} from '@motorex/common';
import { Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from 'reflexbox';
import {
  Language,
  RegisterMutationVariables,
  useRegisterMutation,
} from '~/components/apollo-components';
import { AuthenticationLayout } from '~/components/AuthenticationLayout';
import { LanguageMenu } from '~/components/LanguageMenu';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { Routes } from '~/utils/Routes';
import { AlignedBox, AuthenticationCard, DarkLink } from '~/utils/styles';
import { validationSchema } from './validationSchema';
import { createNotification } from '~/utils/createNotification';

const { Title, Text } = Typography;
const { Item } = Form;
const { Password } = Input;

export const RegisterPage: FunctionComponent = () => {
  const { t, i18n } = useTranslation(['auth, common']);
  const handleError = useErrorHandler();
  const history = useHistory();
  const [register, { loading }] = useRegisterMutation();

  const onSubmit = useCallback(
    async (values: RegisterMutationVariables) => {
      try {
        await register({ variables: values });
        createNotification(
          'success',
          t('auth:Register'),
          t(
            'A verification email has been sent to your inbox. Please verify your email using the link sent to you.',
          ),
        );
        history.push(Routes.auth.login.base);
      } catch (e) {
        handleError(e, t('Registration failed'), {
          [USER_ALREADY_REGISTERED_MESSAGE]: t(USER_ALREADY_REGISTERED_MESSAGE),
          [REGISTER_MISMATCHED_PASSWORD_MESSAGE]: t(
            REGISTER_MISMATCHED_PASSWORD_MESSAGE,
          ),
        });
      }
    },
    [register, history, handleError, t],
  );

  const valSchema = useMemo(() => validationSchema(t), [t]);
  const initialValues = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      language: i18n.language as Language,
    }),
    [i18n.language],
  );

  return (
    <AuthenticationLayout>
      <AuthenticationCard>
        <Flex alignItems="baseline">
          <Box width={3 / 4}>
            <Title level={3}>{t('auth:Register')}</Title>
          </Box>
          <AlignedBox align="right" width={1 / 4}>
            <DarkLink to={Routes.auth.login.base}>{t('auth:Login')}</DarkLink>
          </AlignedBox>
        </Flex>
        <Formik<RegisterMutationVariables>
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={valSchema}
        >
          {({ isValid, setFieldValue }) => (
            <Form layout="vertical">
              <Item
                name="firstName"
                label={<Text>{t('common:First Name')}</Text>}
              >
                <Input name="firstName" size="large" />
              </Item>
              <Item
                name="lastName"
                label={<Text>{t('common:Last Name')}</Text>}
              >
                <Input name="lastName" size="large" />
              </Item>
              <Item name="email" label={<Text>{t('common:Email')}</Text>}>
                <Input name="email" size="large" />
              </Item>
              <Item name="password" label={<Text>{t('common:Password')}</Text>}>
                <Password name="password" size="large" />
              </Item>
              <Item
                name="passwordConfirmation"
                label={<Text>{t('auth:Password Confirmation')}</Text>}
              >
                <Password name="passwordConfirmation" size="large" />
              </Item>
              <Flex alignItems="baseline">
                <Box width={1 / 2}>
                  <LanguageMenu
                    onLanguageChange={(language: Language) =>
                      setFieldValue('language', language)
                    }
                  />
                </Box>
                <AlignedBox align="right" width={1 / 2}>
                  <SubmitButton
                    loading={loading}
                    disabled={!isValid}
                    size="large"
                  >
                    {t('auth:Register')}
                  </SubmitButton>
                </AlignedBox>
              </Flex>
            </Form>
          )}
        </Formik>
      </AuthenticationCard>
    </AuthenticationLayout>
  );
};
