export const companies = {
  'Add another domain': 'Ajouter domaine',
  'Add company': 'Ajouter entreprise',
  'Add company address here': 'Ajouter adresse entreprise ici',
  'Add company name here': 'Ajouter nom entreprise ici',
  'Add contact email address here': 'Ajouter courriel ici',
  'Add contact phone number here': 'Ajouter ligne directe ici',
  'Add contact first name here': 'Ajouter prénom ici',
  'Add contact last name here': 'Ajouter nom ici',
  'Add domain here': 'Ajouter domaine ici',
  Address: 'Adresse',
  'Back to Overview': "Retour vers l'aperçu",
  'Companies Overview': 'Aperçu des entreprises',
  Company: 'Entreprises',
  'Company Address': "Adresse de l'entreprise",
  'Company Name': "Nom de l'entreprise",
  'Company successfully created.': 'Entreprise est créée',
  Contact: 'Personne de contact',
  'Contact Email Address': 'Courriel de la personne de contact',
  'Contact First Name': 'Prénom de la personne de contact',
  'Contact Last Name': 'Nom de la personne de contact',
  'Contact Phone Number': 'Ligen directe de la personne de contact',
  'Customer No.': 'Numéro client',
  'Domain must be a valid domain and start with an @':
    'Domaine doit avoir un domaine valable qui commence avec @',
  Domains: 'Domaines',
  Employees: 'Collaborateurs',
  'Error fetching company data':
    'Erreur de récupération des données entreprise',
  'Remove this domain': 'Suprimer ce domaine',
  'Users Overview': 'Aperçu des utilisateurs',
  'Company Infos': "Informations sur l'entreprise",
  'Customer Number': 'Numéro client',
  'Add new user': 'Ajouter un utilisateur',
  'Add User': 'Ajouter un utilisateur',
  'Search to Select': 'Rechercher et sélectionner',
  'Delete Company': "Supprimer l'entreprise",
  'Are you sure you want to delete this company?':
    'Êtes-vous sûr de vouloir supprimer cette entreprise?',
  Remove: 'Supprimer',
  'Delete User': "Supprimer l'utilisateur",
  'Are you sure you want to remove {{email}}?':
    'Êtes-vous sûr de vouloir supprimer {{email}}?',
  'A user with this Email is already registered.':
    'Un utilisateur avec ce courriel est déjà enregistré.',
};
