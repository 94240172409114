import { Card, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { Box, Flex } from 'reflexbox';

const { Title } = Typography;

interface IProps {
  children: React.ReactNode;
  title?: string;
  small?: boolean;
}

export const Content: FunctionComponent<IProps> = ({
  children,
  title,
  small = false,
}: IProps) => (
  <Flex flexDirection="column" alignItems="center">
    <Box my="1">
      <Title level={2}>{title}</Title>
    </Box>
    <Box width={small ? { _: 1, md: 450 } : { _: 1, xxl: 1500 }}>
      <Card>{children}</Card>
    </Box>
  </Flex>
);
