export const faq = {
  'Can Easy Monitoring replace laboratory measurements?':
    'Kann das Easy Monitoring die Labormessung ersetzen?',
  'Can I change limit values individually?':
    'Kann ich Grenzwerte individuell verändern?',
  'Currently, these changes are not yet customizable, but will be possible in a next release.':
    'Aktuell sind diese Änderungen noch nicht individuell anpassbar, werden aber bei einem nächsten Release möglich sein.',
  'For normal operation and TRGS 611 measurements (Technical Guideline for Hazardous Substances in Germany) the Easy Monitoring data is sufficient.':
    'Für den normalen Betrieb sowie die TRGS 611 Messungen (Technische Richtlinie für Gefahrenstoffe in D) reichen die Easy Monitoring Daten aus.',
  'How accurate are the measurements using sticks?':
    'Wie genau sind die Messungen mittels Stäbchen? ',
  'How can I generate a QR code?': 'Wie kann ich einen QR Code generieren?',
  'How long is the data stored on the web platform?':
    'Wie lange werden die Daten auf der Web-Plattform gespeichert?',
  'If no interferences are present, the measurements are almost identical to the laboratory values. (The water measurements as well as the emulsion measurements represent the free ions in the respective liquid. These can change the display value due to metal ions, which are released in copper or magnesium, for example).':
    'Sind keine Störeinflüsse vorhanden, entsprechen die Messungen nahezu den Laborwerten. (Die Wassermessungen sowie die Emulsions-Messungen stellen die freien Ionen in der jeweiligen Flüssigkeit dar. Diese können durch Metallionen, welche beispielsweise in Kupfer oder Magnesium freigesetzt werden, den Anzeigewert verändern.)',
  'Laboratory tests are usually more extensive, since the same biological activities are also recorded. However, laboratory measurements are only taken if there are really compelling reasons. In normal KSS operation, the Easy Monitoring data are sufficient.':
    'Laboruntersuchungen sind in der Regel umfangreicher, da dieselben biologische Aktivitäten ebenfalls erfassen. Labormessungen werden jedoch nur bei wirklich zwingenden Gründen dazu genommen, im normalen KSS Betrieb reichen die Easy Monitoring Daten aus.',
  'Naming of the machine/tank/container and the product/medium used. Then click on "Generate QR Code" and print it out on an adhesive label. From this point on, the respective QR code can be generated directly via sources in the app\'s selection menu.':
    'Benennung der Maschine/Tanks/Gebindes sowie des eingesetzten Produktes/Mediums. Danach auf QR Code generieren klicken und diesen mittels Printer auf einer Klebeetikette ausgeben. Ab diesem Zeitpunkt kann im Auswahlmenü der App direkt via Quellen der jeweilige QR Code generiert werden.',
  'Questions?': 'Fragen?',
  'The complete history of the entered data can be displayed and checked. In addition, it enables the display of liquid-relevant data for several users at the same time.':
    'Mit derselben kann die komplette Historie der eingegebenen Daten dargestellt und überprüft werden. Zudem ermöglicht dieselbe die Darstellung flüssigkeitsrelevanter Daten für mehrere Anwender gleichzeitig.',
  'The data is stored for at least 10 years, but is only available on request for this period.':
    'Diese werden mindestens 10 Jahre gespeichert, sind aber nur auf Anfrage für diesen Zeitraum vorrätig gehalten.',
  'The history of the entered data per machine/tank/container, with limitation to the corresponding period. All values displayed on the color sticks with corresponding limit ranges.':
    'Die Historie der eingegebenen Daten pro Maschine/Tank/Gebinde, mit Eingrenzung auf die entsprechende Periode. Sämtliche auf den Color Stäbchen dargestellten Werte mit entsprechenden Grenzbereichen.',
  'The scan process with QR Code enables the clear identification of the "machine/tank" as well as the allocation of the corresponding product for recurring measurements.':
    'Der Scan Vorgang mittels QR Code ermöglicht die eindeutige Identifikation der „Maschine/Tanks“ sowie die Zuweisung des entsprechenden Produktes bei wiederkehrendem Messen.',
  'These can be ordered directly from us via the MOTOREX homepage. Access can be obtained by means of a QR scan on the respective "MWF" or "WATER" tube.':
    'Diese können direkt via MOTOREX Homepage bei uns bestellt werden. Der Zugang kann mittels QR Scan auf dem jeweiligen „MWF“ oder „WATER“ Röhrchen aufgerufen werden.',
  'We have already compiled the answers to the most frequently asked questions for you.':
    'Die Antworten zu den häufigsten Fragen haben wir bereits für Sie zusammengestellt.',
  'What can be simplified with QR Code?':
    'Was kann mittels QR Code vereinfacht werden?',
  'What can I see in the course of the data?':
    'Was kann ich im Verlauf der Daten entnehmen?',
  'What is the web platform for?': 'Wozu dient die Web-Plattform?',
  'Where do I get the test strips?': 'Woher bekomme ich die Teststreifen?',
  'How can I delete my account for Easy Monitoring?':
    'Wie kann ich mein Konto für Easy Monitoring löschen?',
  'Please contact ':
    'Bitte kontaktieren Sie ',
};
