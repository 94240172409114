import { PASSWORD_TOKEN_INVALID_MESSAGE } from '@motorex/common';

export const auth = {
  'Forgot password': 'Oublier mot de passe',
  Login: 'Se connecter',
  'Password change failed': 'Changement de mot de passe échoué',
  'Password Confirmation': 'Confirmation mot de passe',
  'Password successfully set.': 'Mot de passe établi avec succès',
  [PASSWORD_TOKEN_INVALID_MESSAGE]:
    "Votre identifiant de réinitialisation de mot de passe n'est pas valide",
  'Passwords do not match': 'Les mots de passes ne correspondent pas',
  'Phone Number': 'Numéro de téléphone',
  'Please confirm your new password':
    'Veuillez confirmer votre nouveau mot de passe',
  'Please enter a new password': 'Veuillez entrer un nouveau mot de passe',
  'Please enter your Email': 'Veuillez entrer votre courriel',
  'Please enter your email address to request a new password.':
    'Veuillez entrer votre courriel pour un nouveau mot de passe',
  'Please enter your First Name': 'Veuillez entrer votre prénom',
  'Please enter your Last Name': 'Veuillez entrer votre nom',
  'Please enter your password': 'Veuillez entrer votre mot de passe',
  Register: 'Enregistrer',
  'Reset password': 'Réinitialisez mot de passe',
  'Welcome!': 'Bienvenue!',
};
