import {
  INVALID_OLD_PASSWORD_MESSAGE,
  REGISTER_MISMATCHED_PASSWORD_MESSAGE,
} from '@motorex/common';
import { Button, Modal } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdatePasswordMutation } from '~/components/apollo-components';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { IModalProps } from '~/utils/common';
import { createNotification } from '~/utils/createNotification';
import { ButtonRow } from '~/utils/styles';
import { validationSchema } from './validationSchema';

const { Item } = Form;
const { Password } = Input;

interface IFormValues {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export const UpdatePasswordModal: FunctionComponent<IModalProps> = ({
  visible,
  onCancel,
  onOk,
}: IModalProps) => {
  const { t } = useTranslation(['common', 'auth']);
  const handleError = useErrorHandler();
  const [updatePassword, { loading }] = useUpdatePasswordMutation();

  const onSubmit = useCallback(
    async (values: IFormValues, { resetForm }) => {
      try {
        const response = await updatePassword({ variables: values });
        if (response && response.data && response.data.updatePassword) {
          createNotification(
            'success',
            t('Change Password'),
            t('auth:Password successfully set.'),
          );
          resetForm();
          if (onOk != null) onOk();
        }
      } catch (error) {
        handleError(error, t('Change Password'), {
          [INVALID_OLD_PASSWORD_MESSAGE]: t(INVALID_OLD_PASSWORD_MESSAGE),
          [REGISTER_MISMATCHED_PASSWORD_MESSAGE]: t(
            REGISTER_MISMATCHED_PASSWORD_MESSAGE,
          ),
        });
      }
    },
    [t, onOk, handleError, updatePassword],
  );

  const cachedValidationSchema = useMemo(() => validationSchema(t), [t]);
  const initialValues = useMemo(
    () => ({ oldPassword: '', newPassword: '', newPasswordConfirmation: '' }),
    [],
  );

  return (
    <Modal
      title={t('Change Password')}
      visible={visible}
      onCancel={onCancel}
      width={600}
      footer={null}
      maskClosable={false}
      destroyOnClose={true}
    >
      <Formik<IFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={cachedValidationSchema}
      >
        {({ isValid }) => (
          <Form layout="vertical">
            <Item name="oldPassword" label={t('Old password')} required={true}>
              <Password name="oldPassword" size="large" />
            </Item>
            <Item name="newPassword" label={t('New password')} required={true}>
              <Password name="newPassword" size="large" />
            </Item>
            <Item
              name="newPasswordConfirmation"
              label={t('Confirm new password')}
              required={true}
            >
              <Password name="newPasswordConfirmation" size="large" />
            </Item>
            <ButtonRow>
              <Button type="link" size="large" onClick={onCancel}>
                {t('Close')}
              </Button>
              <SubmitButton
                loading={loading || loading}
                disabled={!isValid || loading || loading}
                size="large"
              >
                {t('Save password')}
              </SubmitButton>
            </ButtonRow>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
