import { Empty, Typography } from 'antd';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Cell,
  Pie,
  PieChart,
  PieLabelRenderProps,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from 'recharts';
import { Flex } from 'reflexbox';
import {
  ChemicalsEmulsionResponse,
  ChemicalsWaterResponse,
  StatusType,
  useLastMeasurementsQuery,
} from '~/components/apollo-components';
import { WaterChemical, EmulsionChemical } from '@motorex/common';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { styled } from '~/theme';
import { calculatePercentage } from '~/utils/calculatePercentage';
import { StatusColorMap } from '~/utils/EasyMonitoring/colorMaps';
import { pieChartSm, pieChartLg } from '~/theme/variables';

const { Paragraph } = Typography;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  .recharts-surface {
    overflow: visible;
  }
  @media (max-width: ${pieChartLg}) and (min-width: ${pieChartSm}) {
    max-height: 80%;
    max-width: 80%;
    padding-top: 1.5rem;
    .recharts-layer > text {
      font-size: 18px;
    }
  }
`;

interface IChartData {
  name: StatusType;
  value: number;
  machines: string[];
}

const TooltipContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.light};
  padding: 12px 16px;
  border-radius: 2px;
`;

const renderLabelContent = ({ cx, x, y, fill, value }: PieLabelRenderProps) => {
  if (value === 0) {
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      fill={fill}
      textAnchor={x > Number(cx) ? 'start' : 'end'}
      dominantBaseline="central"
      fontSize={24}
    >
      {`${value.toLocaleString()}%`}
    </text>
  );
};

const renderTooltipContent = ({ payload, active }: TooltipProps) => {
  if (!active || payload == null || payload.length !== 1) {
    return null;
  }

  const tooltipPayload = payload[0];

  return (
    <TooltipContentWrapper>
      {(tooltipPayload.payload as IChartData).machines.map((machine, index) => (
        <Paragraph key={`${machine}-${index}`}>{machine}</Paragraph>
      ))}
    </TooltipContentWrapper>
  );
};

interface IDashboardMeasurementChartProps {
  filter: string | null;
}

export const DashboardMeasurementChart: FunctionComponent<IDashboardMeasurementChartProps> = ({
  filter,
}: IDashboardMeasurementChartProps) => {
  const { t } = useTranslation('EasyMonitoring');
  const handleError = useErrorHandler();
  const { loading, data, error } = useLastMeasurementsQuery();

  const preparedData: IChartData[] | null = useMemo(() => {
    const normalChartData: IChartData = {
      name: StatusType.NORMAL,
      value: 0,
      machines: [],
    };
    const noticeableChartData: IChartData = {
      name: StatusType.NOTICEABLE,
      value: 0,
      machines: [],
    };
    const criticalChartData: IChartData = {
      name: StatusType.CRITICAL,
      value: 0,
      machines: [],
    };

    if (data?.machines) {
      const { items, totalCount } = data.machines;

      const machinesCount = items.reduce((counter, item) => {
        if (filter && item.measurements) {
          const chemicalName = filter.toLowerCase();
          const chemicals = item.measurements.items[0].chemicals;
          const currentMeasurement =
            chemicals != null
              ? chemicals.__typename === 'ChemicalsWaterResponse'
                ? (chemicals as ChemicalsWaterResponse)[
                    chemicalName as WaterChemical
                  ]
                : (chemicals as ChemicalsEmulsionResponse)[
                    chemicalName as EmulsionChemical
                  ]
              : null;
          if (currentMeasurement) {
            counter++;
            addToChartData(currentMeasurement.status, item.machineName);
          }

          if (currentMeasurement) {
            counter++;
            addToChartData(currentMeasurement.status, item.machineName);
          }
        } else {
          counter = totalCount;
          addToChartData(item.status, item.machineName);
        }
        return counter;
      }, 0);

      normalChartData.value = calculatePercentage(
        normalChartData.value,
        machinesCount,
      );
      noticeableChartData.value = calculatePercentage(
        noticeableChartData.value,
        machinesCount,
      );
      criticalChartData.value = calculatePercentage(
        criticalChartData.value,
        machinesCount,
      );
    }

    function addToChartData(status: string, machineName: string) {
      switch (status) {
        case StatusType.NORMAL:
          normalChartData.value++;
          normalChartData.machines.push(machineName);
          break;
        case StatusType.NOTICEABLE:
          noticeableChartData.value++;
          noticeableChartData.machines.push(machineName);
          break;
        case StatusType.CRITICAL:
          criticalChartData.value++;
          criticalChartData.machines.push(machineName);
          break;
      }
    }

    if (
      normalChartData.value === 0 &&
      noticeableChartData.value === 0 &&
      criticalChartData.value === 0
    ) {
      return null;
    }

    return [normalChartData, noticeableChartData, criticalChartData];
  }, [data, filter]);

  useEffect(() => {
    if (error) {
      handleError(error, t('Error Fetching Last Measurements'), {});
    }
  }, [error, handleError, t]);

  if (preparedData == null) {
    return (
      <Flex height="100%" justifyContent="center" alignItems="center">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Flex>
    );
  }

  return (
    <StyledResponsiveContainer>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <PieChart>
          <Pie
            data={preparedData}
            dataKey="value"
            nameKey="name"
            label={renderLabelContent}
            labelLine={false}
            innerRadius="50%"
            cx="50%"
            cy="50%"
          >
            {preparedData.map((entry) => (
              <Cell key={entry.name} fill={StatusColorMap[entry.name]} />
            ))}
          </Pie>
          <Tooltip
            wrapperStyle={{ zIndex: '1' }}
            content={renderTooltipContent}
          />
        </PieChart>
      )}
    </StyledResponsiveContainer>
  );
};
