import { MeasurementType, StatusType } from '~/components/apollo-components';

export const dashboard = {
  Actuality: 'Actualité',
  Average: 'Moyenne',
  [StatusType.CRITICAL]: 'Critique',
  criticalChemicalsAtSource: 'Précaire <2>{{chemicals}}</2> à {{source}}',
  Decline: 'Diminution',
  [MeasurementType.EMULSION]: 'Emulsion',

  'Error Fetching Sources': 'Erreur de récupération des données entreprise',
  'Error Fetching Last Measurements':
    'Erreur lors du téléchargement des sources',
  'Error Fetching Overview Information':
    "Erreur lors du téléchargement des informations d'aperçus",
  Factor: 'Facteur',
  'Last Measurement': 'Dernière valeur mesurée',
  'Last Measurements': 'Dernières valeurs mesurées',
  'Filter by measurement values': 'Filtrer lors des valeurs de mesures',
  'Measured by': 'Mesuré par',
  'Measurement Type': 'Type de mesuration',
  'Measurement Types Selection': 'Sélection du type de mesuration',
  [StatusType.NOTICEABLE]: 'percevable',
  [StatusType.NORMAL]: 'normal',

  'of measured values in the standard range':
    'Des valeurs mesurées dans la norme',
  Overview: 'Aperçu',
  Product: 'Produit',
  'Search Source': 'Chercher source',
  Source: 'Source',
  'Source Details': 'Source en détails',
  'sources tested in the last 7 days':
    'Source contrôlée dans les derniers 7 jours',
  'Sources with all measured values in the standard range':
    'Source avec toute les valeurs mesurées dans la norme',
  'Sources with at least one abnormal reading':
    'Source avec au moins une valeur abnormale',
  'Sources with at least one critical reading':
    'Source avec au moins une valeur critique',
  'sources with negative trend': 'Source avec tendence négative',
  Status: 'Statut',
  'Warning {{current}}/{{total}}': 'Avertissement  {{current}}/{{total}}',
  [MeasurementType.WATER]: 'Wau',
  'Delete source': 'Supprimer source',
  'Delete measurement': 'Supprimer mesure',
  'delete source': 'Supprimer source',
  'delete measurement': 'Supprimer mesure',
  'Are you sure you want to delete {{name}}?':
    'Etes-vous sûr de vouloir supprimer {{name}}?',
  'Are you sure you want to delete the measurement from {{name}}?':
    'Êtes-vous sûr de vouloir supprimer la mesure de {{name}}?',
  'This will delete all associated measurements as well.':
    'Cela supprimera toutes les mesures associées.',
};
