import {
  INVALID_OLD_PASSWORD_MESSAGE,
  LOGIN_INVALID_CREDENTIALS_MESSAGE,
  REGISTER_MISMATCHED_PASSWORD_MESSAGE,
  USER_ALREADY_REGISTERED_MESSAGE,
  USER_ASSIGN_ADMIN_NOT_ALLOWED_MESSAGE,
  USER_DEACTIVATED_MESSAGE,
  VERIFY_TOKEN_INVALID_MESSAGE,
  USER_NOT_VERIFIED,
  COMPANY_ALREADY_CREATED_MESSAGE,
} from '@motorex/common';

export const common = {
  '{{field}} must contain a valid number':
    '{{field}} deve contenere un numero valido.',
  '{{rangeStart}}-{{rangeEnd}} of {{totalItems}} items':
    '{{rangeStart}}-{{rangeEnd}} di {{totalItems}} voci',
  [COMPANY_ALREADY_CREATED_MESSAGE]:
    'Esiste già un’azienda con il dominio inserito.',
  [USER_ALREADY_REGISTERED_MESSAGE]:
    'Esiste già un utente con l’indirizzo e-mail indicato.',
  'A verification email has been sent to your inbox. Please verify your email using the link sent to you.':
    'Una e-mail di conferma è stata inviata alla tua casella di posta elettronica. Procedi alla conferma del tuo indirizzo e-mail utilizzando il link ricevuto.',
  'Add email here': 'Inserisci l’indirizzo e-mail.',
  'Add first name here': 'Inserisci il nome.',
  'Add job title here': 'Inserisci il titolo professionale.',
  'Add last name here': 'Inserisci il cognome.',
  'Add new user': 'Aggiungi nuovo utente',
  'Add phone number here': 'Inserisci il numero di telefono.',
  Address: 'Indirizzo',
  Administrator: 'Amministratore',
  All: 'Tutti',
  'An unexpected error occurred.': 'Si è verificato un errore inatteso.',
  'At least one number is required': 'È necessario almeno un numero.',
  'At least one role must be specified': 'È necessario almeno un ruolo.',
  Authorization: 'Autorizzazione',
  Authorize: 'Autorizza',
  Back: 'Indietro',
  'Back Home': 'Torna alla home page',
  'Back to Overview': 'Torna al riepilogo',
  'Before accounts are finally deleted, they are deactivated for 90 days. The account can be restored at the "Edit" page.':
    'Prima che gli account vengano completamente cancellati dal sistema, vengono disattivati per 90 giorni. L’account può essere ripristinato nella pagina "Modifica".',
  Cancel: 'Annulla',
  'Cannot load licenses.': 'Impossibile caricare le licenze.',
  'Change Password': 'Modifica password',
  'Change Profile Image': 'Modifica immagine del profilo',
  'Changes successfully saved.':
    'Le modifiche sono state salvate con successo.',
  Close: 'Chiudi',
  'Confirm password': 'Ripeti la password.',
  'Confirm new password': 'Ripeti la nuova password.',
  'Confirm password is required': 'È necessario ripetere la password.',
  Contact: 'Referente',
  'Could not fetch data. Please check your connection.':
    'Non è stato possibile richiamare i dati. Verifica la connessione alla rete.',
  'Could not fetch user.': 'Non è stato possibile caricare l’utente.',
  'Creation failed': 'Creazione non riuscita',
  Customer: 'Cliente',
  Dashboard: 'Dashboard',
  'Date Created': 'Data di creazione',
  'Date Deleted': 'Data di cancellazione',
  'Date Registered': 'Data di registrazione',
  Delete: 'Cancella',
  'Delete account': 'Cancella account',
  'Discard Changes': 'Annulla modifiche',
  Edit: 'Modifica',
  'Edit Profile': 'Modifica profilo',
  'Edit User': 'Modifica utente',
  Email: 'Indirizzo e-mail',
  'Email Address': 'Indirizzo e-mail',
  'Email was successfully sent.': 'L’e-mail è stata inviata con successo.',
  Error: 'Errore',
  'Error Fetching Data': 'Errore durante il richiamo dei dati',
  'Error fetching user data': 'Errore durante il richiamo dei dati dell’utente',
  'Error occurred': 'Si è verificato un errore.',
  FAQ: 'FAQ',
  'First Name': 'Nome',
  'Font Size': 'Dimensioni del carattere',
  'Forgot password?': 'Password dimenticata?',
  'If you continue, the account for {{name}} will be reactivated.':
    'Se si continua, l’account di {{name}} verrà riattivato.',
  'If you continue, the account for {{name}} will first be deactivated for 90 days and then deleted.':
    'Dopo la conferma l’account di {{name}} sarà prima disattivato per 90 giorni e poi cancellato.',
  'Image must be smaller than 2MB.':
    'L’immagine deve essere più piccola di 2 MB.',
  Imprint: 'Colophon',
  Language: 'Lingua',
  Large: 'Grandi',
  'Last Name': 'Cognome',
  Licenses: 'Licenze',
  Login: 'Login',
  'Login failed': 'Login non riuscito',
  'Logs for all Encoding Jobs': 'Log di tutti i lavori di encoding',
  [LOGIN_INVALID_CREDENTIALS_MESSAGE]: 'Dati di login errati!',
  Logout: 'Logout',
  'Looks like the page you are looking for is not available.':
    'Sembra che la pagina che stavi cercando non sia disponibile.',
  'Manage users': 'Gestione utenti',
  Medium: 'Medie',
  'Network Error': 'Errore di rete',
  'New password': 'Nuova password',
  next: 'successivo',
  No: 'No',
  Number: 'Numero',
  'Old password': 'Password precedente',
  Open: 'Aperto',
  Password: 'Password',
  'Phone Number': 'Numero di telefono',
  'Please try again later.': 'Riprova più tardi.',
  previous: 'precedente',
  'Privacy Protection': 'Protezione dei dati',
  'Profile Image': 'Immagine del profilo',
  'Request new password': 'Richiedi nuova password',
  'Request password': 'Richiesta password',
  'Resend register email': 'Invia di nuovo l’e-mail di conferma',
  Restore: 'Ripristina',
  'Restore account': 'Ripristina account',
  'Role(s)': 'Ruolo',
  Save: 'Salva',
  'Save password': 'Salva password',
  'Save Password': 'Salva password',
  Search: 'Cerca',
  'Search...': 'Ricerca in corso...',
  'Set new password': 'Assegna nuova password',
  Settings: 'Impostazioni',
  'Settings were successfully updated.':
    'Le impostazioni sono state salvate con successo.',
  'Show profile': 'Visualizza profilo',
  'Sign out': 'Logout',
  Small: 'Piccole',
  'Sorry, something went wrong.': 'Ops, qualcosa è andato storto.',
  'Sorry, you are not authorized to access this page.':
    'Purtroppo non sei autorizzato ad accedere a questa pagina.',
  'Terms and Conditions': 'CGC',
  'The width of your screen is too small for this web application. Try to turn the device clockwise – this might help.':
    'La larghezza del tuo schermo è troppo piccola per questa applicazione web. Prova a girare il dispositivo in senso orario - questo potrebbe aiutare.',
  'Unexpected error occurred.': 'Si è verificato un errore inatteso.',
  'Update failed': 'Salvataggio non riuscito',
  'User deleted': 'Utente cancellato',
  'User restored': 'Utente ripristinato',
  'User successfully created.': 'Utente creato con successo.',
  'User successfully marked as to delete.':
    'Utente contrassegnato con successo come da cancellare.',
  'User successfully restored.': 'L’utente è stato ripristinato con successo.',
  'Validation Error': 'Errore di validazione',
  'Validation error during data processing.':
    'Errore di validazione durante l’elaborazione dei dati.',
  View: 'Visualizza',
  'Welcome!': 'Benvenuto!',
  [INVALID_OLD_PASSWORD_MESSAGE]: 'La password precedente non è valida.',
  [REGISTER_MISMATCHED_PASSWORD_MESSAGE]:
    'Le password inserite non corrispondono.',
  [USER_ASSIGN_ADMIN_NOT_ALLOWED_MESSAGE]:
    'Non è consentito assegnare un ulteriore ruolo a un amministratore.',
  Yes: 'Sì',
  'You are not authorized to perform this action.':
    'Non sei autorizzato a eseguire questa azione.',
  'You can only upload JPG or PNG files.':
    'È possibile caricare solo file JPG o PNG.',
  'You will receive an email with a link to reset your password.':
    'Riceverai a breve un’e-mail con un link per reimpostare la tua password.',
  [USER_DEACTIVATED_MESSAGE]:
    'Il tuo account è disattivato. Contatta l’amministratore per farlo ripristinare.',
  [USER_NOT_VERIFIED]:
    'Il tuo account non è verificato. Controlla se nella tua casella di posta in arrivo è presente l’e-mail di verifica inviata al momento della registrazione.',
  [VERIFY_TOKEN_INVALID_MESSAGE]: 'Il tuo token di verifica non è valido.',
  'Verify Email': 'Il tuo indirizzo e-mail è stato verificato con successo.',
};
