export const Routes = {
  admin: {
    base: '/admin',
    companies: {
      base: '/admin/companies',
      create: '/admin/companies/create',
      details: (id: string): string => `/admin/companies/${id}`,
    },
    users: {
      base: '/admin/users',
      create: '/admin/users/create',
      edit: (id: string): string => `/admin/users/${id}`,
    },
  },
  auth: {
    changePassword: {
      base: '/change-password/:token',
    },
    forgotPassword: {
      base: '/forgot-password',
    },
    login: {
      base: '/login',
    },
    register: {
      base: '/register',
    },
    verifyEmail: {
      base: '/verify-email/:token',
    },
  },
  base: '/',
  easyMonitoring: {
    base: '/easy-monitoring',
    dashboard: {
      base: '/easy-monitoring/dashboard',
    },
    machine: {
      base: '/easy-monitoring/source',
      single: {
        base: (id: string): string => `/easy-monitoring/source/${id}`,
        qr: (id: string): string => `/easy-monitoring/source/${id}/qr`,
      },
    },
  },
  profile: {
    base: '/profile',
  },
  static: {
    contact: {
      base: '/contact',
    },
    faq: {
      base: '/faq',
    },
    imprint: {
      base: '/imprint',
    },
    licenses: {
      base: '/licenses',
    },
    terms: {
      base: '/terms',
    },
    privacy: {
      base: '/privacy',
    },
  },
};
