import { createGlobalStyle } from './theme';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'OpenSans';
    font-weight: 300;
    src: url(./assets/fonts/OpenSans-Light.ttf) format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 400;
    src: url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 600;
    src: url(./assets/fonts/OpenSans-SemiBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 700;
    src: url(./assets/fonts/OpenSans-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 800;
    src: url(./assets/fonts/OpenSans-ExtraBold.ttf) format('truetype');
  }

  .ant-table-column-sort .ant-table-column-sorters {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
