import { ChemicalEnum } from '@motorex/common';
import { Typography } from 'antd';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'reflexbox/styled-components';
import { styled } from '~/theme';
import {
  ChemicalsEmulsionResponse,
  MeasurementFragment,
} from '~/components/apollo-components';
import { getLabelTextForChemical } from '~/utils/EasyMonitoring/getLabelTextForChemical';
import { BlackLabel } from '~/utils/styles';

const { Text } = Typography;

const WrapperBox = styled(Box)`
  margin-bottom: 1rem;
`;
export const ExtraValueBox = styled(Box)`
  margin: 1rem 0 1rem 0;
`;
export const FactorBaseText = styled(Text)`
  padding: 1rem 2rem 1rem 2rem;
`;
export const FactorOperatorText = styled(FactorBaseText)`
  font-size: 12px;
`;
export const FactorText = styled(FactorBaseText)`
  background-color: ${({ theme }) => theme.colors.secondaryLight};
`;
export const FactorTotalText = styled(FactorText)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
`;
interface IExtraEmulsionValueProps {
  factor?: number;
  currentMeasurement: MeasurementFragment | null;
  chemical: ChemicalEnum.CONCENTRATION | ChemicalEnum.TEMPERATURE;
  unit: string;
}
function isEmulsionResponse(
  chemicals: MeasurementFragment['chemicals'],
): chemicals is ChemicalsEmulsionResponse {
  return chemicals.__typename === 'ChemicalsEmulsionResponse';
}
export const ExtraEmulsionValue: FunctionComponent<IExtraEmulsionValueProps> = ({
  factor,
  currentMeasurement,
  chemical,
  unit,
}: IExtraEmulsionValueProps) => {
  const { t } = useTranslation('EasyMonitoring');
  const label = (
    <Box>
      <BlackLabel>{getLabelTextForChemical(t)[chemical]}</BlackLabel>
    </Box>
  );
  const measuredValue = useMemo(() => {
    if (
      currentMeasurement != null &&
      chemical != null &&
      isEmulsionResponse(currentMeasurement.chemicals)
    ) {
      const chemicals = currentMeasurement.chemicals;
      const chemicalData = chemicals[chemical];
      return chemicalData?.measuredValue;
    }
    return null;
  }, [currentMeasurement, chemical]);
  if (chemical === ChemicalEnum.CONCENTRATION && factor) {
    return (
      <WrapperBox>
        {label}
        <ExtraValueBox>
          {measuredValue != null && measuredValue > -1 ? (
            <>
              <Text>{`${measuredValue.toFixed(2)} ${unit}`}</Text>
              <FactorOperatorText>x</FactorOperatorText>
              <FactorText>{t('Factor') + ` ${factor.toFixed(2)}`}</FactorText>
              <FactorOperatorText>=</FactorOperatorText>
              <FactorTotalText>{`${(measuredValue * factor).toFixed(
                2,
              )} ${unit}`}</FactorTotalText>
            </>
          ) : (
            t('n.a.')
          )}
        </ExtraValueBox>
      </WrapperBox>
    );
  } else {
    return (
      <WrapperBox>
        {label}
        <ExtraValueBox>
          {measuredValue != null && measuredValue > -1
            ? `${measuredValue} ${unit}`
            : t('n.a.')}
        </ExtraValueBox>
      </WrapperBox>
    );
  }
};
