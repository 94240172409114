/**
 * Reference of possible values can be found under the following link:
 * https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
 */

// Custom definitions
export const OpenSans = 'Open Sans';

export const colors = {
  dew: '#f0fffe',
  grey: {
    dark: 'rgba(0,0,0,0.6)',
    medium: '#b4b4b4',
    light: '#f2f2f2',
  },
  primary: '#009989',
  secondary: '#a6ded8',
  secondaryLight: 'rgba(166,222,216,0.2)',
  disabled: 'rgba(0,0,0,0.25)',
  yellow: '#ffb400',
  red: '#db1811',
  blue: '#11c9db',
  green: '#10ab03',
  black: '#2f2f2f',
  white: '#ffffff',
};

export const defaults = {
  borderRadius: '4px',
  margin: '24px',
};

// Theme colors
export const primaryColor = colors.primary;
export const infoColor = colors.primary;
export const headingColor = colors.primary;
export const textColor = colors.black;
export const textColorSecondary = colors.grey.dark;

// Layout
export const layoutBodyBackground = colors.grey.light;
export const layoutHeaderBackground = colors.primary;
export const layoutFooterBackground = layoutHeaderBackground;
export const layoutHeaderHeight = '64px';
export const layoutHeaderPadding = 0;
export const layoutFooterPadding = '0 96px 24px';
export const layoutSiderBackground = layoutHeaderBackground;
export const layoutSiderBackgroundLight = colors.white;
export const borderRadiusSm = defaults.borderRadius;

// Button
export const btnFontWeight = 'bold';
export const btnDefaultColor = colors.primary;
export const btnPrimaryBg = colors.primary;

// Card
export const cardRadius = '8px';

// Collapse
export const collapseHeaderBg = 'rgba(0,153,137,0.1)';

// Descriptions
export const descriptionsItemTrailingColon = false;
export const descriptionsSmallPadding = 0;
export const descriptionsItemPaddingBottom = 0;

// Divider
export const dividerColor = colors.grey.light;

// Menu
export const menuItemPadding = 0;

// Popover
export const popoverBg = colors.grey.light;

// Table
export const tableHeaderColor = colors.black;
export const tableHeaderBg = colors.grey.light;

// Breakpoints
export const screenXs = '480px';
export const screenSm = '576px';
export const screenSmMax = '767px';
export const screenMd = '768px';
export const screenLg = '992px';
export const screenXl = '1200px';
export const screenXxl = '1600px';
const breakpoints: any = [
  screenXs,
  screenSm,
  screenMd,
  screenLg,
  screenXl,
  screenXxl,
];
breakpoints._ = 0;
breakpoints.xs = screenXs;
breakpoints.sm = screenSm;
breakpoints.md = screenMd;
breakpoints.lg = screenLg;
breakpoints.xl = screenXl;
breakpoints.xxl = screenXxl;

// Custom Component Breakpoints
export const pieChartSm = '992px';
export const pieChartLg = '1500px';
export const footerSm = '991px';

export const fontFamily = `${OpenSans}, sans-serif`;

export { breakpoints };

// Adaptive card sizes
export const boxCard = {
  width: {
    oneThird: { _: 1, xl: 1 / 3 },
    twoThird: { _: 1, xl: 2 / 3 },
    half: { _: 1, lg: 1 / 2 },
  },
  padding: {
    horizontalXl: { _: 0, xl: '0.625rem' },
    verticalXl: { _: '0.625rem', xl: 0 },
    horizontalLg: { _: 0, lg: '0.625rem' },
    verticalLg: { _: '0.625rem', lg: 0 },
  },
};
