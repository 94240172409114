import { TFunction } from 'i18next';
import { object, string } from 'yup';

export const validationSchema = (t: TFunction): any =>
  object().shape({
    email: string()
      .label(t('Email'))
      .email()
      .required(),
    password: string()
      .label(t('Password'))
      .required(),
  });
