import { Typography } from 'antd';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox/styled-components';
import { useDashboardOverviewQuery } from '~/components/apollo-components';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { OverviewCardElement } from '~/pages/EasyMonitoring/Dashboard/OverviewCard/OverviewCardElement';
import { styled } from '~/theme';
import { calculatePercentage } from '~/utils/calculatePercentage';
import { ReactComponent as ActualityIcon } from './images/aktualitaet.svg';
import { ReactComponent as AverageIcon } from './images/durchschnitt.svg';
import { ReactComponent as DeclineIcon } from './images/verschlechterung.svg';
import { DashboardCard } from '~/utils/styles';

const { Title, Paragraph } = Typography;

const CardFlex = styled(Flex)`
  :not(:last-of-type) {
    border-right: 2px solid ${({ theme }) => theme.colors.grey.light};
  }
`;

export const OverviewCard: FunctionComponent = () => {
  const { t } = useTranslation('EasyMonitoring');
  const handleError = useErrorHandler();
  const { data, loading, error } = useDashboardOverviewQuery();

  const average = useMemo(() => {
    if (data?.measurements && data?.normalMeasurements) {
      return calculatePercentage(
        data.normalMeasurements.totalCount,
        data.measurements.totalCount,
        1,
      );
    }

    return '-';
  }, [data]);

  const declinedSources = useMemo(() => {
    if (data?.declinedMachines) {
      return data.declinedMachines.items.map(({ machineName }) => (
        <Paragraph key={machineName}>{machineName}</Paragraph>
      ));
    }

    return null;
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError(error, t('Error Fetching Overview Information'), {});
    }
  }, [error, handleError, t]);

  return (
    <DashboardCard>
      <Flex flexDirection="column" height="100%">
        <Box>
          <Title level={3}>{t('Overview')}</Title>
        </Box>
        <Flex flexGrow={1}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <CardFlex width={1 / 3} flexDirection="column">
                <OverviewCardElement
                  icon={<ActualityIcon />}
                  title={t('Actuality')}
                  description={t('sources tested in the last 7 days')}
                  value={`${data?.machines?.recentlyUpdatedMachines ?? '-'}`}
                  unit={`/${data?.machines?.totalCount ?? '-'}`}
                />
              </CardFlex>
              <CardFlex width={1 / 3} flexDirection="column">
                <OverviewCardElement
                  icon={<DeclineIcon />}
                  title={t('Decline')}
                  description={t('sources with negative trend')}
                  value={`${data?.declinedMachines?.totalCount ?? '-'}`}
                  unit={`/${data?.machines?.totalCount ?? '-'}`}
                  additionalInfo={declinedSources}
                />
              </CardFlex>
              <CardFlex width={1 / 3} flexDirection="column">
                <OverviewCardElement
                  icon={<AverageIcon />}
                  title={t('Average')}
                  description={t('of measured values in the standard range')}
                  value={`${average}%`}
                />
              </CardFlex>
            </>
          )}
        </Flex>
      </Flex>
    </DashboardCard>
  );
};
