import { Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorLayout } from '~/components/ErrorLayout';
import { SecondaryLinkButton } from '~/components/SecondaryLinkButton';
import { Routes } from '~/utils/Routes';
import styled from 'styled-components';

const { Title } = Typography;

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const NotFound: FunctionComponent = () => {
  const { t } = useTranslation('common');

  return (
    <ErrorLayout>
      <StyledTitle level={2}>
        {t('Looks like the page you are looking for is not available.')}
      </StyledTitle>
      <SecondaryLinkButton size="large" to={Routes.base} padding="6.4px 23px">
        {t('Back Home')}
      </SecondaryLinkButton>
    </ErrorLayout>
  );
};
