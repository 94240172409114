import React, { FunctionComponent, ReactNode } from 'react';
import { AdminLayout } from '~/components/AdminLayout';
import { UserLayout } from '~/components/UserLayout';
import { AuthenticationLayout } from '~/components/AuthenticationLayout';
import { useAuthentication } from '~/hooks/useAuthentication';
import { isGranted } from '~/utils/common';
import { Role } from './apollo-components';
import { ContactCard } from '~/components/ContactCard';
import { Flex } from 'reflexbox/styled-components';

interface IStaticPageProps {
  children: ReactNode;
}

export const StaticPage: FunctionComponent<IStaticPageProps> = ({
  children,
}: IStaticPageProps) => {
  const { currentUser } = useAuthentication();

  const AuthLayout = () => {
    return (
      <AuthenticationLayout showBackLink={true}>
        {children}
      </AuthenticationLayout>
    );
  };

  const LayoutComponent =
    currentUser == null
      ? AuthLayout
      : isGranted(currentUser, Role.ADMIN)
      ? AdminLayout
      : UserLayout;

  return (
    <LayoutComponent>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        maxWidth={currentUser != null ? '885px' : '100%'}
        margin="0 auto"
      >
        <Flex flex="1 0 auto">{children}</Flex>
        <Flex padding="1.75rem 0">
          <ContactCard />
        </Flex>
      </Flex>
    </LayoutComponent>
  );
};
