import { Result } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes } from '~/utils/Routes';
import { LinkButton } from '../LinkButton';

export const NotAuthorized: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Result
      status="403"
      title="403"
      subTitle={t('Sorry, you are not authorized to access this page.')}
      extra={
        <LinkButton type="primary" to={Routes.base}>
          {t('Back Home')}
        </LinkButton>
      }
    />
  );
};
