import { LocaleObject } from 'yup';

/* eslint-disable no-template-curly-in-string */

export const it: LocaleObject = {
  mixed: {
    default: '${path} non è valido',
    required: '${path} è un campo obbligatorio',
    oneOf: '${path} deve corrispondere a uno dei seguenti valori: ${values}',
    notOneOf:
      '${path} non deve corrispondere a uno dei seguenti valori: ${values}',
  },
  string: {
    length: '${path} deve avere una lunghezza esatta di ${length} caratteri',
    min: '${path} deve avere una lunghezza minima di ${min} caratteri',
    max: '${path} deve avere una lunghezza massima di ${max} caratteri',
    matches: "${path} deve essere come segue: '${regex}'",
    email: '${path} deve contenere un indirizzo e-mail valido',
    url: '${path} deve essere un URL valido',
    trim: '${path} non deve contenere spazi all’inizio o alla fine',
    lowercase: '${path} può contenere solo caratteri minuscoli',
    uppercase: '${path} può contenere solo caratteri maiuscoli',
  },
  number: {
    min: '${path} deve essere maggiore o uguale a ${min}',
    max: '${path} deve essere minore o uguale a ${max}',
    lessThan: '${path} deve essere minore di ${less}',
    positive: '${path} deve essere un numero positivo',
    negative: '${path} deve essere un numero negativo',
    integer: '${path} deve essere un numero intero',
  },
  date: {
    min: '${path} deve essere posteriore a ${min}',
    max: '${path} deve essere precedente a ${max}',
  },
  object: {
    noUnknown:
      'Il campo ${path} non deve avere chiav…non siano specificate nella forma dell’oggetto',
  },
  array: {
    min: 'Il campo ${path} deve avere almeno ${min} voci',
    max: 'Il campo ${path} può avere un massimo di ${max} voci',
  },
  boolean: {},
};
