import { Collapse, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticPage } from '~/components/StaticPage';
import { faqContent } from '~/pages/StaticPages/FAQ/utils/faqContent';
import { styled } from '~/theme';
import { FullWidthCard, PrimaryText } from '~/utils/styles';

const { Text, Title, Paragraph } = Typography;
const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  margin-top: 3.25rem;

  .ant-collapse-item {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledParagraph = styled(Paragraph)`
  && {
    margin-bottom: 0;
  }
  letter-spacing: 1px;
  line-height: 22px;
`;

export const FAQ: FunctionComponent = () => {
  const { t } = useTranslation('faq');

  return (
    <StaticPage>
      <FullWidthCard>
        <Title level={3}>{t('Questions?')}</Title>
        <PrimaryText>
          {t(
            'We have already compiled the answers to the most frequently asked questions for you.',
          )}
        </PrimaryText>
        <StyledCollapse expandIconPosition="right" bordered={false}>
          {faqContent.map((faqItem, index) => (
            <Panel key={index} header={t(faqItem.question)}>
              {faqItem.answer.split('\n').map((paragraph, pIndex) => { 
                if (faqItem.replaceEmail !== null && faqItem.replaceEmail !== undefined) {
                    if (paragraph.includes(faqItem.replaceEmail)) {
                      const paragraphWithEmail = paragraph.split(faqItem.replaceEmail);
                      const hrefEmail = `mailto:${faqItem.replaceEmail}`;
                      return (
                        <StyledParagraph key={pIndex}>
                          {t(paragraphWithEmail[0])}
                          <Text>
                            <a
                              href={hrefEmail}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {faqItem.replaceEmail}
                            </a>
                          </Text>
                          {t(paragraphWithEmail[1])}
                        </StyledParagraph>
                        );
                    }
                }
                return (
                  <Paragraph key={pIndex}>{t(paragraph)}</Paragraph>
                  );
                }
              )}
            </Panel>
          ))}
        </StyledCollapse>
      </FullWidthCard>
    </StaticPage>
  );
};
