import { Button, Typography } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Formik, FieldArray } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'reflexbox';
import { ButtonRow } from '~/utils/styles';
import {
  CreateCompanyInput,
  CompanyInformationFragment,
} from '../apollo-components';
import { validationSchema } from './validationSchema';

const { Item } = Form;
const { Title } = Typography;

const emptyCompany: CreateCompanyInput = {
  name: '',
  address: null,
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhoneNumber: null,
  domains: [''],
};

interface IProps {
  existingData?: CompanyInformationFragment;
  onSubmit: (values: CreateCompanyInput) => void;
  onCancel: () => void;
  loading: boolean;
}

export const CompanyForm: FunctionComponent<IProps> = ({
  existingData,
  onSubmit,
  onCancel,
  loading,
}: IProps) => {
  const { t } = useTranslation(['Admin', 'common']);
  const valSchema = useMemo(() => validationSchema(t), [t]);

  return (
    <Formik<CreateCompanyInput>
      onSubmit={onSubmit}
      validationSchema={valSchema}
      initialValues={existingData || emptyCompany}
    >
      {({ isValid, values }) => (
        <Form layout="vertical">
          <Box mb={4}>
            <Title level={3}>{t('Add company')}</Title>
          </Box>
          <Item name="name" label={t('Company Name')} required={true}>
            <Input name="name" />
          </Item>
          <Item name="customerNumber" label={t('Customer Number')}>
            <Input name="customerNumber" />
          </Item>
          <Item name="address" label={t('Company Address')}>
            <Input name="address" />
          </Item>
          <Item
            name="contactFirstName"
            label={t('Contact First Name')}
            required={true}
          >
            <Input name="contactFirstName" />
          </Item>
          <Item
            name="contactLastName"
            label={t('Contact Last Name')}
            required={true}
          >
            <Input name="contactLastName" />
          </Item>
          <Item
            name="contactEmail"
            label={t('Contact Email Address')}
            required={true}
          >
            <Input name="contactEmail" />
          </Item>
          <Item name="contactPhoneNumber" label={t('Contact Phone Number')}>
            <Input name="contactPhoneNumber" />
          </Item>
          <Item name="domains-container" label={t('Domains')} required={true}>
            <FieldArray
              name="domains"
              render={(arrayHelpers) => (
                <div>
                  {values?.domains
                    ? values.domains.map((domain, index) => (
                        <Item key={index} name={`domains.${index}`}>
                          <Input
                            name={`domains.${index}`}
                            suffix={
                              values.domains.length > 1 && (
                                <Button
                                  type="link"
                                  onClick={() => arrayHelpers.remove(index)}
                                  title={t('Remove this domain')}
                                >
                                  <DeleteOutlined translate="no" />
                                </Button>
                              )
                            }
                          />
                        </Item>
                      ))
                    : null}
                  <Box textAlign="center" mt={2}>
                    <Button
                      type="link"
                      onClick={() => arrayHelpers.push('')}
                      icon={<PlusOutlined translate="no" />}
                    >
                      {t('Add another domain')}
                    </Button>
                  </Box>
                </div>
              )}
            />
          </Item>
          <ButtonRow>
            <Button
              disabled={loading}
              onClick={onCancel}
              type="link"
              size="large"
            >
              {t('common:Cancel')}
            </Button>
            <SubmitButton loading={loading} disabled={!isValid} size="large">
              {t('common:Save')}
            </SubmitButton>
          </ButtonRow>
        </Form>
      )}
    </Formik>
  );
};
