import { Typography } from 'antd';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';
import { useCompanyQuery } from '~/components/apollo-components';
import { AdminLayout } from '~/components/AdminLayout';
import { ISecondaryHeaderProps } from '~/components/Header/SecondaryHeader';
import { createNotification } from '~/utils/createNotification';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { DashboardCard } from '~/utils/styles';
import { Routes } from '~/utils/Routes';
import { ContactCard } from './ContactCard';
import { UsersCard } from './UsersCard';
import { DeleteCompany } from '~/components/DeleteCompany';

const { Text } = Typography;

export const StyledText = styled(Text)`
  margin-left: 1rem;
  vertical-align: middle;
`;

interface IRouteParams {
  id: string;
}

export const CompanyDetails: FunctionComponent<RouteComponentProps<
  IRouteParams
>> = ({ history, match: { params } }: RouteComponentProps<IRouteParams>) => {
  const { t } = useTranslation('Admin');
  const { data, loading, error } = useCompanyQuery({
    variables: {
      id: params.id,
    },
  });

  const secondaryHeaderOptions: ISecondaryHeaderProps = useMemo(() => {
    return {
      backLink: Routes.admin.companies.base,
    };
  }, []);

  useEffect(() => {
    if (error) {
      createNotification('error', t('Error fetching company data'));
      history.push(Routes.admin.companies.base);
    }
  }, [error, history, t]);

  return (
    <AdminLayout secondaryHeaderOptions={secondaryHeaderOptions}>
      <Flex flexDirection="column">
        <Box pb="1rem">
          <Flex flexWrap="wrap">
            {loading || data == null ? (
              <DashboardCard>
                <LoadingSpinner />
              </DashboardCard>
            ) : (
              <ContactCard data={data?.company} />
            )}
          </Flex>
        </Box>
        <Box>
          <UsersCard data={data?.company} loading={loading} />
        </Box>
        {data?.company.id && <DeleteCompany companyId={data?.company.id} />}
      </Flex>
    </AdminLayout>
  );
};
