import { USER_ALREADY_REGISTERED_MESSAGE } from '@motorex/common';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import {
  CreateUserInput,
  useCreateUserMutation,
} from '~/components/apollo-components';
import { AdminLayout } from '~/components/AdminLayout';
import { Content } from '~/components/Content';
import { UserForm } from '~/components/UserForm';
import { useAddBreadcrumb } from '~/hooks/useAddBreadcrumb';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { createNotification } from '~/utils/createNotification';
import { Routes } from '~/utils/Routes';

export const CreateUser: FunctionComponent<RouteComponentProps> = ({
  history,
}: RouteComponentProps) => {
  const { t } = useTranslation();
  useAddBreadcrumb({
    title: t('Add new user'),
    path: Routes.admin.users.create,
  });
  const [update, { loading }] = useCreateUserMutation();
  const handleError = useErrorHandler();

  const onSubmit = useCallback(
    async (values: CreateUserInput) => {
      try {
        await update({
          variables: values,
        });

        createNotification('success', t('User successfully created.'));
        history.push(Routes.admin.users.base);
      } catch (e) {
        handleError(e, t('Creation failed'), {
          [USER_ALREADY_REGISTERED_MESSAGE]: t(USER_ALREADY_REGISTERED_MESSAGE),
        });
      }
    },
    [t, update, history, handleError],
  );

  const onCancel = useCallback(() => history.push(Routes.admin.users.base), [
    history,
  ]);

  return (
    <AdminLayout>
      <Content small={true} title={t('Add new user')}>
        <UserForm loading={loading} onSubmit={onSubmit} onCancel={onCancel} />
      </Content>
    </AdminLayout>
  );
};
