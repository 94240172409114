import React, { FunctionComponent, ReactNode } from 'react';
import { ISecondaryHeaderProps } from '~/components/Header/SecondaryHeader';
import { LowResolutionOverlay } from '~/components/LowResolutionOverlay';
import { MainContent } from '~/components/MainContent';
import { ResponsiveLayout } from '~/utils/styles';
import { UserHeader } from './Header/UserHeader';
import { Footer } from './Footer';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

interface IUserLayoutProps {
  children: ReactNode;
  secondaryHeaderOptions?: ISecondaryHeaderProps;
}

export const UserLayout: FunctionComponent<IUserLayoutProps> = ({
  children,
  secondaryHeaderOptions,
}: IUserLayoutProps) => {
  const { md } = useBreakpoint();

  if (!md) {
    return (
      <ResponsiveLayout>
        <LowResolutionOverlay />
      </ResponsiveLayout>
    );
  }
  return (
    <ResponsiveLayout>
      <UserHeader secondaryHeaderOptions={secondaryHeaderOptions} />
      <MainContent secondaryHeaderEnabled={secondaryHeaderOptions != null}>
        {children}
      </MainContent>
      <Footer />
    </ResponsiveLayout>
  );
};
