import { Card, Typography } from 'antd';
import React, { FunctionComponent, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox';
import { ChartLegend } from '~/pages/EasyMonitoring/Dashboard/LastMeasurementsCard/ChartLegend';
import { DashboardMeasurementChart } from '~/pages/EasyMonitoring/Dashboard/LastMeasurementsCard/DashboardMeasurementChart';
import { FilterMeasurementsDropdown } from './FilterMeasurementsDropdown';
import { ChemicalType } from '~/components/apollo-components';

const { Title } = Typography;

export const LastMeasurementsCard: FunctionComponent = () => {
  const { t } = useTranslation('EasyMonitoring');

  const [
    measurementFilter,
    setMeasurementFilter,
  ] = useState<ChemicalType | null>(null);

  const onMeasurementFilterChange = useCallback(
    (filter: ChemicalType | null) => {
      setMeasurementFilter(filter);
    },
    [setMeasurementFilter],
  );

  return (
    <Card>
      <Flex>
        <Box width={2 / 3}>
          <Title level={3}>{t('Last Measurements')}</Title>
        </Box>
        <Flex width={1 / 3}>
          <FilterMeasurementsDropdown
            filter={measurementFilter}
            setFilter={onMeasurementFilterChange}
          />
        </Flex>
      </Flex>
      <Flex>
        <Box width={2 / 3} minHeight="250px" justifyContent="flex-end">
          <DashboardMeasurementChart filter={measurementFilter} />
        </Box>

        <Flex width={1 / 3} flexDirection="column" justifyContent="flex-end">
          <ChartLegend />
        </Flex>
      </Flex>
    </Card>
  );
};
