import { Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'reflexbox';
import { StatusType } from '~/components/apollo-components';
import { Hexagon } from '~/components/Hexagon';
import { styled } from '~/theme';
import { StatusColorMap } from '~/utils/EasyMonitoring/colorMaps';

const { Text } = Typography;

const StatusText = styled(Text)`
  margin-left: 0.5rem;
`;

interface IStatusLabelProps {
  status: StatusType;
}

export const StatusLabel: FunctionComponent<IStatusLabelProps> = ({
  status,
}: IStatusLabelProps) => {
  const { t } = useTranslation('EasyMonitoring');
  return (
    <Flex alignItems="center">
      <Hexagon color={StatusColorMap[status]} size={17} />
      <StatusText>{t(status)}</StatusText>
    </Flex>
  );
};
