import {
  INVALID_OLD_PASSWORD_MESSAGE,
  LOGIN_INVALID_CREDENTIALS_MESSAGE,
  REGISTER_MISMATCHED_PASSWORD_MESSAGE,
  USER_ALREADY_REGISTERED_MESSAGE,
  USER_ASSIGN_ADMIN_NOT_ALLOWED_MESSAGE,
  USER_DEACTIVATED_MESSAGE,
  VERIFY_TOKEN_INVALID_MESSAGE,
  USER_NOT_VERIFIED,
  COMPANY_ALREADY_CREATED_MESSAGE,
} from '@motorex/common';

export const common = {
  '{{field}} must contain a valid number':
    '{{field}} doit comprendre un chiffre valide',
  '{{rangeStart}}-{{rangeEnd}} of {{totalItems}} items':
    '{{rangeStart}}-{{rangeEnd}} de {{totalItems}} entrées',
  [COMPANY_ALREADY_CREATED_MESSAGE]:
    'Une entreprise avec ce domaine existe déjà',
  [USER_ALREADY_REGISTERED_MESSAGE]: 'Un utilisateur avec ce nom existe déjà',
  'A verification email has been sent to your inbox. Please verify your email using the link sent to you.':
    'Un courriel de confirmation a été envoyé à votre boîte de réception',
  'Add email here': 'Ajouter courriel',
  'Add first name here': 'Ajouter prénom',
  'Add job title here': 'Ajouter profession ',
  'Add last name here': 'Ajouter nom ',
  'Add new user': 'Ajouter utilisateur',
  'Add phone number here': 'Ajouter numéro de téléphone',
  Address: 'Adresse',
  Administrator: 'Administrateur',
  All: 'Tous',
  'An unexpected error occurred.': 'Une erreur est survenue',
  'At least one number is required': 'Doit comprendre au moins un chiffre',
  'At least one role must be specified': 'Doit comprendre au moins un rôle',
  Authorization: 'Autorisation',
  Authorize: 'Autorisé',
  Back: 'Retour ',
  'Back Home': "Retour à l'accueil",
  'Back to Overview': "Retour vers l'aperçu",
  'Before accounts are finally deleted, they are deactivated for 90 days. The account can be restored at the "Edit" page.':
    "Vous serez déactivé pendant 90 jours avant que l'utilisateur est définitivement effacer définitivement. Le compte peut être réactivé sous Edit.",
  Cancel: 'Annuler',
  'Cannot load licenses.': 'Charge de licences pas possible',
  'Change Password': 'Changer mot de passe',
  'Change Profile Image': 'Changer photo du profil',
  'Changes successfully saved.': 'Changements enregistrés',
  Close: 'Fermer',
  'Confirm password': 'Répétez mot de passe',
  'Confirm new password': 'Répétez nouveau mot de passe',
  'Confirm password is required':
    'Il est nécessaire de répéter le mot de passe',
  Contact: 'Personne de contact',
  'Could not fetch data. Please check your connection.':
    "Les données n'ont pu être contrôlées. Vérifiez votre connexion.",
  'Could not fetch user.': "Utilisateur n'a pas pu être téléchargé",
  'Creation failed': 'Création échouée',
  Customer: 'Client',
  Dashboard: 'Tableau de bord',
  'Date Created': 'Date de création ',
  'Date Deleted': "Date d'annulation",
  'Date Registered': "Date d'enregistrement",
  Delete: 'Effacer',
  'Delete account': 'Effacer utilisateur',
  'Discard Changes': 'Rejeter les changements',
  Edit: 'Éditer',
  'Edit Profile': 'Éditer profile',
  'Edit User': 'Éditer utilisateur',
  Email: 'Courriel ',
  'Email Address': 'Courriel',
  'Email was successfully sent.': 'Courriel envoyé avec succès ',
  Error: 'Erreur',
  'Error Fetching Data': 'Erreur de récupération des données',
  'Error fetching user data': 'Erreur de récupération des données utilisateur',
  'Error occurred': 'Erreur',
  FAQ: 'FAQ',
  'First Name': 'Prénom de la personne de contact',
  'Font Size': 'Taille des caractères',
  'Forgot password?': 'Oublier mot de passe?',
  'If you continue, the account for {{name}} will be reactivated.':
    'En continuant le compte de {{name}} est denouvau activé.',
  'If you continue, the account for {{name}} will first be deactivated for 90 days and then deleted.':
    'Après confirmation le compte de  {{name}} sera déactivé pendant 90 jours et après effacer',
  'Image must be smaller than 2MB.': 'Doit être inférieur à 2MB',
  Imprint: 'Mentions légales',
  Language: 'Langue',
  Large: 'Grand',
  'Last Name': 'Nom',
  Licenses: 'Licences',
  Login: 'Se connecter',
  'Login failed': 'Connexion échoué',
  'Logs for all Encoding Jobs': 'Protocoles de tous les jobs',
  [LOGIN_INVALID_CREDENTIALS_MESSAGE]: 'Données de connexion fausses!',
  Logout: 'Déconnecter',
  'Looks like the page you are looking for is not available.':
    "La page que vous cherchez n'existe pas",
  'Manage users': 'Administration utilisateurs',
  Medium: 'Moyen',
  'Network Error': 'Erreur réseau',
  'New password': 'Nouveau mot de passe',
  next: 'Suivant',
  No: 'Non',
  Number: 'Nombre',
  'Old password': 'Ancien mot de passe',
  Open: 'Ouvert',
  Password: 'Mot de passe',
  'Phone Number': 'Numéro de téléphone',
  'Please try again later.': 'Essayez plus tard',
  previous: 'Précédent',
  'Privacy Protection': 'Protection des données',
  'Profile Image': 'Portrait du profil',
  'Request new password': 'Demander un nouveau mot de passe',
  'Request password': 'Demande de mot de passe',
  'Resend register email': 'Envoyer à nouveau le courriel de confirmation',
  Restore: 'Rétablir',
  'Restore account': 'Rétablir le compte',
  'Role(s)': 'Rôle(s)',
  Save: 'Sauvegarder',
  'Save password': 'Sauvegarder mot de passe',
  'Save Password': 'Sauvegarder mot de passe',
  Search: 'Chercher',
  'Search...': 'Chercher …',
  'Set new password': 'Définir nouveau mot de passe',
  Settings: 'Paramètres',
  'Settings were successfully updated.': 'Paramètres sauvegarder avec succès',
  'Show profile': 'Montrer profil',
  'Sign out': 'Déconnecter',
  Small: 'Petit',
  'Sorry, something went wrong.': 'Désolé, il y a erreur',
  'Sorry, you are not authorized to access this page.':
    "Vopus n'êtes pas autorisé à accéder à cette page",
  'Terms and Conditions': 'Conditions générales',
  'The width of your screen is too small for this web application. Try to turn the device clockwise – this might help.':
    "La largeur de votre écran est trop faible pour cette application Web. Essayez de tourner l'appareil dans le sens des aiguilles d'une montre - cela pourrait vous aider.",
  'Unexpected error occurred.': 'Une erreur est survenue',
  'Update failed': 'Mise à jour échoué',
  'User deleted': 'Utilisateur effacé',
  'User restored': 'Utilisateur réactivé',
  'User successfully created.': 'Création utilisateur réussie',
  'User successfully marked as to delete.':
    'Utilisateur marqué pour être supprimé',
  'User successfully restored.': 'Utilisateur restauré',
  'Validation Error': 'Erreur de validation',
  'Validation error during data processing.':
    'Erreur de validation lors de traitements des données',
  View: 'Visionner',
  'Welcome!': 'Bienvenue!',
  [INVALID_OLD_PASSWORD_MESSAGE]: "L'ancien mot de passe est invalide",
  [REGISTER_MISMATCHED_PASSWORD_MESSAGE]:
    'Les mots de passe entrés ne correspondent pas',
  [USER_ASSIGN_ADMIN_NOT_ALLOWED_MESSAGE]:
    "Vous n'avez pas l'autorisation d'attribuer un rôle à un administrateur",
  Yes: 'Oui',
  'You are not authorized to perform this action.':
    "Vous n'êtes pas autorisé à effectuer cette action",
  'You can only upload JPG or PNG files.':
    'Vous ne pouvez télécharger que des fichiers JPG ou PNG',
  'You will receive an email with a link to reset your password.':
    'Vous recevrez un courriel avec un lien pour réinitialiser votre mot de passe',
  [USER_DEACTIVATED_MESSAGE]:
    'Votre compte est désactivé. Veuillez contacter votre administrateur pour le restaurer.',
  [USER_NOT_VERIFIED]:
    "Votre compte n'est pas vérifié. Veuillez vérifier dans votre boîte de réception si un courriel de vérification vous a été envoyé lors de votre inscription.",
  [VERIFY_TOKEN_INVALID_MESSAGE]: "votre identifiant n'est pas valable.",
  'Verify Email': 'contôlez courriel',
};
