import { Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { StatusType } from '~/components/apollo-components';
import { StatusLabel } from '~/components/EasyMonitoring/StatusLabel';
import { styled } from '~/theme';

const { Text } = Typography;

const ChartLegendItemWrapper = styled.div`
  :not(:last-of-type) {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 2px solid ${({ theme }) => theme.colors.grey.light};
  }
`;

const InfoText = styled(Text)`
  font-size: 10px;
`;

export interface IChartLegendItem {
  status: StatusType;
  infoText: string;
}

export const ChartLegendItem: FunctionComponent<IChartLegendItem> = ({
  status,
  infoText,
}: IChartLegendItem) => {
  return (
    <ChartLegendItemWrapper>
      <StatusLabel status={status} />
      <InfoText>{infoText}</InfoText>
    </ChartLegendItemWrapper>
  );
};
