import { Spin } from 'antd';
import React, { FunctionComponent } from 'react';
import { Flex } from 'reflexbox';

export const LoadingSpinner: FunctionComponent = () => {
  return (
    <Flex
      justifyContent="center"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      <Spin />
    </Flex>
  );
};
