import { ChemicalEnum } from '@motorex/common';
import { Typography } from 'antd';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox/styled-components';
import {
  MachineFragment,
  MeasurementFragment,
  MeasurementType,
  useDeleteMeasurementMutation,
  useMeasurementResultsLazyQuery,
  useMeasurementTimesQuery,
} from '~/components/apollo-components';
import { LoadingOverlay } from '~/components/LoadingOverlay';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { MeasurementDropdown } from '~/pages/EasyMonitoring/MachineDetail/MeasurementResultsCard/MeasurementDropdown';
import { MeasurementResultBar } from '~/pages/EasyMonitoring/MachineDetail/MeasurementResultsCard/MeasurementResultBar';
import { styled } from '~/theme';
import { createNotification } from '~/utils/createNotification';
import { DashboardCard, LinkStyleButton } from '~/utils/styles';
import { DeleteMeasurementModal } from './DeleteMeasurementModal';
import { ExtraEmulsionValue } from './ExtraEmulsionValue';

const { Title } = Typography;

const NavigationButton = styled(LinkStyleButton)`
  padding: 0;
`;

const CardContent = styled(Flex)`
  position: relative;
`;

interface IMeasurementResultsCard {
  machine?: MachineFragment;
  loading: boolean;
}

const isWaterMachine = (measurementType: MeasurementType) => {
  return measurementType === MeasurementType.WATER;
};

export const MeasurementResultsCard: FunctionComponent<IMeasurementResultsCard> = ({
  machine,
  loading,
}: IMeasurementResultsCard) => {
  const { t } = useTranslation('EasyMonitoring');
  const handleError = useErrorHandler();
  const [
    currentMeasurement,
    setCurrentMeasurement,
  ] = useState<MeasurementFragment | null>(null);

  const {
    data: dropdownData,
    loading: dropdownLoading,
    refetch: dropdownRefetch,
  } = useMeasurementTimesQuery({
    skip: machine?.id == null,
    variables: { machineId: machine?.id ?? '' },
  });

  const [modalOpen, setModalOpen] = useState(false);

  const [
    getMeasurement,
    { loading: loadingMeasurement, data, error },
  ] = useMeasurementResultsLazyQuery();

  const [deleteMeasurement] = useDeleteMeasurementMutation();

  const handlePreviousClick = useCallback(async () => {
    if (currentMeasurement?.previous) {
      getMeasurement({ variables: { id: currentMeasurement.previous.id } });
    }
  }, [currentMeasurement, getMeasurement]);

  const handleNextClick = useCallback(async () => {
    if (currentMeasurement?.next) {
      getMeasurement({ variables: { id: currentMeasurement.next.id } });
    }
  }, [currentMeasurement, getMeasurement]);

  const handleDeleteClick = useCallback(
    async (id: string) => {
      await deleteMeasurement({ variables: { id } });
      if (currentMeasurement?.previous) {
        handlePreviousClick();
      } else if (currentMeasurement?.next) {
        handleNextClick();
      } else {
        setCurrentMeasurement(null);
      }
      dropdownRefetch();
      setModalOpen(false);
      createNotification('success', t('common:Measurement deleted.'));
    },
    [
      deleteMeasurement,
      handlePreviousClick,
      handleNextClick,
      currentMeasurement,
      dropdownRefetch,
      t,
    ],
  );

  const handleDropdownSelect = useCallback(
    async (measurementId: string) => {
      getMeasurement({ variables: { id: measurementId } });
    },
    [getMeasurement],
  );

  useEffect(() => {
    if (!loadingMeasurement && data != null) {
      setCurrentMeasurement(data.measurement);
    } else if (!loading && machine?.measurements != null) {
      const measurement = machine.measurements.items[0];
      setCurrentMeasurement(measurement);
    }

    if (error) {
      handleError(error);
    }
  }, [
    error,
    handleError,
    data,
    loadingMeasurement,
    currentMeasurement,
    loading,
    machine,
  ]);

  return (
    <>
      <DashboardCard>
        <Flex flexDirection="column" minHeight="100%">
          <Flex justifyContent="space-between">
            <Title level={3}>{t('Measurement Results')}</Title>
            <MeasurementDropdown
              data={dropdownData}
              loading={dropdownLoading}
              currentMeasurement={currentMeasurement}
              handleDropdownSelect={handleDropdownSelect}
            />
          </Flex>
          <CardContent
            flexDirection="column"
            justifyContent="space-between"
            flex="1 0 auto"
          >
            {((loading && !machine) || loadingMeasurement) && (
              <LoadingOverlay />
            )}
            {machine != null && (
              <Box>
                {isWaterMachine(machine.measurementType) && (
                  <MeasurementResultBar
                    chemicalKey={ChemicalEnum.NITRATE}
                    chemicals={currentMeasurement?.chemicals}
                    measurementType={machine.measurementType}
                  />
                )}
                <MeasurementResultBar
                  chemicalKey={ChemicalEnum.NITRITE}
                  chemicals={currentMeasurement?.chemicals}
                  measurementType={machine.measurementType}
                />
                <MeasurementResultBar
                  unit="°"
                  chemicalKey={ChemicalEnum.HARDNESS}
                  chemicals={currentMeasurement?.chemicals}
                  measurementType={machine.measurementType}
                />
                {isWaterMachine(machine.measurementType) && (
                  <MeasurementResultBar
                    unit="°"
                    chemicalKey={ChemicalEnum.ALKALINITY}
                    chemicals={currentMeasurement?.chemicals}
                    measurementType={machine.measurementType}
                  />
                )}
                <MeasurementResultBar
                  chemicalKey={ChemicalEnum.PH}
                  chemicals={currentMeasurement?.chemicals}
                  measurementType={machine.measurementType}
                />
                {isWaterMachine(machine.measurementType) && (
                  <>
                    <MeasurementResultBar
                      chemicalKey={ChemicalEnum.CHLORINE}
                      chemicals={currentMeasurement?.chemicals}
                      measurementType={machine.measurementType}
                    />
                    <MeasurementResultBar
                      chemicalKey={ChemicalEnum.CO2}
                      chemicals={currentMeasurement?.chemicals}
                      measurementType={machine.measurementType}
                    />
                  </>
                )}
                <ExtraEmulsionValue
                  factor={machine.factor}
                  currentMeasurement={currentMeasurement}
                  chemical={ChemicalEnum.CONCENTRATION}
                  unit="%"
                />
                <ExtraEmulsionValue
                  currentMeasurement={currentMeasurement}
                  chemical={ChemicalEnum.TEMPERATURE}
                  unit="°C"
                />
              </Box>
            )}
            <Flex justifyContent="space-between">
              <NavigationButton
                type="link"
                onClick={handlePreviousClick}
                disabled={
                  loadingMeasurement || currentMeasurement?.previous == null
                }
              >
                {t('common:previous')}
              </NavigationButton>
              <LinkStyleButton
                type="link"
                onClick={() => {
                  if (currentMeasurement) setModalOpen(true);
                }}
              >
                {t('delete measurement')}
              </LinkStyleButton>
              <NavigationButton
                type="link"
                onClick={handleNextClick}
                disabled={
                  loadingMeasurement || currentMeasurement?.next == null
                }
              >
                {t('common:next')}
              </NavigationButton>
            </Flex>
          </CardContent>
        </Flex>
      </DashboardCard>
      {currentMeasurement && (
        <DeleteMeasurementModal
          onCancel={() => setModalOpen(false)}
          onOk={() => handleDeleteClick(currentMeasurement.id)}
          visible={modalOpen}
          name={currentMeasurement.createdAt}
        />
      )}
    </>
  );
};
