import React, { FunctionComponent } from 'react';
import { Box, Flex } from 'reflexbox';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '~/assets/logo.svg';
import Background from '~/assets/background.svg';
import { LowResolutionOverlay } from '~/components/LowResolutionOverlay';
import { styled } from '~/theme';
import { responsiveBlockerStyle } from '~/utils/styles';
import { Footer } from './Footer';
import { Link } from 'react-router-dom';
import { Routes } from '~/utils/Routes';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const { Text } = Typography;

interface IProps {
  children: React.ReactNode;
  showBackLink?: boolean | null;
}

const Container = styled.div`
  background: url(${Background}) no-repeat top center fixed;
  background-size: cover;

  ${responsiveBlockerStyle};
`;

const Wrapper = styled(Flex)`
  min-height: calc(100vh - 48px);
`;

export const AuthenticationLayout: FunctionComponent<IProps> = ({
  children,
  showBackLink,
}: IProps) => {
  const { t } = useTranslation('common');
  const { md } = useBreakpoint();
  if (!md) {
    return (
      <Container>
        <LowResolutionOverlay />
      </Container>
    );
  }
  return (
    <Container>
      <Wrapper
        flexDirection={{ _: 'column', xl: 'row' }}
        justifyContent={{ _: 'flex-start', xl: 'space-between' }}
        p={{ _: '50px 150px', xl: '200px 50px 100px 150px' }}
      >
        <Box mb={{ _: 5, xl: 0 }}>
          <Flex flexDirection="column">
            <Logo width={318} height={175} />
          </Flex>
        </Box>
        <Box mr="100px" width={{ _: 1, xl: 800 }}>
          {showBackLink && (
            <Box>
              <Link to={Routes.base}>
                <Text>{t('Back Home')}</Text>
              </Link>
            </Box>
          )}
          {children}
        </Box>
      </Wrapper>
      <Footer authenticationLayout={true} />
    </Container>
  );
};
