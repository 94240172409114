import { CaretDownOutlined } from '@ant-design/icons/lib';
import { Dropdown, Menu } from 'antd';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '~/components/apollo-components';
import { DarkButton } from '~/components/DarkButton';
import { setLanguage } from '~/i18n';

export interface ILanguageMenuProps {
  onLanguageChange?: (language: Language) => void;
}

export const LanguageMenu: FunctionComponent<ILanguageMenuProps> = ({
  onLanguageChange,
}: ILanguageMenuProps) => {
  const { t } = useTranslation();

  const onChangeLanguageClick = useCallback(
    async (language: Language) => {
      await setLanguage(language);

      if (onLanguageChange != null) {
        onLanguageChange(language);
      }
    },
    [onLanguageChange],
  );

  const languagesMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key={Language.DEDE}>
          <DarkButton
            onClick={() => onChangeLanguageClick(Language.DEDE)}
            type="link"
          >
            Deutsch
          </DarkButton>
        </Menu.Item>
        <Menu.Item key={Language.ENUS}>
          <DarkButton
            onClick={() => onChangeLanguageClick(Language.ENUS)}
            type="link"
          >
            English
          </DarkButton>
        </Menu.Item>
        <Menu.Item key={Language.FRFR}>
          <DarkButton
            onClick={() => onChangeLanguageClick(Language.FRFR)}
            type="link"
          >
            Français
          </DarkButton>
        </Menu.Item>
        <Menu.Item key={Language.ITIT}>
          <DarkButton
            onClick={() => onChangeLanguageClick(Language.ITIT)}
            type="link"
          >
            Italiano
          </DarkButton>
        </Menu.Item>
      </Menu>
    ),
    [onChangeLanguageClick],
  );

  return (
    <Dropdown overlay={languagesMenu} trigger={['click']}>
      <DarkButton type="link">
        {t('common:Language')} <CaretDownOutlined translate="no" />
      </DarkButton>
    </Dropdown>
  );
};
