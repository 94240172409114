import { auth } from '~/translations/de/auth';
import { common } from '~/translations/de/common';
import { EasyMonitoring } from '~/translations/de/EasyMonitoring';
import { Admin } from '~/translations/de/Admin';
import { faq } from '~/translations/de/faq';
import { staticPages } from '~/translations/de/staticPages';
import { privacyProtection } from '~/translations/de/privacyProtection';
import { termsAndConditions } from '~/translations/de/termsAndConditions';

export const de = {
  auth,
  common,
  faq,
  staticPages,
  EasyMonitoring,
  Admin,
  privacyProtection,
  termsAndConditions,
};
