import { Button, Card } from 'antd';
import { ColumnProps } from 'antd/es/table';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Flex } from 'reflexbox';
import {
  CollectionQueryOptions,
  DashboardMachineFragment,
  MeasurementType,
  SortArgs,
  SortOrder,
  StatusType,
  useDashboardMachinesQuery,
  useDeleteMachineMutation,
  UserInformationFragment,
} from '~/components/apollo-components';
import { ReactComponent as MachinesActive } from '~/assets/icons/machines_active.svg';
import { Table } from '~/components/DataTable';
import { ColoredMachineName } from '~/components/EasyMonitoring/ColoredMachineName';
import { StatusLabel } from '~/components/EasyMonitoring/StatusLabel';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { getCombinedUsername } from '~/utils/common';
import { formatFromISO } from '~/utils/dates';
import { Routes } from '~/utils/Routes';
import { DeleteOutlined } from '@ant-design/icons';
import { MachineDeleteModal } from './MachineDeleteModal';
import { useState } from 'react';
import { createNotification } from '~/utils/createNotification';

const initialSort: SortArgs = {
  field: 'updatedAt',
  order: SortOrder.DESC,
};

export const MachinesCard: FunctionComponent = () => {
  const { t } = useTranslation('EasyMonitoring');
  const handleError = useErrorHandler();
  const { data, loading, error, refetch } = useDashboardMachinesQuery({
    variables: { pagination: { currentPage: 1, itemsPerPage: 10 } },
  });
  const history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [
    machineToDelete,
    setMachineToDelete,
  ] = useState<DashboardMachineFragment | null>(null);

  const columns: ColumnProps<DashboardMachineFragment>[] = useMemo(
    () => [
      {
        dataIndex: 'machineName',
        key: 'machineName',
        render: (item: string, record: DashboardMachineFragment) => {
          return (
            <Link to={Routes.easyMonitoring.machine.single.base(record.id)}>
              <ColoredMachineName
                measurementType={record.measurementType}
                content={item}
              />
            </Link>
          );
        },
        sorter: true,
        title: t('Source'),
      },
      {
        dataIndex: 'productName',
        key: 'productName',
        sorter: true,
        title: t('Product'),
      },
      {
        dataIndex: 'measurementType',
        key: 'measurementType',
        render: (item: MeasurementType) => {
          return t(item);
        },
        sorter: true,
        title: t('Measurement Type'),
      },
      {
        dataIndex: 'updatedAt',
        defaultSortOrder: 'descend',
        key: 'updatedAt',
        render: (date: string) => formatFromISO(date),
        sorter: true,
        title: t('Last Measurement'),
      },
      {
        dataIndex: 'lastMeasurer',
        key: 'measuredBy',
        title: t('Measured by'),
        sorter: true,
        render: (item: UserInformationFragment | null) =>
          item != null ? getCombinedUsername(item) : '-',
      },
      {
        dataIndex: 'status',
        key: 'status',
        render: (item: StatusType, record: DashboardMachineFragment) => {
          return (
            <Flex alignItems="center" justifyContent="space-between">
              <StatusLabel status={item} />
              <Button
                danger
                type="text"
                shape="circle"
                icon={<DeleteOutlined translate={undefined} />}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteModalOpen(true);
                  setMachineToDelete(record);
                }}
              />
            </Flex>
          );
        },
        sorter: true,
        title: t('Status'),
      },
    ],
    [t],
  );

  const [deleteMachine] = useDeleteMachineMutation();

  const handleDeleteAction = async () => {
    if (machineToDelete != null) {
      await deleteMachine({ variables: { machineId: machineToDelete?.id } });
    }
    setIsDeleteModalOpen(false);
    createNotification('success', t('common:Machine successfully deleted.'));
    await refetch();
  };

  const onFetch = useCallback(
    async (options: CollectionQueryOptions) => {
      await refetch({
        ...options,
      });
    },
    [refetch],
  );

  const tableTitle = useMemo(() => {
    return (
      <Flex alignItems="center">
        {t('Source Details')}
        <MachinesActive />
      </Flex>
    );
  }, [t]);

  useEffect(() => {
    if (error) {
      handleError(error, t('Error Fetching Sources'), {});
    }
  }, [error, handleError, t]);

  return (
    <>
      <Card>
        <Table<DashboardMachineFragment>
          title={tableTitle}
          searchPlaceholder={t('Search Source')}
          loading={loading}
          columns={columns}
          data={data?.machines ?? { items: [], totalCount: 0 }}
          initialSorting={initialSort}
          refetch={onFetch}
          onRow={(record: DashboardMachineFragment) => ({
            onClick: () => {
              history.push('/easy-monitoring/source/' + record.id);
            },
          })}
        />
      </Card>
      <MachineDeleteModal
        visible={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onOk={() => handleDeleteAction()}
        name={machineToDelete?.machineName ?? ''}
      />
    </>
  );
};
