export const calculatePercentage = (
  count: number,
  total: number,
  precision = 1,
): number => {
  if (total === 0) {
    return 0;
  }

  const precisionCalc = precision > 0 ? precision * 10 : 1;
  return Math.round((count / total) * 100 * precisionCalc) / precisionCalc;
};
