import { ChemicalEnum } from '@motorex/common';
import { TFunction } from 'i18next';

export const getPlainLabelTextForChemical = (
  t: TFunction,
): Record<ChemicalEnum, string> => ({
  [ChemicalEnum.ALKALINITY]: t('Alkalinity (A)'),
  [ChemicalEnum.CHLORINE]: `${t('Chlorine')} mg/l`,
  [ChemicalEnum.CO2]: `${t('Carbon Dioxide')} (CO2) mg/l`,
  [ChemicalEnum.CONCENTRATION]: t('Concentration'),
  [ChemicalEnum.HARDNESS]: t('Hardness (H)'),
  [ChemicalEnum.NITRATE]: `${t('Nitrate')} (NO3) mg/l`,
  [ChemicalEnum.NITRITE]: `${t('Nitrite')} (NO2) mg/l`,
  [ChemicalEnum.PH]: t('pH-value'),
  [ChemicalEnum.TEMPERATURE]: t('temperature'),
});
