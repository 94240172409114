import { Button } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { styled } from '~/theme';
import { createNotification } from '~/utils/createNotification';
import { ButtonRow, Column, SplitInputRow } from '~/utils/styles';
import {
  CreateUserInput,
  useInitiatePasswordChangeMutation,
  UserInformationFragment,
} from '../apollo-components';
import { RoleSelection } from './components/RoleSelection';
import { validationSchema } from './validationSchema';

const BottomRow = styled(ButtonRow)`
  margin-top: 48px;
`;

const { Item } = Form;

const ErrorWrapper = styled.div`
  color: red;
  margin-top: 2px;
`;

const RoleSelectionColumn = styled(Column)`
  margin-top: 10px;
  margin-bottom: ${({ theme }) => theme.defaults.margin};

  > div:first-of-type {
    margin-top: 5px;
  }

  > div.ant-form-item-label > label > span {
    color: red;
  }
`;

const emptyUser: CreateUserInput = {
  email: '',
  firstName: '',
  jobTitle: null,
  lastName: '',
  phoneNumber: null,
  roles: [],
};

interface IProps {
  existingData?: UserInformationFragment;
  onSubmit: (values: CreateUserInput) => void;
  onCancel: () => void;
  loading: boolean;
}

export const UserForm: FunctionComponent<IProps> = ({
  existingData,
  onSubmit,
  onCancel,
  loading,
}: IProps) => {
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const valSchema = useMemo(() => validationSchema(t), [t]);

  const transformDataAndSubmit = useCallback(
    ({ jobTitle, phoneNumber, ...values }: CreateUserInput) => {
      // Set `jobTitle`, `phoneNumber` to null if they are empty strings
      onSubmit({
        ...values,
        jobTitle: jobTitle === '' ? null : jobTitle,
        phoneNumber: phoneNumber === '' ? null : phoneNumber,
      });
    },
    [onSubmit],
  );

  const [
    initiatePasswordChange,
    { loading: changePasswordLoading },
  ] = useInitiatePasswordChangeMutation();

  const onPasswordRequest = useCallback(async () => {
    if (!existingData) {
      return;
    }
    try {
      const response = await initiatePasswordChange({
        variables: {
          email: existingData.email,
        },
      });
      if (response && response.data && response.data.initiatePasswordChange) {
        createNotification(
          'success',
          t('Change Password'),
          t('Email was successfully sent.'),
        );
      }
    } catch (error) {
      handleError(error, t('Change Password'), {});
    }
  }, [t, initiatePasswordChange, existingData, handleError]);

  return (
    <Formik<CreateUserInput>
      onSubmit={transformDataAndSubmit}
      validationSchema={valSchema}
      initialValues={existingData || emptyUser}
    >
      {({
        errors,
        values,
        isValid,
        touched,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form layout="vertical">
          <SplitInputRow>
            <Item name="firstName" label={t('First Name')} required={true}>
              <Input name="firstName" placeholder={t('Add first name here')} />
            </Item>
            <Item name="lastName" label={t('Last Name')} required={true}>
              <Input name="lastName" placeholder={t('Add last name here')} />
            </Item>
          </SplitInputRow>
          <Item name="jobTitle" label={t('Job Title')}>
            <Input name="jobTitle" placeholder={t('Add job title here')} />
          </Item>
          <Item name="email" label={t('Email Address')} required={true}>
            <Input name="email" placeholder={t('Add email here')} />
          </Item>
          <Item name="phoneNumber" label={t('Phone Number')}>
            <Input
              name="phoneNumber"
              placeholder={t('Add phone number here')}
            />
          </Item>
          <RoleSelectionColumn>
            <div className="ant-form-item-label">
              <label>
                <span>*</span> {t('Role(s)')}
              </label>
            </div>
            <RoleSelection
              selectedRoles={values.roles}
              onUpdate={(roles) => {
                setFieldTouched('roles');
                setFieldValue('roles', roles);
              }}
            />
            {errors.roles && touched.roles && (
              <ErrorWrapper className="ant-form-explain">
                <span>{errors.roles}</span>
              </ErrorWrapper>
            )}
          </RoleSelectionColumn>
          {existingData && (
            <Flex justifyContent="space-between">
              {existingData.deletedAt == null && (
                <Button
                  type="default"
                  loading={changePasswordLoading}
                  onClick={onPasswordRequest}
                >
                  {t('Resend register email')}
                </Button>
              )}
              <Box />
            </Flex>
          )}
          <BottomRow>
            <Button disabled={loading} onClick={onCancel} type="default">
              {t('Cancel')}
            </Button>
            <SubmitButton loading={loading} disabled={!isValid}>
              {existingData ? t('Save') : t('Register')}
            </SubmitButton>
          </BottomRow>
        </Form>
      )}
    </Formik>
  );
};
