import React, { FunctionComponent } from 'react';
import { useMeQuery } from '~/components/apollo-components';
import { HexagonImage } from '~/components/Hexagon/HexagonImage';
import { ReactComponent as ProfileImagePlaceholder } from '~/assets/icons/profile.svg';
import { API, API_PATH } from '~/utils/API';

interface IProfileImageProps {
  width?: string;
  height?: string;
}

export const ProfileImage: FunctionComponent<IProfileImageProps> = ({
  width,
  height,
}: IProfileImageProps) => {
  const { data } = useMeQuery();

  return data?.me.photo ? (
    <HexagonImage
      background={`${API_PATH}${API.profile.photo.single(data.me.photo)}`}
      width={width}
      height={height}
    />
  ) : (
    <ProfileImagePlaceholder width="100%" height="100%" />
  );
};
