import React, { FunctionComponent, useMemo } from 'react';
import { useMeQuery } from '~/components/apollo-components';
import { LoadingPage } from '~/components/LoadingPage';
import { AuthenticationContext } from '~/contexts/AuthenticationContext';

interface IProps {
  children: React.ReactNode;
}

export const AuthenticationProvider: FunctionComponent<IProps> = ({
  children,
}: IProps) => {
  const { data, loading } = useMeQuery();

  const values = useMemo(() => {
    return {
      currentUser: data != null ? data.me : null,
    };
  }, [data]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <AuthenticationContext.Provider value={values}>
      {children}
    </AuthenticationContext.Provider>
  );
};
