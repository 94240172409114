import { Button, Typography } from 'antd';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';
import {
  CompanyInformationFragment,
  UpdateCompanyContactInput,
  useUpdateCompanyContactMutation,
} from '~/components/apollo-components';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { createNotification } from '~/utils/createNotification';
import { DashboardCard, PrimaryText, ButtonRow } from '~/utils/styles';
import { boxCard } from '~/theme/variables';
import { useFormik, FormikProvider } from 'formik';
import { validationSchema } from './validationSchema';
import { Form, Input, Select, SubmitButton } from 'formik-antd';

const { Title, Paragraph } = Typography;
const { Item } = Form;

const NormalButton = styled(Button)`
  font-weight: normal;
`;

const StyledParagraph = styled(Paragraph)`
  &.ant-typography {
    margin-bottom: 0.5rem;
  }
`;

interface IProps {
  data: CompanyInformationFragment;
}

export const ContactCard: FunctionComponent<IProps> = ({ data }: IProps) => {
  const { t } = useTranslation(['Admin', 'common']);
  const handleError = useErrorHandler();
  const [isEditing, toggleIsEditing] = useToggle(false);
  const [update, { loading: isUpdating }] = useUpdateCompanyContactMutation();
  const valSchema = useMemo(() => validationSchema(t), [t]);

  const onSubmit = useCallback(
    async (values: UpdateCompanyContactInput) => {
      try {
        await update({
          variables: { ...values },
        });

        createNotification('success', t('common:Changes successfully saved.'));
        toggleIsEditing();
      } catch (e) {
        if (e instanceof Error) {
          createNotification('error', t(`common:${e?.message}`));
        }

        handleError(e, t('common:Update failed'));
      }
    },
    [t, update, handleError, toggleIsEditing],
  );

  const formik = useFormik({
    initialValues: data,
    onSubmit: onSubmit,
    validationSchema: valSchema,
  });

  return (
    <>
      <FormikProvider value={formik}>
        <Box width={boxCard.width.half} pt={boxCard.padding.verticalLg}>
          <DashboardCard>
            <Flex flexDirection="column" height="100%">
              <Flex>
                <Box width={2 / 3}>
                  <Title level={3}>{t('Company Infos')}</Title>
                </Box>
                <Box width={1 / 3} textAlign="right">
                  <NormalButton
                    type="link"
                    onClick={() => toggleIsEditing()}
                    disabled={isEditing}
                  >
                    {t('common:Edit')}
                  </NormalButton>
                </Box>
              </Flex>
              <Flex>
                <Box width="100%">
                  {isEditing ? (
                    <Form layout="vertical">
                      <Item name="name" label={t('Company Name')}>
                        <Input name="name" />
                      </Item>
                      <Item name="customerNumber" label={t('Customer Number')}>
                        <Input name="customerNumber" />
                      </Item>
                      <Item name="address" label={t('Address')}>
                        <Input name="address" />
                      </Item>
                      <Item name="domains" label={t('Domains')}>
                        <Select name="domains" mode="tags" />
                      </Item>
                    </Form>
                  ) : (
                    <>
                      <StyledParagraph>
                        <PrimaryText>{t('Company Name')}</PrimaryText>
                      </StyledParagraph>
                      <Paragraph>{data.name}</Paragraph>
                      <StyledParagraph>
                        <PrimaryText>{t('Customer Number')}</PrimaryText>
                      </StyledParagraph>
                      <Paragraph>{data.customerNumber}</Paragraph>
                      <StyledParagraph>
                        <PrimaryText>{t('Address')}</PrimaryText>
                      </StyledParagraph>
                      <Paragraph>{data.address}</Paragraph>
                      <StyledParagraph>
                        <PrimaryText>{t('Domains')}</PrimaryText>
                      </StyledParagraph>
                      <Paragraph>
                        {data.domains != null ? data.domains.join(', ') : '...'}
                      </Paragraph>
                    </>
                  )}
                </Box>
              </Flex>
            </Flex>
          </DashboardCard>
        </Box>
        <Box
          width={boxCard.width.half}
          pl={boxCard.padding.horizontalLg}
          pt={boxCard.padding.verticalLg}
        >
          <DashboardCard>
            <Flex flexDirection="column" height="100%">
              <Flex>
                <Box width={2 / 3}>
                  <Title level={3}>{t('Contact')}</Title>
                </Box>
                <Box width={1 / 3} textAlign="right">
                  <NormalButton
                    type="link"
                    onClick={() => toggleIsEditing()}
                    disabled={isEditing}
                  >
                    {t('common:Edit')}
                  </NormalButton>
                </Box>
              </Flex>
              <Flex>
                <Box width="100%">
                  {isEditing ? (
                    <Form layout="vertical">
                      <Item
                        name="contactFirstName"
                        label={t('common:First Name')}
                      >
                        <Input name="contactFirstName" />
                      </Item>
                      <Item
                        name="contactLastName"
                        label={t('common:Last Name')}
                      >
                        <Input name="contactLastName" />
                      </Item>
                      <Item
                        name="contactEmail"
                        label={t('common:Email Address')}
                      >
                        <Input name="contactEmail" />
                      </Item>
                      <Item
                        name="contactPhoneNumber"
                        label={t('common:Phone Number')}
                      >
                        <Input name="contactPhoneNumber" />
                      </Item>
                      <ButtonRow>
                        <Button
                          disabled={isUpdating}
                          onClick={toggleIsEditing}
                          type="link"
                          size="large"
                        >
                          {t('Cancel')}
                        </Button>
                        <SubmitButton
                          loading={isUpdating}
                          disabled={!formik.isValid}
                          size="large"
                        >
                          {t('Save')}
                        </SubmitButton>
                      </ButtonRow>
                    </Form>
                  ) : (
                    <>
                      <StyledParagraph>
                        <PrimaryText>{t('common:First Name')}</PrimaryText>
                      </StyledParagraph>
                      <Paragraph>{data.contactFirstName}</Paragraph>
                      <StyledParagraph>
                        <PrimaryText>{t('common:Last Name')}</PrimaryText>
                      </StyledParagraph>
                      <Paragraph>{data.contactLastName}</Paragraph>
                      <StyledParagraph>
                        <PrimaryText>{t('common:Email Address')}</PrimaryText>
                      </StyledParagraph>
                      <Paragraph>{data.contactEmail}</Paragraph>
                      <StyledParagraph>
                        <PrimaryText>{t('common:Phone Number')}</PrimaryText>
                      </StyledParagraph>
                      <Paragraph>
                        {data.contactPhoneNumber != null &&
                        data.contactPhoneNumber !== ''
                          ? data.contactPhoneNumber
                          : '-'}
                      </Paragraph>
                    </>
                  )}
                </Box>
              </Flex>
            </Flex>
          </DashboardCard>
        </Box>
      </FormikProvider>
    </>
  );
};
