import { Descriptions } from 'antd';
import React, { FunctionComponent, useMemo } from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox';
import {
  MeasurementType,
  useMachineQuery,
} from '~/components/apollo-components';
import { useParams } from 'react-router-dom';
import { LoadingPage } from '~/components/LoadingPage';
import { styled } from '~/theme';
import { getCombinedUsername } from '~/utils/common';

const QrDescriptions = styled(Descriptions)`
  .ant-descriptions-item-label {
    font-weight: 600;
  }

  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    font-size: 7px;
    line-height: 7.6px;
  }
`;

const QR_CODE_DELIMITER = '/-/';

type UpdatedEmulsionQRCodeFormat = {
	customer: string
	machine: string
	product: string
	factor: string
}

type UpdatedWaterQRCodeFormat = {
	customer: string
	container: string
	product: string
}

export const QrCodeGeneration: FunctionComponent = () => {
  const { id } = useParams();
  const { t } = useTranslation('EasyMonitoring');
  const { data, loading } = useMachineQuery({ variables: { id } });

  const customerData = useMemo(() => {
    if (data != null) {
      const { company, user } = data.machine;
      if (company != null) {
        return company.customerNumber != null
          ? `${company.customerNumber} ${company.name}`
          : company.name;
      } else if (user != null) {
        return getCombinedUsername(user);
      }

      return null;
    }
  }, [data]);

  const qrCodeData = useMemo(() => {
    if (data != null) {
      if (data.machine.measurementType === MeasurementType.EMULSION) {
				return `${QR_CODE_DELIMITER}${JSON.stringify({
					customer: customerData,
					machine: data.machine.machineName,
					product: data.machine.productName,
					factor: data.machine.factor.toFixed(2)
				} as UpdatedEmulsionQRCodeFormat)}${QR_CODE_DELIMITER}`;
			} else {
				return `${QR_CODE_DELIMITER}${JSON.stringify({
					customer: customerData,
					container: data.machine.machineName,
					product: data.machine.productName,
				} as UpdatedWaterQRCodeFormat)}${QR_CODE_DELIMITER}`;
			}
    }

    return null;
  }, [customerData, data]);

  if (loading) {
    return <LoadingPage />;
  }

  if (data == null || qrCodeData == null) {
    return null;
  }

  return (
    <Flex
      width="200px"
      height="100vh"
      margin="0 auto"
      justifyContent="center"
      alignItems="center"
    >
      <Flex>
        <QRCode size={68} value={qrCodeData} level="M" />
        <Box marginLeft="4px">
          <QrDescriptions layout="vertical" column={1}>
            {customerData != null && (
              <Descriptions.Item label={t('Customer')}>
                {customerData}
              </Descriptions.Item>
            )}
            <Descriptions.Item label={t('Product')}>
              {data.machine.productName}
            </Descriptions.Item>
            <Descriptions.Item label={t('Source')}>
              {data.machine.machineName}‚
            </Descriptions.Item>
            <Descriptions.Item label={t('Measurement Type')}>
              {t(data.machine.measurementType)}
            </Descriptions.Item>
            {data.machine.measurementType === MeasurementType.EMULSION && (
              <Descriptions.Item label={t('Factor')}>
                {data.machine.factor}
              </Descriptions.Item>
            )}
          </QrDescriptions>
        </Box>
      </Flex>
    </Flex>
  );
};
