import { Typography, DatePicker } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import { Moment } from 'moment';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'reflexbox';

const { RangePicker } = DatePicker;
const { Title } = Typography;

interface IHistoryHeader {
  range?: RangeValue<Moment> | null;
  onDateSelect: (range: RangeValue<Moment>) => void;
}

export const HistoryHeader: FunctionComponent<IHistoryHeader> = ({
  range,
  onDateSelect,
}: IHistoryHeader) => {
  const { t } = useTranslation('EasyMonitoring');

  const handleChange = useCallback(
    (value: RangeValue<Moment>) => {
      onDateSelect(value);
    },
    [onDateSelect],
  );

  return (
    <Flex justifyContent="space-between">
      <Title level={3}>{t('Details')}</Title>
      <RangePicker
        format="DD.MM.YYYY"
        defaultValue={range}
        value={range}
        onChange={handleChange}
      />
    </Flex>
  );
};
