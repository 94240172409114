import { ApolloProvider } from '@apollo/client';
import React, { FunctionComponent, useRef } from 'react';
import { ThemeAndConfigProvider } from '~/providers/ThemeAndConfigProvider';
import { Router } from './components/Router';
import { GlobalStyle } from './GlobalStyle';
import { Motorex } from './Motorex';
import { AuthenticationProvider } from './providers/AuthenticationProvider';
import { BreadcrumbProvider } from './providers/BreadcrumbProvider';
import { createApolloClient } from './utils/ApolloClient';

export const App: FunctionComponent = () => {
  // Setup ApolloClient and WebSockets
  const client = useRef(createApolloClient());

  return (
    <ApolloProvider client={client.current}>
      <ThemeAndConfigProvider>
        <GlobalStyle />
        <Router>
          <AuthenticationProvider>
            <BreadcrumbProvider>
              <Motorex />
            </BreadcrumbProvider>
          </AuthenticationProvider>
        </Router>
      </ThemeAndConfigProvider>
    </ApolloProvider>
  );
};
