import React, { FunctionComponent } from 'react';
import { AuthenticatedRoute, Switch } from '~/components/Route';
import { Routes } from '~/utils/Routes';
import { Role } from '~/components/apollo-components';
import { CreateCompany } from './Companies/CreateCompany';
import { CompanyDetails } from './Companies/CompanyDetails';
import { CompanyList } from './Companies/CompanyList';
import { CreateUser } from './Users/CreateUser';
import { UpdateUser } from './Users/UpdateUser';
import { UserList } from './Users/UserList';

export const adminPaths = [
  Routes.admin.companies.base,
  Routes.admin.companies.create,
  Routes.admin.companies.details(':id'),
  Routes.admin.users.base,
  Routes.admin.users.create,
  Routes.admin.users.edit(':id'),
];

export const AdminRouter: FunctionComponent = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        path={Routes.admin.companies.create}
        component={CreateCompany}
        exact={true}
        neededRoles={[Role.ADMIN]}
      />
      <AuthenticatedRoute
        path={Routes.admin.companies.details(':id')}
        component={CompanyDetails}
        exact={true}
        neededRoles={[Role.ADMIN]}
      />
      <AuthenticatedRoute
        path={Routes.admin.companies.base}
        component={CompanyList}
        exact={true}
        neededRoles={[Role.ADMIN]}
      />
      <AuthenticatedRoute
        path={Routes.admin.users.create}
        component={CreateUser}
        exact={true}
        neededRoles={[Role.ADMIN]}
      />
      <AuthenticatedRoute
        path={Routes.admin.users.edit(':id')}
        component={UpdateUser}
        exact={true}
        neededRoles={[Role.ADMIN]}
      />
      <AuthenticatedRoute
        path={Routes.admin.users.base}
        component={UserList}
        exact={true}
        neededRoles={[Role.ADMIN]}
      />
    </Switch>
  );
};
