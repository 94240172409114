import { MeasurementType, StatusType } from '~/components/apollo-components';

export const dashboard = {
  Actuality: 'Aktualität',
  Average: 'Durchschnitt',
  [StatusType.CRITICAL]: 'kritisch',
  criticalChemicalsAtSource: 'Kritischer <2>{{chemicals}}</2> bei {{source}}',
  Decline: 'Verschlechterung',
  [MeasurementType.EMULSION]: 'Emulsion',
  'Error Fetching Sources': 'Fehler beim Laden der Quellen',
  'Error Fetching Last Measurements': 'Fehler beim Laden der letzten Messungen',
  'Error Fetching Overview Information':
    'Fehler beim Laden der Übersichtsinformationen',
  Factor: 'Faktor',
  'Last Measurement': 'Letzte Messung',
  'Last Measurements': 'Letzte Messungen',
  'Filter by measurement values': 'Nach Messwerten filtern',
  'Measured by': 'Gemessen durch',
  'Measurement Type': 'Messtyp',
  'Measurement Types Selection': 'Messtypen-Auswahl',
  [StatusType.NOTICEABLE]: 'auffällig',
  [StatusType.NORMAL]: 'normal',
  'of measured values in the standard range': 'der Messwerte im Normbereich',
  Overview: 'Überblick',
  Product: 'Produkt',
  'Search Source': 'Quelle suchen',
  Source: 'Quelle',
  'Source Details': 'Quellen im Detail',
  'sources tested in the last 7 days': 'Quellen in den letzten 7 Tagen geprüft',
  'Sources with all measured values in the standard range':
    'Quellen mit allen Messwerten im Normbereich',
  'Sources with at least one abnormal reading':
    'Quellen mit mind. einem auffälligen Messwert',
  'Sources with at least one critical reading':
    'Quellen mit mind. einem kritischen Messwert',
  'sources with negative trend': 'Quellen mit negativer Tendenz',
  Status: 'Status',
  'Warning {{current}}/{{total}}': 'Warnung {{current}}/{{total}}',
  [MeasurementType.WATER]: 'Wasser',
  'Delete source': 'Quelle löschen',
  'Delete measurement': 'Messung löschen',
  'delete source': 'Quelle löschen',
  'delete measurement': 'Messung löschen',
  'Are you sure you want to delete {{name}}?':
    'Sind Sie sicher, dass Sie die Quelle {{name}} löschen möchten? ',
  'Are you sure you want to delete the measurement from {{name}}?':
    'Sind Sie sicher, dass Sie die Messung von {{name}} löschen möchten?',
  'This will delete all associated measurements as well.':
    'Dadurch werden alle zugehörigen Messungen ebenfalls gelöscht.',
};
