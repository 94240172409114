import { TFunction } from 'i18next';
import { object, ref, string } from 'yup';

export const validationSchema = (t: TFunction): any =>
  object().shape({
    firstName: string().label(t('First Name')).required().min(2).max(32),
    lastName: string().label(t('Last Name')).required().min(2).max(32),
    email: string().label(t('Email')).email().required(),
    password: string().label(t('Password')).required().min(8),
    passwordConfirmation: string()
      .label(t('Password Confirmation'))
      .oneOf([ref('password')], t('Passwords do not match'))
      .required()
      .min(8),
  });
