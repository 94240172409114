import React, {
  FunctionComponent,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatFromISO } from '~/utils/dates';

import {
  MeasurementHistoryQuery,
  StatusType,
  MeasurementType,
} from '~/components/apollo-components';
import { compareAsc, parseISO } from 'date-fns';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
  Legend,
  Line,
  ResponsiveContainer,
  ReferenceArea,
} from 'recharts';
import { ChemicalEnum, OverallThresholds } from '@motorex/common';
import { styled } from '~/theme';
import { colors } from '~/theme/variables';
import { getColoredGraphSections } from '~/utils/EasyMonitoring/getColoredGraphSections';

const { Paragraph } = Typography;

const TooltipContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.light};
  padding: 7px 24px 7px 24px;
  border-radius: 2px;
  text-align: center;
  box-shadow: 0 -3px 4px #b0b0b0;
`;

const StyledParagraph = styled(Paragraph)`
  && {
    margin-bottom: 0;
  }
`;

interface IMeasurementHistoryGraph {
  initialData: MeasurementHistoryQuery | null;
  data: MeasurementHistoryQuery | null;
  isInitial: boolean;
  selectedChemical: ChemicalEnum;
  setSelectedChemical: (chemical: ChemicalEnum) => void;
}

type ChartValue = {
  date: string | null;
  value: number | null;
  status: number | null;
};

const tickCount = (selectedChemical: ChemicalEnum) => {
  if (
    OverallThresholds[selectedChemical.toLowerCase() as ChemicalEnum].max < 20
  ) {
    return 3;
  } else {
    return 2;
  }
};

export const MeasurementHistoryGraph: FunctionComponent<IMeasurementHistoryGraph> = ({
  initialData,
  data,
  isInitial,
  selectedChemical,
}: IMeasurementHistoryGraph) => {
  const { t } = useTranslation('EasyMonitoring');
  const [measurementType, setMeasurementType] = useState<MeasurementType>(
    MeasurementType.WATER,
  );
  const dateTranslation = t('Date');

  const chartData: ChartValue[] = useMemo(() => {
    const measurementData = isInitial ? initialData : data;

    if (measurementData) {
      setMeasurementType(measurementData.machine.measurementType);
    }

    if (
      measurementData &&
      measurementData.machine.measurements &&
      measurementData.machine.measurements.items
    ) {
      const items = [...measurementData.machine.measurements.items];

      if (isInitial) {
        items.sort((a, b) => {
          const dateA = parseISO(a.createdAt);
          const dateB = parseISO(b.createdAt);
          return compareAsc(dateA, dateB);
        });
      }

      const dataList = items.reduce((filtered: ChartValue[], option) => {
        const foundChemical = Object.entries(option.chemicals).find(
          ([key]) => key === selectedChemical,
        );

        if (foundChemical != null) {
          const currentValue: ChartValue = {
            value: foundChemical[1]
              ? foundChemical[1].key.toLowerCase() ===
                ChemicalEnum.CONCENTRATION
                ? foundChemical[1].measuredValue > -1
                  ? (
                      foundChemical[1].measuredValue *
                      measurementData.machine.factor
                    ).toFixed(2)
                  : null
                : foundChemical[1].key.toLowerCase() ===
                  ChemicalEnum.TEMPERATURE
                ? foundChemical[1].measuredValue > -1
                  ? foundChemical[1].measuredValue
                  : null
                : foundChemical[1].measuredValue
              : null,
            date: formatFromISO(option.createdAt, 'dd.MM.'),
            status: foundChemical[1] ? foundChemical[1].status : null,
          };

          filtered.push(currentValue);
        }

        return filtered;
      }, []);
      if (dataList.length > 20) {
        dataList.push({ date: null, value: null, status: null });
      }
      dataList.push({ date: null, value: null, status: null });

      return dataList;
    }
    return [];
  }, [initialData, isInitial, data, selectedChemical]);

  const yAxisDomain: { min: number; max: number } = useMemo(() => {
    const chemicalType = selectedChemical.toLowerCase() as ChemicalEnum;
    if (
      chemicalType === ChemicalEnum.CONCENTRATION ||
      chemicalType === ChemicalEnum.TEMPERATURE
    ) {
      const maximum = Math.ceil(
        Math.max(...chartData.map((c) => c.value ?? 0), 0),
      );
      const adaptedMax = maximum + (50 - (maximum % 50));
      return {
        min: 0,
        max: adaptedMax,
      };
    } else {
      return {
        min:
          OverallThresholds[selectedChemical.toLowerCase() as ChemicalEnum].min,
        max:
          OverallThresholds[selectedChemical.toLowerCase() as ChemicalEnum].max,
      };
    }
  }, [chartData, selectedChemical]);

  const renderTooltipContent = useCallback(
    ({ payload, active }: TooltipProps) => {
      if (!active || payload == null || payload.length !== 1) {
        return null;
      }
      const value = payload[0].payload.value;
      const status: StatusType = payload[0].payload.status as StatusType;
      return (
        <TooltipContentWrapper>
          <StyledParagraph>{value}</StyledParagraph>
          <StyledParagraph>{t(status)}</StyledParagraph>
        </TooltipContentWrapper>
      );
    },
    [t],
  );

  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        margin={{ top: 5, right: 15, left: 0, bottom: 5 }}
        data={chartData}
      >
        <XAxis
          dataKey="date"
          label={{
            value: dateTranslation,
            position: 'insideBottomRight',
            offset: 10,
          }}
          interval="preserveEnd"
          tickCount={10}
        />
        <YAxis
          domain={[yAxisDomain.min, yAxisDomain.max]}
          dataKey="value"
          type="number"
          interval="preserveStartEnd"
          tickCount={tickCount(selectedChemical)}
        />

        <Tooltip content={renderTooltipContent} cursor={false} />

        <Legend />

        {getColoredGraphSections(selectedChemical, measurementType).map(
          (elem, index) => {
            if (!elem.upperLimit && !elem.lowerLimit) {
              return null;
            }
            return (
              <ReferenceArea
                y1={elem.upperLimit ?? yAxisDomain.max}
                y2={elem.lowerLimit ?? yAxisDomain.min}
                fill={elem.color}
                fillOpacity={0.15}
                key={index}
              />
            );
          },
        )}

        <Line
          type="linear"
          dot={false}
          dataKey="value"
          stroke={colors.primary}
          legendType="none"
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
