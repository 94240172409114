import {
  INVALID_OLD_PASSWORD_MESSAGE,
  LOGIN_INVALID_CREDENTIALS_MESSAGE,
  REGISTER_MISMATCHED_PASSWORD_MESSAGE,
  USER_ALREADY_REGISTERED_MESSAGE,
  USER_ASSIGN_ADMIN_NOT_ALLOWED_MESSAGE,
  USER_DEACTIVATED_MESSAGE,
  VERIFY_TOKEN_INVALID_MESSAGE,
  USER_NOT_VERIFIED,
  COMPANY_ALREADY_CREATED_MESSAGE,
} from '@motorex/common';

export const common = {
  '{{field}} must contain a valid number':
    '{{field}} muss eine valide Zahl enthalten',
  '{{rangeStart}}-{{rangeEnd}} of {{totalItems}} items':
    '{{rangeStart}}-{{rangeEnd}} von {{totalItems}} Einträgen',
  [COMPANY_ALREADY_CREATED_MESSAGE]:
    'Ein Unternehmen mit der eingegebenen Domain existiert bereits.',
  [USER_ALREADY_REGISTERED_MESSAGE]:
    'Ein Nutzer mit der angegebenen Email existiert bereits.',
  'A verification email has been sent to your inbox. Please verify your email using the link sent to you.':
    'Eine Bestätigungs-E-Mail wurde an Ihren Posteingang gesendet. Bitte verifizieren Sie Ihre E-Mail über den Ihnen zugesandten Link.',
  'Add email here': 'Bitte E-Mail Adresse eingeben',
  'Add first name here': 'Bitte Vorname eingeben',
  'Add job title here': 'Bitte Berufsbezeichnung eingeben',
  'Add last name here': 'Bitte Nachname eingeben',
  'Add new user': 'Nutzer hinzufügen',
  'Add phone number here': 'Bitte Telefonnummer eingeben',
  Address: 'Adresse',
  Administrator: 'Administrator',
  All: 'Alle',
  'An unexpected error occurred.': 'Ein unerwarteter Fehler ist aufgetreten.',
  'At least one number is required': 'Es wird mindestens eine Zahl benötigt',
  'At least one role must be specified':
    'Es wird mindestens eine Rolle benötigt',
  Authorization: 'Autorisierung',
  Authorize: 'Autorisieren',
  Back: 'Zurück',
  'Back Home': 'Zurück zu Startseite',
  'Back to Overview': 'Zurück zur Übersicht',
  'Before accounts are finally deleted, they are deactivated for 90 days. The account can be restored at the "Edit" page.':
    'Bevor Nutzer komplett aus dem System gelöscht werden, sind sie für 90 Tage deaktiviert. Der Account kann auf der "Bearbeiten" Seite wiederhergestellt werden.',
  Cancel: 'Abbrechen',
  'Cannot load licenses.': 'Lizenzen können nicht geladen werden.',
  'Change Password': 'Passwort ändern',
  'Change Profile Image': 'Profilbild ändern',
  'Changes successfully saved.': 'Änderungen erfolgreich gespeichert.',
  Close: 'Schließen',
  'Confirm password': 'Passwort wiederholen',
  'Confirm new password': 'Neues Passwort wiederholen',
  'Confirm password is required': 'Passwort wiederholen wird benötigt',
  Contact: 'Kontakt',
  'Could not fetch data. Please check your connection.':
    'Es konnten keine Daten abgerufen werden. Bitte überprüfen Sie Ihre Verbindung.',
  'Could not fetch user.': 'Nutzer konnte nicht geladen werden.',
  'Creation failed': 'Erstellung fehlgeschlagen',
  Customer: 'Kunde',
  Dashboard: 'Dashboard',
  'Date Created': 'Erstellungsdatum',
  'Date Deleted': 'Löschdatum',
  'Date Registered': 'Registrierungsdatum',
  Delete: 'Löschen',
  'Delete account': 'Nutzer löschen',
  'Discard Changes': 'Änderungen verwerfen',
  Edit: 'Bearbeiten',
  'Edit Profile': 'Profil bearbeiten',
  'Edit User': 'Nutzer bearbeiten',
  Email: 'E-Mail-Adresse',
  'Email Address': 'E-Mail-Adresse',
  'Email was successfully sent.': 'E-Mail wurde erfolgreich versendet.',
  Error: 'Fehler',
  'Error Fetching Data': 'Fehler beim Abrufen der Daten',
  'Error fetching user data': 'Fehler beim Abrufen von Benutzerdaten',
  'Error occurred': 'Fehler aufgetreten',
  FAQ: 'FAQ',
  'First Name': 'Vorname',
  'Font Size': 'Schriftgröße',
  'Forgot password?': 'Passwort vergessen?',
  'If you continue, the account for {{name}} will be reactivated.':
    'Wenn Sie fortfahren, wird der Account von {{name}} erneut aktiviert.',
  'If you continue, the account for {{name}} will first be deactivated for 90 days and then deleted.':
    'Nach der Bestätigung wird der Account von {{name}} erst für 90 Tage deaktiviert und danach gelöscht.',
  'Image must be smaller than 2MB.': 'Bild muss kleiner als 2MB sein.',
  Imprint: 'Impressum',
  Language: 'Sprache',
  Large: 'Groß',
  'Last Name': 'Nachname',
  Licenses: 'Lizenzen',
  Login: 'Einloggen',
  'Login failed': 'Login fehlgeschlagen',
  'Logs for all Encoding Jobs': 'Logs aller Encoding Jobs',
  [LOGIN_INVALID_CREDENTIALS_MESSAGE]: 'Falsche Anmeldedaten!',
  Logout: 'Abmelden',
  'Looks like the page you are looking for is not available.':
    'Sieht so aus, als wäre die von Ihnen gesuchte Seite nicht vorhanden.',
  'Manage users': 'Nutzerverwaltung',
  Medium: 'Mittel',
  'Network Error': 'Netzwerk-Fehler',
  'New password': 'Neues Passwort',
  next: 'folgende',
  No: 'Nein',
  Number: 'Nummer',
  'Old password': 'Altes Passwort',
  Open: 'Offen',
  Password: 'Passwort',
  'Phone Number': 'Telefonnummer',
  'Please try again later.': 'Bitte versuchen Sie es später noch einmal.',
  previous: 'vorherige',
  'Privacy Protection': 'Datenschutz',
  'Profile Image': 'Profilbild',
  'Request new password': 'Neues Passwort anfragen',
  'Request password': 'Passwortanfrage',
  'Resend register email': 'Bestätigungsmail erneut senden',
  Restore: 'Wiederherstellen',
  'Restore account': 'Account wiederherstellen',
  'Role(s)': 'Rolle(n)',
  Save: 'Speichern',
  'Save password': 'Passwort speichern',
  'Save Password': 'Passwort speichern',
  Search: 'Suchen',
  'Search...': 'Suchen...',
  'Set new password': 'Neues Passwort vergeben',
  Settings: 'Einstellungen',
  'Settings were successfully updated.':
    'Einstellungen wurden erfolgreich gespeichert.',
  'Show profile': 'Profil anzeigen',
  'Sign out': 'Abmelden',
  Small: 'Klein',
  'Sorry, something went wrong.': 'Sorry, das Etwas ist schief gelaufen.',
  'Sorry, you are not authorized to access this page.':
    'Sie sind leider nicht berechtigt, auf diese Seite zuzugreifen.',
  'Terms and Conditions': 'AGB',
  'The width of your screen is too small for this web application. Try to turn the device clockwise – this might help.':
    'Die Breite Ihres Bildschirms ist zu klein für diese Web Anwendung. Drehen Sie das Gerät vertikal - das könnte helfen.',
  'Unexpected error occurred.': 'Unerwarteter Fehler aufgetreten.',
  'Update failed': 'Speichern fehlgeschlagen',
  'User deleted': 'Nutzer gelöscht',
  'User restored': 'Nutzer wiederhergestellt',
  'User successfully created.': 'Nutzer erfolgreich erstellt.',
  'User successfully marked as to delete.':
    'Nutzer erfolgreich zum Löschen freigegeben.',
  'User successfully restored.':
    'Der Nutzer wurde erfolgreich wiederhergestellt.',
  'Validation Error': 'Validierungsfehler',
  'Validation error during data processing.':
    'Validierungsfehler bei der Bearbeitung der Daten.',
  View: 'Ansehen',
  'Welcome!': 'Willkommen!',
  [INVALID_OLD_PASSWORD_MESSAGE]: 'Das alte Passwort ist ungültig.',
  [REGISTER_MISMATCHED_PASSWORD_MESSAGE]:
    'Die eingegebenen Passwörter stimmen nicht überein.',
  [USER_ASSIGN_ADMIN_NOT_ALLOWED_MESSAGE]:
    'Es ist nicht erlaubt, einem Admin eine weitere Rolle zuzuweisen',
  Yes: 'Ja',
  'You are not authorized to perform this action.':
    'Sie sind nicht berechtigt, diese Aktion durchzuführen.',
  'You can only upload JPG or PNG files.':
    'Sie können nur JPG- oder PNG-Dateien hochladen.',
  'You will receive an email with a link to reset your password.':
    'Sie werden in Kürze eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts erhalten.',
  [USER_DEACTIVATED_MESSAGE]:
    'Ihr Account ist deaktiviert. Bitte kontaktieren Sie ihren Administrator um ihn wiederherstellen zu lassen.',
  [USER_NOT_VERIFIED]:
    'Ihr Konto ist nicht verifiziert. Bitte überprüfen Sie Ihren Posteingang auf eine Verifizierungs-E-Mail, die bei der Registrierung gesendet wurde.',
  [VERIFY_TOKEN_INVALID_MESSAGE]: 'Ihr Verifizierungs-Token ist ungültig.',
  'Verify Email': 'Ihre E-Mail wurde erfolgreich verifiziert.',
};
