import React, { FunctionComponent } from 'react';
import { Switch as BaseSwitch } from 'react-router-dom';
import { NotFound } from '../Error';
import { Route } from './Route';

interface IProps {
  children: React.ReactNode;
}

export const Switch: FunctionComponent<IProps> = ({ children }: IProps) => {
  return (
    <BaseSwitch>
      {children}
      <Route component={NotFound} />
    </BaseSwitch>
  );
};
