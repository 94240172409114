import React, { FunctionComponent, useMemo } from 'react';
import { Header } from '~/components/Header';
import { IMenuItem } from '~/components/Header/AppNavigation';
import { Routes } from '~/utils/Routes';
import { NotificationDropdown } from './NotificationDropdown';
import { ISecondaryHeaderProps } from './SecondaryHeader';

interface IUserHeaderProps {
  secondaryHeaderOptions?: ISecondaryHeaderProps;
}

export const UserHeader: FunctionComponent<IUserHeaderProps> = ({
  secondaryHeaderOptions,
}: IUserHeaderProps) => {
  const menuItems: IMenuItem[] = useMemo(() => {
    const menus: IMenuItem[] = [];

    menus.push({
      key: 'easy-monitoring',
      route: Routes.easyMonitoring.base,
      label: 'Easy Monitoring',
    });

    return menus;
  }, []);

  return (
    <Header
      menuItems={menuItems}
      secondaryHeaderOptions={secondaryHeaderOptions}
    >
      <NotificationDropdown />
    </Header>
  );
};
