import React, { FunctionComponent, ReactNode } from 'react';
import { ISecondaryHeaderProps } from '~/components/Header/SecondaryHeader';
import { LowResolutionOverlay } from '~/components/LowResolutionOverlay';
import { MainContent } from '~/components/MainContent';
import { ResponsiveLayout } from '~/utils/styles';
import { AdminHeader } from './Header/AdminHeader';
import { Footer } from './Footer';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

interface IAdminLayoutProps {
  children: ReactNode;
  secondaryHeaderOptions?: ISecondaryHeaderProps;
}

export const AdminLayout: FunctionComponent<IAdminLayoutProps> = ({
  children,
  secondaryHeaderOptions,
}: IAdminLayoutProps) => {
  const { md } = useBreakpoint();
  if (!md) {
    return (
      <ResponsiveLayout>
        <LowResolutionOverlay />
      </ResponsiveLayout>
    );
  }
  return (
    <ResponsiveLayout>
      <AdminHeader secondaryHeaderOptions={secondaryHeaderOptions} />
      <MainContent secondaryHeaderEnabled={secondaryHeaderOptions != null}>
        {children}
      </MainContent>
      <Footer />
    </ResponsiveLayout>
  );
};
