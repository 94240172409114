import { createContext } from 'react';
import { noop } from '~/utils/common';

export interface IBreadcrumb {
  title: string;
  path: string;
}

export interface IBreadcrumbContext {
  addBreadcrumb: (
    item: IBreadcrumb,
    isTopLevelRenderFinished?: boolean,
  ) => () => void;
  items: IBreadcrumb[];
}

export const BreadcrumbContext = createContext<IBreadcrumbContext>({
  addBreadcrumb: () => noop,
  items: [],
});
