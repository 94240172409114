import { TFunction } from 'i18next';
import { object, ref, string } from 'yup';

export const validationSchema = (t: TFunction): any =>
  object().shape({
    newPassword: string().label(t('New password')).min(8).required(),
    newPasswordConfirmation: string()
      .label(t('Confirm password'))
      .oneOf([ref('newPassword')], t('auth:Passwords do not match'))
      .required(),
    oldPassword: string().label(t('Old password')).required(),
  });
