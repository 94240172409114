export const faq = {
  'Can Easy Monitoring replace laboratory measurements?':
    'Easy Monitoring peut-il remplacer les mesures de laboratoire ?',
  'Can I change limit values individually?':
    'Puis-je modifier les valeurs limites individuellement ?',
  'Currently, these changes are not yet customizable, but will be possible in a next release.':
    'Actuellement, ces changements ne sont pas encore personnalisables, mais seront possibles dans une prochaine version.',
  'For normal operation and TRGS 611 measurements (Technical Guideline for Hazardous Substances in Germany) the Easy Monitoring data is sufficient.':
    'Les données Easy Monitoring sont suffisantes pour le fonctionnement normal et les mesures TRGS 611 (directive technique pour les substances dangereuses en Allemagne)',
  'How accurate are the measurements using sticks?':
    "Quelle est la précision des mesures effectuées à l'aide de bâtons ?",
  'How can I generate a QR code?': 'Comment puis-je générer un code QR ?',
  'How long is the data stored on the web platform?':
    'Quelle est la durée de conservation des données sur la plate-forme web ?',
  'If no interferences are present, the measurements are almost identical to the laboratory values. (The water measurements as well as the emulsion measurements represent the free ions in the respective liquid. These can change the display value due to metal ions, which are released in copper or magnesium, for example).':
    "Si aucune interférence n'est présente, les mesures sont presque identiques aux valeurs de laboratoire. (Les mesures de l'eau ainsi que les mesures de l'émulsion représentent les ions libres dans le liquide respectif. Ceux-ci peuvent modifier la valeur affichée en raison des ions métalliques, qui sont libérés dans le cuivre ou le magnésium, par exemple).",
  'Laboratory tests are usually more extensive, since the same biological activities are also recorded. However, laboratory measurements are only taken if there are really compelling reasons. In normal KSS operation, the Easy Monitoring data are sufficient.':
    "Les tests de laboratoire sont généralement plus poussés, puisque les mêmes activités biologiques sont également enregistrées. Toutefois, les mesures de laboratoire ne sont prises que si des motifs impérieux le justifient. Lors fonctionnement normal du liquide réfrigérant, les données de l'Easy Monitoring sont suffisantes.",
  'Naming of the machine/tank/container and the product/medium used. Then click on "Generate QR Code" and print it out on an adhesive label. From this point on, the respective QR code can be generated directly via sources in the app\'s selection menu.':
    'Désignation de la machine/citerne/conteneur et du produit/moyen utilisé. Cliquez ensuite sur "Générer le code QR" et imprimez-le sur une étiquette adhésive. Par la suite, le code QR correspondant peut être généré directement par les sources dans le menu de sélection de l\'application.',
  'Questions?': 'questions?',
  'The complete history of the entered data can be displayed and checked. In addition, it enables the display of liquid-relevant data for several users at the same time.':
    "L'historique complet des données saisies peut être affiché et vérifié. Il permet aussi l'affichage des données relatives aux liquides pour plusieurs utilisateurs en même temps.",
  'The data is stored for at least 10 years, but is only available on request for this period.':
    'Les données sont conservées pendant au moins 10 ans, mais ne sont fournies que sur demande pour cette période.',
  'The history of the entered data per machine/tank/container, with limitation to the corresponding period. All values displayed on the color sticks with corresponding limit ranges.':
    "L'historique des données saisies par machine/citerne/conteneur, avec limitation à la période correspondante. Toutes les valeurs affichées sur les bâtonnets de couleur avec les plages limites correspondantes.",
  'The scan process with QR Code enables the clear identification of the "machine/tank" as well as the allocation of the corresponding product for recurring measurements.':
    'Le processus de balayage avec le code QR permet l\'identification précise de la "machine/réservoir" ainsi que l\'attribution du produit correspondant pour les mesures récurrentes.',
  'These can be ordered directly from us via the MOTOREX homepage. Access can be obtained by means of a QR scan on the respective "MWF" or "WATER" tube.':
    'Vous pouvez les commander directement par notre site Internet MOTOREX. L\'accès peut être obtenu au moyen d\'un QR scan sur le tube "MWF" ou "WATER" correspondant.',
  'We have already compiled the answers to the most frequently asked questions for you.':
    'Nous avons déjà regroupé les réponses aux questions les plus fréquentes.',
  'What can be simplified with QR Code?':
    "Qu'est-ce qui peut être simplifié avec le code QR ?",
  'What can I see in the course of the data?':
    'Que puis-je voir au cours des données ?',
  'What is the web platform for?': 'À quoi sert la plate-forme web ?',
  'Where do I get the test strips?':
    'Où puis-je me procurer les bâtonnets de test ?',
  'How can I delete my account for Easy Monitoring?':
    'Comment puis-je supprimer mon compte Easy Monitoring ?',
  'Please contact ':
    'Veuillez contacter ',
};
