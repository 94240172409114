import { VERIFY_TOKEN_INVALID_MESSAGE } from '@motorex/common';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  VerifyEmailMutationVariables,
  useVerifyEmailMutation,
} from '~/components/apollo-components';
import { LoadingPage } from '~/components/LoadingPage';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { createNotification } from '~/utils/createNotification';
import { Routes } from '~/utils/Routes';

interface IRouteParams {
  token: string;
}

export const VerifyEmail: FunctionComponent<RouteComponentProps<
  IRouteParams
>> = ({ match }: RouteComponentProps<IRouteParams>) => {
  const { t } = useTranslation();
  const [verifyEmail] = useVerifyEmailMutation();
  const history = useHistory();
  const handleError = useErrorHandler();

  const processVerification = useCallback(async () => {
    try {
      const payload: VerifyEmailMutationVariables = {
        token: match.params.token,
      };
      const response = await verifyEmail({ variables: payload });
      if (response?.data?.verifyEmail) {
        createNotification(
          'success',
          t('Verify Email'),
          t('Your email was successfully verified.'),
        );
      } else {
        createNotification(
          'error',
          t('Verify Email'),
          t(VERIFY_TOKEN_INVALID_MESSAGE),
        );
      }
    } catch (error) {
      handleError(error, t('Verify Email'), {
        [VERIFY_TOKEN_INVALID_MESSAGE]: t(VERIFY_TOKEN_INVALID_MESSAGE),
      });
    } finally {
      history.push(Routes.auth.login.base);
    }
  }, [handleError, history, t, match, verifyEmail]);

  useEffect(() => {
    processVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingPage />;
};
