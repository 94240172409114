import { ApolloQueryResult } from '@apollo/client';
import { USER_ALREADY_REGISTERED_MESSAGE } from '@motorex/common';
import { Divider, Typography } from 'antd';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';
import { ReactComponent as LeftArrow } from '~/assets/icons/arrow_left.svg';
import {
  MeQuery,
  MeasurementType,
  UpdateUserInput,
  useUpdateUserMutation,
  UserInformationFragment,
} from '~/components/apollo-components';
import { ProfileForm } from '~/components/ProfileForm';
import { ProfileImage } from '~/components/ProfileImage';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { createNotification } from '~/utils/createNotification';
import { Routes } from '~/utils/Routes';
import { DarkLink, DisabledText, SmallButton } from '~/utils/styles';

const { Text } = Typography;

const LinkText = styled(Text)`
  margin-left: 1rem;
`;

const AlignedArrow = styled(LeftArrow)`
  vertical-align: text-bottom;
`;

interface IProps {
  data: UserInformationFragment;
  refetch: () => Promise<ApolloQueryResult<MeQuery>>;
  toggleIsEditing: (nextValue?: any) => void;
}
export const EditProfile: FunctionComponent<IProps> = ({
  data,
  refetch,
  toggleIsEditing,
}: IProps) => {
  const { t } = useTranslation(['EasyMonitoring', 'Admin', 'common']);
  const handleError = useErrorHandler();
  const [updateUser, { loading: isUpdatingUser }] = useUpdateUserMutation();

  const onSubmit = useCallback(
    async (values: UpdateUserInput) => {
      try {
        await updateUser({
          variables: { ...values },
        });

        createNotification('success', t('common:Changes successfully saved.'));
        await refetch();
        toggleIsEditing();
      } catch (e) {
        handleError(e, t('common:Update failed'), {
          [USER_ALREADY_REGISTERED_MESSAGE]: t(USER_ALREADY_REGISTERED_MESSAGE),
        });
      }
    },
    [handleError, refetch, t, toggleIsEditing, updateUser],
  );

  const formData = useMemo(() => {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
    };
  }, [data]);

  return (
    <>
      <Flex>
        <Box width={1 / 3}>
          <DarkLink to={Routes.base}>
            <AlignedArrow />
            <LinkText>{t('common:Back')}</LinkText>
          </DarkLink>
        </Box>
        <Box width={1 / 3} textAlign="center">
          <Box width="104px" height="104px">
            <ProfileImage />
          </Box>
        </Box>
        <Box width={1 / 3} textAlign="right">
          <SmallButton
            size="small"
            type="link"
            onClick={toggleIsEditing}
            disabled={true}
          >
            {t('common:Change Profile Image')}
          </SmallButton>
        </Box>
      </Flex>
      <Divider />
      <ProfileForm
        data={formData}
        companyName={data.company?.name ?? '-'}
        loading={isUpdatingUser}
        onSubmit={onSubmit}
        onCancel={() => toggleIsEditing()}
      />
      <Divider />
      <DisabledText>{t('Measurement Types Selection')}</DisabledText>
      <Flex my={3}>
        <DisabledText>{t(MeasurementType.WATER)}</DisabledText>
      </Flex>
      <Flex mt={3}>
        <DisabledText>{t(MeasurementType.EMULSION)}</DisabledText>
      </Flex>
      <Divider />
      <SmallButton size="small" type="link" disabled={true}>
        {t('common:Change Password')}
      </SmallButton>
    </>
  );
};
