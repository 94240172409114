import { ColumnProps, TableProps } from 'antd/lib/table';
import { TFunction } from 'i18next';
import React, { ReactNode } from 'react';
import {
  CollectionQueryOptions,
  FilterArgs,
  SortArgs,
} from '../apollo-components';
import { DataTable, ITableData } from './DataTable';
import { TableSettingsProvider } from './TableSettingsProvider';

export type ColumnCreator<T> = (t: TFunction) => ColumnProps<T>[];

interface IProps<T> {
  id?: string;
  columns: ColumnProps<T>[];
  data: ITableData<T>;
  loading: boolean;
  title?: ReactNode | string;
  titleLevel?: 1 | 2 | 3 | 4 | 5;
  maxWidth?: number;
  additionalActions?: React.ReactNode;
  searchDisabled?: boolean;
  searchPlaceholder?: string;
  initialFilter?: FilterArgs;
  initialSorting?: SortArgs;
  refetch: (fetchOptions: CollectionQueryOptions) => void;
}

type ICombinedProps<T> = Omit<TableProps<T>, keyof IProps<T>> & IProps<T>;

// eslint-disable-next-line @typescript-eslint/ban-types
export function Table<T extends object>({
  refetch,
  initialFilter,
  initialSorting,
  ...restProps
}: ICombinedProps<T>): any {
  return (
    <TableSettingsProvider
      refetch={refetch}
      initialFilter={initialFilter}
      initialSorting={initialSorting}
    >
      <DataTable {...restProps} />
    </TableSettingsProvider>
  );
}
