export const companies = {
  'Add another domain': 'Weitere Domain hinzufügen',
  'Add company': 'Unternehmen hinzufügen',
  'Add company address here': 'Adresse des Unternehmens hier hinzufügen',
  'Add company name here': 'Name des Unternehmens hier hinzufügen',
  'Add contact email address here':
    'E-Mail des Ansprechpartner hier hinzufügen',
  'Add contact phone number here':
    'Telefonnummer des Ansprechpartners hier hinzufügen',
  'Add contact first name here': 'Vorname der Ansprechpartner hier hinzufügen',
  'Add contact last name here': 'Nachname der Ansprechpartner hier hinzufügen',
  'Add domain here': 'Domain hier hinzufügen',
  Address: 'Adresse',
  'Back to Overview': 'Zurück zur Übersicht',
  'Companies Overview': 'Übersicht Unternehmen',
  Company: 'Unternehmen',
  'Company Address': 'Adresse des Unternehmens',
  'Company Name': 'Name des Unternehmens',
  'Company successfully created.': 'Unternehmen erfolgreich erstellt.',
  Contact: 'Ansprechpartner',
  'Contact Email Address': 'E-Mail des Ansprechpartner',
  'Contact First Name': 'Vorname der Ansprechpartner',
  'Contact Last Name': 'Nachname der Ansprechpartner',
  'Contact Phone Number': 'Telefonnummer des Ansprechpartners',
  'Customer No.': 'Kundenummer',
  'Domain must be a valid domain and start with an @':
    'Domain muss eine gültige Domain enthalten und mit einem @ beginnen',
  Domains: 'Domains',
  Employees: 'Mitarbeiter',
  'Error fetching company data': 'Fehler beim Abrufen von Firmendaten',
  'Remove this domain': 'Diese Domain entfernen',
  'Users Overview': 'Übersicht Nutzer',
  'Company Infos': 'Firmeninformationen',
  'Customer Number': 'Kundennummer',
  'Add new user': 'Neuen Nutzer hinzufügen',
  'Add User': 'Nutzer hinzufügen',
  'Search to Select': 'Suchen und auswählen',
  'Delete Company': 'Unternehmen löschen',
  'Are you sure you want to delete this company?':
    'Sind Sie sicher, dass Sie dieses Unternehmen löschen möchten?',
  Remove: 'Entfernen',
  'Delete User': 'Nutzer löschen',
  'Are you sure you want to remove {{email}}?':
    'Sind Sie sicher, dass Sie {{email}} entfernen möchten?',
  'A user with this Email is already registered.':
    'Ein Nutzer mit dieser E-Mail ist bereits registriert.',
};
