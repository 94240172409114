import { Button, Modal } from 'antd';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { validationSchema } from './validationSchema';
import { Maybe, UserInformationFragment } from '~/components/apollo-components';

const { Item } = Form;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onOk: (
    id: string,
    data: {
      email: string;
      firstName: string;
      lastName: string;
      phoneNumber: Maybe<string> | undefined;
    },
  ) => Promise<void>;
  data: UserInformationFragment;
}

export const UpdateUserModal: FC<IProps> = ({
  isOpen,
  onClose,
  onOk,
  data,
}: IProps) => {
  const { t } = useTranslation(['Admin', 'common']);
  const valSchema = useMemo(() => validationSchema(t), [t]);
  const { email, firstName, lastName, phoneNumber, id } = data;

  const formik = useFormik({
    initialValues: { email, firstName, lastName, phoneNumber },
    onSubmit: (values) => onOk(id, values),
    validationSchema: valSchema,
  });
  return (
    <>
      <Modal
        visible={isOpen}
        // never used
        onOk={onClose}
        onCancel={onClose}
        title={t('Update User')}
        footer={null}
      >
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <Item name="email" label={t('common:Email')}>
              <Input name="email" />
            </Item>
            <Item name="firstName" label={t('common:First Name')}>
              <Input name="firstName" />
            </Item>
            <Item name="lastName" label={t('common:Last Name')}>
              <Input name="lastName" />
            </Item>
            <Item name="phoneNumber" label={t('common:Phone Number')}>
              <Input name="phoneNumber" />
            </Item>
            <SubmitButton style={{ marginRight: '8px' }}>
              {t('common:Save')}
            </SubmitButton>
            <Button onClick={onClose}>{t('Cancel')}</Button>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
};
