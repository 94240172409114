import { Modal, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { UserInformationFragment } from '~/components/apollo-components';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (userId: string) => void;
  user: UserInformationFragment;
}

export const UserDeleteModal: FunctionComponent<IProps> = ({
  visible,
  onCancel,
  onOk,
  user,
}: IProps) => {
  const { t } = useTranslation(['Admin', 'common']);
  return (
    <Modal
      visible={visible}
      onOk={() => onOk(user.id)}
      onCancel={onCancel}
      title={t('Delete User')}
    >
      <Typography>
        {t('Are you sure you want to remove {{email}}?', {
          email: `${user.email}`,
        })}
      </Typography>
    </Modal>
  );
};
