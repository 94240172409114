import { useContext, useEffect, useMemo } from 'react';
import { BreadcrumbContext, IBreadcrumb } from '~/contexts/BreadcrumbContext';

export function useAddBreadcrumb(
  { path, title }: IBreadcrumb,
  isTopLevelRenderFinished = false,
): any {
  const { addBreadcrumb } = useContext(BreadcrumbContext);
  const breadcrumb = useMemo(() => ({ path, title }), [path, title]);
  useEffect(() => {
    const rm = addBreadcrumb(breadcrumb, isTopLevelRenderFinished);
    return () => {
      return rm();
    };
  }, [addBreadcrumb, breadcrumb, isTopLevelRenderFinished]);
}
