import React, { FunctionComponent, useEffect } from 'react';
import { Route as BaseRoute, RouteProps } from 'react-router-dom';

export const Route: FunctionComponent<RouteProps> = ({
  path,
  ...props
}: RouteProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);

  return <BaseRoute path={path} {...props} />;
};
