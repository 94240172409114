export const staticPages = {
  Contact: 'Kontakt',
  FAQ: 'FAQ',
  Imprint: 'Impressum',
  Licenses: 'Lizenzen',
  'Privacy Protection': 'Datenschutz',
  'Privacy Policy': 'Datenschutz',
  'Terms and Conditions': 'AGB',
  'If you have any other questions, please contact us directly via one of the following channels':
    'Bei allen anderen Fragen kontaktieren Sie uns gern direkt über  einen der folgenden Kanäle',
  Phone: 'Telefon',
  Telephone: 'Telefon',
  Fax: 'Telefax',
  'E-Mail': 'E-Mail',
  'Company name': 'Firmenname',
  'Contact information': 'Kontaktinformationen',
  'Authorized person': 'Vertretungsberechtigte Person',
  'Entry in the commercial register': 'Handelsregistereintrag',
  'VAT-Number': 'Mehrwertsteuernummer',
  'Responsible for content': 'Verantwortlich für die Inhalte',
  Copyright: 'Urheberrecht',
  'Please read the relevant paragraph in our privacy policy.':
    'Bitte lesen Sie den entsprechenden Absatz in unseren Datenschutz-Bestimmungen.',
};
