import React, { FunctionComponent } from 'react';
import { useToggle } from 'react-use';
import { useMeQuery, Role } from '~/components/apollo-components';
import { AdminLayout } from '~/components/AdminLayout';
import { Content } from '~/components/Content';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { UserLayout } from '~/components/UserLayout';
import { useAuthentication } from '~/hooks/useAuthentication';
import { isGranted } from '~/utils/common';
import { EditProfile } from './EditProfile';
import { ViewProfile } from './ViewProfile';

export const Profile: FunctionComponent = () => {
  const { currentUser } = useAuthentication();
  const [isEditing, toggleIsEditing] = useToggle(false);
  const { data, loading, refetch } = useMeQuery();
  const LayoutComponent = isGranted(currentUser, Role.ADMIN)
    ? AdminLayout
    : UserLayout;

  return (
    <LayoutComponent>
      <Content small={true}>
        {loading || data == null ? (
          <LoadingSpinner />
        ) : isEditing ? (
          <EditProfile
            data={data.me}
            refetch={refetch}
            toggleIsEditing={toggleIsEditing}
          />
        ) : (
          <ViewProfile
            data={data.me}
            refetch={refetch}
            toggleIsEditing={toggleIsEditing}
          />
        )}
      </Content>
    </LayoutComponent>
  );
};
