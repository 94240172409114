import {
  USER_ALREADY_REGISTERED_MESSAGE,
  USER_ASSIGN_ADMIN_NOT_ALLOWED_MESSAGE,
} from '@motorex/common';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import {
  CreateUserInput,
  useUpdateUserMutation,
  useUserQuery,
} from '~/components/apollo-components';
import { AdminLayout } from '~/components/AdminLayout';
import { Content } from '~/components/Content';
import { LoadingContentWrapper } from '~/components/LoadingContentWrapper';
import { UserForm } from '~/components/UserForm';
import { useAddBreadcrumb } from '~/hooks/useAddBreadcrumb';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { createNotification } from '~/utils/createNotification';
import { Routes } from '~/utils/Routes';

interface IRouteParams {
  id: string;
}

export const UpdateUser: FunctionComponent<RouteComponentProps<
  IRouteParams
>> = ({ history, match: { params } }: RouteComponentProps<IRouteParams>) => {
  const { t } = useTranslation();
  const handleError = useErrorHandler();

  useAddBreadcrumb({
    path: Routes.admin.users.edit(params.id),
    title: t('Edit User'),
  });

  const [update, { loading }] = useUpdateUserMutation();
  const { data, error, loading: userLoading } = useUserQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: params.id },
  });

  const onSubmit = useCallback(
    async (values: CreateUserInput) => {
      try {
        await update({
          variables: { ...values },
        });

        createNotification('success', t('Changes successfully saved.'));
        history.push(Routes.admin.users.base);
      } catch (e) {
        handleError(e, t('Update failed'), {
          [USER_ASSIGN_ADMIN_NOT_ALLOWED_MESSAGE]: t(
            USER_ASSIGN_ADMIN_NOT_ALLOWED_MESSAGE,
          ),
          [USER_ALREADY_REGISTERED_MESSAGE]: t(USER_ALREADY_REGISTERED_MESSAGE),
        });
      }
    },
    [t, update, history, handleError],
  );

  // Handle loading error
  useEffect(() => {
    if (error) {
      createNotification('error', t('Error'), t('Could not fetch user.'));
      history.push(Routes.admin.users.base);
    }
  }, [error, history, t]);

  const onCancel = useCallback(() => history.push(Routes.admin.users.base), [
    history,
  ]);

  return (
    <AdminLayout>
      <Content small={true} title={t('Edit User')}>
        {!userLoading && data && data.user ? (
          <UserForm
            loading={loading}
            existingData={data.user}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        ) : (
          <LoadingContentWrapper />
        )}
      </Content>
    </AdminLayout>
  );
};
