import { Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '~/theme';
import { DarkLink } from '~/utils/styles';
import { ReactComponent as LeftArrow } from '~/assets/icons/arrow_left.svg';

const { Text } = Typography;

interface IBackLinkProps {
  to: string;
}

const AlignedArrow = styled(LeftArrow)`
  vertical-align: text-bottom;
`;

const LinkText = styled(Text)`
  margin-left: 1rem;
`;

export const BackLink: FunctionComponent<IBackLinkProps> = ({
  to,
}: IBackLinkProps) => {
  const { t } = useTranslation('common');
  return (
    <DarkLink to={to}>
      <AlignedArrow />
      <LinkText>{t('Back to Overview')}</LinkText>
    </DarkLink>
  );
};
