import { ChemicalEnum } from '@motorex/common';
import { TFunction } from 'i18next';
import { IHistoryRowData } from '~/pages/EasyMonitoring/MachineDetail/MeasurementHistoryCard/HistoryNumbersTable';
import { getLabelTextForChemical } from '~/utils/EasyMonitoring/getLabelTextForChemical';
import { getPlainLabelTextForChemical } from '~/utils/EasyMonitoring/getPlainLabelTextForChemical';

export const emulsionRows = (t: TFunction): IHistoryRowData[] => [
  {
    rowInfo: {
      label: t('Measured by'),
      plainLabel: t('Measured by'),
      key: 'measuredBy',
    },
  },
  {
    rowInfo: {
      label: getLabelTextForChemical(t)[ChemicalEnum.CONCENTRATION],
      plainLabel: getPlainLabelTextForChemical(t)[ChemicalEnum.CONCENTRATION],
      key: ChemicalEnum.CONCENTRATION,
    },
  },
  {
    rowInfo: {
      label: getLabelTextForChemical(t)[ChemicalEnum.NITRITE],
      plainLabel: getPlainLabelTextForChemical(t)[ChemicalEnum.NITRITE],
      key: ChemicalEnum.NITRITE,
    },
  },
  {
    rowInfo: {
      label: getLabelTextForChemical(t)[ChemicalEnum.HARDNESS],
      plainLabel: getPlainLabelTextForChemical(t)[ChemicalEnum.HARDNESS],
      key: ChemicalEnum.HARDNESS,
    },
  },
  {
    rowInfo: {
      label: getLabelTextForChemical(t)[ChemicalEnum.PH],
      plainLabel: getPlainLabelTextForChemical(t)[ChemicalEnum.PH],
      key: ChemicalEnum.PH,
    },
  },
  {
    rowInfo: {
      label: getLabelTextForChemical(t)[ChemicalEnum.TEMPERATURE],
      plainLabel: getPlainLabelTextForChemical(t)[ChemicalEnum.TEMPERATURE],
      key: ChemicalEnum.TEMPERATURE,
    },
  },
];
