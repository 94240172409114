import { Icon } from '@ant-design/compatible';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from '~/components/apollo-components';
import { styled } from '~/theme';

const Wrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.defaults.borderRadius};
  transition: all 0.3s;
  cursor: pointer;

  span {
    font-weight: bold;
  }

  border: ${({ selected, theme }) =>
    selected
      ? `2px solid ${theme.colors.primary}`
      : `1px solid ${theme.colors.white}`};

  box-shadow: ${({ selected }) =>
    selected ? '0 0 5px 0 rgba(0, 0, 0, 0.3)' : 'none'};

  :hover {
    border-color: ${(props) => props.theme.colors.primary};
  }

  > div:last-of-type {
    margin-top: 14px;
    i {
      font-size: 2.5em;
    }
  }
`;

interface IProps {
  role: Role;
  icon: string;
  selected: boolean;
  toggleRole: (role: Role) => void;
}

export const RoleTile: FunctionComponent<IProps> = ({
  role,
  icon,
  selected,
  toggleRole,
}: IProps) => {
  const { t } = useTranslation();
  const onClick = useCallback(() => toggleRole(role), [toggleRole, role]);

  return (
    <Wrapper selected={selected} onClick={onClick}>
      <div>
        <span>{t(role)}</span>
      </div>
      <div>
        <Icon type={icon} />
      </div>
    </Wrapper>
  );
};
