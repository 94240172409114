import { ChemicalEnum, EmulsionChemical } from '@motorex/common';

export const checkIfEmulsion = (
  chemicalType: ChemicalEnum | EmulsionChemical,
): chemicalType is EmulsionChemical => {
  return [
    ChemicalEnum.CONCENTRATION,
    ChemicalEnum.HARDNESS,
    ChemicalEnum.NITRITE,
    ChemicalEnum.PH,
    ChemicalEnum.TEMPERATURE,
  ].includes(chemicalType);
};
