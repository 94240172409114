import { createContext } from 'react';
import { noop } from '~/utils/common';
import { CollectionQueryOptions, SortArgs } from '../apollo-components';

export interface ITableSettingsContext {
  pagination: {
    currentPage: number;
    onPagination: (
      currentPage?: number,
      pageSize?: number,
    ) => Pick<CollectionQueryOptions, 'pagination'> | null;
    itemsPerPage: number;
  };
  search: {
    onSearch: (searchTerm: string) => void;
    searchTerm: string | null;
  };
  onSort: (
    sort: SortArgs | null,
  ) => Pick<CollectionQueryOptions, 'sort'> | null;
  onFilter: (filter: any) => Pick<CollectionQueryOptions, 'filter'> | null;
  mergeFetchOptions: (partialOptions: Partial<CollectionQueryOptions>) => void;
}

const noopNull = () => null;
export const TableSettingsContext = createContext<ITableSettingsContext>({
  mergeFetchOptions: noop,
  onFilter: noopNull,
  onSort: noopNull,
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    onPagination: noopNull,
  },
  search: {
    onSearch: noopNull,
    searchTerm: '',
  },
});
