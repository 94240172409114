import React, { FunctionComponent, ReactNode } from 'react';
import { Box, Flex } from 'reflexbox/styled-components';
import { BackLink } from '~/components/Header/SecondaryHeader/BackLink';
import { styled } from '~/theme';

const SecondaryHeaderWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  height: 60px;
  padding: 0 96px;
  margin-top: 60px;

  @media (max-width: ${({ theme }) => theme.screenXl}) {
    padding: 0 15px;
  }
`;

export interface ISecondaryHeaderProps {
  backLink: string;
  additionalContent?: ReactNode;
}

export const SecondaryHeader: FunctionComponent<ISecondaryHeaderProps> = ({
  backLink,
  additionalContent,
}: ISecondaryHeaderProps) => {
  return (
    <SecondaryHeaderWrapper justifyContent="space-between">
      <Box>
        <BackLink to={backLink} />
      </Box>
      <Flex>{additionalContent}</Flex>
    </SecondaryHeaderWrapper>
  );
};
