export const termsAndConditions = {
  'General Terms of Sale and Delivery':
    'Conditions générales de vente et de livraison (CGVL)',

  'These General Terms of Sale and Delivery are binding if the Seller delivers the ordered goods or if it is stated that the General Terms of Sale and Delivery are applicable. Any other conditions of the Buyer differing from these terms are only valid if expressly accepted in writing by the Seller.':
    'Ces CGVL ont un caractère obligatoire, pour autant que la maison vendeuse ait délivré la marchandise commandée ou qu’elles soient mentionnées comme applicables dans la confirmation de commande. Des conditions différentes de l’acheteur ne sont valables que si elles ont été acceptées expressément et par écrit par la maison vendeuse.',
  'The contract of sale shall be considered to have been agreed if, when the order has been received, the Seller acknowledges it in writing or the goods have been delivered without any reservations.':
    'Le contrat de vente est considéré comme conclu, si la maison vendeuse a confirmé par écrit l’acceptation d’une commande après réception de celle-ci ou si la marchandise a été livrée sans réserves.',
  'Where quantities and weights are stated in orders, the Seller shall be entitled to make deliveries of up to 10% above or below the stated figure. The weights at departure shall be decisive.':
    'Les indications concernant quantité et poids lors de la commande, donnent droit à des excédents ou à des réductions de livraison jusqu’à 10 %. Les poids de départ sont déterminants.',
  'Unless agreed otherwise in writing, the Seller’s prices shall be net, ex works and in Swiss Francs. Drums and packaging are – with the exception of containers and reusable receptacles – included in the price.':
    'Les prix de la maison vendeuse s’entendent nets, départ usine, en francs suisses, pour autant que rien d’autre n’ait été convenu par écrit. Les emballages sont compris dans les prix, à l’exception des conteneurs et des emballages réutilisables.    ',
  'The terms of payment shall be 30 days net from the date of the invoice. Variations to these terms or conditions of payment shall only be valid and binding if made in writing. The purchaser shall effect payments at the Seller’s domicile, with no deductions in respect of cash payments, bank charges, taxes and fees of any kind. If payment is delayed, the Seller reserves the right to stop outstanding deliveries and is entitled to charge interest on overdue payments at a rate of 6% p.a.':
    'Le délai de paiement s’entend net à 30 jours, date de facture. Des délais ou conditions de paiement divergents ne sont valables et n’ont force obligatoire que sous forme écrite. Pour des livraisons dans des pays sans délais de paiement définis par écrit, le paiement a lieu par un accréditif irrévocable et confirmé par une banque suisse. Les paiements sont à effectuer par l’acheteur au domicile de la maison vendeuse sans déduction d’escompte, de frais, impôts et taxes quelconques. En cas de retard de paiement la maison vendeuse se réserve le droit de stopper la livraison de commandes ouvertes et est autorisée à calculer un intérêt de retard de 6 % par an.',
  'The Seller shall retain the ownership of the delivery until the goods have been paid for in full. The purchaser has a duty to take necessary measures to protect the Seller’s property. The Seller is entitled to have his property secured through suitable measures.':
    'La maison vendeuse se réserve la propriété à la livraison jusqu’à son paiement complet. L’acheteur est obligé à prendre les mesures nécessaires pour la protection de la propriété de la maison vendeuse. La maison vendeuse est autorisée à assurer sa propriété par des mesures appropriées.    ',
  'The delivery period shall commence with the acceptance of the order by the Seller. The delivery periods shall be extended accordingly if':
    'Le délai de livraison commence avec l’acceptation de la commande par la maison vendeuse. Le délai de livraison peut être prolongé de manière convenable:    ',
  'Information required to execute the order is not supplied to the Seller in good time, or if this information is subsequently changed by the purchaser.':
    'si les indications nécessaires pour la mise en oeuvre de la commande n’arrivent pas à temps à la maison vendeuse ou si l’acheteur les modifie ultérieurement;     ',
  'Payment is not made by the stipulated date, the letter of credit has been opened too late or if the necessary import licences are not received by the Seller in due time.':
    'si des délais de paiement ne sont pas observés, des accréditifs ouverts avec retard ou les licences d’importation nécessaires remises trop tard à la maison vendeuse;     ',
  'If obstacles arise which the Seller cannot avoid in spite of taking the necessary care, irrespective of whether these occur at the Seller, the purchaser or a third party. Such obstacles also include force majeure such as epidemics, mobilization of troops, war, riot, serious disruptions to the business, accidents, industrial conflicts, delayed or incorrect delivery of the necessary raw materials, semi-finished or finished products, irresolvable problems with important tools that causes them to be scrapped, measures imposed by the authorities or omissions, and natural occurrences.':
    'si des empêchements surgissent que la maison vendeuse ne peut pas prévoir, malgré l’application des soins requis, s’ils sont dus à la maison vendeuse, à l’acheteur ou à une tierce personne. Comptent également comme empêchements, par exemple, évènements de force majeure, épidémies, mobilisation, guerre, émeute, dérangements considérables, accidents, conflits de travail, fourniture tardive ou défectueuse des matières premières, produits semi-finis et finis, pièces travaillées importantes rebutées, mesures officielles ou omissions, phénomènes naturels.    ',
  'The Seller shall be advised in good time of special requests relating to the shipment and insurance of the goods. Transport shall be at the expense and risk of the purchaser. Complaints in relation to transport shall be made without delay by the purchaser to the last freight forwarder upon receipt of the delivery or freight documents. The purchaser shall be responsible for insurance against losses of all kinds. Even if the Seller has to take out the insurance, it shall be charged to the purchaser.':
    'Les voeux particuliers concernant l’expédition et l’assurance doivent être communiqués à temps à la maison vendeuse. Le transport est effectué aux risques et périls de l’acheteur, départ usine. Les plaintes se rapportant au transport sont à communiquer immédiatement par l’acheteur au dernier transporteur à la réception de la livraison ou des documents. L’assurance contre des dommages quelconques incombe à l’acheteur. Elle va aux frais de l’acheteur, même si elle est conclue par la maison vendeuse. ',
  'Any complaints regarding volume of delivery, packing or other defects are to be addressed from the Buyer to the Seller in writing within 8 days after receipt of goods at the latest.':
    'Les réclamations concernant le volume de livraison, l’emballage et toute autre raison doivent être communiquées par écrit par l’acheteur à la maison vendeuse dans les 8 jours suivant la réception.',
  'The Seller’s guarantees include':
    'Les garanties de la maison vendeuse couvrent:',
  'Seller warrants that the Products delivered to Buyer pursuant to the contract of sale as defined herein are free of manufacturing faults and material flaws and that they conform to the specifications expressly described in the order acknowledgment pursuant to Clause 2 hereinabove, in the instructions for use and the specification sheet, with the tolerances applicable in the relevant industry.':
    'La maison vendeuse garantie que les produits livrés à l’acheteur conformément au contrat de vente tel que décrit par les présentes sont libres de tout défaut matériel et de fabrication et qu’ils correspondent aux spécifications expressément décrites dans la confirmation de la commande selon la clause 2 ci-dessus, dans le mode d’emploi et dans la feuille de spécifications, avec les tolérances industrielles applicables en la matière.',
  'Buyer understands and acknowledges that the acceptability and suitability of the Products for a particular use is solely within its own discretionary power to decide. Seller makes no other warranty, written or oral, statutory, express or implied, including but not limited to merchantability or fitness for a particular purpose.':
    'L’acheteur donne acte et confirme que la décision relative à l’acceptabilité et l’aptitude des produits pour une utilisation particulière est dans son seul et exclusif pouvoir discrétionnaire. La maison vendeuse ne donne aucune autre garantie, que ce soit par écrit, oralement, expresse ou implicite, notamment quant à l’utilité générale ou l’aptitude du produit pour un but déterminé.',
  'The warranty is void unless the Buyer inspects the products and notifies Seller in writing within 8 days upon delivery of the Products of any rejections based on non conformity to specifications or of defects in the Products.':
    'La garantie n’est valable qu’à condition que l’acheteur vérifie les produits et informe par écrit la maison vendeuse dans les 8 jours dès la livraison des produits de tout refus en raison de défauts ou de non-conformité aux spécifications des produits. ',
  'In the event of defective or non conformity to specifications of Products, Seller’ warranty is limited to a replacement delivery of the defective products subject to Buyer returning the defective goods to Seller. More particularly, Buyer understands and acknowledges that depending the items on which the Product is used, a proper cleaning and entire removal of any residues and remains thereof is required for which Buyer is exclusively responsible. As a consequence, Seller shall not be liable for any other direct, indirect and/or consequential damages, losses and causes of action, whether in contract, tort, including negligence or otherwise.':
    'Dans le cas de produits défectueux ou non conformes aux spécifications, la responsabilité de la maison vendeuse est limitée à remplacer les produits défectueux contre remise de ceux-ci à la maison vendeuse. En particulier, l’acheteur donne acte et confirme que dépendant du substrat sur lequel le produit est appliqué, un nettoyage adéquat et un enlèvement total de tout reste est nécessaire et qu’il est en est le seul et entier responsable. Ainsi, le vendeur décline toute responsabilité pour tout autre dommage, perte et demande directs, indirects et/ou consécutifs que ce soit d’ordre contractuel, délictuel, y compris par négligence ou autre. ',
  'These general terms of sale and delivery shall be subject to Swiss law and shall exclude the United Nations Convention on Contracts for the International Sales of Goods (Vienna Sales Convention) dated 11 April 1980. The only place of jurisdiction shall be Langenthal, Switzerland.':
    'Les présentes conditions générales de vente et de livraison sont soumises au droit suisse, sous l’exclusion de l’accord des Nations Unies sur les contrats sur l’achat des marchandises international (droit d’achat viennois) du 11 avril 1980. L’unique siège du tribunal compétent: Langenthal, Suisse.',
};
