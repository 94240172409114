import { TFunction } from 'i18next';
import { object, ref, string } from 'yup';

export const validationSchema = (t: TFunction): any =>
  object().shape({
    confirmPassword: string()
      .label(t('Confirm password'))
      .oneOf([ref('newPassword')], t('Passwords do not match'))
      .required(),
    newPassword: string().label(t('New password')).min(8).required(),
  });
