import { TFunction } from 'i18next';
import { array, object, string } from 'yup';

export const validationSchema = (t: TFunction): any =>
  object().shape({
    name: string().label(t('Company Name')).required().max(64),
    address: string().label(t('Company Address')).nullable().max(150),
    contactFirstName: string()
      .label(t('Contact First Name'))
      .required()
      .max(32),
    contactLastName: string().label(t('Contact Last Name')).required().max(32),
    contactEmail: string()
      .label(t('Contact Email'))
      .email()
      .required()
      .max(128),
    contactPhoneNumber: string()
      .label(t('Contact Phone Number'))
      .nullable()
      .max(64),
    domains: array()
      .of(
        string()
          .label(t('Domain'))
          .required()
          .matches(/^@[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+\.[a-z]+$/, {
            message: t('Domain must be a valid domain and start with an @'),
          })
          .max(128),
      )
      .label(t('Domains'))
      .required()
      .min(1),
  });
