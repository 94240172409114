import { PASSWORD_TOKEN_INVALID_MESSAGE } from '@motorex/common';

export const auth = {
  'Forgot password': 'Passwort vergessen',
  Login: 'Anmelden',
  'Password change failed': 'Die Änderung des Passworts ist fehlgeschlagen',
  'Password Confirmation': 'Passwort-Bestätigung',
  'Password successfully set.': 'Passwort erfolgreich gesetzt.',
  [PASSWORD_TOKEN_INVALID_MESSAGE]:
    'Ihr Token zum Zurücksetzen des Passworts ist ungültig',
  'Passwords do not match': 'Passwörter stimmen nicht überein',
  'Phone Number': 'Telefonnummer',
  'Please confirm your new password': 'Bitte bestätigen Sie Ihr neues Passwort',
  'Please enter a new password': 'Bitte geben Sie ein neues Passwort ein',
  'Please enter your Email': 'Bitte geben Sie Ihre E-Mail-Adresse ein',
  'Please enter your email address to request a new password.':
    'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.',
  'Please enter your First Name': 'Bitte geben Sie Ihren Vornamen ein',
  'Please enter your Last Name': 'Bitte geben Sie Ihren Nachnamen ein',
  'Please enter your password': 'Bitte geben Sie Ihr Passwort ein',
  Register: 'Registrieren',
  'Reset password': 'Passwort zurücksetzen',
  'Welcome!': 'Willkommen!',
};
