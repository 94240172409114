import { StatusColorMap } from './colorMaps';
import {
  ChemicalEnum,
  EmulsionChemical,
  EmulsionThresholds,
  WaterChemical,
  WaterThresholds,
} from '@motorex/common';
import { StatusType, MeasurementType } from '~/components/apollo-components';
import { checkIfEmulsion } from './checkIfEmulsion';

type colorThreshold = {
  upperLimit: number | undefined;
  lowerLimit: number | undefined;
  color: string;
};

export const getColoredGraphSections = (
  selectedChemical: ChemicalEnum,
  measurementType: MeasurementType,
): colorThreshold[] => {
  if (!measurementType) {
    return [];
  }

  if (
    (measurementType === MeasurementType.EMULSION &&
      !checkIfEmulsion(selectedChemical as ChemicalEnum)) ||
    (measurementType === MeasurementType.WATER &&
      (selectedChemical === ChemicalEnum.CONCENTRATION ||
        selectedChemical === ChemicalEnum.TEMPERATURE))
  ) {
    return [];
  }

  const selectedChemicalEnum = selectedChemical as ChemicalEnum;

  const currentChemicalType =
    measurementType && measurementType === MeasurementType.WATER
      ? WaterThresholds[selectedChemicalEnum as WaterChemical]
      : EmulsionThresholds[selectedChemicalEnum as EmulsionChemical];

  const graphColors: colorThreshold[] = [];

  Object.entries(currentChemicalType).forEach((value) => {
    const currentThresholdColor =
      StatusColorMap[StatusType[value[0].toUpperCase() as StatusType]];

    if (value[0] === 'overallRange') return;
    if (Array.isArray(value[1].max && value[1].min)) {
      graphColors.push({
        upperLimit: value[1].max[1],
        lowerLimit: value[1].max[0],
        color: currentThresholdColor,
      });
      graphColors.push({
        upperLimit: value[1].min[1],
        lowerLimit: value[1].min[0],
        color: currentThresholdColor,
      });
    } else {
      graphColors.push({
        upperLimit: value[1].max,
        lowerLimit: value[1].min,
        color: currentThresholdColor,
      });
    }
  });
  return graphColors;
};
