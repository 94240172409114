import { PlusOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/es/table';
import { TFunction } from 'i18next';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'reflexbox';
import { ReactComponent as RightArrow } from '~/assets/icons/arrow_right.svg';
import {
  CollectionQueryOptions,
  SortArgs,
  SortOrder,
  CompanyInformationFragment,
  useCompaniesQuery,
} from '~/components/apollo-components';
import { AdminLayout } from '~/components/AdminLayout';
import { Content } from '~/components/Content';
import { Table } from '~/components/DataTable';
import { initialOptions } from '~/components/DataTable/TableSettingsProvider';
import { SecondaryLinkButton } from '~/components/SecondaryLinkButton';
import { createNotification } from '~/utils/createNotification';
import { Routes } from '~/utils/Routes';
import { BoldLink, IconLink } from '~/utils/styles';

const columns = (t: TFunction): ColumnProps<CompanyInformationFragment>[] => [
  {
    dataIndex: 'name',
    defaultSortOrder: 'ascend',
    key: 'name',
    render: (text: any, record: CompanyInformationFragment) => (
      <BoldLink to={Routes.admin.companies.details(record.id)}>
        {record.name}
      </BoldLink>
    ),
    sorter: true,
    title: t('Company'),
  },
  {
    dataIndex: 'customerNumber',
    key: 'customerNumber',
    sorter: true,
    title: t('Customer No.'),
  },
  {
    dataIndex: 'address',
    key: 'address',
    sorter: true,
    title: t('Address'),
  },
  {
    dataIndex: 'domains',
    key: 'domains',
    render: (text: any, record: CompanyInformationFragment) =>
      record.domains.map((r) => r).join(', '),
    sorter: true,
    title: t('Domains'),
  },
  {
    dataIndex: 'contactFirstName',
    key: 'contactFirstName',
    render: (text: any, record: CompanyInformationFragment) =>
      `${record.contactFirstName} ${record.contactLastName}`.trim(),
    sorter: true,
    title: t('Contact'),
  },
  {
    key: 'actions',
    render: (text: any, record: CompanyInformationFragment) => (
      <Flex justifyContent="flex-end">
        <IconLink
          to={Routes.admin.companies.details(record.id)}
          title={t('common:View')}
        >
          <RightArrow />
        </IconLink>
      </Flex>
    ),
    title: '',
  },
];

const initialSort: SortArgs = {
  field: 'name',
  order: SortOrder.ASC,
};

export const CompanyList: FunctionComponent = () => {
  const { t } = useTranslation(['Admin', 'common']);

  const { data, loading, error, refetch } = useCompaniesQuery({
    variables: {
      ...initialOptions,
      sort: initialSort,
    },
  });

  const allColumns = useMemo(() => columns(t), [t]);

  const onFetch = useCallback(
    async (options: CollectionQueryOptions) => {
      await refetch(options);
    },
    [refetch],
  );

  useEffect(() => {
    if (error) {
      createNotification('error', t('Error fetching company data'));
    }
  }, [error, t]);

  return (
    <AdminLayout>
      <Content>
        <Table<CompanyInformationFragment>
          title={t('Companies Overview')}
          data={data?.companies ?? { items: [], totalCount: 0 }}
          loading={loading}
          columns={allColumns}
          refetch={onFetch}
          additionalActions={
            <Box mt={data && data.companies.totalCount > 0 ? 0 : 3}>
              <SecondaryLinkButton
                to={Routes.admin.companies.create}
                icon={<PlusOutlined translate="no" />}
                size="large"
              >
                {t('Add company')}
              </SecondaryLinkButton>
            </Box>
          }
          initialSorting={initialSort}
        />
      </Content>
    </AdminLayout>
  );
};
