import { TFunction } from 'i18next';
import { object, string } from 'yup';

export const validationSchema = (t: TFunction): any =>
  object().shape({
    contactFirstName: string()
      .label(t('Contact First Name'))
      .required()
      .max(32),
    contactLastName: string().label(t('Contact Last Name')).required().max(32),
    contactEmail: string()
      .label(t('Contact Email'))
      .email()
      .required()
      .max(128),
    contactPhoneNumber: string()
      .label(t('Contact Phone Number'))
      .nullable()
      .max(64),
    name: string().label(t('Company Name')).required().max(64),
    address: string().label(t('Company Address')).nullable().max(150),
    domains: string().label(t('Domains')).required().min(4),
  });
