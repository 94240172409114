import { Typography } from 'antd';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChemicalFragment,
  MeasurementFragment,
  StatusType,
} from '~/components/apollo-components';
import { styled } from '~/theme';
import { getTranslationForChemicalStatus } from '~/utils/EasyMonitoring/chemicalTranslation';
import { WarningIcon } from '~/utils/EasyMonitoring/styles';

const { Paragraph, Text } = Typography;

interface IWarningIconProps {
  status: StatusType;
}

const LargeWarningIcon = styled(WarningIcon)<IWarningIconProps>`
  font-size: 1.85rem;
  margin-right: 0.75rem;
`;

const WarningText = styled(Text)`
  font-size: 0.875rem;
`;

interface IDetailStatusProps {
  lastMeasurement: MeasurementFragment | null;
}

export const DetailStatus: FunctionComponent<IDetailStatusProps> = ({
  lastMeasurement,
}: IDetailStatusProps) => {
  const { t } = useTranslation('EasyMonitoring');

  const filteredChemicals: ChemicalFragment[] = useMemo(() => {
    if (lastMeasurement != null) {
      // eslint-disable-next-line no-unused-vars
      const { __typename, ...realChemicals } = lastMeasurement.chemicals;
      return Object.values(realChemicals).filter(
        (chemical: ChemicalFragment | null) =>
          chemical != null && chemical.status !== StatusType.NORMAL,
      );
    }

    return [];
  }, [lastMeasurement]);

  if (filteredChemicals == null || filteredChemicals.length === 0) {
    return (
      <Paragraph>
        {t('All latest measured values are in the normal range.')}
      </Paragraph>
    );
  }

  return (
    <div>
      {filteredChemicals.map(({ status, key }) => {
        const translation = getTranslationForChemicalStatus(t)[key][
          status as Exclude<StatusType, StatusType.NORMAL>
        ];
        return (
          <Paragraph key={key}>
            <LargeWarningIcon translate="no" status={status} />
            <WarningText>{translation}</WarningText>
          </Paragraph>
        );
      })}
    </div>
  );
};
