import React, { FunctionComponent } from 'react';
import { Box, Flex } from 'reflexbox';
import { UserLayout } from '~/components/UserLayout';
import { LastMeasurementsCard } from '~/pages/EasyMonitoring/Dashboard/LastMeasurementsCard';
import { OverviewCard } from '~/pages/EasyMonitoring/Dashboard/OverviewCard';
import { MachinesCard } from '~/pages/EasyMonitoring/Dashboard/MachinesCard';
import { WarningCard } from '~/pages/EasyMonitoring/Dashboard/WarningCard';
import { boxCard } from '~/theme/variables';

export const Dashboard: FunctionComponent = () => {
  return (
    <UserLayout>
      <Flex flexDirection="column">
        <WarningCard />
        <Box paddingBottom="1.75rem">
          <Flex flexWrap="wrap">
            <Box
              width={boxCard.width.half}
              pr={boxCard.padding.horizontalLg}
              pb={boxCard.padding.verticalLg}
            >
              <OverviewCard />
            </Box>
            <Box
              width={boxCard.width.half}
              pl={boxCard.padding.horizontalLg}
              pt={boxCard.padding.verticalLg}
            >
              <LastMeasurementsCard />
            </Box>
          </Flex>
        </Box>
        <Box>
          <MachinesCard />
        </Box>
      </Flex>
    </UserLayout>
  );
};
