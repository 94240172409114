import { Modal } from 'antd';
import { createBrowserHistory } from 'history';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Router as BaseRouter } from 'react-router-dom';

interface IProps {
  children: React.ReactNode;
}

export const Router: FunctionComponent<IProps> = ({ children }: IProps) => {
  const history = useRef(createBrowserHistory());

  useEffect(() => {
    history.current.listen(() => {
      Modal.destroyAll();
    });
  }, []);

  return <BaseRouter history={history.current}>{children}</BaseRouter>;
};
