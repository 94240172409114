import { WarningOutlined } from '@ant-design/icons';
import { Badge, Button } from 'antd';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'reflexbox';
import {
  StatusType,
  useMachinesQuery,
  useUpdateUserSettingsMutation,
  UpdateUserSettingsInput,
} from '~/components/apollo-components';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { styled } from '~/theme';
import { colors } from '~/theme/variables';

const NotificationButtonWrapper = styled(Flex)`
  margin-right: 2rem;
`;

const NotificationBadge = styled(Badge)`
  .ant-bagde-count {
    font-weight: bold;
  }

  .ant-scroll-number {
    font-weight: bold;
    box-shadow: 0 0 0 2px ${colors.white};
    right: 2px !important;
    background: ${colors.red};
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;
`;

export const NotificationDropdown: FunctionComponent = () => {
  const { t } = useTranslation(['common']);
  const handleError = useErrorHandler();
  const { loading, data, error } = useMachinesQuery({
    variables: {
      filter: { status: [StatusType.CRITICAL] },
    },
  });
  const [update] = useUpdateUserSettingsMutation();

  const onClick = useCallback(async () => {
    try {
      const variables: UpdateUserSettingsInput = { dashboardWarnings: true };
      await update({
        variables,
      });
    } catch (e) {
      handleError(e, t('An unexpected error occurred.'));
    }
  }, [handleError, t, update]);

  if (
    error ||
    loading ||
    data?.machines == null ||
    data.machines.totalCount === 0
  )
    return null;

  return (
    <NotificationButtonWrapper alignItems="center">
      <NotificationBadge count={data.machines.totalCount} offset={[0, 13]}>
        <StyledButton
          type="link"
          onClick={onClick}
          icon={
            <WarningOutlined
              style={{ color: colors.white, fontSize: '34px', lineHeight: 0 }}
              translate="no"
            />
          }
        />
      </NotificationBadge>
    </NotificationButtonWrapper>
  );
};
