import { Button } from 'antd';
import { ButtonProps, ButtonType } from 'antd/lib/button';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface IProps extends ButtonProps {
  children: React.ReactNode;
  to: string;
  type?: ButtonType;
}

export const LinkButton: FunctionComponent<IProps> = ({
  children,
  to,
  type = 'primary',
  ...rest
}: IProps) => (
  <Link to={to}>
    <Button type={type} {...rest}>
      {children}
    </Button>
  </Link>
);
