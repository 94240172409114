import { Button, Popover, Typography } from 'antd';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { Box, Flex } from 'reflexbox';
import { Hexagon } from '~/components/Hexagon';
import { styled, theme } from '~/theme';
import { IconLabel } from '~/utils/styles';

const { Text } = Typography;

const PopoverButton = styled(Button)`
  width: 65px;
  height: 65px;
  padding: 0;
`;

const Value = styled(Text)`
  font-size: 20px;
`;

const Unit = styled(Text)`
  font-size: 18px;
`;

interface IOverviewCardElementProps {
  icon: ReactNode;
  title: string;
  description: string;
  value: string;
  unit?: string;
  additionalInfo?: ReactNode;
}

export const OverviewCardElement: FunctionComponent<IOverviewCardElementProps> = ({
  icon,
  title,
  description,
  value,
  unit,
  additionalInfo,
}: IOverviewCardElementProps) => {
  const OverviewIcon = useMemo(() => {
    const HexagonIcon = (
      <Hexagon color={theme.colors.primary} size={65} icon={icon} />
    );

    if (additionalInfo != null) {
      return (
        <Popover
          placement="bottom"
          content={additionalInfo}
          trigger="hover"
          arrowPointAtCenter={true}
        >
          <PopoverButton type="link">{HexagonIcon}</PopoverButton>
        </Popover>
      );
    }

    return HexagonIcon;
  }, [additionalInfo, icon]);

  return (
    <>
      <Flex alignItems="center" flexDirection="column" marginTop="1.5rem">
        {OverviewIcon}
        <IconLabel>{title}</IconLabel>
      </Flex>
      <Flex alignItems="center" flexDirection="column" marginTop="1.5rem">
        <Box>
          <Text>
            <Value>{value}</Value>
            <Unit>{unit}</Unit>
          </Text>
        </Box>
        <Box maxWidth="6.5rem" textAlign="center">
          {description}
        </Box>
      </Flex>
    </>
  );
};
