import React, { FunctionComponent } from 'react';
import { AuthenticatedRoute, Switch } from '~/components/Route';
import { MachineDetail } from '~/pages/EasyMonitoring/MachineDetail/MachineDetail';
import { QrCodeGeneration } from '~/pages/EasyMonitoring/MachineDetail/pages/QrCodeGeneration';
import { Routes } from '~/utils/Routes';

export const machineDetailPaths = [
  Routes.easyMonitoring.machine.single.base(':id'),
  Routes.easyMonitoring.machine.single.qr(':id'),
];

export const MachineDetailRouter: FunctionComponent = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        path={Routes.easyMonitoring.machine.single.qr(':id')}
        component={QrCodeGeneration}
        exact={true}
      />
      <AuthenticatedRoute
        path={Routes.easyMonitoring.machine.single.base(':id')}
        component={MachineDetail}
        exact={true}
      />
    </Switch>
  );
};
