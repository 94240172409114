import { Menu, Dropdown } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import { styled } from '~/theme';
import {
  ChemicalEnum,
  WaterThresholds,
  EmulsionThresholds,
} from '@motorex/common';
import { getTranslationForChemicals } from '~/pages/EasyMonitoring/Dashboard/LastMeasurementsCard/getTranslationForChemicals.tsx';
import { Flex, Box } from 'reflexbox';
import { ChemicalType, MeasurementType } from '~/components/apollo-components';

const DropdownTrigger = styled(Flex)`
  height: 3rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.medium};
  width: 100%;
  padding: 0 0.75rem;
`;

interface IFilterMeasurementsDropdownProps {
  filter: ChemicalEnum;
  setFilter: (filter: ChemicalEnum) => void;
  measurementType: MeasurementType | null;
}

export const GraphDropdownFilter: FunctionComponent<IFilterMeasurementsDropdownProps> = ({
  filter,
  setFilter,
  measurementType,
}: IFilterMeasurementsDropdownProps) => {
  const { t } = useTranslation('EasyMonitoring');

  const threshold =
    measurementType === MeasurementType.WATER
      ? WaterThresholds
      : EmulsionThresholds;

  const listOfChemicals = Object.keys(threshold).map((elem) => {
    return elem;
  });

  const menu = (
    <Menu>
      {listOfChemicals.map((chemical) => {
        const label = getTranslationForChemicals(t)[chemical.toUpperCase()];
        return (
          <Menu.Item
            onClick={() => setFilter(chemical as ChemicalEnum)}
            key={chemical}
          >
            {label}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Flex alignItems="center">
      <Dropdown
        overlay={menu}
        placement="bottomCenter"
        arrow={true}
        trigger={['click']}
      >
        <DropdownTrigger alignItems="center">
          <Box flex="1 1 auto">
            {
              getTranslationForChemicals(t)[
                filter.toUpperCase() as ChemicalType
              ]
            }
          </Box>
          <Box flex="0 0 auto">
            <CaretDownFilled translate="no" />
          </Box>
        </DropdownTrigger>
      </Dropdown>
    </Flex>
  );
};
