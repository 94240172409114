import React, { FunctionComponent, ReactNode } from 'react';
import { styled } from '~/theme';
import { colors } from '~/theme/variables';
import { ReactComponent as HexagonShape } from './hexagon.svg';

interface ISizeProps {
  size: number;
}

interface IShapeProps extends ISizeProps {
  bordercolor: string;
  color: string;
}

const HexagonWrapper = styled.div<ISizeProps>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const HexagonContent = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  > * {
    margin: 0 auto;
  }
`;

const ColoredShape = styled(HexagonShape)<IShapeProps>`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ color }) => color};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  fill: currentColor;
  stroke: ${({ bordercolor }) => bordercolor};
`;

export interface IHexagonProps {
  size?: number;
  bordercolor?: string;
  color?: string;
  icon?: ReactNode;
}

export const Hexagon: FunctionComponent<IHexagonProps> = ({
  bordercolor = colors.white,
  color = colors.white,
  size = 45,
  icon,
}: IHexagonProps) => {
  return (
    <HexagonWrapper size={size}>
      {icon && <HexagonContent>{icon}</HexagonContent>}
      <ColoredShape size={size} color={color} bordercolor={bordercolor} />
    </HexagonWrapper>
  );
};
