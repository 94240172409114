import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/de';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';
import { Language } from '~/components/apollo-components';
import { de } from './translations/de';
import { en } from './translations/en';
import { it } from './translations/it';
import { fr } from './translations/fr';
import { de as deYup } from './translations/yup/de';
import { en as enYup } from './translations/yup/en';
import { it as itYup } from './translations/yup/it';
import { fr as frYup } from './translations/yup/fr';

const resources = {
  [Language.DEDE]: de,
  [Language.ENUS]: en,
  [Language.ITIT]: it,
  [Language.FRFR]: fr,
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.ENUS,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    resources,
  });

moment.locale('en');

export const setLanguage = async (language: Language): Promise<void> => {
  switch (language) {
    case Language.DEDE:
      moment.locale('de');
      setLocale(deYup);
      await i18n.changeLanguage(Language.DEDE);
      break;
    case Language.FRFR:
      moment.locale('fr');
      setLocale(frYup);
      await i18n.changeLanguage(Language.FRFR);
      break;
    case Language.ITIT:
      moment.locale('it');
      setLocale(itYup);
      await i18n.changeLanguage(Language.ITIT);
      break;
    case Language.ENUS:
    default:
      moment.locale('en');
      setLocale(enYup);
      await i18n.changeLanguage(Language.ENUS);
      break;
  }
};

export default i18n;
