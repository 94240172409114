import { MeasurementType, StatusType } from '~/components/apollo-components';

export const dashboard = {
  Actuality: 'Attualità',
  Average: 'Media',
  [StatusType.CRITICAL]: 'critico',
  criticalChemicalsAtSource: '<2>{{chemicals}}</2> critico/a in {{source}}',
  Decline: 'Peggioramento',
  [MeasurementType.EMULSION]: 'Emulsione',
  'Error Fetching Sources': 'Errore durante il caricamento delle sorgenti',
  'Error Fetching Last Measurements':
    'Errore durante il caricamento ultime misurazioni',
  'Error Fetching Overview Information':
    'Errore durante il caricamento delle informazioni di riepilogo',
  Factor: 'Fattore',
  'Last Measurement': 'Ultima misurazione',
  'Last Measurements': 'Ultime misurazioni',
  'Filter by measurement values': 'Filtra per valori misurati',
  'Measured by': 'Misurazione eseguita da',
  'Measurement Type': 'Tipo di misura',
  'Measurement Types Selection': 'Selezione dei tipi di misura',
  [StatusType.NOTICEABLE]: 'anomalo',
  [StatusType.NORMAL]: 'normale',
  'of measured values in the standard range':
    'dei valori misurati nel range normale',
  Overview: 'Riepilogo',
  Product: 'Prodotto',
  'Search Source': 'Cerca sorgente',
  Source: 'Sorgente',
  'Source Details': 'Sorgenti in dettaglio',
  'sources tested in the last 7 days': 'Sorgenti testate negli ultimi 7 giorni',
  'Sources with all measured values in the standard range':
    'Sorgenti con tutti i valori misurati nel range normale',
  'Sources with at least one abnormal reading':
    'Sorgenti con almeno un valore misurato anomalo',
  'Sources with at least one critical reading':
    'Sorgenti con almeno un valore misurato critico',
  'sources with negative trend': 'Sorgenti con tendenza negativa',
  Status: 'Stato',
  'Warning {{current}}/{{total}}': 'Allerta {{current}}/{{total}}',
  [MeasurementType.WATER]: 'Acqua',
  'Delete source': 'Elimina sorgente',
  'Delete measurement': 'Elimina misurazione',
  'delete source': 'Elimina sorgente',
  'delete measurement': 'Elimina misurazione',
  'Are you sure you want to delete {{name}}?':
    'Sei sicuro di voler eliminare {{name}}?',
  'Are you sure you want to delete the measurement from {{name}}?':
    'Sei sicuro di voler eliminare la misurazione da {{name}}?',
  'This will delete all associated measurements as well.':
    'Questo eliminerà tutte le misurazioni associate.',
};
