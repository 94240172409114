export const API_PATH = '/api';

// Express REST API call
export const API = {
  base: '/',
  profile: {
    base: '/profile',
    photo: {
      base: '/profile/photo',
      single: (id: string): string => `/profile/photo/${id}`,
    },
  },
};
