import React, { FunctionComponent } from 'react';
import { Card, Divider, Typography } from 'antd';
import { Box, Flex } from 'reflexbox/styled-components';
import { styled } from '~/theme';
import { useTranslation } from 'react-i18next';
import { SocialMediaIcon } from './SocialMediaIcon';
import { ReactComponent as FacebookLogo } from '~/assets/icons/facebook.svg';
import { ReactComponent as InstagramLogo } from '~/assets/icons/instagram.svg';
import { ReactComponent as YoutubeLogo } from '~/assets/icons/youtube.svg';
import { ReactComponent as FacebookPowersportslogo } from '~/assets/icons/facebook-powersports.svg';

const { Text, Title, Paragraph } = Typography;

const StyledTitle = styled(Title)`
  && {
    font-size: 1.5rem;
    font-weight: 500;
  }
  padding-bottom: 0.75rem;
`;

const SectionDivider = styled(Divider)`
  height: auto;
  padding-right: 1.5rem;
`;

const StyledParagraph = styled(Paragraph)`
  && {
    margin-bottom: 0;
  }
  letter-spacing: 1px;
  line-height: 22px;
`;

const StyledText = styled(Text)`
  margin-left: 5px;
`;
const SocialMediaBox = styled(Box)`
  flex-wrap: wrap;
  align-items: center;
`;

export const ContactCard: FunctionComponent = () => {
  const { t } = useTranslation('staticPages');

  return (
    <Flex flexDirection="column" width="100%">
      <Box padding="0.5rem 0">
        <Card>
          <Flex>
            <Box>
              <StyledTitle>
                {t(
                  'If you have any other questions, please contact us directly via one of the following channels',
                )}
                :
              </StyledTitle>
            </Box>
          </Flex>
          <Flex>
            <Box width={1 / 3}>
              <StyledParagraph>
                {t('Phone')}:<StyledText>+41 62 919 75 75</StyledText>
              </StyledParagraph>
              <StyledParagraph>
                {t('Fax')}:<StyledText>+41 62 919 75 95</StyledText>
              </StyledParagraph>
              <StyledParagraph>
                {t('E-Mail')}:
                <StyledText>
                  <a
                    href="mailto:info@motorex.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@motorex.com
                  </a>
                </StyledText>
              </StyledParagraph>
            </Box>
            <SectionDivider type="vertical" orientation="center" />
            <Box width={1 / 3}>
              <StyledParagraph>MOTOREX-BUCHER GROUP AG</StyledParagraph>
              <StyledParagraph>Bern-Zürich-Strasse 31</StyledParagraph>
              <StyledParagraph>Postfach 4901</StyledParagraph>
              <StyledParagraph>Langenthal, Schweiz</StyledParagraph>
            </Box>
            <SectionDivider type="vertical" />
            <SocialMediaBox width={1 / 3}>
              <SocialMediaIcon
                icon={<FacebookLogo />}
                link="https://www.facebook.com/MOTOREXOil"
              />
              <SocialMediaIcon
                icon={<InstagramLogo />}
                link="https://www.instagram.com/motorex_powersports"
              />
              <SocialMediaIcon
                icon={<YoutubeLogo />}
                link="https://www.youtube.com/MOTOREXOil"
              />
              <SocialMediaIcon
                icon={<FacebookPowersportslogo />}
                link="https://www.facebook.com/MOTOREXPowersports"
              />
            </SocialMediaBox>
          </Flex>
        </Card>
      </Box>
    </Flex>
  );
};
