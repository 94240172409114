import { Button, Card, Layout, Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from 'reflexbox';
import { css } from '~/theme';

const { Text } = Typography;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonRow = styled(Row)`
  justify-content: flex-end;
  align-items: baseline;
  margin-top: 48px;
  > button:last-of-type {
    margin-left: ${({ theme }) => theme.defaults.margin};
  }
`;

export const SplitInputRow = styled(Row)`
  > div {
    flex: 1;
    :last-of-type {
      margin-left: ${({ theme }) => theme.defaults.margin};
    }
  }
`;

interface IAlignedBoxProps {
  align: string;
}

export const AlignedBox = styled(Box)<IAlignedBoxProps>`
  text-align: ${({ align }) => align};
`;

export const DarkLink = styled(Link)`
  color: ${({ theme }) => theme.textColor};
`;

export const IconLink = styled(Link)`
  svg {
    vertical-align: middle;
  }
`;

export const BoldLink = styled(Link)`
  font-weight: bold;
`;

export const LinkStyleButton = styled(Button)`
  font-weight: normal;
`;

export const AuthenticationCard = styled(Card)`
  box-shadow: 7px 6px 30px -8px rgba(0, 0, 0, 0.75);
  border-radius: 7px;
`;

export const DashboardCard = styled(Card)`
  height: 100%;

  .ant-card-body {
    height: 100%;
  }
`;

export const FullWidthCard = styled(Card)`
  width: 100%;
`;

export const BlackLabel = styled(Text)`
  font-weight: 600;
`;

export const IconLabel = styled(BlackLabel)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const PrimaryText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const PrimaryBoldText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
`;

export interface IStyledButtonProps extends ButtonProps {
  padding?: string;
}

export const SecondaryButton = styled(Button)<IStyledButtonProps>`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  fill: ${({ theme }) => theme.colors.primary};
  box-shadow: 1px 1px 4px ${({ theme }) => theme.colors.grey.dark};
  font-size: 14px;
  text-shadow: none;
  min-height: 48px;
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

  :hover, :active, :focus {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const DisabledText = styled(Text)`
  color: ${({ theme }) => theme.colors.disabled};
`;

export const SmallButton = styled(Button)`
  font-weight: 600;
  font-size: 11px;
  padding-left: 0;
  padding-right: 0;
`;

export const responsiveBlockerStyle = css`
  @media (max-width: ${({ theme }) => theme.screenSmMax}) {
    overflow: hidden;
    height: 100vh;
  }
`;

export const ResponsiveLayout = styled(Layout)`
  ${responsiveBlockerStyle};
  @media (max-width: ${({ theme }) => theme.screenSmMax}) {
    overflow: hidden;
    height: 100vh;
  }
`;
