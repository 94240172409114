import React, { FunctionComponent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex } from 'reflexbox/styled-components';
import { useMachineQuery } from '~/components/apollo-components';
import { ISecondaryHeaderProps } from '~/components/Header/SecondaryHeader';
import { UserLayout } from '~/components/UserLayout';
import { DetailOverviewCard } from '~/pages/EasyMonitoring/MachineDetail/DetailOverviewCard';
import { MachineDropdown } from '~/pages/EasyMonitoring/MachineDetail/MachineDropdown';
import { MeasurementHistoryCard } from '~/pages/EasyMonitoring/MachineDetail/MeasurementHistoryCard';
import { MeasurementResultsCard } from '~/pages/EasyMonitoring/MachineDetail/MeasurementResultsCard';
import { Routes } from '~/utils/Routes';
import { boxCard } from '~/theme/variables';

export const MachineDetail: FunctionComponent = () => {
  const { id } = useParams();
  const { data, loading } = useMachineQuery({ variables: { id } });

  const secondaryHeaderOptions: ISecondaryHeaderProps = useMemo(() => {
    return {
      backLink: Routes.easyMonitoring.dashboard.base,
      additionalContent: (
        <MachineDropdown
          currentMachine={data?.machine}
          currentMachineLoading={loading}
        />
      ),
    };
  }, [data, loading]);

  return (
    <UserLayout secondaryHeaderOptions={secondaryHeaderOptions}>
      <Flex flexDirection="column">
        <Box>
          <Flex paddingBottom="1.75rem" flexWrap="wrap">
            <Box
              width={boxCard.width.oneThird}
              pr={boxCard.padding.horizontalXl}
              pb={boxCard.padding.verticalXl}
            >
              <DetailOverviewCard loading={loading} machine={data?.machine} />
            </Box>
            <Box
              width={boxCard.width.twoThird}
              pl={boxCard.padding.horizontalXl}
              pt={boxCard.padding.verticalXl}
            >
              <MeasurementResultsCard
                loading={loading}
                machine={data?.machine}
              />
            </Box>
          </Flex>
        </Box>
        <Box>
          <MeasurementHistoryCard />
        </Box>
      </Flex>
    </UserLayout>
  );
};
