import React, { FunctionComponent } from 'react';
import { MeasurementType } from '~/components/apollo-components';
import { styled } from '~/theme';
import { MeasurementTypeColorMap } from '~/utils/EasyMonitoring/colorMaps';

interface IColoredTextProps {
  measurementType: MeasurementType;
}

const ColoredText = styled.span<IColoredTextProps>`
  color: ${({ measurementType }) => MeasurementTypeColorMap[measurementType]};
  font-weight: bold;
  text-transform: uppercase;
  width: 376px;
  white-space: nowrap;
`;

interface IColoredMachineNameProps {
  measurementType: MeasurementType;
  content: string;
}

export const ColoredMachineName: FunctionComponent<IColoredMachineNameProps> = ({
  measurementType,
  content,
}: IColoredMachineNameProps) => {
  return <ColoredText measurementType={measurementType}>{content}</ColoredText>;
};
