import React, { FunctionComponent } from 'react';
import { AuthenticatedRoute, Route, Switch } from '~/components/Route';
import { Base } from '~/pages/Base';
import { Dashboard } from '~/pages/EasyMonitoring/Dashboard';
import {
  machineDetailPaths,
  MachineDetailRouter,
} from '~/pages/EasyMonitoring/MachineDetail';
import { Routes } from '~/utils/Routes';

export const EasyMonitoring: FunctionComponent = () => {
  return (
    <Switch>
      <Route
        path={Routes.easyMonitoring.dashboard.base}
        component={Dashboard}
        exact={true}
      />
      <AuthenticatedRoute
        path={machineDetailPaths}
        component={MachineDetailRouter}
        exact={true}
      />
      <Route path={Routes.easyMonitoring.base} component={Base} exact={true} />
    </Switch>
  );
};
