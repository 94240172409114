export const details = {
  'Add another graph': 'Ajouter un diagramme',
  'Alkalinity (A)': 'Alcalinité',
  'All latest measured values are in the normal range.':
    'Toutes les valeurs mesurées sont dans la norme',
  'Carbon Dioxide': 'Dioxyde de carbone',
  Chlorine: 'Chlore',
  'CO<1>2</1>': 'CO<1>2</1>',
  Concentration: 'Concentration',
  'Critical Alkalinity': 'Alcalinité critique',
  'Critical Chlorine': 'Quantité chlore critique',
  'Critical CO<1>2</1>': 'Quantité critique CO<1>2</1>',
  'Critical Hardness': 'Dureté totale critique',
  'Critical Nitrate': 'Quantité nitrate critique',
  'Critical Nitrite': 'Qunatité nitrite critique',
  'Critical pH-value': 'Valeur pH critique',
  'Critical Temperature': 'Température critique',
  Date: 'Date',
  Details: 'Détails',
  'Export Table': 'Exporter tableau ',
  'Hardness (H)': 'Dureté totale',
  'Hide graph': 'Masqué diagramme',
  History: 'Historique',
  'History in Numbers': 'Historique en chiffres',
  'Measurement Results': 'Résultats de mesures',
  Nitrate: 'Nitrate',
  Nitrite: 'Nitrite',
  'Noticeable Alkalinity': 'Alcalinité critique',
  'Noticeable Chlorine': 'Quantité pH critique',
  'Noticeable CO<1>2</1>': 'Quantité CO<1>2</1> critique',
  'Noticeable Hardness': 'Dureté totale critique',
  'Noticeable Nitrate': 'Quantité nitrate critique',
  'Noticeable Nitrite': 'Quantité nitrate critique',
  'Noticeable pH-value': 'Valeur pH critique',
  'Noticeable Temperature': 'Température perceptible',
  'pH-value': 'Valeur pH',
  Temperature: 'Température',
};
