import { Layout } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Flex } from 'reflexbox';
import { styled } from '~/theme';
import { boxCard, footerSm, screenXl } from '~/theme/variables';
import { Routes } from '~/utils/Routes';
import { AlignedBox } from '~/utils/styles';

const { Footer: AntFooter } = Layout;

interface IProps {
  authenticationLayout?: boolean;
}

const FooterWrapper = styled(AntFooter)`
  background: none;
  @media (max-width: ${screenXl}) {
    padding: 0 25px 28px 25px;
  }
`;

const FooterBox = styled(AlignedBox)<IProps>`
  a {
    color: ${({ theme, align, authenticationLayout }) =>
      authenticationLayout && align === 'left'
        ? theme.colors.white
        : theme.colors.black};

    padding: 0 1rem;

    :first-of-type {
      padding-left: 0;
    }

    :last-of-type {
      padding-right: 0;
    }
  }
  @media (max-width: ${footerSm}) {
    a {
      color: ${({ theme }) => theme.colors.black};
    }
    text-align: center;
  }
`;

const StyledLink = styled(Link)`
  white-space: nowrap;
`;

export const Footer: FunctionComponent<IProps> = ({
  authenticationLayout = false,
}: IProps) => {
  const { t } = useTranslation('staticPages');

  return (
    <FooterWrapper>
      <Flex
        flexDirection={{ lg: 'row', md: 'column' }}
        alignContent={{ md: 'center' }}
      >
        <FooterBox
          width={boxCard.width.oneThird}
          authenticationLayout={authenticationLayout}
          align="left"
        >
          <Link to={Routes.static.contact.base}>{t('Contact')}</Link>
          <Link to={Routes.static.faq.base}>{t('FAQ')}</Link>
        </FooterBox>
        <FooterBox width={boxCard.width.twoThird} align="right">
          <StyledLink to={Routes.static.imprint.base}>
            {t('Imprint')}
          </StyledLink>
          <StyledLink to={Routes.static.licenses.base}>
            {t('Licenses')}
          </StyledLink>
          <StyledLink to={Routes.static.terms.base}>
            {t('Terms and Conditions')}
          </StyledLink>
          <StyledLink to={Routes.static.privacy.base}>
            {t('Privacy Protection')}
          </StyledLink>
        </FooterBox>
      </Flex>
    </FooterWrapper>
  );
};
