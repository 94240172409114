import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, Flex } from 'reflexbox';
import { MeasurementGraph } from './MeasurementHistoryGraph/MeasurementGraph';
import { AddChartButton } from '../MeasurementResultsCard/AddChartButton';
import { MeasurementHistoryQuery } from '~/components/apollo-components';
import { LoadingOverlay } from '~/components/LoadingOverlay';
import styled from 'styled-components';
import { checkIfEmulsion } from '~/utils/EasyMonitoring/checkIfEmulsion';
import { MeasurementType } from '~/components/apollo-components';

import { ChemicalEnum } from '@motorex/common';

const RelativeBox = styled(Box)`
  position: relative;
`;

interface IMeasurementGraphProps {
  data: MeasurementHistoryQuery | undefined;
  isInitial: boolean;
  loading: boolean;
}

export const HistoryGraphs: FunctionComponent<IMeasurementGraphProps> = ({
  isInitial,
  data,
  loading,
}: IMeasurementGraphProps) => {
  const [listOfGraphs, setListOfGraphs] = useState<ChemicalEnum[]>([
    ChemicalEnum.HARDNESS,
  ]);

  const onRemoveGraphByIndex = useCallback(
    (index: number) => {
      const newGraphsList: ChemicalEnum[] = [...listOfGraphs];
      newGraphsList.splice(index, 1);
      setListOfGraphs(newGraphsList);
    },
    [listOfGraphs],
  );

  const onAddGraph = useCallback(() => {
    const newGraph: ChemicalEnum = ChemicalEnum.HARDNESS;
    setListOfGraphs([...listOfGraphs, newGraph]);
  }, [listOfGraphs]);

  const onChangeDropdownChemical = useCallback(
    (index: number, newValue: ChemicalEnum) => {
      const changedList: ChemicalEnum[] = [...listOfGraphs];
      changedList[index] = newValue;
      setListOfGraphs(changedList);
    },
    [listOfGraphs],
  );

  useEffect(() => {
    if (data) {
      const newListOfGraphs: ChemicalEnum[] =
        data.machine.measurementType === MeasurementType.EMULSION
          ? listOfGraphs.filter((currentChemical) => {
              return checkIfEmulsion(currentChemical as ChemicalEnum);
            })
          : listOfGraphs.filter((currentChemical) => {
              return (
                currentChemical !== ChemicalEnum.CONCENTRATION &&
                currentChemical !== ChemicalEnum.TEMPERATURE
              );
            });

      if (newListOfGraphs.length !== listOfGraphs.length) {
        newListOfGraphs.length > 0
          ? setListOfGraphs(newListOfGraphs)
          : setListOfGraphs([ChemicalEnum.HARDNESS]);
      }
    }
  }, [data, listOfGraphs]);

  return (
    <RelativeBox>
      {(loading || !data) && <LoadingOverlay />}
      {listOfGraphs.map((graphChemical, index) => {
        return (
          <MeasurementGraph
            isInitial={isInitial}
            data={data ?? null}
            index={index}
            onChangeDropdownChemical={onChangeDropdownChemical}
            currentChemical={graphChemical}
            onRemoveGraphByIndex={onRemoveGraphByIndex}
            key={graphChemical + '-' + index}
          />
        );
      })}

      <Flex justifyContent="flex-end">
        <Box>
          <AddChartButton onClick={onAddGraph} />
        </Box>
      </Flex>
    </RelativeBox>
  );
};
