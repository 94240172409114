import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Flex } from 'reflexbox';
import { useDeleteCompanyMutation } from './apollo-components';

interface IProps {
  companyId: string;
}

export const DeleteCompany: FC<IProps> = ({ companyId }: IProps) => {
  const { t } = useTranslation('Admin');
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const [deleteMutation] = useDeleteCompanyMutation();

  const deleteFunction = useCallback(
    (id) => {
      deleteMutation({ variables: { id } });
      history.push('/admin/companies');
    },
    [deleteMutation, history],
  );
  return (
    <>
      <Flex flexDirection="row-reverse" marginTop={2}>
        <Button danger size="large" onClick={openModal}>
          {t('Delete Company')}
        </Button>
      </Flex>
      <Modal
        title={t('Delet Company')}
        visible={open}
        onCancel={closeModal}
        onOk={() => deleteFunction(companyId)}
      >
        <p>{t('Are you sure you want to delete this company?')}</p>
      </Modal>
    </>
  );
};
