export const chemicals = {
  Alkalinity: 'Durezza carbonatica',
  Concentration: 'Concentrazione',
  'CO<1>2</1>': 'CO<1>2</1>',
  Hardness: 'Durezza totale',
  Nitrate: 'Nitrato',
  Nitrite: 'Nitrito',
  pH: 'pH',
  Chlorine: 'Cloro',
  Temperature: 'Temperatura',
};
