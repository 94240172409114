import { MeasurementType, StatusType } from '~/components/apollo-components';
import { colors } from '~/theme/variables';

export const StatusColorMap: Record<StatusType, string> = {
  [StatusType.NORMAL]: colors.green,
  [StatusType.NOTICEABLE]: colors.yellow,
  [StatusType.CRITICAL]: colors.red,
};

export const MeasurementTypeColorMap: Record<MeasurementType, string> = {
  [MeasurementType.EMULSION]: colors.yellow,
  [MeasurementType.WATER]: colors.blue,
};
