export const chemicals = {
  Alkalinity: 'Karbonhärte',
  Chlorine: 'Chlor',
  Concentration: 'Konzentration',
  'CO<1>2</1>': 'CO<1>2</1>',
  Hardness: 'Gesamthärte',
  Nitrate: 'Nitrat',
  Nitrite: 'Nitrit',
  pH: 'pH',
  Temperature: 'Temperatur',
};
