import { createContext } from 'react';
import { UserInformationFragment } from '~/components/apollo-components';

export interface IAuthenticationContext {
  currentUser: UserInformationFragment | null;
}

export const AuthenticationContext = createContext<IAuthenticationContext>({
  currentUser: null,
});
