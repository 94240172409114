import { Card } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox';
import { ReactComponent as Logo } from '~/assets/logo.svg';
import { css, styled } from '~/theme';
import { PrimaryText } from '~/utils/styles';

const overlayComponentStyle = css`
  max-width: 300px;
`;

const OverlayFlex = styled(Flex)`
  position: absolute;
  // Since ant design notifications have a z-index of 1010
  // we need to make sure, that we are a bit higher to prevent any display
  z-index: 1015;

  width: 100%;
  height: 100vh;
  padding: 1rem;

  background-color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.screenMd}) {
    display: none;
  }
`;

const OverlayLogo = styled(Logo)`
  ${overlayComponentStyle};
`;

const OverlayCard = styled(Card)`
  ${overlayComponentStyle};
  text-align: center;
`;

export const LowResolutionOverlay: FunctionComponent = () => {
  const { t } = useTranslation('common');

  return (
    <OverlayFlex flexDirection="column" justifyContent="center">
      <Flex justifyContent="center">
        <OverlayLogo width="100%" height="auto" />
      </Flex>
      <Flex justifyContent="center">
        <OverlayCard>
          <Box>
            <PrimaryText>
              {t(
                'The width of your screen is too small for this web application. Try to turn the device clockwise – this might help.',
              )}
            </PrimaryText>
          </Box>
        </OverlayCard>
      </Flex>
    </OverlayFlex>
  );
};
