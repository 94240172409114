import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Flex, Box } from 'reflexbox';
import { MeasurementHistoryQuery } from '~/components/apollo-components';
import { ChemicalEnum } from '@motorex/common';
import { MeasurementHistoryGraph } from '../MeasurementHistoryGraph';
import { GraphDropdownFilter } from '../MeasurementHistoryGraph/GraphDropdownFilter';
import { RemoveChartButton } from '../../MeasurementResultsCard/RemoveChartButton';

const { Title } = Typography;

interface IMeasurementGraphProps {
  isInitial: boolean;
  data: MeasurementHistoryQuery | null;
  index: number;
  currentChemical: ChemicalEnum;
  onChangeDropdownChemical: (index: number, newValue: ChemicalEnum) => void;
  onRemoveGraphByIndex: (index: number) => void;
}

export const MeasurementGraph: FunctionComponent<IMeasurementGraphProps> = ({
  isInitial,
  data,
  index,
  currentChemical,
  onChangeDropdownChemical,
  onRemoveGraphByIndex,
}: IMeasurementGraphProps) => {
  const { t } = useTranslation('EasyMonitoring');
  const [measurementFilter, setMeasurementFilter] = useState<ChemicalEnum>(
    currentChemical,
  );

  const onRemoveGraph = useCallback(() => {
    onRemoveGraphByIndex(index);
  }, [index, onRemoveGraphByIndex]);

  const onMeasurementFilterChange = useCallback(
    (filter: ChemicalEnum) => {
      onChangeDropdownChemical(index, filter);
    },
    [index, onChangeDropdownChemical],
  );

  const setNewMeasurementFilter = useCallback(
    (newChemical: ChemicalEnum) => {
      setMeasurementFilter(newChemical);
    },
    [setMeasurementFilter],
  );

  return (
    <Box>
      <Flex margin="1.5rem 0">
        <Box width={1 / 3}>
          <Title level={4}>
            {t('History')} {index + 1}
          </Title>
        </Box>
        <Box width={1 / 3}>
          <GraphDropdownFilter
            filter={measurementFilter}
            setFilter={onMeasurementFilterChange}
            measurementType={data ? data.machine.measurementType : null}
          />
        </Box>
        {index > 0 && <RemoveChartButton onClick={onRemoveGraph} />}
      </Flex>
      <MeasurementHistoryGraph
        initialData={data ?? null}
        data={data}
        isInitial={isInitial}
        selectedChemical={measurementFilter}
        setSelectedChemical={setNewMeasurementFilter}
      />
    </Box>
  );
};
