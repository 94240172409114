import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '~/theme';
import { SecondaryButton } from '~/utils/styles';
import { PlusOutlined } from '@ant-design/icons';

const ButtonText = styled.span`
  margin-left: 0.5rem;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-top: 1rem;
`;

interface IAddChartButtonProps {
  onClick: () => void;
}

export const AddChartButton: FunctionComponent<IAddChartButtonProps> = ({
  onClick,
}: IAddChartButtonProps) => {
  const { t } = useTranslation('EasyMonitoring');

  return (
    <StyledSecondaryButton
      onClick={onClick}
      icon={<PlusOutlined translate="no" />}
      type="primary"
    >
      <ButtonText>{t('Add another graph')}</ButtonText>
    </StyledSecondaryButton>
  );
};
