import {
  LOGIN_INVALID_CREDENTIALS_MESSAGE,
  USER_DEACTIVATED_MESSAGE,
  USER_NOT_VERIFIED,
} from '@motorex/common';
import { Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox';
import {
  Language,
  LoginMutationVariables,
  MeDocument,
  MeQuery,
  MeQueryVariables,
  useLoginMutation,
} from '~/components/apollo-components';
import { AuthenticationLayout } from '~/components/AuthenticationLayout';
import { LanguageMenu } from '~/components/LanguageMenu';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { Routes } from '~/utils/Routes';
import {
  AlignedBox,
  AuthenticationCard,
  ButtonRow,
  DarkLink,
} from '~/utils/styles';
import { validationSchema } from './validationSchema';

const { Title, Text } = Typography;
const { Item } = Form;
const { Password } = Input;

export const LoginPage: FunctionComponent = () => {
  const { t, i18n } = useTranslation(['auth', 'common']);
  const handleError = useErrorHandler();
  const [login, { loading }] = useLoginMutation({
    update(cache, { data }) {
      if (data == null) {
        return;
      }
      cache.writeQuery<MeQuery, MeQueryVariables>({
        data: {
          me: data.login,
        },
        query: MeDocument,
      });
    },
  });

  const onSubmit = useCallback(
    async (values: LoginMutationVariables) => {
      try {
        await login({ variables: values });
      } catch (e) {
        handleError(e, t('Login failed'), {
          [LOGIN_INVALID_CREDENTIALS_MESSAGE]: t(
            LOGIN_INVALID_CREDENTIALS_MESSAGE,
          ),
          [USER_DEACTIVATED_MESSAGE]: t(USER_DEACTIVATED_MESSAGE),
          [USER_NOT_VERIFIED]: t(USER_NOT_VERIFIED),
        });
      }
    },
    [t, login, handleError],
  );

  const valSchema = useMemo(() => validationSchema(t), [t]);
  const initialValues = useMemo(
    () => ({ email: '', password: '', language: i18n.language as Language }),
    [i18n.language],
  );

  return (
    <AuthenticationLayout>
      <AuthenticationCard>
        <Flex alignItems="baseline">
          <Box width={3 / 4}>
            <Title level={3}>{t('auth:Welcome!')}</Title>
          </Box>
          <AlignedBox align="right" width={1 / 4}>
            <DarkLink to={Routes.auth.register.base}>
              {t('auth:Register')}
            </DarkLink>
          </AlignedBox>
        </Flex>
        <Formik<LoginMutationVariables>
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={valSchema}
        >
          {({ isValid, setFieldValue }) => (
            <Form layout="vertical">
              <Item name="email" label={<Text>{t('common:Email')}</Text>}>
                <Input name="email" size="large" />
              </Item>
              <Item name="password" label={<Text>{t('common:Password')}</Text>}>
                <Password name="password" size="large" />
              </Item>
              <Flex alignItems="baseline" justifyContent="space-between">
                <Box width={1 / 2}>
                  <LanguageMenu
                    onLanguageChange={(language) =>
                      setFieldValue('language', language)
                    }
                  />
                </Box>
                <AlignedBox align="right" width={1 / 2}>
                  <ButtonRow>
                    <DarkLink to={Routes.auth.forgotPassword.base}>
                      {t('auth:Forgot password')}
                    </DarkLink>
                    <SubmitButton
                      loading={loading}
                      disabled={!isValid}
                      size="large"
                    >
                      {t('auth:Login')}
                    </SubmitButton>
                  </ButtonRow>
                </AlignedBox>
              </Flex>
            </Form>
          )}
        </Formik>
      </AuthenticationCard>
    </AuthenticationLayout>
  );
};
