import { TFunction } from 'i18next';
import { object, string } from 'yup';

export const validationSchema = (t: TFunction): any =>
  object().shape({
    email: string().email().label(t('Email Address')).required().max(128),
    firstName: string().label(t('First Name')).required().max(32),
    lastName: string().label(t('Last Name')).required().max(32),
    phoneNumber: string().label(t('Phone Number')).nullable().max(64),
  });
