export const faq = {
  'Can Easy Monitoring replace laboratory measurements?':
    'L’Easy Monitoring può sostituire le misure di laboratorio?',
  'Can I change limit values individually?':
    'Posso modificare i valori limite individualmente?',
  'Currently, these changes are not yet customizable, but will be possible in a next release.':
    'Attualmente queste modifiche non sono ancora personalizzabili, ma saranno possibili in una prossima release.',
  'For normal operation and TRGS 611 measurements (Technical Guideline for Hazardous Substances in Germany) the Easy Monitoring data is sufficient.':
    'I dati di Easy Monitoring sono sufficienti per il normale esercizio e per le misurazioni secondo TRGS 611 (Regole tecniche per le sostanze pericolose vigenti in Germania).',
  'How accurate are the measurements using sticks?':
    'Quanto precise sono le misurazioni per mezzo di strisce reattive? ',
  'How can I generate a QR code?': 'Come posso generare un codice QR?',
  'How long is the data stored on the web platform?':
    'Per quanto tempo i dati vengono memorizzati sulla piattaforma web?',
  'If no interferences are present, the measurements are almost identical to the laboratory values. (The water measurements as well as the emulsion measurements represent the free ions in the respective liquid. These can change the display value due to metal ions, which are released in copper or magnesium, for example).':
    'Se non ci sono interferenze, le misurazioni sono quasi identiche ai valori di laboratorio. (Le analisi dell’acqua e le analisi dell’emulsione rappresentano gli ioni liberi nel rispettivo liquido. Questi possono modificare il valore visualizzato a causa di ioni metallici rilasciati, ad esempio, dal rame o dal magnesio.)',
  'Laboratory tests are usually more extensive, since the same biological activities are also recorded. However, laboratory measurements are only taken if there are really compelling reasons. In normal KSS operation, the Easy Monitoring data are sufficient.':
    'Le analisi di laboratorio sono di norma più ampie, poiché rilevano anche le attività biologiche. Le analisi di laboratorio vengono però effettuate solo se vi sono ragioni veramente convincenti per farlo; nelle normali condizioni di utilizzo del fluido lubrorefrigerante i dati Easy Monitoring sono sufficienti.',
  'Naming of the machine/tank/container and the product/medium used. Then click on "Generate QR Code" and print it out on an adhesive label. From this point on, the respective QR code can be generated directly via sources in the app\'s selection menu.':
    'Dare un nome alla macchina, al serbatoio o alla confezione nonché al prodotto o fluido utilizzato. Poi cliccare su "Genera codice QR" e stampare il codice QR su un’etichetta adesiva. Da questo punto in poi, il rispettivo codice QR potrà essere generato direttamente tramite la voce "Sorgenti" nel menu di selezione dell’app.',
  'Questions?': 'Domande?',
  'The complete history of the entered data can be displayed and checked. In addition, it enables the display of liquid-relevant data for several users at the same time.':
    'La cronologia completa dei dati inseriti può essere visualizzata e controllata. Inoltre consente la visualizzazione di dati rilevanti dei liquidi per più utenti contemporaneamente.',
  'The data is stored for at least 10 years, but is only available on request for this period.':
    'I dati vengono conservati per almeno 10 anni, ma saranno disponibili per tale periodo solo su richiesta.',
  'The history of the entered data per machine/tank/container, with limitation to the corresponding period. All values displayed on the color sticks with corresponding limit ranges.':
    'La cronologia dei dati inseriti per ogni macchina, serbatoio o confezione, con limitazione al periodo corrispondente. Tutti i valori visualizzati sulle strisce reattive con le rispettive aree limite.',
  'The scan process with QR Code enables the clear identification of the "machine/tank" as well as the allocation of the corresponding product for recurring measurements.':
    'Il processo di scansione mediante codice QR consente l’identificazione univoca della macchina o del serbatoio e l’abbinamento del prodotto corrispondente per le misurazioni ricorrenti.',
  'These can be ordered directly from us via the MOTOREX homepage. Access can be obtained by means of a QR scan on the respective "MWF" or "WATER" tube.':
    'Queste possono essere ordinate direttamente da noi attraverso la home page di MOTOREX. L’accesso può essere richiamato tramite scansione QR sul rispettivo tubetto "MWF" o "WATER".',
  'We have already compiled the answers to the most frequently asked questions for you.':
    'Abbiamo già raccolto per te le risposte alle domande più frequenti.',
  'What can be simplified with QR Code?':
    'Cosa si può semplificare mediante la scansione del codice QR?',
  'What can I see in the course of the data?':
    'Cosa posso vedere nella cronologia dei dati?',
  'What is the web platform for?': 'A cosa serve la piattaforma web?',
  'Where do I get the test strips?': 'Dove posso reperire le strisce reattive?',
  'How can I delete my account for Easy Monitoring?':
    'Come posso eliminare il mio account Easy Monitoring?',
  'Please contact ':
    'Si prega di contattare ',
};
