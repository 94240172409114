import { Modal, Typography } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  name: string;
}

export const MachineDeleteModal: FC<IProps> = ({
  visible,
  onCancel,
  onOk,
  name,
}: IProps) => {
  const { t } = useTranslation('EasyMonitoring');

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={t('Delete source')}
    >
      <Typography>
        {t(`Are you sure you want to delete {{name}}?`, { name: name })}
      </Typography>
      <Typography>
        {t(`This will delete all associated measurements as well.`)}
      </Typography>
    </Modal>
  );
};
