import { Divider } from 'antd';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'reflexbox/styled-components';
import { ReactComponent as Logo } from '~/assets/logo.svg';
import {
  ISecondaryHeaderProps,
  SecondaryHeader,
} from '~/components/Header/SecondaryHeader';
import { AppNavigation, IMenuItem } from './AppNavigation';
import { css, styled } from '~/theme';
import { Routes } from '~/utils/Routes';
import { UserDropdown } from './UserDropdown';

interface IStyledHeaderProps {
  secondaryEnabled: boolean;
}

const StyledHeader = styled.header<IStyledHeaderProps>`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: ${({ secondaryEnabled }) => (secondaryEnabled ? '120px' : '60px')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
`;

const LogoLink = styled(Link)`
  height: 48px;
  margin: 0 5px;

  svg {
    height: 100%;
    width: auto;
  }
`;

const MainHeaderStyle = ({ secondaryEnabled }: IStyledHeaderProps) => {
  if (secondaryEnabled) {
    return css`
      height: 60px;
      position: fixed;
      width: 100%;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    `;
  }

  return css`
    height: 100%;
  `;
};

const MainHeaderFlex = styled(Flex)<IStyledHeaderProps>`
  justify-content: space-between;
  align-items: center;
  ${MainHeaderStyle};
`;

const HeaderDivider = styled(Divider)`
  margin: 0;
  height: 4rem;
`;

interface IHeaderProps {
  secondaryHeaderOptions?: ISecondaryHeaderProps;
  children?: React.ReactNode;
  menuItems: IMenuItem[];
}

export const Header: FunctionComponent<IHeaderProps> = ({
  secondaryHeaderOptions,
  children,
  menuItems,
}: IHeaderProps) => {
  const secondaryHeaderEnabled: boolean = secondaryHeaderOptions != null;
  return (
    <StyledHeader
      className="ant-layout-header"
      secondaryEnabled={secondaryHeaderEnabled}
    >
      <MainHeaderFlex secondaryEnabled={secondaryHeaderEnabled}>
        <LogoLink to={Routes.base}>
          <Logo />
        </LogoLink>
        <HeaderDivider type="vertical" />
        <AppNavigation menuItems={menuItems} />
        <Box flex="1" />
        {children}
        <HeaderDivider type="vertical" />
        <UserDropdown />
      </MainHeaderFlex>
      {secondaryHeaderOptions && (
        <SecondaryHeader
          backLink={secondaryHeaderOptions.backLink}
          additionalContent={secondaryHeaderOptions.additionalContent}
        />
      )}
    </StyledHeader>
  );
};
