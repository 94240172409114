import { CaretUpFilled } from '@ant-design/icons';
import {
  WaterChemical,
  EmulsionChemical,
  ChemicalEnum,
  WaterThresholds,
  EmulsionThresholds,
} from '@motorex/common';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox/styled-components';
import {
  ChemicalsEmulsionResponse,
  ChemicalsWaterResponse,
  MeasurementFragment,
  MeasurementType,
  StatusType,
} from '~/components/apollo-components';
import { styled } from '~/theme';
import { calculatePercentage } from '~/utils/calculatePercentage';
import { StatusColorMap } from '~/utils/EasyMonitoring/colorMaps';
import { getLabelTextForChemical } from '~/utils/EasyMonitoring/getLabelTextForChemical';
import {
  IThresholdEntry,
  normalizeThreshold,
} from '~/utils/EasyMonitoring/normalizeThreshold';
import { BlackLabel } from '~/utils/styles';

interface IColorStyleProps {
  status: StatusType;
}

interface IValueFlexPosition {
  measurementValuePosition: number;
}

const ColorBox = styled(Box)<IColorStyleProps>`
  background-color: ${({ status }) => StatusColorMap[status]};
`;

const LineBox = styled(Box)`
  position: relative;
  margin-bottom: 2rem;
`;

const LineWrapperFlex = styled(Flex)`
  width: 100%;
  height: 7px;
  background-color: ${({ theme }) => theme.colors.grey.medium};
`;

const ValueFlex = styled(Flex)<IValueFlexPosition>`
  position: absolute;
  top: 0;
  left: calc(
    -6.5px + ${({ measurementValuePosition }) => measurementValuePosition}%
  );
`;

const ValueText = styled.span`
  position: absolute;
  top: 8px;
`;

interface IMeasurementResultBarProps {
  chemicalKey: WaterChemical | EmulsionChemical;
  measurementType: MeasurementType;
  chemicals?: MeasurementFragment['chemicals'];
  unit?: string;
}

export const MeasurementResultBar: FunctionComponent<IMeasurementResultBarProps> = ({
  chemicalKey,
  chemicals,
  measurementType,
  unit,
}: IMeasurementResultBarProps) => {
  const { t } = useTranslation('EasyMonitoring');
  const { thresholdRanges, valuePosition } = useMemo(() => {
    const chemical =
      chemicals != null
        ? chemicals?.__typename === 'ChemicalsWaterResponse'
          ? (chemicals as ChemicalsWaterResponse)[chemicalKey as WaterChemical]
          : (chemicals as ChemicalsEmulsionResponse)[
              chemicalKey as EmulsionChemical
            ] ?? null
        : null;

    if (chemical === null) {
      return {
        thresholdRanges: [] as IThresholdEntry[],
        valuePosition: {
          label: null,
          value: null,
          position: null,
        },
      };
    }

    const threshold =
      measurementType === MeasurementType.WATER
        ? WaterThresholds[chemicalKey as WaterChemical]
        : EmulsionThresholds[chemicalKey as EmulsionChemical];

    const thresholdRanges = normalizeThreshold(threshold);

    const { overallRange } = threshold;
    const overallRangeValue = overallRange.max - overallRange.min;

    let valueForPosition = chemical?.measuredValue ?? 0;

    if (chemicalKey === ChemicalEnum.PH && valueForPosition >= 1) {
      valueForPosition -= 1;
    }

    const valuePosition = calculatePercentage(
      valueForPosition,
      overallRangeValue,
    );

    return {
      thresholdRanges,
      valuePosition: {
        label: chemical?.label,
        value: chemical?.measuredValue ?? 0,
        position: valuePosition,
      },
    };
  }, [chemicalKey, chemicals, measurementType]);

  return (
    <Box>
      <BlackLabel>{getLabelTextForChemical(t)[chemicalKey]}</BlackLabel>
      <LineBox>
        <LineWrapperFlex>
          {thresholdRanges.map(({ width, status }: IThresholdEntry, index) => (
            <ColorBox key={index} width={`${width}%`} status={status} />
          ))}
        </LineWrapperFlex>
        {valuePosition?.position != null && (
          <ValueFlex
            measurementValuePosition={
              valuePosition.position < 100 ? valuePosition.position : 100
            }
          >
            <CaretUpFilled translate="no" />
            <ValueText>
              {(valuePosition && valuePosition.value) ||
              (valuePosition && valuePosition.value === 0)
                ? valuePosition.value.toLocaleString()
                : null}
              {unit}
            </ValueText>
          </ValueFlex>
        )}
      </LineBox>
    </Box>
  );
};
