import { IThreshold, StatusEnum } from '@motorex/common';
import { StatusType } from '~/components/apollo-components';
import { calculatePercentage } from '~/utils/calculatePercentage';

const StatusEnumTypeMap: Record<StatusEnum, StatusType> = {
  [StatusEnum.CRITICAL]: StatusType.CRITICAL,
  [StatusEnum.NOTICEABLE]: StatusType.NOTICEABLE,
  [StatusEnum.NORMAL]: StatusType.NORMAL,
};

export interface IThresholdEntry {
  status: StatusType;
  min: number;
  width: number;
}

export const normalizeThreshold = (
  threshold: IThreshold,
): IThresholdEntry[] => {
  const { overallRange, ...actualThresholds } = threshold;

  const thresholdEntries: IThresholdEntry[] = [];
  Object.entries(actualThresholds).forEach(([status, { min, max }]) => {
    const overallRangeValue = overallRange.max - overallRange.min;
    if (Array.isArray(min) && Array.isArray(max)) {
      const [lowMin, lowMax] = min;
      const [highMin, highMax] = max;
      const limitedMax = highMax ?? overallRange.max;
      const limitedMin = lowMin ?? overallRange.min;

      const lowRange = lowMax - limitedMin;
      const highRange = limitedMax - highMin;
      const lowWidth = calculatePercentage(lowRange, overallRangeValue);
      const highWidth = calculatePercentage(highRange, overallRangeValue);

      thresholdEntries.push({
        status: StatusEnumTypeMap[status as StatusEnum],
        min: limitedMin,
        width: lowWidth,
      });
      thresholdEntries.push({
        status: StatusEnumTypeMap[status as StatusEnum],
        min: highMin,
        width: highWidth,
      });
    } else {
      const limitedMax = max ?? overallRange.max;
      const limitedMin = min ?? overallRange.min;
      const range = limitedMax - limitedMin;
      const width = calculatePercentage(range, overallRangeValue);
      thresholdEntries.push({
        status: StatusEnumTypeMap[status as StatusEnum],
        min,
        width,
      });
    }
  });

  return thresholdEntries.sort((a, b) => a.min - b.min);
};
